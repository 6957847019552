import { gql } from '@apollo/client'

const GET_CONDITIONS_COUNTERS = gql`
  query getConditionsCounters($input: ConditionsCountersInput!) {
    getConditionsCounters(input: $input) {
      conditionsCounters {
        availables
        sintomatic
        differentiated
        abandon
        illness
        injury
      }
      status {
        success
        code
        message
      }
    }
  }
`

export default GET_CONDITIONS_COUNTERS
