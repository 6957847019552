import { gql } from '@apollo/client'

const BLOCK_SCHEDULE = gql`
  mutation blockSchedule($input: BlockScheduleInput!) {
    blockSchedule(input: $input) {
      schedule {
        id_schedule_type
        id_user_profesional
        id_user_patient
        datetime
        Profesional {
          id
          name
          last_name
          UserType {
            name_user
          }
        }
      }
      status {
        success
        code
        message
      }
    }
  }
`

export default BLOCK_SCHEDULE
