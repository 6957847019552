import { gql } from '@apollo/client'

const CREATE_SINGLE_PLAYER_GPS = gql`
  mutation createSinglePlayerGps($input: CreateSinglePlayerGpsInput!) {
    createSinglePlayerGps(input: $input) {
      status {
        success
        code
        message
      }
    }
  }
`
export default CREATE_SINGLE_PLAYER_GPS

