import React, { useContext, useState } from 'react'
import Styles from './KinesicTreatmentTeamStyle'
import { TitleSection , CustomBreadcrumb, TeamTableFilter } from '../../components'
import KinesicTreatmentTeamTable from './KinesicTreatmentTeamTable'
import moment from 'moment'
import { appStore } from '../../store/appStore'

const KinesicTreatmentTeam = () => {
  const { state: { defaultCategoryType } } = useContext(appStore)
  const [filters, setFilters] = useState({
    id_category_type: defaultCategoryType,
    start_date: moment().startOf('day').format('YYYY-MM-DD'),
  })

  return(
    <Styles>
      <CustomBreadcrumb active="Treatamiento Kinésico" />
      <TitleSection title={'Tratamiento Kinésico'} />
      <TeamTableFilter
        filters={filters}
        setFilters={setFilters}
      />
      <KinesicTreatmentTeamTable
        filters={filters}
      />
    </Styles>
  )
}

export default KinesicTreatmentTeam
