import React from 'react'
import PropTypes from 'prop-types'
import { useTable, useBlockLayout } from 'react-table'
import { useSticky } from 'react-table-sticky'
import Styles from './StickyTableStyle'

function StickyTable ({
  columns,
  data,
  getRowProps = () => ({}),
  getCellProps = () => ({})
}) {

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 112,
      width: 112,
      maxWidth: 400
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    {
      columns,
      data,
      defaultColumn
    },
    useBlockLayout,
    useSticky
  )

  return (
    <Styles>
      <div
        {...getTableProps()}
        className="table sticky"
        // style={{ width: '100%', height: '70vh' }}
      >
        <div className="header">
          {headerGroups.map((headerGroup, key) => (
            <div key={key} {...headerGroup.getHeaderGroupProps()} className="tr">
              {headerGroup.headers.map((column) => (
                <div
                  key={key}
                  className="th"
                  // Return an array of prop objects and react-table will merge them appropriately
                  {...column.getHeaderProps([
                    {
                      className: column.className,
                      style: column.style,
                    },
                  ])}
                >
                  {column.render('Header')}
                </div>
              ))}
            </div>
          ))}
        </div>

        <div {...getTableBodyProps()} className="body">
          {rows.map((row, key) => {
            prepareRow(row)
            return (
              <div key={key} {...row.getRowProps(getRowProps(row))} className="tr">
                {row.cells.map((cell, i) => {
                  return (
                    <div
                      key={i}
                      className="td"
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getCellProps(cell)
                      ])}
                    >
                      {cell.render('Cell')}
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
    </Styles>
  )
}

StickyTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  getRowProps: PropTypes.func,
  getCellProps: PropTypes.func
}

export default StickyTable
