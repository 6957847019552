import styled from 'styled-components'

export default styled.div`
  .form-container {
    padding: 16px;
    max-width: 516px;
    margin: auto;
  }

  .form-title {
    margin-bottom: 16px;
  }
`
