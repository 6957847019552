import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'
import { GetSelectOptions } from '../../../components'

const SelectFilter = ({
  label = '',
  value = '',
  placeholder = '',
  id = '',
  name = '',
  queryName = '',
  mapName = '',
  query = null,
  options = null,
  handleChange,
  touched = false,
  error = false,
  ...rest
}) => {
  return (
    <Form.Group {...rest}>
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        as="select"
        id={id}
        name={name}
        value={value || ''}
        onChange={handleChange}
        isInvalid={(touched && error)}
      >
        {query &&
          <GetSelectOptions
            placeholder={placeholder}
            queryName={queryName}
            mapName={mapName}
            query={query}
          />
        }
        {options && options}
      </Form.Control>
    </Form.Group>
  )
}

SelectFilter.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleChange: PropTypes.func.isRequired,
  touched: PropTypes.bool,
  error: PropTypes.bool,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  queryName: PropTypes.string,
  mapName: PropTypes.string,
  query: PropTypes.object,
  options: PropTypes.object,
}

export default SelectFilter
