import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import { FaRegImage } from 'react-icons/fa'
import Styles from './DropZoneStyles'

function DropZone({
  setFiles,
  limit = 10,
  accept = 'image/jpeg, image/jpg, image/png',
  maxSize = 5 // En Megabytes
}) {
  const [limitError, setLimitError] = useState(false)
  const onDrop = useCallback(acceptedFiles => {
    setLimitError(false)
    setFiles((prevState) => {
      if ((prevState.length + acceptedFiles.length) > limit) {
        setLimitError(true)
        return prevState
      } else {
        return (
          prevState.concat(acceptedFiles)
        )
      }
    })
  }, [])

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    fileRejections
  } = useDropzone({
    onDrop,
    accept,
    maxSize: maxSize * 1000000
  })

  return (
    <Styles>
      <div className="dropzone zoom" {...getRootProps()}>
        <FaRegImage className="mx-2 mb-0" size={40} color={'#848484'} />
        <input {...getInputProps()} />
        {isDragActive ?
          <p className="mb-0">Arrastre y suelte su archivo aquí ...</p> :
          <p className="mb-0">Arrastre y suelte su archivo ó selecciónelo desde aquí.<br/> <span className="font-weight-bold">( Tamaño máximo por archivo: {maxSize}Mb )</span></p>
        }
      </div>
      {limitError &&
        <p className="text-danger ml-2 mb-0">Solo puedes cargar {limit} archivos a la vez.</p>
      }
      {fileRejections.map(({ file, errors }) => (
        errors.map(e =>
          e.code === 'file-too-large' &&
          <p className="text-danger ml-2 mb-0">El archivo {file.name} supera el tamaño máximo de {maxSize}Mb.</p>
        )
      ))}
    </Styles>
  )
}

DropZone.propTypes = {
  setFiles: PropTypes.func.isRequired,
  limit: PropTypes.number,
  accept: PropTypes.string,
  maxSize: PropTypes.number
}

export default DropZone
