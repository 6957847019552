import React from 'react'
import ReactToolTip from 'react-tooltip'
import Styles from './AvailabilityConditionStyle'

const AvailabilityConditions = () => {
  return (
    <Styles className="d-none d-md-flex mb-1 p-0">
      <div
        data-tip data-for="condition1"
        className="btn-circle color1 zoom">
      </div>
      <ReactToolTip
        id="condition1"
        place="bottom"
        effect="solid"
        type="info"
      >
        Apto para entrenar
      </ReactToolTip>

      <div
        data-tip data-for="condition2"
        className="btn-circle color2 zoom">
      </div>
      <ReactToolTip
        id="condition2"
        place="bottom"
        effect="solid"
        type="info"
      >
        Apto para entrenar sintomático
      </ReactToolTip>

      <div
        data-tip data-for="condition3"
        className="btn-circle color3 zoom">
      </div>
      <ReactToolTip
        id="condition3"
        place="bottom"
        effect="solid"
        type="info"
      >
        Entrenamiento diferenciado
      </ReactToolTip>

      <div
        data-tip data-for="condition4"
        className="btn-circle color4 zoom">
      </div>
      <ReactToolTip
        id="condition4"
        place="bottom"
        effect="solid"
        type="info"
      >
        Abandona entrenamiento / en obervación
      </ReactToolTip>

      <div
        data-tip data-for="condition5"
        className="btn-circle color5 zoom">
      </div>
      <ReactToolTip
        id="condition5"
        place="bottom"
        effect="solid"
        type="info"
      >
        Lesionado
      </ReactToolTip>

      <div
        data-tip data-for="condition6"
        className="btn-circle color6 zoom">
      </div>
      <ReactToolTip
        id="condition6"
        place="bottom"
        effect="solid"
        type="info"
      >
        Enfermo
      </ReactToolTip>

      <div
        data-tip data-for="condition7"
        className="btn-circle color7 zoom">
      </div>
      <ReactToolTip
        id="condition7"
        place="bottom"
        effect="solid"
        type="info"
      >
        Descanso
      </ReactToolTip>

      <div
        data-tip data-for="condition8"
        className="btn-circle color8 zoom">
      </div>
      <ReactToolTip
        id="condition8"
        place="bottom"
        effect="solid"
        type="info"
      >
        Permiso administrativo
      </ReactToolTip>

      <div
        data-tip data-for="condition9"
        className="btn-circle color9 zoom">
      </div>
      <ReactToolTip
        id="condition9"
        place="bottom"
        effect="solid"
        type="info"
      >
        Regenerativo/compensación física
      </ReactToolTip>

      <div
        data-tip data-for="condition20"
        className="btn-circle color20 zoom">
      </div>
      <ReactToolTip
        id="condition20"
        place="bottom"
        effect="solid"
        type="info"
      >
        Cuarentena
      </ReactToolTip>

      <div
        data-tip data-for="condition21"
        className="btn-circle color21 zoom">
      </div>
      <ReactToolTip
        id="condition21"
        place="bottom"
        effect="solid"
        type="info"
      >
        Liberado/fin proceso
      </ReactToolTip>

    </Styles>
  )
}

export default AvailabilityConditions
