import { gql } from '@apollo/client'

const CREATE_PLAYER_KINESIC_EVALUATION = gql`
  mutation createPlayerKinesicEvaluation($input: CreatePlayerKinesicEvaluationInput!) {
    createPlayerKinesicEvaluation(input: $input) {
      status {
          success
          code
          message
      }
    }
  }
`

export default CREATE_PLAYER_KINESIC_EVALUATION
