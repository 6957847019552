import React from 'react'
import { Link } from 'react-router-dom'
import { Form } from 'react-bootstrap'
import { AutoReportContext } from './AutoReportContext'
import AutoReportInput from './AutoReportInput'
import { UserImage } from '../../components'

const useAutoReportTable = (meters, rowState, setRowState) => {

  const { filters, windowDate } = React.useContext(AutoReportContext)

  const getRows = (player, meters) => {
    let rows = {}
    let totalSumWellness = {}
    let multiplicationPSE = {}

    const row = player.autoreport.meters.reduce((acc, meter) => {
      return {
        ...acc,
        [meter.id_meter]: {
          ...player.autoreport,
          ...meter
        }
      }
    }, {})

    meters.filter(meter => filters.id_dimension_test.includes(meter.DimensionTest.id)).forEach((type) => {
      if (type.id == 6 ||type.id == 215) {
        totalSumWellness = {
          ['totalSumWellness']: player.autoreport.aritmeticValues.find(
            a => filters.id_dimension_test.includes(a.idDimentsionTest.toString())
          )?.totalSumWellness

        }
      }

      if (type.id == 138 ||type.id == 143) {
        multiplicationPSE = {
          ['multiplicationPSE']: player.autoreport.aritmeticValues.find(
            a => filters.id_dimension_test.includes(a.idDimentsionTest.toString())
          )?.multiplicationPSE
        }
      }

      if (row[type.id]) {
        rows = {
          ...rows,
          [type.id]: {
            id_player: player.id,
            ...row[type.id],
            ...type
          }
        }
      } else {
        rows = {
          ...rows,
          [type.id]: {
            id: null,
            id_player: player.id,
            ...type
          }
        }
      }
    })

    return { ...rows, ...totalSumWellness, ...multiplicationPSE }
  }

  const columns = React.useMemo(() => [
    {
      Header: ' ',
      sticky: 'left',
      className: 'player',
      columns: [
        {
          Header: 'Deportista',
          accessor: 'player',
          className: 'player',
          Cell: function playerName({ cell: { value } }) {
            return(
              <Link to={`/player-info/${filters.id_category_type}/${value.id}/autoreport`}>
                <UserImage userId={value.userId} userGender={value.gender} imageWidth="30px" />
                {value.name}
              </Link>
            )
          }
        },
      ]
    },
    {
      Header: windowDate.name,
      columns: meters.filter(meter => filters.id_dimension_test.includes(meter.DimensionTest.id)).reduce((acc, meter) => {

        if (meter.id == 6 ||meter.id == 215) {
          acc.push({
            Header: 'Suma Total Wellness',
            accessor: 'totalSumWellness',
            Cell: function measurementValue({ cell: { value } }) {
              return (
                <Form.Control
                  type="text"
                  placeholder="--"
                  value={value || ''}
                  disabled
                />
              )
            }
          })
        }

        if (meter.id == 138 ||meter.id == 143) {
          acc.push({
            Header: 'Carga de Entrenamiento (UA)',
            accessor: 'multiplicationPSE',
            Cell: function measurementValue({ cell: { value } }) {
              return (
                <Form.Control
                  type="text"
                  placeholder="--"
                  value={value || ''}
                  disabled
                />
              )
            }
          })
        }

        acc.push(
          {
            Header: meter.name,
            accessor: meter.id,
            Cell: function measurementValue({ cell: { value, column } }) {
              return (
                <AutoReportInput
                  cell={value}
                  column={column}
                  rowState={rowState}
                  setRowState={setRowState}
                />
              )
            }
          }
        )
        return acc
      }, [])
    }
  ], [meters, windowDate, filters, rowState])

  return {
    columns,
    getRows
  }
}

export default useAutoReportTable
