import { gql } from '@apollo/client'

const GET_DASHBOARD_DIAGNOSTICS = gql`
  query getDashboardDiagnostics($input: DashboardDiagnosticInput) {
    getDashboardDiagnostics(input: $input) {
      status {
        success
        code
        message
      }
      dashboardData {
        allDiagnostics{
          soreness {
            x
            y
          }
          injury {
            x
            y
          }
          illness {
            x
            y
          }
          surgery {
            x
            y
          }
        }
        diagnosticTypes {
          x
          y
        }
        subDiagnostics {
          x
          y
        }
      }
    }
  }
`

export default GET_DASHBOARD_DIAGNOSTICS
