import { gql } from '@apollo/client'

const GET_AVAILABILITY_CONDITIONS_TYPES = gql`
  {
    getAvailabilityConditionsTypes {
      status {
        success
        code
        message
      }
      availabilityConditionsTypes {
        id
        name_availability_condition_type
      }
    }
  }
`

export default GET_AVAILABILITY_CONDITIONS_TYPES
