import { useState, useEffect } from 'react'
import axios from 'axios'

/**
* Hook to fetch data with axios
*
* @returns request states (data, loading, error)
*/
const useAxios = (options) => {
  const { url = null, triggers = null } = options || {}

  const [data, setData] = useState(null)
  const [error, setError] = useState('')
  const [loading, setloading] = useState(false)

  const resetRequest = () => {
    setData(null)
    setError('')
  }

  const fetchData = async (options) => {
    setloading(true)

    axios(options)
      .then((res) => {
        setData(res.data)
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setloading(false)
      })
  }

  useEffect(() => {
    if (url) {
      resetRequest()
      fetchData(options)
    }
  }, [triggers])

  return { data, error, loading, fetchData }
}

export default useAxios
