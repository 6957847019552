import styled from 'styled-components'

export default styled.div`

  .p{
    text-align: center;
    vertical-align: center;
  }

  .chart-bg {
    background-color: #dedede;
    width:360px;
    height:200px;
    margin: 10px;
  }

  .chart-bg-2 {
    background-color: #dedede;
    width:100%;
    height:250px;
    margin: 20px 5px;
  }

  .zoom {
    transition: transform .5s;
  }

  .zoom:hover {
    transform: scale(1.05);
  }

  div .player {
    width: 100px;
    min-width: 100px;
    padding: 8px !important;
    background-color: #FFFFFF;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    }
`
