import React from 'react'
import BiomechanicsProvider from './BiomechanicsContext'
import Biomechanics from './Biomechanics'
import { PlayerLayout } from '../../Layouts/PlayerLayout'
import { useLocation } from 'react-router'

// eslint-disable-next-line react/display-name
export default () => {
  const { pathname } = useLocation()

  if (pathname.split('/')[1] === 'biomechanic') {
    return (
      <BiomechanicsProvider>
        <PlayerLayout>
          <Biomechanics />
        </PlayerLayout>
      </BiomechanicsProvider>
    )
  } else {
    return (
      <BiomechanicsProvider>
        <Biomechanics />
      </BiomechanicsProvider>
    )
  }
}
