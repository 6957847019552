import styled from 'styled-components'

export default styled.div`
  width: 100%;
  height: calc(100vh - 70px);

  .btn-circle  {
    margin: 5px;
  }

  .tr {
    width: calc(100vw - 140px) !important;
    min-width: 1000px;
  }

  .selected {
    transform: scale(1.5);
  }

  .filter-btn {
    text-decoration: none;
    color: #142c59;
    border: none;
    outline: none;
    margin: 0 !important;
    padding-bottom: 14px !important;

    &:active {
      box-shadow: none !important;
    }
  }

  .proximos-eventos{
    text-decoration: underline;
  }

  .button {
    color: $btn-color;
    display: inline-block;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    background: none;
    background-image: none;
    border: 1px solid $btn-border;
    padding: .375rem 1rem;
    border-radius: 4px;
    line-height: normal;
    white-space: nowrap;

    &:active,
    &.rbc-active {
      background-image: none;
      box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
      background-color: $active-background;
      border-color: $active-border;

      &:hover,
      &:focus {
        color: $btn-color;
        background-color: darken($btn-bg, 17%);
        border-color: darken($btn-border, 25%);
      }
    }

    &:focus {
      color: $btn-color;
      background-color: $active-background;
      border-color: $active-border;
    }

    &:hover {
      color: $btn-color;
      background-color: $active-background;
      border-color: $active-border;
    }
  }
`
