import { gql } from '@apollo/client'

const DELETE_DIAGNOSTIC_MEDICNE = gql`
  mutation deleteDiagnosticMedicine($input: DeleteDiagnosticMedicinenput!) {
    deleteDiagnosticMedicine(input: $input) {
      status {
        success
        code
        message
      }
    }
  }
`

export default DELETE_DIAGNOSTIC_MEDICNE
