import { gql } from '@apollo/client'

const GET_EVALUATION_OBJECTIVES = gql`
  query getEvaluationObjectives($input: EvaluationObjectiveInput!) {
    getEvaluationObjectives(input: $input) {
      evaluationObjectives {
        id
        text
        date
        id_return_play
      }
      status {
        success
        code
        message
      }
    }
  }
`

export default GET_EVALUATION_OBJECTIVES

