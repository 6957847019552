import React from 'react'
import PropTypes from 'prop-types'
import { useMutation, useQuery } from '@apollo/client'
import { StickyTable, Loading } from '../../components'
import { GET_TEAM_KINESIC_TREATMENTS } from '../../apollo/queries'
import { ASSOCIATE_DIAGNOSTIC } from '../../apollo/mutations'
import { KinesicTreatmentTeamInput } from './KinesicTreatmentTeamInput'
import usePlayerDimension from '../PlayerInfo/usePlayerDimension'
import useKinesicTreatment from './useKinesicTreatment'

const KinesicTreatmentTeamTable = ({ filters }) => {
  const { allMeters } = usePlayerDimension(null, null, 16)

  const { data: teamKinesic, loading, error, refetch } = useQuery(GET_TEAM_KINESIC_TREATMENTS, {
    variables:{
      input:{
        id_category_type: filters.id_category_type,
        start_date: filters.start_date,
        end_date: filters.start_date,
      }
    },
    // fetchPolicy: 'network-only'
  })

  const [associateDiagnostic, associateDiagnosticResults] = useMutation(ASSOCIATE_DIAGNOSTIC)

  const handleAssociateDiagnostic = (associateDiagnosticInput) => {
    const { id_diagnostic, id_player_dimension, id_player, date, } = associateDiagnosticInput
    associateDiagnostic({
      variables: {
        input: {
          id_player: parseInt(id_player, 10),
          id_diagnostic: id_diagnostic ? parseInt(id_diagnostic, 10) : null,
          id_player_dimension: parseInt(id_player_dimension, 10),
          date
        }
      }
    }).then(() => {
      refetch() // TODO: check this
    })
  }

  const tableInputs = {
    allMeters,
    teamKinesicTreatments: teamKinesic?.getTeamKinesicTreatments?.teamKinesicTreatments,
    date: filters.start_date,
    InputCell: KinesicTreatmentTeamInput,
    playerInfoTab: 'nutrition-new',
    handleAssociateDiagnostic,
    associateDiagnosticResults
  }
  const { data, columns } = useKinesicTreatment(tableInputs)

  if (error) {
    return <div>Error :(</div>
  }

  if (loading) {
    return <Loading />
  }

  return(
    <StickyTable
      columns={columns}
      data={data}
      getCellProps={cellInfo => ({
        style: {
          background: cellInfo.row.index % 2 === 0 ? '#e0e6f2' : '#ffffff',
        },
        fixedColumns: {
          left: 1,
          right: 1
        },
      })}
    />
  )
}

KinesicTreatmentTeamTable.propTypes = {
  filters: PropTypes.shape({
    id_category_type: PropTypes.string,
    start_date: PropTypes.string,
  }).isRequired
}

export default KinesicTreatmentTeamTable
