import React, { useRef } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Modal, Button, Spinner, ListGroup } from 'react-bootstrap'
import { FaCalendar, FaCalendarDay, FaClock, FaStethoscope, FaUserAlt } from 'react-icons/fa'
import { useMutation } from '@apollo/client'
import { GET_SCHEDULES } from '../../../../apollo/queries'
import { CANCEL_SCHEDULE } from '../../../../apollo/mutations'
import { StatusAlert } from '../../../../components'
import Styles from './ScheduleInfoStyle'
import AuthorizedElement from '../../../../keycloak/utils/AuthorizedElement'

const ScheduleInfo = ({ schedule = {}, openModal, setOpenModal }) => {
  const modalHeaderRef = useRef(null)

  const [cancelSchedule, cancelResults] = useMutation(CANCEL_SCHEDULE, {
    refetchQueries: [
      { query: GET_SCHEDULES }
    ]
  })

  const handleCancelSchedule = (event) => {
    event.preventDefault()
    if (window.confirm('Esta acción es irreversible, ¿Estás seguro de cancelar esta consulta?')) {
      cancelSchedule({
        variables: {
          input: {
            id: schedule.id
          }
        }
      })
    }
  }

  const handleClose = () => setOpenModal(false)

  if (cancelResults.error) {
    return (
      <div>Error :(</div>
    )
  }

  return (
    <Modal
      size="lg"
      show={openModal}
      onHide={handleClose}
      dialogClassName="full-width"
      contentClassName="full-height"
    >
      <Styles>
        <Modal.Header closeButton ref={modalHeaderRef} className="bg-success text-white">
          <Modal.Title>
            <FaCalendarDay className="mr-1 mb-2" color={'white'} />
            {'Información de Consulta'}
          </Modal.Title>
        </Modal.Header>

        {cancelResults.data &&
          <StatusAlert
            successMessage={'Consulta cancelada con éxito.'}
            status={cancelResults.data.cancelSchedule.status}
          />
        }
        <Modal.Body className="pb-0">
          <ListGroup variant="flush">
            <ListGroup.Item>
              <FaStethoscope size={24} className="mr-3" />
              {schedule.Profesional.name} {schedule.Profesional.last_name}
            </ListGroup.Item>
            <ListGroup.Item>
              <FaUserAlt size={24} className="mr-3" />
              {schedule.Patient.name} {schedule.Patient.last_name}
            </ListGroup.Item>
            <ListGroup.Item>
              <FaCalendar size={24} className="mr-3" />
              {moment(schedule.datetime).calendar()}
            </ListGroup.Item>
            <ListGroup.Item>
              <FaClock size={24} className="mr-3" />
              {schedule.duration} {'minutos'}
            </ListGroup.Item>
          </ListGroup>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-end">
          <Button
            variant="secondary"
            onClick={() => handleClose()}
          >
            {'Cerrar'}
          </Button>
          {moment(schedule.datetime).isAfter() && (
            !cancelResults?.data?.cancelSchedule.status.success && (
              <AuthorizedElement roles={['permission_calendar-cancel-schedule']}>
                <Button
                  variant="outline-danger"
                  onClick={(event) => handleCancelSchedule(event)}
                >
                  {cancelResults.loading ?
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    : ('Cancelar Consulta')
                  }
                </Button>
              </AuthorizedElement>
            )
          )}
        </Modal.Footer>
      </Styles>
    </Modal>
  )
}

ScheduleInfo.propTypes = {
  schedule: PropTypes.shape({}),
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired
}

export default ScheduleInfo
