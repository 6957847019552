import { gql } from '@apollo/client'

const GET_PLAYER_KINESIC_EVALUATIONS = gql`
  query getPlayerKinesicEvaluations($input: PlayerKinesicEvaluationInput!) {
    getPlayerKinesicEvaluations(input: $input) {
      playerKinesicEvaluations {
        id
        id_player
        id_kinesic_blocks
        values
        date
        KinesicBlock {
          id
          name
        }
      }
      status {
        success
        code
        message
      }
    }
  }
`
export default GET_PLAYER_KINESIC_EVALUATIONS

