/* eslint-disable react/prop-types */
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import { useMutation } from '@apollo/client'
import { CREATE_SINGLE_PLAYER_DIMENSION } from '../../../../apollo/mutations'
import { useDimensionRules, useFormatsHelper } from '../../../../hooks'
import { NUTRITION_DIMENSION } from '../constants'
import hasRole from '../../../../keycloak/utils/hasRole'
import { appStore } from '../../../../store/appStore'

const NutritionInput = ({ cellInfo, column }) => {
  const { state: { tenant } } = useContext(appStore)

  const tenantCode = tenant?.code
  const dimensionId = NUTRITION_DIMENSION
  const categoryId = cellInfo.categoryId
  const positionTypeId = cellInfo.positionTypeId
  const meterId = parseInt(column.id)

  const { rules, runRules } = useDimensionRules({ tenantCode, dimensionId, categoryId, positionTypeId, meterId })
  // const rules = undefined
  // const runRules = () => {}

  const Input = ({ inputValue, setInputValue }) => {
    const [existId, setExistId] = React.useState(cellInfo?.id ? true : false)
    const [success, setSuccess] = React.useState(false)
    const [createPlayerDimendion, createPlayerDimensionResult] = useMutation(CREATE_SINGLE_PLAYER_DIMENSION)

    const handleSuccess = () => {
      setSuccess(true)
      setExistId(true)
      setTimeout(() => setSuccess(false), 2000)
    }

    const handleBlur = () => {
      if ((!inputValue && existId) || inputValue) {
        createPlayerDimendion({
          variables: {
            input: {
              id_dimension: NUTRITION_DIMENSION,
              id_player: parseInt(cellInfo.id_player, 10),
              meter_id: parseInt(column.id, 10),
              meter_value: inputValue,
              date: cellInfo.date,
            }
          }
        }).then(({ data }) => {
          if (data.createSinglePlayerDimension.status.success) {
            handleSuccess()
          }
        })
      }
    }

    if (createPlayerDimensionResult.loading) {
      return (
        <div>Cargando...</div>
      )
    }
    if (hasRole(['permission_team-create-nutritional-new', 'permission_team-create-massive-nutri', 'permission_team-update-nutritional-new', 'permission_team-update-massive-nutri'])) {
      return (
        <input
          type="text"
          placeholder="--"
          style={
            success ? (
              { border: 'green 2px solid' }
            ) : (createPlayerDimensionResult.error) ? (
              { border: 'red 2px solid' }
            ) : {}
          }
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={() => handleBlur()}
        />
      )
    }
    return (
      <div>{inputValue}</div>
    )
  }

  const TooltipInput = ({ withTooltip = true }) => {
    const [inputValue, setInputValue] = React.useState(cellInfo?.cellValue)
    const { parseFloatValue } = useFormatsHelper()
    const { clasification, color } = runRules(rules, parseFloatValue(inputValue)) || {}
    return (
      <>
        {withTooltip ? (
          <div
            data-tip
            data-for={`level-${column.id}-${cellInfo.id_player}-${cellInfo.date}`}
            style={{ background: color, height: '100%' }}
          >
            <Input
              inputValue={inputValue}
              setInputValue={setInputValue}
            />
            {clasification && (
              <ReactTooltip
                place="right"
                effect="solid"
                type="info"
                id={`level-${column.id}-${cellInfo.id_player}-${cellInfo.date}`}
              >
                {clasification}
              </ReactTooltip>
            )}
          </div>
        ) : (
          <Input
            inputValue={inputValue}
            setInputValue={setInputValue}
          />
        )}
      </>
    )
  }

  return (
    <>
      {rules ? (
        <TooltipInput/>
      ) : (
        <TooltipInput withTooltip={false}/>
      )}
    </>
  )
}

NutritionInput.propTypes = {
  cellInfo: PropTypes.shape({
    id: PropTypes.string,
    cellValue: PropTypes.string,
    id_player: PropTypes.string,
    date: PropTypes.string
  }).isRequired,
  column: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  filters: PropTypes.shape({
    id_gps_moment: PropTypes.number,
    id_gps_moment_desc: PropTypes.number,
    activity: PropTypes.number
  }).isRequired
}

export default NutritionInput
