import styled from 'styled-components'

export default styled.div`
  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
    border-color: #ccc #ccc #fff;
  }

  .table-striped{
  background-color: #e0e6f2;
}

  .nav.nav.nav-tabs {
    min-width: 320px;
  }

  .filter-btn {
    position: initial
  }

  /* h3 {text-align: center; position: relative; background-color: #fff; color: #6c757d}
  h3 .line {border-bottom: 1px solid #6c757d; position: absolute; width: 100%; left: 0; top: 50%;  z-index: 1;}
  h3 .text {background-color: #fff; position: relative; z-index: 2; padding: 10px; display: inline-block;} */


`
