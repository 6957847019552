import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { useParams } from 'react-router-dom'
import { useQuery, useLazyQuery } from '@apollo/client'
import {
  GET_DIAGNOSTIC_TYPES,
  GET_SUB_DIAGNOSTICS,
  GET_PATHOLOGIES,
  GET_DIAGNOSTIC_COMPLEMENTS,
  GET_SKELETAL_MUSCLES,
  GET_BODY_ZONES,
  GET_PLEXO_SYSTEMS,
  GET_PROCEDURE_MATERIALS,
  GET_AVAILABILITY_CONDITIONS_TYPES
} from '../../apollo/queries'
import { Form, Col } from 'react-bootstrap'
import Styles from './DiagnosisFormStyle'

const id_diagnostic_type = 1
const INJURY_DIAGNOTIC_TYPE_ID = 2
const NON_EDITABLE_DIAGNOSTICS = [id_diagnostic_type, INJURY_DIAGNOTIC_TYPE_ID]

const DiagnosisForm = ({
  values,
  touched,
  errors,
  setFieldValue,
  setTouched,
  handleChange,
  id_diagnostic_type = null,
  id_sub_diagnostic = null
}) => {
  const { action } = useParams()

  const diagnosticTypes = useQuery(GET_DIAGNOSTIC_TYPES)
  const availabilityTypes = useQuery(GET_AVAILABILITY_CONDITIONS_TYPES)
  const [subDiagnosticQuery, subDiagnostics] = useLazyQuery(GET_SUB_DIAGNOSTICS)
  const [pathologiesQuery, pathologies] = useLazyQuery(GET_PATHOLOGIES)
  const [diagnosticComplementsQuery, diagnosticComplements] = useLazyQuery(GET_DIAGNOSTIC_COMPLEMENTS)
  const [skeletalMusclesQuery, skeletalMuscles] = useLazyQuery(GET_SKELETAL_MUSCLES)
  const [bodyZonesQuery, bodyZones] = useLazyQuery(GET_BODY_ZONES)
  const [plexoSystemsQuery, plexoSystems] = useLazyQuery(GET_PLEXO_SYSTEMS)
  const [procedureMaterialsQuery, procedureMaterials] = useLazyQuery(GET_PROCEDURE_MATERIALS)


  const handleQueries = (id_diagnostic_type, id_sub_diagnostic) => {
    subDiagnosticQuery({
      variables: { input: { id_diagnostic_type } }
    })
    pathologiesQuery({
      variables: {
        input: { id_diagnostic_type, id_sub_diagnostic } }
    })
    diagnosticComplementsQuery({
      variables: { input: { id_diagnostic_type,id_sub_diagnostic } }
    })
    skeletalMusclesQuery({
      variables: { input: { id_sub_diagnostic } }
    })
    bodyZonesQuery({
      variables: { input: { id_sub_diagnostic } }
    })
    plexoSystemsQuery({
      variables: { input: { id_sub_diagnostic } }
    })
    procedureMaterialsQuery({
      variables: { input: { id_sub_diagnostic } }
    })
  }

  React.useEffect(() => {
    if (id_diagnostic_type && id_sub_diagnostic) {
      handleQueries(id_diagnostic_type, id_sub_diagnostic)
    }
  }, [id_diagnostic_type, id_sub_diagnostic])

  const errorStyle = {
    control: styles => ({
      ...styles,
      borderColor: '#dc3545 !important'
    })
  }

  const diagnosticOptions = diagnosticTypes.data?.getDiagnosticTypes.diagnosticTypes.reduce(
    (acc, current) => ([...acc, { value: current.id, label: current.name } ])
    , [{ value: '', label: 'Selecciona una categoría' }]
  )

  const subDiagnosticOptions = subDiagnostics.data?.getSubDiagnostics.subDiagnostics.reduce(
    (acc, current) => ([...acc, { value: current.id, label: current.name } ])
    , [{ value: '', label: 'Selecciona una sub-categoría' }]
  ) || [{ value: '', label: 'Selecciona una sub-categoría' }]

  const pathologiesOptions = pathologies.data?.getPathologies.pathologies.reduce(
    (acc, current) => ([...acc, { value: current.id, label: current.name } ])
    , [{ value: '', label: 'Selecciona un diagnóstico' }]
  ) || [{ value: '', label: 'Selecciona un diagnóstico' }]

  const diagnosticComplementsOptions = diagnosticComplements.data?.getDiagnosticComplements.diagnosticComplements.reduce(
    (acc, current) => ([...acc, { value: current.id, label: current.name } ])
    , [{ value: '', label: 'Selecciona un complemento I' }]
  ) || [{ value: '', label: 'Selecciona un complemento I' }]

  const skeletalMuscleOptions = skeletalMuscles.data?.getSkeletalMuscles.skeletalMuscles?.reduce(
    (acc, current) => ([...acc, { value: current.id, label: current.name } ])
    , [{ value: '', label: 'Selecciona un complemento II' }]
  ) || [{ value: '', label: 'Selecciona un complemento II' }]

  const bodyZonesOptions = bodyZones.data?.getBodyZones.bodyZones?.reduce(
    (acc, current) => ([...acc, { value: current.id, label: current.name } ])
    , [{ value: '', label: 'Selecciona una articulación/ligamento' }]
  ) || [{ value: '', label: 'Selecciona una articulación/ligamento' }]

  const plexoSystemsOptions = plexoSystems.data?.getPlexoSystems.plexoSystems?.reduce(
    (acc, current) => ([...acc, { value: current.id, label: current.name } ])
    , [{ value: '', label: 'Selecciona un sistema plexo' }]
  ) || [{ value: '', label: 'Selecciona un sistema plexo' }]

  const procedureMaterialOptions = procedureMaterials.data?.getProcedureMaterials.procedureMaterials?.reduce(
    (acc, current) => ([...acc, { value: current.id, label: current.name } ])
    , [{ value: '', label: 'Selecciona un procedimiento/materiales/reflejo' }]
  ) || [{ value: '', label: 'Selecciona un procedimiento/materiales/reflejo' }]

  const availabilityConditionOptions = availabilityTypes.data?.getAvailabilityConditionsTypes.availabilityConditionsTypes?.reduce(
    (acc, current) => ([...acc, { value: current.id, label: current.name_availability_condition_type } ])
    , [{ value: '', label: 'Selecciona una condición' }]
  )

  const sideOptions = [
    { value: '1', label: 'Derecha' },
    { value: '2', label: 'Izquierda' },
    { value: '3', label: 'Izquierda y derecha' },
    { value: '4', label: 'No aplica' }
  ]

  const [selectedValues, setSelectedValues] = React.useState(values)

  return (
    <React.Fragment>
      <Styles>
        <p>(*): Obligatorio</p>
        <div className="row">
          <div className="col col-md-6">
            <Form.Row>
              <Form.Group as={Col} controlId="Category" className="col-12 col-md-6">
                <Form.Label>Categoría*</Form.Label>
                <Select
                  isClearable
                  isSearchable
                  placeholder=""
                  name="id_diagnostic_type"
                  noOptionsMessage={() => 'No se encontraron coincidencias'}
                  isDisabled={(action === 'edit' && NON_EDITABLE_DIAGNOSTICS.includes(parseInt(id_diagnostic_type, 10)))}
                  isLoading={diagnosticTypes.loading}
                  value={diagnosticOptions?.find(option => option.value === values.id_diagnostic_type )}
                  styles={(touched.id_diagnostic_type && !selectedValues.id_diagnostic_type) ? errorStyle : {}}
                  onChange={(option) => {
                    setSelectedValues((prevState) => ({ ...prevState, 'id_diagnostic_type': option?.value ||'' }))
                    setFieldValue('id_diagnostic_type', option?.value ||'')
                    setFieldValue('id_sub_diagnostic', '')
                    setFieldValue('id_pathology', '')
                    handleQueries(
                      parseInt(option?.value, 10),
                      parseInt(values.id_sub_diagnostic, 10)
                    )
                  }}
                  onBlur={() => setTouched({ ...touched, 'id_diagnostic_type': true })}
                  options={diagnosticOptions}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="Subcategory" className="col-12 col-md-6">
                <Form.Label>Categoría II*</Form.Label>
                <Select
                  isClearable
                  isSearchable
                  placeholder=""
                  name="id_sub_diagnostic"
                  noOptionsMessage={() => 'No se encontraron coincidencias'}
                  isLoading={subDiagnostics.loading}
                  value={subDiagnosticOptions?.find(option => option.value === values.id_sub_diagnostic)}
                  styles={(touched.id_sub_diagnostic && !selectedValues.id_sub_diagnostic) ? errorStyle : {}}
                  options={subDiagnosticOptions}
                  onBlur={() => setTouched({ ...touched, 'id_sub_diagnostic': true })}
                  onChange={(option) => {
                    setSelectedValues((prevState) => ({ ...prevState, 'id_sub_diagnostic': option?.value ||'' }))
                    setFieldValue('id_sub_diagnostic', option?.value ||'')
                    setFieldValue('id_pathology', '')
                    pathologiesQuery({
                      variables:
                            {
                              input: {
                                id_diagnostic_type: parseInt(values.id_diagnostic_type, 10),
                                id_sub_diagnostic: parseInt(option?.value, 10)
                              }
                            }
                    })
                    setFieldValue('id_diagnostic_complement', '')
                    diagnosticComplementsQuery({
                      variables:
                            {
                              input: {
                                id_diagnostic_type: parseInt(values.id_diagnostic_type, 10),
                                id_sub_diagnostic: parseInt(option?.value, 10)
                              }
                            }
                    })
                    setFieldValue('id_skeletal_muscle', '')
                    skeletalMusclesQuery({
                      variables:
                            {
                              input: {
                                id_sub_diagnostic: parseInt(option?.value, 10)
                              }
                            }
                    })
                    setFieldValue('id_body_zone', '')
                    bodyZonesQuery({
                      variables:
                            {
                              input: {
                                id_sub_diagnostic: parseInt(option?.value, 10)
                              }
                            }
                    })
                    setFieldValue('id_plexo_system', '')
                    plexoSystemsQuery({
                      variables:
                            {
                              input: {
                                id_sub_diagnostic: parseInt(option?.value, 10)
                              }
                            }
                    })
                    setFieldValue('id_procedure_material', '')
                    procedureMaterialsQuery({
                      variables:
                            {
                              input: {
                                id_sub_diagnostic: parseInt(option?.value, 10)
                              }
                            }
                    })
                  }}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="Pathology" className="col-12 col-md-6">
                <Form.Label>Diagnóstico*</Form.Label>
                <Select
                  isClearable
                  isSearchable
                  placeholder=""
                  name="id_pathology"
                  noOptionsMessage={() => 'No se encontraron coincidencias'}
                  isLoading={pathologies.loading}
                  value={pathologiesOptions?.find(option => option.value === values.id_pathology )}
                  styles={(touched.id_pathology && !selectedValues.id_pathology) ? errorStyle : {}}
                  onChange={(option) => {
                    setSelectedValues((prevState) => ({ ...prevState, 'id_pathology': option?.value ||'' }))
                    setFieldValue('id_pathology', option?.value ||'')
                  }}
                  onBlur={() => setTouched({ ...touched, 'id_pathology': true })}
                  options={pathologiesOptions}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="Complement" className="col-12 col-md-6">
                <Form.Label>Complemento I</Form.Label>
                <Select
                  isClearable
                  isSearchable
                  placeholder=""
                  name="id_diagnostic_complement"
                  noOptionsMessage={() => 'No se encontraron coincidencias'}
                  isLoading={diagnosticComplements.loading}
                  isDisabled={!diagnosticComplements.data?.getDiagnosticComplements.diagnosticComplements.length}
                  // styles={(touched.id_diagnostic_complement && !selectedValues.id_diagnostic_complement && diagnosticComplements.data?.getDiagnosticComplements.diagnosticComplements.length) ? errorStyle : {}}
                  value={diagnosticComplementsOptions?.find(option => option.value === values.id_diagnostic_complement )}
                  onChange={(option) => {
                    setSelectedValues((prevState) => ({ ...prevState, 'id_diagnostic_complement': option?.value ||'' }))
                    setFieldValue('id_diagnostic_complement', option?.value ||'')
                  }}
                  onBlur={() => setTouched({ ...touched, 'id_diagnostic_complement': true })}
                  options={diagnosticComplementsOptions}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="SkeletalMuscle" className="col-12 col-md-6">
                <Form.Label>
                  {'Complemento II'}
                  {`${skeletalMuscles.data?.getSkeletalMuscles.skeletalMuscles?.length && (touched.id_skeletal_muscle && errors.id_skeletal_muscle) ? '*' : ''}`}
                </Form.Label>
                <Select
                  isClearable
                  isSearchable
                  placeholder=""
                  name="id_skeletal_muscle"
                  noOptionsMessage={() => 'No se encontraron coincidencias'}
                  isLoading={skeletalMuscles.loading}
                  isDisabled={!skeletalMuscles.data?.getSkeletalMuscles.skeletalMuscles?.length}
                  styles={(touched.id_skeletal_muscle && errors.id_skeletal_muscle) ? errorStyle : {}}
                  value={skeletalMuscleOptions?.find(option => option.value === values.id_skeletal_muscle )}
                  onChange={(option) => {
                    setTouched({ ...touched, 'id_skeletal_muscle': true })
                    // handleSelectedValues('id_skeletal_muscle', option)
                    setFieldValue('id_skeletal_muscle', option?.value ||'')
                  }}
                  onBlur={() => setTouched({ ...touched, 'id_sub_diagnostic': true })}
                  options={skeletalMuscleOptions}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="Side" className="col-12 col-md-6">
                <Form.Label>Lateralidad*</Form.Label>
                <Select
                  isClearable
                  isSearchable
                  placeholder=""
                  name="side"
                  noOptionsMessage={() => 'No se encontraron coincidencias'}
                  styles={(touched.side && errors.side) ? errorStyle : {}}
                  value={sideOptions?.find(option => option.value == values.side )}
                  onChange={(option) => {
                    setFieldValue('side', option?.value ||'')
                  }}
                  onBlur={() => setTouched({ ...touched, 'side': true })}
                  options={sideOptions}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="joint" className="col-12 col-md-6">
                <Form.Label>Sistema Articular</Form.Label>
                <Select
                  isClearable
                  isSearchable
                  placeholder=""
                  name="id_joint_ligament"
                  noOptionsMessage={() => 'No se encontraron coincidencias'}
                  isLoading={bodyZones.loading}
                  isDisabled={!bodyZones.data?.getBodyZones.bodyZones?.length}
                  // styles={(touched.id_joint_ligament && errors.id_joint_ligament) ? errorStyle : {}}
                  value={bodyZonesOptions?.find(option => option.value == values.id_joint_ligament )}
                  onChange={(option) => {
                    setFieldValue('id_joint_ligament', option?.value ||'')
                  }}
                  onBlur={() => setTouched({ ...touched, 'id_joint_ligament': true })}
                  options={bodyZonesOptions}
                />
              </Form.Group>
              <Form.Group as={Col} className="col-12 col-md-6">
                <Form.Label>Sistema Plexo</Form.Label>
                <Select
                  isClearable
                  isSearchable
                  placeholder=""
                  name="id_plexo_system"
                  noOptionsMessage={() => 'No se encontraron coincidencias'}
                  isLoading={plexoSystems.loading}
                  isDisabled={!plexoSystems.data?.getPlexoSystems.plexoSystems?.length}
                  styles={(touched.id_plexo_system && errors.id_plexo_system) ? errorStyle : {}}
                  value={plexoSystemsOptions?.find(option => option.value == values.id_plexo_system )}
                  onChange={(option) => {
                    setFieldValue('id_plexo_system', option?.value ||'')
                  }}
                  onBlur={() => setTouched({ ...touched, 'id_plexo_system': true })}
                  options={plexoSystemsOptions}
                />
              </Form.Group>
              {values.id_diagnostic_type === '2' && values.id_sub_diagnostic === '6' &&
                <>
                  <Form.Group as={Col} controlId="Text" className="p-0 m-0">
                    <Form.Label>Dimensión Anteroposterior</Form.Label>
                    <Form.Control
                      name="injury_muscle_front_rear_dimension"
                      value={values.injury_muscle_front_rear_dimension}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col} className="col-12 col-md-6">
                    <Form.Label>Dimensión Mediolateral</Form.Label>
                    <Form.Control
                      name="injury_muscle_middle_side_dimension"
                      value={values.injury_muscle_middle_side_dimension}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col} className="col-12 col-md-6">
                    <Form.Label>Dimensión Profundidad</Form.Label>
                    <Form.Control
                      name="injury_muscle_depth_dimension"
                      value={values.injury_muscle_depth_dimension}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </>
              }

            </Form.Row>
          </div>
          <div className="col-12 col-md-6">
            <Form.Row>
              <Form.Group as={Col} controlId="Process">
                <Form.Label>Procedimiento / Materiales / Reflejo</Form.Label>
                <Select
                  isClearable
                  isSearchable
                  placeholder=""
                  name="id_procedure_material"
                  noOptionsMessage={() => 'No se encontraron coincidencias'}
                  isLoading={procedureMaterials.loading}
                  isDisabled={!procedureMaterials.data?.getProcedureMaterials.procedureMaterials?.length}
                  // styles={(touched.id_procedure_material && errors.id_procedure_material) ? errorStyle : {}}
                  value={procedureMaterialOptions?.find(option => option.value === values.id_procedure_material )}
                  onChange={(option) => {
                    setFieldValue('id_procedure_material', option?.value ||'')
                  }}
                  onBlur={() => setTouched({ ...touched, 'id_procedure_material': true })}
                  options={procedureMaterialOptions}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="availability-condition">
                <Form.Label>Condición Disponibilidad*</Form.Label>
                <Select
                  isClearable
                  isSearchable
                  placeholder=""
                  name="id_availability_condition_type"
                  noOptionsMessage={() => 'No se encontraron coincidencias'}
                  isLoading={availabilityTypes.loading}
                  styles={(touched.id_availability_condition_type && !selectedValues.id_availability_condition_type) ? errorStyle : {}}
                  value={availabilityConditionOptions?.find(option => option.value === values.id_availability_condition_type )}
                  onChange={(option) => {
                    setSelectedValues((prevState) => ({ ...prevState, 'id_availability_condition_type': option?.value ||'' }))
                    setFieldValue('id_availability_condition_type', option?.value ||'')
                  }}
                  onBlur={() => setTouched({ ...touched, 'id_availability_condition_type': true })}
                  options={availabilityConditionOptions}
                />
              </Form.Group>
            </Form.Row>

            <Form.Group as={Col} controlId="Text" className="p-0 m-0">
              <Form.Label>Comentario</Form.Label>
              <Form.Control
                as="textarea"
                name="comments"
                className="diagnosis-textarea"
                value={values.comments}
                onChange={handleChange}
              />
            </Form.Group>
          </div>
        </div>
      </Styles>
    </React.Fragment>
  )
}

DiagnosisForm.propTypes = {
  values: PropTypes.shape({
    id_availability_condition_type: PropTypes.string,
    id_diagnostic_complement: PropTypes.string,
    id_diagnostic_type: PropTypes.string,
    id_general_mechanism: PropTypes.string,
    id_joint_ligament: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    id_match_moment: PropTypes.string,
    id_pathology: PropTypes.string,
    id_plexo_system: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    id_procedure_material: PropTypes.string,
    id_severity: PropTypes.string,
    id_skeletal_muscle: PropTypes.string,
    id_specific_mechanism: PropTypes.string,
    id_sub_diagnostic: PropTypes.string,
    side: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    comments: PropTypes.string,
    injury_muscle_front_rear_dimension: PropTypes.string,
    injury_muscle_middle_side_dimension: PropTypes.string,
    injury_muscle_depth_dimension: PropTypes.string,
  }),
  touched: PropTypes.shape({
    id_diagnostic_type: PropTypes.bool,
    id_sub_diagnostic: PropTypes.bool,
    id_pathology: PropTypes.bool,
    id_diagnostic_complement: PropTypes.bool,
    id_skeletal_muscle: PropTypes.bool,
    side: PropTypes.bool,
    id_joint_ligament: PropTypes.bool,
    id_plexo_system: PropTypes.bool,
    id_procedure_material: PropTypes.bool,
    id_availability_condition_type: PropTypes.bool,
  }),
  errors: PropTypes.shape({
    id_diagnostic_type: PropTypes.string,
    id_sub_diagnostic: PropTypes.string,
    id_pathology: PropTypes.string,
    id_diagnostic_complement: PropTypes.string,
    id_skeletal_muscle: PropTypes.string,
    side: PropTypes.string,
    id_joint_ligament: PropTypes.string,
    id_plexo_system: PropTypes.string,
    id_procedure_material: PropTypes.string,
    id_availability_condition_type: PropTypes.string,
  }),
  setFieldValue: PropTypes.func.isRequired,
  setTouched: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  id_diagnostic_type: PropTypes.number,
  id_sub_diagnostic: PropTypes.number,
}

export default DiagnosisForm
