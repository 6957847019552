import styled from 'styled-components'

export default styled.div`

  body{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(45deg, #fbda61, #ff5acd);
  }

  .card{
    position: relative;
    width: 350px;
    height: 190px;
    /* height: 450px; */
    background: #ffffff;
    border-radius: 20px;
    box-shadow: 0 35px 80px rgba(0,0,0,0.15);
    transition: 0.5s;
    overflow: hidden;
  }

  .card:hover{
    height:450px;
  }

  .imgBx{
    position: absolute;
    left: 50%;
    top: -50px;
    transform: translateX(-50%);
    width:150px;
    height: 150px;
    background: #000;
    border-radius: 20px;
    box-shadow: 0 15px 50px rgba(0,0,0,0.35);
    overflow: hidden;
    transition: 0.5s;
  }

  .card:hover .imgBx{
    width:200px;
    height: 200px;
  }

  .imgBx img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .card .content{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;

  }

  .card .content .details {
    padding: 40px;
    text-align: center;
    width: 100%;
    transition: 0.5s;
    transform: translateY(130px);
  }

  .card:hover .content .details{
    transform: translateY(0px);
  }

  .card .content .details h2 {
    font-size: 1.25em;
    font-weight: 600;
    color: #555;
    line-height: 1.2em;
  }

  .card .content .details h2 span{
    font-size: 0.75em;
    font-weight: 500;
    opacity: 0.5;
  }

  .card .content .details .data {
    display: flex-column;
    justify-content: space-between;
    margin: 20px 0;
  }

  .card .content .details .data h3{
    font-size: 1em;
    color: #555;
    line-height: 1.2em;
    font-weight: 600;
  }

  .card .content .details .data h3 span{
    font-size: 0.85em;
    font-weight: 400;
    opacity: 0.5;
  }

`
