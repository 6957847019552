import { gql } from '@apollo/client'

const LOGIN = gql`
  mutation Login($LoginInput: LoginInput!) {
    loginUser(LoginInput: $LoginInput) {
      token
    }
  }
`

export default LOGIN
