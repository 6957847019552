import { gql } from '@apollo/client'

const CREATE_SINGLE_KINESIC_TREATMENT = gql`
 mutation createSingleKinesicTreatment($input: CreateSingleKinesicTreatmentInput!) {
  createSingleKinesicTreatment(input: $input) {
    status {
      success
      code
      message
    }
  }
}
`
export default CREATE_SINGLE_KINESIC_TREATMENT
