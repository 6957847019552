import React from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { Redirect } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { Slider } from '../../components'
import constants from '../../config/constants'
import Styles from './LoginStyle'

const Login = () => {
  const { keycloak } = useKeycloak()

  if (keycloak.authenticated) {
    return (
      <Redirect to="/" />
    )
  }

  return (
    <Styles>
      <div className="row no-gutters">
        <Slider />
        <div className="col-12 col-md-5 login-form-wrapper">
          <div className="container">
            <img className="mt-5" src={`${constants.STATIC_IMAGES_URL}/reconquer-logo.png`} width="250px" />
            <h1 className="mt-5 display-4 text-white">¡Bienvenido!</h1>
            <Button
              variant="btn btn-success btn-lg btn-block mb-3"
              type="submit"
              onClick={() => {
                localStorage.removeItem('defaultCategoryType')
                keycloak.login()
              }}
            >
              Iniciar Sesión
            </Button>
          </div>
        </div>

      </div>
    </Styles>
  )
}

export default Login
