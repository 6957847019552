import React from 'react'
import PropTypes from 'prop-types'
import { FilterRow } from '../FilterRow'
import { DateFilter } from '../DateFilter'

const PlayerInfoFilter = ({ children, filters, setFilters, extras }) => {
  return (
    <>
      <FilterRow>
        {children}
        <DateFilter
          value={{
            start_date: filters.start_date,
            end_date: filters.end_date
          }}
          setValue={setFilters}
        />
      </FilterRow>
      {extras}
    </>
  )
}

PlayerInfoFilter.propTypes = {
  children: PropTypes.node,
  extras: PropTypes.node,
  filters: PropTypes.shape({
    start_date: PropTypes.instanceOf(Date).isRequired,
    end_date: PropTypes.instanceOf(Date).isRequired,
  }),
  setFilters: PropTypes.func.isRequired
}

export default PlayerInfoFilter
