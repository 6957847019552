import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import es from 'date-fns/locale/es'
import { Form } from 'react-bootstrap'
import { registerLocale } from 'react-datepicker'

registerLocale('es', es)

const DatePickerInput = ({
  label = 'Fecha',
  name = 'date',
  value = new Date(),
  handleChange,
  handleBlur,
  setFieldValue,
  touched = false,
  error = '',
  width = '',
  disabled = false,
  maxDate = new Date()
}) => {
  return (
    <Form.Group className={width}>
      {label && <h6 className="form-label">{label}</h6>}
      {window.innerWidth > 576 ?
        <DatePicker
          showYearDropdown
          className="date"
          locale="es"
          wrapperClassName="w-100"
          dateFormat="dd/MM/yyyy"
          name={name}
          maxDate={maxDate}
          selected={value}
          onChange={date => setFieldValue(name, date)}
          onBlur={handleBlur}
          disabled={disabled}
          customInput={
            <Form.Control
              required
              as="input"
              placeholder="Selecciona una fecha"
              isInvalid={touched && error ? true : false}
            />
          }
        /> :
        <Form.Control
          required
          as="input"
          type="date"
          name="date"
          placeholder="Selecciona una fecha"
          value={
            typeof value === 'string'
              ? value
              : new Date(
                value - (value.getTimezoneOffset() * 60000)
              ).toISOString().split('T')[0]
          }
          onChange={handleChange}
          isInvalid={touched && error ? true : false}
        />
      }
      {(touched && error) &&
        <div className="invalid-feedback d-block">
          {error}
        </div>
      }
    </Form.Group>
  )
}

DatePickerInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.instanceOf(Date),
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
  touched: PropTypes.bool,
  error: PropTypes.string,
  width: PropTypes.string,
  disabled: PropTypes.bool,
  maxDate: PropTypes.instanceOf(Date)
}

export default DatePickerInput
