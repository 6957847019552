import { gql } from '@apollo/client'

const UPDATE_PLAYER_GPS = gql`
  mutation updatePlayerGps($input: UpdatePlayerGpsInput!) {
    updatePlayerGps(input: $input) {
      status {
        success
        code
        message
      }
    }
  }
`
export default UPDATE_PLAYER_GPS
