import { gql } from '@apollo/client'

const GET_TEAM_LAST_CLINICAL_EVENT = gql`
  query getTeamLastClinicalEvent($input: TeamLastClinicalEventInput!) {
    getTeamLastClinicalEvent(input: $input) {
      teamLastClinicalEvent {
        id
        active
        createdAt
        updatedAt
        Player {
          id
          id_category_type
          User {
            name
            last_name
          }
        }
        Diagnostics {
          id
          side
          createdAt
          DiagnosticType {
            name
          }
          DiagnosticComplement {
            name
          }
          Pathology {
            name
          }
          SkeletalMuscle {
            name
          }
          BodyZone {
            name
          }
          DiagnosticAvailabilities {
            id
            AvailabilityCondition {
              id
              main
              date
              AvailabilityConditionType {
                id
                name_availability_condition_type
              }
            }
          }
        }
      }
      status {
        success
        code
        message
      }
    }
  }

`
export default GET_TEAM_LAST_CLINICAL_EVENT
