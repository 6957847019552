import { gql } from '@apollo/client'

const GET_SCHEDULES = gql`
  query getSchedules {
    getSchedules {
      schedules {
        id
        datetime
        duration
        id_schedule_type
        Profesional {
          id
          name
          last_name
          UserType {
            name_user
          }
        }
        Patient {
          id
          name
          last_name
          UserType {
            name_user
          }
        }
      }
      status {
        success
        code
        message
      }
    }
  }
`

export default GET_SCHEDULES
