import { gql } from '@apollo/client'

const CREATE_USER = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      status {
        success
        code
        message
      }
    }
  }
`

export default CREATE_USER
