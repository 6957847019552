import styled from 'styled-components'

export default styled.div`
  .content {
    & > div {
      padding: 14px 14px 0 14px;
    }
  }

  @media (min-width: 767px) {
    .content {
      transition: padding 0.3s;
    }
    .aside-collapsed {
      padding-left: 80px;
    }
    .aside-expanded {
      padding-left: 280px;
    }
  }

  @media only screen and (max-width: 767px){
    .content {
      & > div {
        transition: padding 0.3s;
      }
    }
  }
`
