import { gql } from '@apollo/client'

const GET_MEDICAL_HISTORY_PLAYERS = gql`
  query getMedicalHistoryPlayers($input: MedicalHistoryPlayerInput!) {
    getMedicalHistoryPlayers(input: $input) {
      medicalHistoryPlayers{
        id
        id_player
        id_dimension_history
        backgrounds
        date
        DimensionHistory {
          id
          name
          Histories {
            id
            name
            Backgrounds {
              id
              name
              id_background_type
              BackgroundOptions {
                id
                name
              }
              Background {
                id
                name
              }
            }
          }
        }
      }
      status{
        success
        code
        message
      }
    }
  }
`

export default GET_MEDICAL_HISTORY_PLAYERS
