const apiUri = 'https://triceps.reconquer.app'
const clinicalApiUri = 'https://clinical-api.reconquer.app/api'

const domainBase = {
  realm: 'TRICEPS',
  apiUri,
  clinicalApiUri
}

/**
 * Listado de Dominios
 */
export const domains = {
  'triceps.reconquer.app': domainBase,

  'leonas.reconquer.app': {
    ...domainBase,
    realm: 'LEONAS',
  },
  'coch.reconquer.app': {
    ...domainBase,
    realm: 'COCH',
  },
  'palestino.reconquer.app': {
    ...domainBase,
    realm: 'PALESTINO',
  },
  'ucatolica.reconquer.app': {
    ...domainBase,
    realm: 'UCATOLICA',
  },
  'sportclinic.reconquer.app': {
    ...domainBase,
    realm: 'SPORTCLINIC',
  },
  'udechile.reconquer.app': {
    ...domainBase,
    realm: 'UDECHILE',
  },
  'unionespanola.reconquer.app': {
    ...domainBase,
    realm: 'UNIONESPANOLA',
  },
  'febachile.reconquer.app': {
    ...domainBase,
    realm: 'FEBACHILE',
  },
  'cobsandcogs.reconquer.app': {
    ...domainBase,
    realm: 'COBSANDCOGS',
  },
  'anfpformativo.reconquer.app': {
    ...domainBase,
    realm: 'ANFPFORMATIVO',
  },
  'fernandezvial.reconquer.app': {
    ...domainBase,
    realm: 'AFV',
  },
  'afa.reconquer.app': {
    ...domainBase,
    realm: 'AFA',
  },
  'cdantofagasta.reconquer.app': {
    ...domainBase,
    realm: 'ANTOFAGASTA',
  },
  'audaxitaliano.reconquer.app': {
    ...domainBase,
    realm: 'AUDAXITALIANO',
  },
  'cdlaserena.reconquer.app': {
    ...domainBase,
    realm: 'LASERENA',
  },
  'coquimbounido.reconquer.app': {
    ...domainBase,
    realm: 'COQUIMBO',
  },
  'anfp.reconquer.app': {
    ...domainBase,
    realm: 'ANFP',
  },
  'colocolo.reconquer.app': {
    ...domainBase,
    realm: 'COLOCOLO',
  },
  'triceps-qa.reconquer.app': {
    ...domainBase,
    realm: 'TRICEPS-QA',
    apiUri: 'https://triceps-qa.reconquer.app',
    clinicalApiUri: 'https://clinical-api-qa.reconquer.app/api'
  },
  localhost: {
    ...domainBase,
    realm: 'TRICEPS-QA',
    apiUri: 'https://triceps-qa.reconquer.app',
    // apiUri: 'http://localhost:4000',
    clinicalApiUri: 'https://clinical-api-qa.reconquer.app/api'
  }
}

const domainConfig = () => {
  const hostname = window.location.hostname

  return domains[hostname]
}

/**
 * Retorna la configuración de un dominio
 * @example
 *
 * ```js
 *   const config = domainConfig()
 * ```
 * @returns {ConfigType} Configuración del dominio
 */
export default domainConfig()
