import React from 'react'
import { FilterRow, DateFilter } from '../../../components'
import { KinesicEvaluationContext } from './KinesicEvaluationContext'

const KinesicEvaluationFilters = () => {
  const { filters, setFilters } = React.useContext(KinesicEvaluationContext)
  return (
    <div className="col d-flex align-items-baseline justify-content-end">
      <FilterRow>
        <DateFilter
          value={{
            start_date: filters.start_date,
            end_date: filters.end_date
          }}
          setValue={setFilters}
        />
      </FilterRow>
    </div>
  )
}

export default KinesicEvaluationFilters
