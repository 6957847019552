import React, { useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import ReactDatePicker from 'react-datepicker'
import { Formik } from 'formik'
import { Form, Modal } from 'react-bootstrap'
import { DatePickerInput, FooterForm, StatusAlert } from '../../../../components'
import { useMutation } from '@apollo/client'
import { GET_SCHEDULES } from '../../../../apollo/queries'
import { BLOCK_SCHEDULE } from '../../../../apollo/mutations'
import { useDateHelper } from '../../../../hooks'

const ScheduleBlockForm = ({ singleEvent = {}, handleClose }) => {

  const { getFormattedDate, getDate, getTimeFromDate, concatDateAndTime } = useDateHelper()

  const [blockHour, setBlockHour] = useState({
    date: singleEvent.start_date || new Date(new Date().setHours(9, 0)),
    startHour: singleEvent.start_date || new Date(new Date().setHours(9, 0)),
    endHour: singleEvent.end_date || new Date(new Date().setHours(9, 30))
  })

  console.log({ blockHour })

  // const [date, setDate] = useState(singleEvent.start_date || new Date())
  // const [startHour, setStartHour] = useState(singleEvent.start_date || new Date(new Date().setHours(9, 0)))
  // const [endHour, setEndHour] = useState(singleEvent.end_date || new Date(new Date().setHours(9, 30)))

  const [blockMutation, blockMutationResult] = useMutation(BLOCK_SCHEDULE,
    {
      refetchQueries: [
        { query: GET_SCHEDULES }
      ]
    }
  )

  const handleBlockHour = (date, key) => {
    if (key === 'date') {
      setBlockHour((prevState) => ({
        date,
        startHour: handleTime(date, prevState.startHour),
        endHour: handleTime(date, prevState.endHour),
      }))
    } else {
      setBlockHour((prevState) => ({
        ...prevState,
        [key]: handleTime(prevState.date, date)
      }))
    }
  }

  const handleTime = (date, timeDate) => {
    console.log({ date, timeDate })
    const newDate = typeof date === 'string' ? date : getDate(date)
    const newTime = typeof timeDate === 'string' ? timeDate : getTimeFromDate(timeDate)
    console.log({ concat: concatDateAndTime(newDate, newTime), newDate, newTime })
    return concatDateAndTime(newDate, newTime)
  }

  const handleDate = (_, value) => {
    handleBlockHour(value, 'date')
    // setDate(value)
  }

  // const handleStartHour = (hour) => {
  //   setStartHour(handleTime(hour))
  // }

  // const handleEndHour = (hour) => {
  //   setEndHour(handleTime(hour))
  // }

  return (
    <Formik
      initialValues={{
        date: blockHour.date,
        start_hour: blockHour.startHour,
        end_hour: blockHour.endHour
      }}
      validate={() => {
        const errors = {}
        //  TODO: VALIDATE INPUTS
        return errors
      }}
      onSubmit={() => {
        blockMutation({
          variables: {
            input: {
              date: getFormattedDate(blockHour.date),
              start_hour:blockHour.startHour,
              end_hour: blockHour.endHour
              // start_hour: values.start_hour.toISOString(),
              // end_hour: values.end_hour.toISOString(),
            }
          }
        })
      }}
    >
      {({
        // values,
        // touched,
        // errors,
        // handleBlur,
        handleSubmit,
        // handleChange,
        // setFieldValue
      }) => (
        <Form onSubmit={handleSubmit}>
          {blockMutationResult.data && (
            <StatusAlert
              successMessage={'Horario bloqueado con éxito.'}
              status={blockMutationResult?.data?.blockSchedule.status}
            />
          )}
          <Modal.Body>
            <Form.Group>
              <Form.Label>Fecha</Form.Label>
              <DatePickerInput
                label=""
                id="date"
                name="date"
                value={blockHour.date}
                maxDate={new Date(moment().add(1, 'year'))}
                handleChange={(e) => handleBlockHour(e.target.value, 'date')}
                // handleChange={(e) => setDate(e.target.value)}
                setFieldValue={handleDate}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Horario de inicio</Form.Label>
              <ReactDatePicker
                showTimeSelect
                showTimeSelectOnly
                wrapperClassName="w-100"
                id="start_hour"
                name="start_hour"
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                timeIntervals={15}
                selected={blockHour.startHour}
                onChange={date => handleBlockHour(date, 'startHour')}
                // onChange={date => setFieldValue('start_hour', handleTime(date))}
                customInput={
                  <Form.Control
                    required
                    as="input"
                  />
                }
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Horario de término</Form.Label>
              <ReactDatePicker
                showTimeSelect
                showTimeSelectOnly
                wrapperClassName="w-100"
                id="end_hour"
                name="end_hour"
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                timeIntervals={15}
                selected={blockHour.endHour}
                onChange={date => handleBlockHour(date, 'endHour')}
                // onChange={date => setFieldValue('end_hour', date)}
                customInput={
                  <Form.Control
                    required
                    as="input"
                  />
                }
              />
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <FooterForm
              action={'CREATE'}
              loading={blockMutationResult.loading}
              handleCancel={() => handleClose()}
            />
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  )
}

ScheduleBlockForm.propTypes = {
  singleEvent: PropTypes.shape({
    start_date: PropTypes.instanceOf(Date).isRequired,
    end_date: PropTypes.instanceOf(Date).isRequired,
  }),
  handleClose: PropTypes.func.isRequired,
}

export default ScheduleBlockForm
