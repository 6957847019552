import { gql } from '@apollo/client'

const UPDATE_KINESIC_TREATMENT = gql`
  mutation updateKinesicTreatment($input: UpdateKinesicTreatmentInput!) {
    updateKinesicTreatment(input: $input) {
      status {
        success
        code
        message
      }
    }
  }
`

export default UPDATE_KINESIC_TREATMENT
