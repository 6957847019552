import styled from 'styled-components'

export default styled.div`
  label {
    background-color: #28a745;
    color: white;
    padding: 0 10px;
    width: 100%;
    text-align: center;
  }

  .form-label {
    background-color: #28a745;
    color: white;
    padding: 0 10px;
    width: 100%;
    text-align: center;
  }

  input[type=checkbox] {
    transform: scale(1.5);
  }

`
