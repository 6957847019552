import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'

const PlayerActiveFilter = ({ value, setValue }) => {
  return (
    <Form.Group>
      <Form.Control
        as="select"
        custom
        value={value}
        onChange={(e) => setValue(e.target.value)}
      >
        <option value="">Todos los jugadores</option>
        <option value="1">Activos</option>
        <option value="0">Inactivos</option>
      </Form.Control>
    </Form.Group>
  )
}

PlayerActiveFilter.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func
}

export default PlayerActiveFilter

