import { gql } from '@apollo/client'

const CREATE_MASSIVE_GPS = gql`
  mutation CreateMassiveGps($input: CreateMassiveGpsInput!) {
    createMassiveGps(input: $input) {
      status {
        success
        code
        message
      }
      errors {
        row
        message
        index
      }
    }
  }
`
export default CREATE_MASSIVE_GPS
