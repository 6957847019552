import React from 'react'
import ReactToolTip from 'react-tooltip'
import hasRole from '../../../../keycloak/utils/hasRole'
import AuthorizedElement from '../../../../keycloak/utils/AuthorizedElement'
import { useParams, Link, useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_CLINICAL_EVENT_DETAILS, } from '../../../../apollo/queries'
import { FaCheck, FaPen, FaFileAlt, FaPrescriptionBottleAlt, FaFileMedicalAlt, FaRunning } from 'react-icons/fa'
import { Button, Modal } from 'react-bootstrap'
import { Loading, CustomBreadcrumb } from '../../../../components'
import { PlayerLayout } from '../../../Layouts/PlayerLayout'
import { KinesicTreatment } from './KinesicTreatment'
import { KinesicTreatmentButton } from './KinesicTreatment/KinesicTreatmentButton'
import { Evaluation } from './Evaluation'
import { Medicines } from './Medicines'
import { appStore } from '../../../../store/appStore'
import ClinicalEventsDates from './ClinicalEventsDates'
import constants from '../../../../config/constants'
import Styles from './ClinicalEventsDetailStyle'

const ClinicalEventsDetail = () => {
  let { id_player, id_clinical_event } = useParams()
  const history = useHistory()

  const { state } = React.useContext(appStore)

  const breadcrumbPath = [
    { label: 'Ver Deportistas', href: '/players' },
    { label: 'Resumen médico', href: `/player-info/${state.player?.id_category_type}/${id_player}/clinical-event` }
  ]

  const [diagnosticId, setDiagnosticId] = React.useState(null)
  const [kinesicModal, setKinesicModal] = React.useState(false)
  const [evaluationModal, setEvaluationModal] = React.useState(false)
  const [medicinesModal, setMedicinesModal] = React.useState(false)
  const [comments] = React.useState('')
  const [commentsModal, setCommentsModal] = React.useState(false)

  const { data, loading, error } = useQuery(GET_CLINICAL_EVENT_DETAILS, {
    variables: {
      input: {
        id: parseInt(id_clinical_event, 10)
      }
    },
    fetchPolicy: 'network-only'
  })

  if (loading) {
    return (
      <Loading />
    )
  }

  if (error) {
    return (
      <div>Error :(</div>
    )
  }

  return (
    <>
      <CustomBreadcrumb
        path={hasRole(['role_player']) ? breadcrumbPath.slice(1) : breadcrumbPath }
        active="Detalle evento clínico"
      />
      <PlayerLayout>
        <Styles>
          {data.getClinicalEventDetails.clinicalEventDetails?.Diagnostics.map((diagnostic, key) =>
            <div key={key} className="m-3">
              <ClinicalEventsDates
                clinicalEvent={data.getClinicalEventDetails.clinicalEventDetails || {}}
              />
              <div className="row input-bg d-flex align-items-center">
                <div className="col-12 col-md-6 my-3 d-flex">
                  <div
                    data-tip data-for={`condition${diagnostic.DiagnosticAvailabilities[0].AvailabilityCondition.AvailabilityConditionType.id}`}
                    className={`btn-circle zoom mx-2 color${diagnostic.DiagnosticAvailabilities[0].AvailabilityCondition.AvailabilityConditionType.id}`}
                  />
                  <h6 className='mb-0'>{diagnostic.DiagnosticAvailabilities[0].AvailabilityCondition.AvailabilityConditionType.name_availability_condition_type}</h6>
                </div>
                <div className="col-12 col-md-6 my-3">
                  <div className="d-flex justify-content-md-end align-items-center">
                    <h6 className="mb-0 mr-3 d-none d-lg-block">Acciones:</h6>
                    <AuthorizedElement roles={['permission_team-update-player-diagnostic']}>
                      <Link to={{
                        pathname: `/diagnostic/edit/${id_player}/${id_clinical_event}`,
                        diagnostic: { ...diagnostic }
                      }}>
                        <Button
                          variant="warning"
                          className="mr-2"
                          data-tip data-for="edit"
                        >
                          <FaPen color={'white'} />
                          <ReactToolTip
                            id="edit"
                            place="top"
                            effect="solid"
                            type="info"
                          >
                            Editar
                          </ReactToolTip>
                        </Button>
                      </Link>
                    </AuthorizedElement>
                    {hasRole(['permission_team-show-kinesic-dimension']) ? (
                      <KinesicTreatmentButton
                        roles={['permission_team-show-kinesic-dimension']}
                        onClick={() => history.push(`/player-info/${id_player}/diagnostic/${diagnostic.id}/kinesic-treatments`)}
                      />
                    ) : (
                      <KinesicTreatmentButton
                        roles={['permission_team-show-player-kinesic-treatment']}
                        onClick={() => {
                          setKinesicModal(true)
                          setDiagnosticId(diagnostic.id)
                        }}
                      />
                    )}

                    <AuthorizedElement roles={['permission_team-show-player-eva-objectives']}>
                      <Button
                        variant="success"
                        className="mr-2"
                        data-tip data-for="evaluation"
                        onClick={() => {
                          setDiagnosticId(diagnostic.id)
                          setEvaluationModal(true)
                        }}
                      >
                        <FaCheck color={'white'} />
                        <ReactToolTip
                          id="evaluation"
                          place="top"
                          effect="solid"
                          type="info"
                        >
                          Evaluaciones / Objetivos
                        </ReactToolTip>
                      </Button>
                    </AuthorizedElement>
                    <AuthorizedElement roles={['permission_team-show-player-medicines']}>
                      <Button
                        variant="primary"
                        className="mr-2"
                        data-tip data-for="medicines"
                        onClick={() => {
                          setDiagnosticId(diagnostic.id)
                          setMedicinesModal(true)
                        }}
                      >
                        <FaPrescriptionBottleAlt color={'white'} />
                        <ReactToolTip
                          id="medicines"
                          place="top"
                          effect="solid"
                          type="info"
                        >
                          Medicamentos
                        </ReactToolTip>
                      </Button>
                    </AuthorizedElement>

                    <AuthorizedElement roles={['permission_team-show-masotherapy']}>
                      <Link to={{
                        pathname: `/masotherapy/${id_player}/${diagnostic.id}`,
                        diagnostic: { ...diagnostic }
                      }}>
                        <Button
                          style={{ background: '#f20a64', border: '1px solid #f20a64 ' }}
                          className="mr-2"
                          data-tip data-for="massage"
                        >
                          <img
                            src={
                            `${constants.STATIC_IMAGES_URL}/massage-icon.png`
                            }
                          />
                          <ReactToolTip
                            id="massage"
                            place="top"
                            effect="solid"
                            type="info"
                          >
                            Masoterapia
                          </ReactToolTip>
                        </Button>
                      </Link>
                    </AuthorizedElement>
                    {/* <Button
                      size="sm"
                      variant="secondary"
                      className="mr-2"
                      data-tip data-for="comments"
                      onClick={() => {
                        setComments(diagnostic.comments)
                        setCommentsModal(true)
                      }}
                    >
                      <FaFileAlt color={'white'} />
                      <ReactToolTip
                        id="comments"
                        place="top"
                        effect="solid"
                        type="info"
                      >
                      Comentarios
                      </ReactToolTip>
                    </Button> */}
                    <AuthorizedElement roles={['permission_team-show-exams']}>
                      <Button
                        varian="info"
                        className="mr-1"
                        style={{ background: '#1dd0b5', border: '1px solid #1dd0b5' }}
                        data-tip data-for="exams"
                        onClick={() => history.push(`/exams/${id_player}/${diagnostic.id}`)}
                      >
                        <FaFileMedicalAlt color={'#fff'} />
                        <ReactToolTip
                          id="exams"
                          place="top"
                          effect="solid"
                          type="info"
                        >
                          {'Exámenes'}
                        </ReactToolTip>
                      </Button>
                    </AuthorizedElement>
                    <AuthorizedElement roles={['permission_team-show-exams']}>
                      <Button
                        varian="info"
                        className="ml-1"
                        style={{ background: '#96cc3f', border: '1px solid #96cc3f' }}
                        data-tip data-for="biomechanic"
                        onClick={() => history.push(`/biomechanic/${id_player}/${diagnostic.id}`)}
                      >
                        <FaRunning
                          size="16px"
                          color={'#fff'}
                        />
                        <ReactToolTip
                          id="biomechanic"
                          place="top"
                          effect="solid"
                          type="info"
                        >
                          {'Biomecánica'}
                        </ReactToolTip>
                      </Button>
                    </AuthorizedElement>
                  </div>
                </div>
              </div>
              <div className="row justify-content-between">
                <div className="col-12 col-md-8 borde p-3 mt-2 text-center">
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
                      <h6 className="label">Categoría</h6>
                      <p className="mb-0 border input-bg">{diagnostic.DiagnosticType.name}</p>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
                      <h6 className="label">Categoría II</h6>
                      <p className="mb-0 border input-bg">{diagnostic.SubDiagnostic.name}</p>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
                      <h6 className="label">Procedimiento / Materiales</h6>
                      <p className="mb-0 border input-bg">{diagnostic.ProcedureMaterial?.name || '--'}</p>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
                      <h6 className="label">Lateralidad</h6>
                      <p className="mb-0 border input-bg">{diagnostic.side === 1 ? 'Derecha' : diagnostic.side === 2 ? 'Izquierda': diagnostic.side === 3 ? 'Derecha e izquierda' : 'No aplica'}</p>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
                      <h6 className="label">Sistema Plexo</h6>
                      <p className="mb-0 border input-bg">{diagnostic.PlexoSystem?.name || '--'}</p>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
                      <h6 className="label">Articulación/Ligamentos</h6>
                      <p className="mb-0 border input-bg">{diagnostic.JointLigament?.name || '--'}</p>
                    </div>
                    {diagnostic.DiagnosticType.id === '2' && diagnostic.SubDiagnostic.id === '6' &&
                      <>
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
                          <h6 className="label">Dimensión Anteroposterior</h6>
                          <p className="mb-0 border input-bg">{diagnostic.injury_muscle_front_rear_dimension || '--'}</p>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
                          <h6 className="label">Dimensión Mediolateral</h6>
                          <p className="mb-0 border input-bg">{diagnostic?.injury_muscle_middle_side_dimension|| '--'}</p>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
                          <h6 className="label">Dimensión Profundidad</h6>
                          <p className="mb-0 border input-bg">{diagnostic?.injury_muscle_depth_dimension|| '--'}</p>
                        </div>
                      </>
                    }

                  </div>
                </div>
                <div className="col borde p-3 ml-md-2 mt-2">
                  <h6 className="label text-center text-md-left">Diagnóstico</h6>
                  <p className="mb-0 text-center text-md-left">
                    {diagnostic.Pathology.name}{', '}
                    {diagnostic.DiagnosticComplement?.name ? diagnostic.DiagnosticComplement?.name + ', ' : ''}
                    {diagnostic.SkeletalMuscle ? diagnostic.SkeletalMuscle?.name : diagnostic.BodyZone?.name}
                  </p>
                </div>
              </div>
              <hr />
              <div className="row input-bg p-2 mb-2 align-items-center">
                <div className="col">
                  <h6 className="mb-0 ">Comentarios</h6>
                </div>
              </div>
              <div className="row borde py-3 mb-3">
                <p className="px-4 mb-0 ">{diagnostic.comments ? diagnostic.comments : 'Diagnóstico sin comentarios'}</p>
              </div>

              <hr />
              <div className="row d-flex input-bg p-2 mb-2 align-items-center">
                <div className="col">
                  <h6 className="mb-0 ">Antencedentes</h6>
                </div>
                <div className="col d-flex align-items-center justify-content-end">
                  <h6 className="mb-0 mr-3">Acciones:</h6>
                  <AuthorizedElement roles={['permission_team-update-player-clinic-event']}>
                    <Link to={{
                      pathname: `/edit-clinical-event/${id_clinical_event}`,
                      clinicalEvent: { ...data.getClinicalEventDetails.clinicalEventDetails }
                    }}>
                      <Button
                        variant="warning"
                        className="mr-2"
                        data-tip data-for="edit"
                      >
                        <FaPen color={'white'} />
                        <ReactToolTip
                          id="edit"
                          place="top"
                          effect="solid"
                          type="info"
                        >
                          {'Editar'}
                        </ReactToolTip>
                      </Button>
                    </Link>
                  </AuthorizedElement>
                </div>
              </div>
              <div className="row borde py-3 text-center mb-3">
                <div className="col-12 col-md-4 col-lg-2 mb-3">
                  <h6 className="label">Instancia</h6>
                  <p className="mb-0 border rounded input-bg">{data.getClinicalEventDetails.clinicalEventDetails?.Instance?.name || '--'}</p>
                </div>
                <div className="col-12 col-md-4 col-lg-2 mb-3">
                  <h6 className="label">Instancia Partido</h6>
                  <p className="mb-0 border rounded input-bg">{data.getClinicalEventDetails.clinicalEventDetails?.MatchMoment?.name || '--'}</p>
                </div>
                <div className="col-12 col-md-4 col-lg-2 mb-3">
                  <h6 className="label">Presentación</h6>
                  <p className="mb-0 border rounded input-bg">{data.getClinicalEventDetails.clinicalEventDetails?.Severity?.name || '--'}</p>
                </div>
                <div className="col-12 col-md-6 col-lg-3 mb-3">
                  <h6 className="label">Mecanismo Específico</h6>
                  <p className="mb-0 border rounded input-bg">{data.getClinicalEventDetails.clinicalEventDetails?.SpecificMechanism?.name || '--'}</p>
                </div>
                <div className="col-12 col-md-6 col-lg-3 mb-3">
                  <h6 className="label">Mecanismo General</h6>
                  <p className="mb-0 border rounded input-bg">{data.getClinicalEventDetails.clinicalEventDetails?.GeneralMechanism?.name || '--'}</p>
                </div>
              </div>
            </div>
          )}

          {kinesicModal &&
            <AuthorizedElement roles={['permission_team-show-player-kinesic-treatment']}>
              <KinesicTreatment
                diagnosticId={diagnosticId}
                openModal={kinesicModal}
                setOpenModal={(value) => setKinesicModal(value)}
              />
            </AuthorizedElement>
          }

          {evaluationModal &&
            <AuthorizedElement roles={['permission_team-show-player-eva-objectives']}>
              <Evaluation
                diagnosticId={diagnosticId}
                openModal={evaluationModal}
                setOpenModal={(value) => setEvaluationModal(value)}
              />
            </AuthorizedElement>
          }

          {medicinesModal &&
            <AuthorizedElement roles={['permission_team-show-player-medicines']}>
              <Medicines
                diagnosticId={diagnosticId}
                openModal={medicinesModal}
                setOpenModal={(value) => setMedicinesModal(value)}
              />
            </AuthorizedElement>
          }

          <Modal
            size="lg"
            show={commentsModal}
            dialogClassName="full-width"
            contentClassName="full-height"
            onHide={() => setCommentsModal(false)}
          >
            <Modal.Header closeButton className="bg-secondary text-white">
              <Modal.Title>
                <FaFileAlt className="mr-1" color={'white'} />
                  Comentarios
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {comments ? comments : 'Diagnóstico sin comentarios'}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setCommentsModal(false)}>
                Cerrar
              </Button>
            </Modal.Footer>
          </Modal>
        </Styles>
      </PlayerLayout>
    </>
  )
}

export default ClinicalEventsDetail



