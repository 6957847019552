import React from 'react'
import { PropTypes } from 'prop-types'
import { Button } from 'react-bootstrap'
import { SubmitButton } from '../../Buttons/SubmitButton'

const FooterForm = ({ action, loading, handleCancel, className, ...rest }) => {
  return (
    <div className={`d-flex justify-content-end ${className}`} {...rest}>
      <Button
        type="button"
        className="mr-2"
        variant="secondary"
        onClick={(e) => handleCancel(e)}
      >
        {'Cancelar'}
      </Button>
      <SubmitButton
        action={action}
        loading={loading}
      />
    </div>
  )
}

FooterForm.propTypes = {
  action: PropTypes.string,
  loading: PropTypes.bool,
  handleCancel: PropTypes.func,
  className: PropTypes.string
}

export default FooterForm
