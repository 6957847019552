import React from 'react'
import PropTypes from 'prop-types'

const EmptyMessage = ({
  message = 'No hay registros',
  ...props
}) => {
  return(
    <p
      style={{ padding: '4px' }}
      {...props}
    >
      {message}
    </p>
  )
}

EmptyMessage.propTypes = {
  message: PropTypes.string
}

export default EmptyMessage
