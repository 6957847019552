import { gql } from '@apollo/client'

const GET_HISTORY_FILES = gql`
  query getHistoryFiles($input: HistoryFilesInput!) {
    getHistoryFiles(input: $input) {
      historyFiles {
        id
        id_medical_history_player
        name
        mime_type
        comments
        key
        date
      }
      status{
        success
        code
        message
      }
    }
  }
`

export default GET_HISTORY_FILES
