import { gql } from '@apollo/client'

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      status {
        success
        code
        message
      }
    }
  }
`

export default UPDATE_USER
