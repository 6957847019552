import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import hasRole from '../../../../../../keycloak/utils/hasRole'
import AuthorizedElement from '../../../../../../keycloak/utils/AuthorizedElement'
import { Formik } from 'formik'
import { useQuery, useMutation } from '@apollo/client'
import { GET_EVALUATION_OBJECTIVES, GET_RETURN_PLAY } from '../../../../../../apollo/queries'
import { CREATE_EVALUATION_OBJECTIVE, UPDATE_EVALUATION_OBJECTIVE, DELETE_EVALUATION_OBJECTIVE } from '../../../../../../apollo/mutations'
import { Button, Form, Col } from 'react-bootstrap'
import { FaPen, FaTrash } from 'react-icons/fa'
import { SubmitButton } from '../../../../../../components'
import Styles from './EvaluationFormStyle'

const EvaluationForm = ({
  action = 'CREATE',
  idDiagnostic,
  values = {
    date: new Date(),
    text: '',
    id_return_play: null
  },
  setAdding = () => {}
}) => {
  const canUpdate = hasRole(['permission_team-update-player-eva-objectives'])
  const dateRef = React.useRef(null)
  const textRef = React.useRef(null)
  const returnPlayRef = React.useRef(null)

  const [deleted, setDeleted] = React.useState(false)
  const [editing, setEditing] = React.useState(false)
  // const refetchQueries = [{
  //   query: GET_EVALUATION_OBJECTIVES,
  //   variables: { input: { id_diagnostic: idDiagnostic } }
  // }]

  React.useEffect(() => {
    function handler(event) {
      if(
        canUpdate &&
        action === 'EDIT' &&
        (dateRef.current?.contains(event.target) ||
        dateRef.current?.contains(event.target) ||
        returnPlayRef.current?.contains(event.target))
      ) {
        setEditing(true)
      }
    }
    window.addEventListener('click', handler)
    return () => window.removeEventListener('click', handler)
  }, [])

  const returnPlay = useQuery(GET_RETURN_PLAY)

  const [deleteTreatment] = useMutation(DELETE_EVALUATION_OBJECTIVE)

  const [mutation, mutationResult] = useMutation(
    action === 'CREATE' ? CREATE_EVALUATION_OBJECTIVE : UPDATE_EVALUATION_OBJECTIVE,
    {
      update: (cache, { data }) => {
        cache.modify({
          fields: {
            getEvaluationObjectives(existingEva = []) {
              const newEva = data.getEvaluationObjectives
              cache.writeQuery({
                query: GET_EVALUATION_OBJECTIVES,
                data: {
                  newEva,
                  ...existingEva
                }
              })
            }
          }
        })
      }
    }
  )

  const handleDelete = (event) => {
    event.preventDefault()
    if (window.confirm('Esta acción es irreversible, ¿Estás seguro de continuar?')) {
      deleteTreatment({
        variables: {
          input: {
            id: values.id
          }
        }
      }).then(({ data }) => {
        if (data?.deleteEvaluationObjective.status.success) {
          setDeleted(true)
        }
      })
    }
  }

  return (
    <Styles>
      {action === 'CREATE' && <p>Agrega una evaluación o objetivo al diagnóstico:</p>}
      {!deleted &&
        <Formik
          initialValues={values}
          validate={values => {
            const errors = {}
            if (!values.date) {
              errors.date = 'Debes ingresar una fecha.'
            }
            if (!values.text) {
              errors.text = 'Debes ingresar una descripción.'
            }
            return errors
          }}
          onSubmit={(values, { resetForm }) => {
            const diagnostic = action === 'CREATE' ? { id_diagnostic: idDiagnostic } : {}
            mutation({
              variables: {
                input: {
                  ...values,
                  ...diagnostic,
                  id_return_play: parseInt(values.id_return_play, 10)
                }
              }
            }).then(({ data }) => {
              if (action === 'CREATE') {
                if (data.createEvaluationObjective.status.success) {
                  resetForm({ values: '' })
                  setAdding(false)
                }
              } else {
                if (data.updateEvaluationObjective.status.success) {
                  setEditing(false)
                }
              }
              // modalHeaderRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
            })
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
            setFieldValue
          }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} className="date">
                  <Form.Label>Fecha</Form.Label>
                  {window.innerWidth > 576 ?
                    <div ref={dateRef}>
                      <DatePicker
                        showYearDropdown
                        name="date"
                        locale="es"
                        wrapperClassName="w-100"
                        dateFormat="dd/MM/yyyy"
                        readOnly={canUpdate ? false : true}
                        selected={values.date || new Date()}
                        onChange={date => setFieldValue('date', date)}
                        onBlur={handleBlur}
                        customInput={
                          <Form.Control
                            required
                            as="input"
                            placeholder="Selecciona una fecha"
                            className={(action === 'EDIT' && !editing) && 'edit-input'}
                            isInvalid={touched.date && errors.date ? true : false}
                          />
                        }
                      />
                    </div>
                    :
                    <Form.Control
                      required
                      as="input"
                      type="date"
                      name="date"
                      placeholder="Selecciona una fecha"
                      plaintext={canUpdate ? false : true}
                      readOnly={canUpdate ? false : true}
                      ref={dateRef}
                      className={(action === 'EDIT' && !editing) && 'edit-input'}
                      value={
                        typeof values.date === 'string'
                          ? values.date
                          : new Date(
                            values.date - (values.date.getTimezoneOffset() * 60000)
                          ).toISOString().split('T')[0]
                      }
                      onChange={handleChange}
                      isInvalid={touched.date && errors.date ? true : false}
                    />
                  }
                  {/* {(touched.date && errors.date) &&
                          <div className="invalid-feedback d-block">
                            {errors.date}
                          </div>
                        } */}
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>Hitos Reintegro</Form.Label>
                  <Form.Control
                    as="select"
                    name="id_return_play"
                    placeholder="Selecciona un medicamento"
                    disabled={canUpdate ? false : true}
                    ref={returnPlayRef}
                    className={`${(action === 'EDIT' && !editing) && 'edit-input'} text`}
                    value={values.id_return_play ||''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      (touched.id_return_play && errors.id_return_play)
                        ? true
                        : false
                    }
                  >
                    <option>Selecciona un opción</option>
                    {returnPlay.data?.getReturnPlay.returnPlay.map((returnPlay, key) =>
                      <option key={key} value={returnPlay.id}>{returnPlay.name}</option>
                    )}
                  </Form.Control>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Evaluación/Objetivos</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="text"
                    placeholder="Ingresa un texto"
                    plaintext={canUpdate ? false : true}
                    readOnly={canUpdate ? false : true}
                    ref={textRef}
                    rows={editing || action === 'CREATE' ? 4 : 1}
                    className={`${(action === 'EDIT' && !editing) && 'edit-input'} text`}
                    value={values.text}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      (touched.text && errors.text)
                        ? true
                        : false
                    }
                  />
                </Form.Group>

                <Form.Group as={Col}>
                  {/* {action === 'CREATE' && <Form.Label>Acciones</Form.Label>} */}
                  {editing === true ?
                    <div style={{ marginTop: '32px' }}>
                      <AuthorizedElement roles={['permission_team-update-player-eva-objectives']}>
                        <SubmitButton
                          action={action}
                          loading={mutationResult.loading}
                        />
                      </AuthorizedElement>
                      <Button
                        type="button"
                        variant="secondary"
                        className="ml-2"
                        onClick={() => setEditing(false)}
                      >
                        Cerrar
                      </Button>
                    </div> :

                    (action === 'CREATE' ?
                      <div style={{ marginTop: '32px' }}>
                        <AuthorizedElement roles={['permission_team-create-player-eva-objectives']}>
                          <SubmitButton
                            action={action}
                            loading={mutationResult.loading}
                          />
                        </AuthorizedElement>
                        <Button
                          type="button"
                          variant="secondary"
                          className="ml-2"
                          onClick={() => setAdding(false)}
                        >
                          Cerrar
                        </Button>
                      </div> :
                      <div style={{ marginTop: '32px' }}>
                        <AuthorizedElement roles={['permission_team-update-player-eva-objectives']}>
                          <Button
                            type="button"
                            variant="warning"
                            className="mr-2"
                            onClick={() => setEditing(true)}
                          >
                            <FaPen color="white"/>
                          </Button>
                        </AuthorizedElement>
                        <AuthorizedElement roles={['permission_team-delete-player-eva-objectives']}>
                          <Button
                            type="button"
                            variant="danger"
                            onClick={(event) => handleDelete(event)}
                          >
                            <FaTrash />
                          </Button>
                        </AuthorizedElement>
                      </div>
                    )
                  }
                </Form.Group>

              </Form.Row>
            </Form>
          )}
        </Formik>
      }
    </Styles>
  )
}

EvaluationForm.propTypes = {
  action: PropTypes.string,
  idDiagnostic: PropTypes.number,
  values: PropTypes.shape({
    date: PropTypes.instanceOf(Date),
    text: PropTypes.string
  }),
  setAdding: PropTypes.func
}

export default EvaluationForm
