import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FaSearch } from 'react-icons/fa'
import { components } from 'react-select'
import { useLazyQuery } from '@apollo/client'
import { SEARCH_PLAYERS } from '../../apollo/queries'
import { AsyncSelect } from '../Form/AsyncSelect'
import Styles from './SearchPlayerStyle'

const SearchPlayer = () => {
  let history = useHistory()

  const [touched, setTouched] = useState(false)
  const [defaultOptions, setDefaultOptions] = useState([])
  const [searchPlayer] = useLazyQuery(SEARCH_PLAYERS, {
    fetchPolicy: 'no-cache'
  })

  const handleOnChange = (option) => {
    if (option?.categoryId && option?.value) {
      history.push(`/player-info/${option.categoryId}/${option.value}/clinical-event`)
    }
  }

  const handleOptions = (options = []) => {
    return options.map(p => ({
      label: `${p.User.last_name} ${p.User.name}`,
      value: p.id,
      categoryId: p.id_category_type
    }))
  }

  const handleSearch = async (value) => {
    setTouched(true)

    const { data } = await searchPlayer({ variables: { input: { search_query: value } } })
    const options = handleOptions(data?.searchPlayers.players)

    setDefaultOptions(options)
    return options
  }

  return (
    <Styles>
      <AsyncSelect
        placeholder="Buscar Deportista"
        defaultOptions={defaultOptions}
        noOptionsMessage={!touched ? (() => 'Escribe para buscar un deportista') : undefined }
        loadOptionsFunction={handleSearch}
        onChange={(option) => handleOnChange(option)}
        customComponents={{
          DropdownIndicator: (props) => (
            <components.DropdownIndicator {...props}>
              <FaSearch />
            </components.DropdownIndicator>
          )
        }}
        styles={{
          menu: (baseStyles) => ({
            ...baseStyles,
            zIndex: '999'
          }),
        }}
      />
    </Styles>
  )
}

export default SearchPlayer
