import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'
import hasRole from '../../keycloak/utils/hasRole'
import AuthorizedElement from '../../keycloak/utils/AuthorizedElement'
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarContent,
} from 'react-pro-sidebar'
import {
  FaBars,
  FaCalendarAlt,
  FaClipboardCheck,
  FaUserFriends,
  FaUserAlt
} from 'react-icons/fa'
import { appStore } from '../../store/appStore'
import { EventForm } from '../../views/Calendar/EventForm'
import { MedicalStaffForm } from '../../views/MedicalStaff/MedicalStaffForm'
import { PlayerForm } from '../../views/Players/PlayerForm'
import constants from '../../config/constants'
import Styles from './AsideStyle'
import 'react-pro-sidebar/dist/css/styles.css'

const teamSubmenus = [
  'show-players',
  'availabilities-overview',
  'show-massive-gps',
  'show-medical-estatistics',
  'show-team-autoreport',
  'show-massive-loads',
  'show-massive-nutri',
  'show-player-kinesic-evaluation',
  'show-team-kinesic-dimension'
]

const Aside = () => {
  const history = useHistory()
  const { state, dispatch } = React.useContext(appStore)
  const { keycloak } = useKeycloak()

  const ProSidebarRef = React.useRef(null)

  history.listen(() => {
    // dispatch({ type: 'collapseAside' })
  })

  React.useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        !document.getElementById('HeaderMenuIcon').contains(event.target) &&
        !ProSidebarRef.current.contains(event.target)
      ) {
        dispatch({ type: 'collapseAside' })
      }
    }

    if (!state.asideCollapsed && window.innerWidth < 768) {
      window.addEventListener('click', handleOutsideClick)
      return () => window.removeEventListener('click', handleOutsideClick)
    }
  }, [state.asideCollapsed])

  const [eventModal, setEventModal] = React.useState(false)
  const [userModal, setUserModal] = React.useState(false)
  const [playerModal, setPlayerModal] = React.useState(false)
  const [activeMenu, setActiveMenu] = React.useState(false)

  React.useEffect(() => {
    if (!state.asideCollapsed && window.innerWidth < 768) {
      dispatch({ type: 'collapseAside' })
    }
  }, [activeMenu])

  return (
    <Styles>
      <ProSidebar
        ref={ProSidebarRef}
        image={false}
        collapsed={state.asideCollapsed}
        className={`${state.asideCollapsed && 'aside-hidden'}`}
      >
        <SidebarHeader>
          <div
            style={{
              padding: '20px',
              textTransform: 'uppercase',
              fontWeight: 'bold',
              fontSize: 20,
              letterSpacing: '1px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            <div className='d-flex'>
              <button
                onClick={() => dispatch({ type: 'collapseAside' })}
                className="collapse-button"
              >
                <FaBars color="#fff" size={20}/>
              </button>
              <Link
                to="/"
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  marginLeft: '10px'
                }}
              >
                <img
                  className='logoReconquer'
                  width="170px"
                  src={
                  `${constants.STATIC_IMAGES_URL}/reconquer-logo.png`
                  }
                />
              </Link>
            </div>
          </div>
        </SidebarHeader>

        <SidebarContent>
          <Menu iconShape="circle">
            {hasRole(['permission_manage-team']) &&
              <SubMenu
                className={teamSubmenus.includes(activeMenu) ? 'active' : ''}
                suffix={<span className="badge yellow"></span>}
                title="Planteles"
                icon={<FaClipboardCheck />}
              >
                <AuthorizedElement roles={['permission_team-show-players']}>
                  <MenuItem active={activeMenu === 'show-players' } >
                    <Link
                      onClick={() => setActiveMenu('show-players')}
                      to="/players"
                    >
                      {'Deportistas'}
                    </Link>
                  </MenuItem>
                </AuthorizedElement>
                <AuthorizedElement roles={['permission_team-show-availabilities-overview']}>
                  <MenuItem active={activeMenu === 'availabilities-overview' }>
                    <Link
                      onClick={() => setActiveMenu('availabilities-overview')}
                      to="/summary"
                    >
                      {'Resumen Diario'}
                    </Link>
                  </MenuItem>
                </AuthorizedElement>
                <AuthorizedElement roles={['permission_team-show-massive-gps']}>
                  <MenuItem active={activeMenu === 'show-massive-gps' }>
                    <Link
                      onClick={() => setActiveMenu('show-massive-gps')}
                      to="/gps"
                    >
                      {'GPS'}
                    </Link>
                  </MenuItem>
                </AuthorizedElement>
                <AuthorizedElement roles={['permission_team-show-medical-estatistics']}>
                  <MenuItem active={activeMenu === 'show-medical-estatistics' }>
                    <Link
                      onClick={() => setActiveMenu('show-medical-estatistics')}
                      to="/clinical-dashboard"
                    >
                      {'Estadísticas Médicas'}
                    </Link>
                  </MenuItem>
                </AuthorizedElement>
                <AuthorizedElement roles={['permission_team-show-team-autoreport', 'permission_team-show-team-biomedical']}>
                  <MenuItem active={activeMenu === 'show-team-autoreport' }>
                    <Link
                      onClick={() => setActiveMenu('show-team-autoreport')}
                      to="/autoreport"
                    >
                      {'Autoreporte'}
                    </Link>
                  </MenuItem>
                </AuthorizedElement>
                <AuthorizedElement roles={['permission_team-show-massive-nutri']}>
                  <MenuItem
                    active={activeMenu === 'show-massive-nutri'}
                  >
                    <Link
                      onClick={() => setActiveMenu('show-massive-nutri')}
                      to="/nutrition-team"
                    >
                      {'Nutrición'}
                    </Link>
                  </MenuItem>
                </AuthorizedElement>
                <AuthorizedElement roles={['permission_team-show-team-kinesic-dimension']}>
                  <MenuItem
                    active={activeMenu === 'show-team-kinesic-dimension'}
                  >
                    <Link
                      onClick={() => setActiveMenu('show-team-kinesic-dimension')}
                      to="/kinesic-treatment-team"
                    >
                      {'Tratamiento Kinésico'}
                    </Link>
                  </MenuItem>
                </AuthorizedElement>
                <AuthorizedElement roles={['permission_team-show-massive-loads']}>
                  <MenuItem
                    active={activeMenu === 'show-massive-loads'}
                  >
                    <Link
                      onClick={() => setActiveMenu('show-massive-loads')}
                      to="/massive-loads"
                    >
                      {'Cargas Masivas'}
                    </Link>
                  </MenuItem>
                </AuthorizedElement>
              </SubMenu>
            }

            {hasRole(['role_player']) &&
              <AuthorizedElement roles={['permission_team-show-autoreport', 'permission_team-show-biomedical']}>
                <MenuItem
                  className={activeMenu === 'player-home' ? 'active' : ''}
                  onClick={() => setActiveMenu('player-home')}
                  icon={<FaUserAlt />}
                >
                  <Link to={`/player-info/${state.player?.id_category_type}/${state.player?.id}/autoreport`}>
                    {'Resumen Médico'}
                  </Link>
                </MenuItem>
              </AuthorizedElement>
            }

            {hasRole(['permission_manage-calendar']) &&
              <AuthorizedElement roles={['permission_calendar-list-events']}>
                <MenuItem
                  className={activeMenu === 'calendar-list-events' ? 'active' : ''}
                  onClick={() => setActiveMenu('calendar-list-events')}
                  icon={<FaCalendarAlt />}
                >
                  <Link to="/calendar/week">
                    {'Calendario'}
                  </Link>
                </MenuItem>
              </AuthorizedElement>
            }



            {hasRole(['permission_manage-staff']) &&
                <AuthorizedElement roles={['permission_staff-list-users']}>
                  <MenuItem
                    className={activeMenu === 'staff-list-users' ? 'active' : ''}
                    icon={<FaUserFriends />}
                    onClick={() => setActiveMenu('staff-list-users')}
                  >
                    <Link to="/staff">
                      {'Equipo Médico'}
                    </Link>
                  </MenuItem>
                </AuthorizedElement>
            }
            <hr/>
            {(hasRole(['permission_manage-analytics']) && state.tenant?.shinny_active) &&
              <AuthorizedElement roles={['permission_analytics-show-analytics']}>
                <MenuItem
                  className={activeMenu === 'analytics-show-analytics' ? 'active' : ''}
                  onClick={() => setActiveMenu('analytics-show-analytics')}
                  icon={ <img
                    width="15px"
                    src={
                    `${constants.STATIC_IMAGES_URL}/investigation_icon.svg`
                    }
                  />}
                  suffix={<span className="badge red"></span>}
                >
                  <Link to="/analytics">
                    {'Ciencia de Datos'}
                  </Link>
                </MenuItem>
              </AuthorizedElement>
            }

            <MenuItem
              className={activeMenu === 'logout' ? 'active' : ''}
              icon={ <img
                width="15px"
                src={
                  `${constants.STATIC_IMAGES_URL}/logout_icon.svg`
                }
              />}
              suffix={<span className="badge red"></span>}
              onClick={() => {
                setActiveMenu('logout')
                localStorage.removeItem('token')
                keycloak?.logout()
              }}
            >
              {'Cerrar Sesión'}
            </MenuItem>
          </Menu>
        </SidebarContent>
        <footer className='text-center'>
          { <img
            width="35px"
            className='footer-logo'
            src={
            `${constants.STATIC_IMAGES_URL}/triceps-isotipo.svg`
            }
          />}
          <p className="version">Powereded by<a href="https://tricepsmda.com/" target="_blank" rel="noreferrer"> TRICEPS</a></p>
          <hr className='my-1' />
          <p className="version">Ver. {constants.FRONT_VERSION}</p>
        </footer>
      </ProSidebar>

      {eventModal &&
        <EventForm
          openModal={eventModal}
          setOpenModal={(value) => setEventModal(value)}
          singleEvent={{
            action: 'CREATE',
            id_event_type: '',
            id_category_type: '',
            start_date: new Date(),
            end_date: new Date(),
          }}
        />
      }

      {userModal &&
        <MedicalStaffForm
          openModal={userModal}
          setOpenModal={(value) => setUserModal(value)}
        />
      }

      {playerModal &&
        <PlayerForm
          openModal={playerModal}
          setOpenModal={(value) => setPlayerModal(value)}
          values={{
            action: 'CREATE',
            player: {
              name: '',
              last_name: '',
              email: '',
              birthday: new Date(),
              gender: '1',
              id_category_type: state.defaultCategoryType,
              id_position_type: 1,
              side: 1,
              height: 0.0,
              initial_weight: 0.0,
              id_availability_condition_type: 1
            },
            user: { id: null }
          }}
        />
      }
    </Styles>

  )
}

export default Aside
