const useNumberToLetter = () => {
  function NumberToLetter(num){
    let s = '', t
    while (num > 0) {
      t = (num -1) % 26
      s = String.fromCharCode(65 + t) + s
      num = (num - t)/26 | 0
    }
    return s || undefined
  }
  return { NumberToLetter }
}

export default useNumberToLetter

//Ejemplos:
// usar (0+1)
// (0) // undefined
// (1) // A
// (26) // Z
// (-1) // undefined
// (27) // AA
// (475254) // ZZZZ

