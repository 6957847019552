import React from 'react'
import AuthorizedElement from '../../../keycloak/utils/AuthorizedElement'
import moment from 'moment'
import ReactToolTip from 'react-tooltip'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from '@apollo/client'
import { GET_PLAYER_DIMENSIONS } from '../../../apollo/queries'
import { DELETE_PLAYER_DIMENSION } from '../../../apollo/mutations'
import { Button, Table } from 'react-bootstrap'
import { FaPen, FaTrash, FaPlus } from 'react-icons/fa'
import { FilterRow, DateFilter, Loading } from '../../../components'
import Styles from './MasotherapyStyle'
import usePlayerDimension from '../usePlayerDimension'
import MasotherapyModal from './MasotherapyModal/MasotherapyModal'

const Masotherapy = ({ idPlayer }) => {
  const [openModal, setOpenModal] = React.useState(false)
  const [action, setAction] = React.useState('')
  const [updateValues, setUpdateValues] = React.useState({})

  const [filters, setFilters] = React.useState({
    id_player: idPlayer,
    id_dimension: 15, //MASOTHERAPIST
    id_diagnostic: null,
    start_date: new Date(moment().subtract(6, 'months')),
    end_date: new Date()
  })

  const { data, loading , error } = useQuery(GET_PLAYER_DIMENSIONS, {
    variables: {
      input: {
        ...filters,
        start_date: moment(filters.start_date).format('YYYY-MM-DD'),
        end_date: moment(filters.end_date).format('YYYY-MM-DD')
      }
    }
  })

  const { dimension, parseMeters, allOptions, parseAllMeters } = usePlayerDimension(null, null, 15)

  const updateCache = (cache, { data }) => {
    cache.modify({
      fields: {
        getPlayerDimensions(existingPlayerDimensions = []) {
          const newPlayerDimensions = data.getPlayerDimensions
          cache.writeQuery({
            query: GET_PLAYER_DIMENSIONS,
            data: {
              newPlayerDimensions,
              ...existingPlayerDimensions
            }
          })
        }
      }
    })
  }

  const [deletePlayerDimension] = useMutation(DELETE_PLAYER_DIMENSION, {
    update: updateCache
  })

  const handleDelete = (event, id) => {
    event.preventDefault()
    if (window.confirm('Esta acción es irreversible, ¿Estás seguro de borrar esta medición?')) {
      deletePlayerDimension({
        variables: {
          input: { id }
        }
      })
    }
  }

  if (loading) {
    return (
      <Loading />
    )
  }

  if (error) {
    return (
      <div>Error :(</div>
    )
  }

  const { playerDimensions } = data.getPlayerDimensions

  return (
    <Styles>
      <div className="row">
        <div className="col d-flex flex-wrap align-items-baseline justify-content">
          <h5 className="mr-auto text-secondary">
               Historial Masoterapia
          </h5>
          <FilterRow>
            <DateFilter
              value={{
                start_date: filters.start_date,
                end_date: filters.end_date
              }}
              setValue={setFilters}
            />
          </FilterRow>
          <div className='d-lg-block d-none'>
            <AuthorizedElement roles={['permission_team-create-masotherapy']}>
              <Button
                className="mb-2"
                onClick={() => {
                  setOpenModal(true)
                  setAction('CREATE')
                }}
              >
                <FaPlus className="mb-1 mr-2" color={'white'} />
                 Crear Masoterapia
              </Button>
            </AuthorizedElement>
          </div>
        </div>
      </div>
      <div className='d-block d-lg-none'>
        <AuthorizedElement roles={['permission_team-create-masotherapy']}>
          <Button
            className='mb-3' variant="primary" block
            onClick={() => {
              setOpenModal(true)
              setAction('CREATE')
            }}
          >
            <FaPlus className="mb-1 mr-2" color={'white'} />
              Crear Masoterapia
          </Button>
        </AuthorizedElement>
      </div>
      <div className="row">
        <div className="col">
          <Table className='table-font table-responsive-lg' striped bordered hover size="sm" >
            <thead>
              <tr>
                <th className="date">Fecha</th>
                <th>Tipo de Masaje</th>
                <th>Musculo/Región</th>
                <th>Lateralidad</th>
                <th>Comentarios</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {playerDimensions.map(playerDimension => {
                return (
                  <tr key={playerDimension.id}>
                    <td className="text-no-break">{moment(playerDimension.date).format('DD/MM/YYYY')}</td>
                    <td>
                      <ul>
                        {dimension.data?.getDimension?.dimension.DimensionTests.map(test => {
                          const meters = parseMeters(playerDimension.meters, test.id)
                          return (
                            (meters[0]?.value || meters[1]?.value) &&
                              <li key={test.id}>
                                {test.name}
                              </li>
                          )
                        })}
                      </ul>
                    </td>
                    <td>
                      <ul>
                        {dimension.data?.getDimension?.dimension.DimensionTests.map(test => {
                          const meters = parseMeters(playerDimension.meters, test.id)
                          return (
                            (meters[0]?.value || meters[1]?.value) &&
                            <li key={meters[0]?.id}>
                              {meters[0]?.value ? allOptions.get(meters[0]?.value) : '--'}
                            </li>
                          )
                        })}
                      </ul>
                    </td>
                    <td>
                      <ul>
                        {dimension.data?.getDimension?.dimension.DimensionTests.map(test => {
                          const meters = parseMeters(playerDimension.meters, test.id)
                          return (
                            (meters[0]?.value || meters[1]?.value) &&
                            <li key={meters[1]?.id}>
                              {meters[1]?.value ? allOptions.get(meters[1]?.value) : '--'}
                            </li>
                          )
                        })}
                      </ul>
                    </td>
                    <td className="comments">
                      {playerDimension.comments ? playerDimension.comments : '--'}
                    </td>
                    <td>
                      <div className="d-flex justify-content-center">
                        <AuthorizedElement roles={['permission_team-update-masotherapy']}>
                          <Button
                            variant="warning"
                            size="sm"
                            data-tip data-for="detail"
                            className="mx-1"
                            onClick={() => {
                              setOpenModal(true)
                              setAction('EDIT')
                              setUpdateValues({
                                id: playerDimension.id,
                                date: new Date(moment(`${playerDimension.date} 12:00`)),
                                ...parseAllMeters(playerDimension.meters).reduce((acc, current) =>
                                  ({ ...acc, [current.id]: current.value?.toString() })
                                , {})
                              })
                            }}
                          >
                            <FaPen color={'white'}/>
                            <ReactToolTip
                              id="detail"
                              place="top"
                              effect="solid"
                              type="info"
                            >
                            Editar
                            </ReactToolTip>
                          </Button>
                        </AuthorizedElement>
                        <AuthorizedElement roles={['permission_team-delete-masotherapy']}>
                          <Button
                            variant="danger"
                            size="sm"
                            data-tip data-for="delete"
                            className="mx-1"
                            onClick={(event) => handleDelete(event, playerDimension.id)}
                          >
                            <FaTrash color={'white'}/>
                            <ReactToolTip
                              id="delete"
                              place="top"
                              effect="solid"
                              type="info"
                            >
                              Eliminar
                            </ReactToolTip>
                          </Button>
                        </AuthorizedElement>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>
      </div>

      {openModal &&
        <MasotherapyModal
          idPlayer={idPlayer}
          openModal={openModal}
          setOpenModal={(value) => setOpenModal(value)}
          action={action}
          updateValues={updateValues}
          dimension={dimension.data?.getDimension?.dimension}
        />
      }
    </Styles>
  )
}

Masotherapy.propTypes = {
  idPlayer: PropTypes.number
}

export default Masotherapy
