import { gql } from '@apollo/client'

const GET_MASSIVE_LOAD_CONFIGS = gql`
  query getMassiveLoadConfigs {
    getMassiveLoadConfigs {
      massiveLoadConfigs {
        id
        name
      }
      status {
        success
        code
        message
      }
    }
  }
`

export default GET_MASSIVE_LOAD_CONFIGS
