import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { useParams } from 'react-router-dom'
import { Button, Form, Modal } from 'react-bootstrap'
import { FaCheck } from 'react-icons/fa'
import { useMutation } from '@apollo/client'
import { CREATE_PLAYER_GPS } from '../../../apollo/mutations'
import { GET_GPS_MOMENTS, GET_GPS_MOMENT_DESCRIPTIONS, GET_PLAYER_GPS } from '../../../apollo/queries'
import { DatePickerInput, SelectFilter, StatusAlert, SubmitButton } from '../../../components'
import PlayerGpsStyle from './PlayerGpsStyle'

const PlayerGpsModal = ({ gps, openModal, setOpenModal }) => {
  const modalHeaderRef = useRef(null)
  const { id_player, id_category_type } = useParams()

  const initialValues = {
    id_player: parseInt(id_player, 10),
    id_category_type: parseInt(id_category_type, 10),
    id_gps_moment: '1',
    id_gps_moment_desc: '1',
    activity: '1',
    gps_values: '',
    date: new Date(),
  }

  const [gpsValues, setGpsValues] = useState({})

  const update = (cache, { data }) => {
    cache.modify({
      fields: {
        getPlayerGps(existingData = []) {
          const newData = data.getPlayerGps
          cache.writeQuery({
            query: GET_PLAYER_GPS,
            data: {
              newData,
              ...existingData
            }
          })
        }
      }
    })
  }

  const [create, createResult] = useMutation(CREATE_PLAYER_GPS, { update })
  const handleClose = () => setOpenModal(false)

  return (
    <Modal
      size="lg"
      show={openModal}
      dialogClassName="full-width"
      contentClassName="full-height"
      onHide={handleClose}
    >
      <Modal.Header
        closeButton
        ref={modalHeaderRef}
        className="bg-success text-white align-items-center justify-content-space-between"
      >
        <Modal.Title>
          <FaCheck className="mr-1" color={'white'} />
          {'Crear GPS'}
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => {
          create({
            variables: {
              input: {
                ...values,
                activity: parseInt(values.activity, 10),
                id_gps_moment: parseInt(values.id_gps_moment, 10),
                id_gps_moment_desc: parseInt(values.id_gps_moment, 10),
                gps_values: JSON.stringify(gpsValues)
              }
            }
          }).then(({ data }) => {
            if (data.createPlayerGps.status.success) {
              resetForm({ values: '' })
            }
            modalHeaderRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
          })
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            {createResult.data &&
              <StatusAlert
                successMessage={'GPS creado con éxito.'}
                status={createResult.data.createPlayerGps.status}
              />
            }
            <PlayerGpsStyle.Modal>
              <Modal.Body>
                <Form.Row>
                  <DatePickerInput
                    id="date"
                    name="date"
                    width="col-12 col-md-4 mb-4"
                    value={values.date}
                    onChange={handleChange}
                    setFieldValue={setFieldValue}
                    isInvalid={(touched.date && errors.date)}
                  />
                </Form.Row>
                <Form.Row>
                  <SelectFilter
                    id="activity"
                    name="activity"
                    label="Actividad"
                    className="col-12 col-md-4 mb-4"
                    value={values.activity}
                    handleChange={handleChange}
                    options={
                      <>
                        <option value=''>Selecciona una actividad</option>
                        <option value='1'>Entrenamiento</option>
                        <option value='2'>Partido</option>
                      </>
                    }
                  />
                  <SelectFilter
                    id="gps_moment"
                    name="gps_moment"
                    label="Momento"
                    placeholder="Selecciona un momento"
                    className="col-12 col-md-4 mb-4"
                    queryName="getGpsMoments"
                    mapName="gpsMoments"
                    query={GET_GPS_MOMENTS}
                    value={values.id_gps_moment}
                    handleChange={handleChange}
                  />
                  <SelectFilter
                    id="id_gps_moment_desc"
                    name="id_gps_moment_desc"
                    label="Descripción Momento"
                    placeholder="Selecciona una descripción"
                    className="col-12 col-md-4 mb-4"
                    queryName="getGpsMomentDescriptions"
                    mapName="gpsMomentDescriptions"
                    query={GET_GPS_MOMENT_DESCRIPTIONS}
                    value={values.id_gps_moment_desc}
                    handleChange={handleChange}
                  />
                </Form.Row>
                <Form.Row>
                  {gps.map(g =>
                    <Form.Group key={g.id} className="col-12 col-md-4 mb-4">
                      <Form.Label>
                        {g.name}
                      </Form.Label>
                      <Form.Control
                        placeholder="Escriba el nombre de GPS"
                        value={values[g.name]}
                        onChange={(e) =>
                          setGpsValues((currentState) => ({
                            ...currentState,
                            [g.id]: e.target.value
                          }))
                        }
                        onBlur={handleBlur}
                        isInvalid={
                          (touched.date && errors.date)
                            ? true
                            : false
                        }
                      />
                    </Form.Group>
                  )}
                </Form.Row>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={handleClose}
                >
                  {'Cerrar'}
                </Button>
                <SubmitButton
                  loading={createResult.loading}
                  className="btn list-item px-6"
                />
              </Modal.Footer>
            </PlayerGpsStyle.Modal>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

PlayerGpsModal.propTypes = {
  gps: PropTypes.array.isRequired,
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired
}

export default PlayerGpsModal
