import { gql } from '@apollo/client'

const UPDATE_DIAGNOSTIC_MEDICINE = gql`
  mutation updateDiagnosticMedicine($input: UpdateDiagnosticMedicinenput!) {
    updateDiagnosticMedicine(input: $input) {
      status {
        success
        code
        message
      }
    }
  }
`

export default UPDATE_DIAGNOSTIC_MEDICINE
