import { gql } from '@apollo/client'

const GET_AVAILABLE_SCHEDULES = gql`
  query getAvailableSchedules($input: AvailableScheduleInput!) {
    getAvailableSchedules(input: $input) {
      profesionals {
        id
        name
        last_name
        UserType {
          id
          name_user
        }
        availableSchedules {
          start_hour
          format_start_hour
          duration
          date
        }
      }
      status {
        success
        code
        message
      }
    }
  }
`

export default GET_AVAILABLE_SCHEDULES
