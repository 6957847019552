import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import domainConfig from '../config/domains'

const httpLink = createHttpLink({
  // eslint-disable-next-line no-undef
  uri: `${domainConfig.apiUri}/graphql`
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token')
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    }
  }
})

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Player: {
        fields: {
          AvailabilityConditions: {
            // Short for always preferring incoming over existing data.
            merge: false
          },
          DailyMeasurements: {
            merge: false
          }
        }
      },
      Exam: {
        fields: {
          ExamImage: {
            // Short for always preferring incoming over existing data.
            merge: false
          }
        }
      }
    }
  })
})
