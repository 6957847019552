import React from 'react'
// import hasRole from '../../keycloak/utils/hasRole'
// import { Link } from 'react-router-dom'
import { FaBars } from 'react-icons/fa'
import { appStore } from '../../store/appStore'
// import constants from '../../config/constants'
import SearchPlayer from './SearchPlayer'
import Styles from './HeaderStyle'

const Header = () => {
  // const { state } = React.useContext(appStore)
  const { dispatch } = React.useContext(appStore)

  // const avatarType = hasRole(['role_player']) ? 'player' : 'avatar'
  // const gender = state.user?.gender === 2 ? 'femenino' : 'masculino'

  return (
    <Styles>
      <nav className="navbar">
        <FaBars
          id="HeaderMenuIcon"
          className="d-sm-block d-md-none"
          color="#000"
          onClick={() => dispatch({ type: 'collapseAside' })}
          size={20}
        />
        <div className="search-player">
          <SearchPlayer />
        </div>
        {/* <div className="">
          <a
            href="#"
            className="nav-link"
          >
            <img
              src={`${constants.STATIC_IMAGES_URL}/${avatarType}-${gender}.svg`}
              width="30px"
            />
          </a>
        </div> */}
      </nav>
      {/* <nav className="navbar d-flex justify-content-start d-md-none"> */}
      {/* <FaBars
          id="HeaderMenuIcon"
          className="d-sm-block d-md-none mr-3 mt-2"
          color="#000"
          onClick={() => dispatch({ type: 'collapseAside' })}
          size={20}
        /> */}
      {/* <Link to="/">
          <img
            width="120px"
            src={`${constants.STATIC_IMAGES_URL}/reconquer-logo.png`}
          />
        </Link> */}
      {/* </nav> */}
    </Styles>
  )
}

export default Header
