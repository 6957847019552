import React, { useContext } from 'react'
import moment from 'moment'
import AuthorizedElement from '../../keycloak/utils/AuthorizedElement'
import hasRole from '../../keycloak/utils/hasRole'
import Styles from './HomeStyle'
import constants from '../../config/constants'
import PropTypes from 'prop-types'
import ReactToolTip from 'react-tooltip'
import { useQuery } from '@apollo/client'
import { GET_CONDITIONS_COUNTERS, GET_DIAGNOSTIC_INJURY_COUNTERS, GET_TEAM_LAST_CLINICAL_EVENT } from '../../apollo/queries'
import { FaChartBar, FaCalendarAlt, FaClipboardCheck } from 'react-icons/fa'
import { Link, useHistory } from 'react-router-dom'
import { BsListCheck } from 'react-icons/bs'
import { FilterRow, CategoryFilter, Loading, CustomBreadcrumb, UserImage } from '../../components'
import { appStore } from '../../store/appStore'
import { Table } from 'react-bootstrap'

const Home = () => {
  const history = useHistory()
  const { state } = useContext(appStore)
  const [filter, setFilter] = React.useState({
    id_category_type: state.defaultCategoryType,
    date: moment().format('YYYY-MM-DD')
  })

  const { data, loading, error } = useQuery(GET_CONDITIONS_COUNTERS, {
    variables: {
      input: filter
    },
    fetchPolicy: 'network-only',
    skip: !hasRole(['permission_team-show-availabilities-overview'])
  })

  const { data: dataDiagnosticInjuryCounters, loading: injuryCountersLoading, error: injuryCountersError } = useQuery(GET_DIAGNOSTIC_INJURY_COUNTERS,{
    variables: {
      input: {
        id_category_type:filter.id_category_type
      }
    },
  })

  const { data: dataTeamLastClinicalEvent, loading: teamLastClinicalEventloading, error: teamLastClinicalEventError } = useQuery(GET_TEAM_LAST_CLINICAL_EVENT,{
    variables: {
      input: {
        id_category_type:filter.id_category_type
      }
    },
  })

  if (loading || injuryCountersLoading || teamLastClinicalEventloading ) {
    return (
      <Loading />
    )
  }

  if (error || injuryCountersError || teamLastClinicalEventError ) {
    return (
      <div>Error :(</div>
    )
  }

  return (
    <Styles>
      <AuthorizedElement roles={['permission_team-show-availabilities-overview']}>
        <div className="row d-flex m-3 justify-content-between">
          <CustomBreadcrumb
            active="Home"
          />
        </div>
        <div className='d-flex align-items-center justify-content-end'>
          <FilterRow>
            <p className='mb-0 mr-3'>Filtrar por: </p>
            <CategoryFilter
              value={filter.id_category_type}
              setValue={setFilter}
            />
          </FilterRow>
        </div>
        <br className='d-lg-none'/>
        <div className='section'>
          <h5 className="text-secondary text-lg-left text-center">
              Condición disponibilidad
          </h5>
          <div className='row'>
            <div className='col'>
              <div className='d-flex flex-wrap flex-column-sm align-items-strech mb-0'>
                <div
                  className="col btn-cards btn-cards-condition-1 list-item zoom"
                  onClick={() => history.push('/summary')}
                >
                  <h2 className="mb-0">{data?.getConditionsCounters.conditionsCounters.sintomatic}</h2>
                  <p className="mb-0">Apto para entrenar sintomático</p>
                </div>
                <div
                  className="col btn-cards  btn-cards-condition-2 list-item zoom"
                  onClick={() => history.push('/summary')}
                >
                  <h2 className="mb-0">{data?.getConditionsCounters.conditionsCounters.differentiated}</h2>
                  <p className="mb-0">Entrenamiento diferenciado</p>
                </div>
                <div
                  className="col btn-cards  btn-cards-condition-3 list-item zoom"
                  onClick={() => history.push('/summary')}
                >
                  <h2 className="mb-0">{data?.getConditionsCounters.conditionsCounters.abandon}</h2>
                  <p className="mb-0">Abandona entrenamiento <br></br> en observación</p>
                </div>
                <div
                  className="col btn-cards  btn-cards-condition-4 list-item zoom"
                  onClick={() => history.push('/summary')}
                >
                  <h2 className="mb-0">{data?.getConditionsCounters.conditionsCounters.illness}</h2>
                  <p className="mb-0">Enfermo</p>
                </div>
                <div
                  className="col btn-cards  btn-cards-condition-5 list-item zoom"
                  onClick={() => history.push('/summary')}
                >
                  <h2 className="mb-0">{data?.getConditionsCounters.conditionsCounters.injury}</h2>
                  <p className="mb-0">Lesionado</p>
                </div>
              </div>
            </div>
          </div>
          <hr className="hr-text gradient mb-2 mt-2" data-content="Totales"></hr>
          <div className='d-flex flex-wrap flex-column-sm align-items-strech'>
            <div
              className="col p-3 btn-cards list-item zoom"
              onClick={() => history.push('clinical-dashboard?diagnosticType=2')}
            >
              <h2 className="mb-0 text-center">{dataDiagnosticInjuryCounters.getDiagnosticInjuryCounters.diagnosticInjuryCounters.jointInjuries}</h2>
              <p className='mb-0 text-center'> Total lesiones articulares </p>
            </div>
            <div
              className="col p-3 btn-cards list-item zoom"
              onClick={() => history.push('clinical-dashboard?diagnosticType=2')}
            >
              <h2 className="mb-0 text-center">{dataDiagnosticInjuryCounters.getDiagnosticInjuryCounters.diagnosticInjuryCounters.muscleInjuries}</h2>
              <p className='mb-0 text-center'>Total lesiones musculares </p>
            </div>
          </div>
        </div>

        <div className='row'>
          <AuthorizedElement roles={['permission_team-show-player-clinic-events']}>
            <div className='section col bg-white border rounded m-3 p-3'>
              <h5 className='text-secondary text-lg-left text-center'>Lesiones</h5>
              <Table className='text-center align-items-center table-responsive-lg' striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Fecha de Inicio</th>
                    <th>Diágnostico</th>
                    <th>Lateralidad</th>
                    <th>Tipo</th>
                    <th>Condición</th>
                  </tr>
                </thead>
                <tbody>
                  {dataTeamLastClinicalEvent?.getTeamLastClinicalEvent?.teamLastClinicalEvent?.map((teamLastClinicalEvent,clinicalEvent, key) =>
                    <tr key={key}>
                      <td className='player-name'>
                        <Link to={`/clinical-event-details/${teamLastClinicalEvent.Player.id}/${teamLastClinicalEvent.id}`}>
                          <UserImage userId={teamLastClinicalEvent.Player.User.id} userGender={teamLastClinicalEvent.Player.User.gender} imageWidth="40px" />
                          <span className="m-2">{teamLastClinicalEvent.Player.User.last_name} {teamLastClinicalEvent.Player.User.name}</span>
                        </Link>
                      </td>
                      <td className='date'>{moment(teamLastClinicalEvent.Diagnostics[0].createdAt).format('DD/MM/YYYY')}</td>
                      <td className='td-diagnostic align-middle'>
                        <ul>
                          <li>{teamLastClinicalEvent.Diagnostics[0].Pathology.name}</li>
                          {teamLastClinicalEvent.Diagnostics[0].DiagnosticComplement &&
                        <li>{teamLastClinicalEvent.Diagnostics[0].DiagnosticComplement?.name}</li>
                          }
                          {teamLastClinicalEvent.Diagnostics[0].SkeletalMuscle ?
                            <li>{teamLastClinicalEvent.Diagnostics[0].SkeletalMuscle?.name}</li> :
                            <li>{teamLastClinicalEvent.Diagnostics[0].BodyZone?.name}</li>
                          }
                        </ul>
                      </td>
                      <td className='align-middle'>{teamLastClinicalEvent.Diagnostics[0].side === 1 ? 'Derecha' : teamLastClinicalEvent.Diagnostics[0].side === 2 ? 'Izquierda': teamLastClinicalEvent.Diagnostics[0].side === 3 ? 'Derecha e izquierda' : 'No aplica'}</td>
                      <td className='align-middle'>{teamLastClinicalEvent.Diagnostics[0].DiagnosticType.name}</td>
                      <td className='align-middle'> <div
                        data-tip data-for={`condition${teamLastClinicalEvent.Diagnostics[0].DiagnosticAvailabilities[0].AvailabilityCondition.AvailabilityConditionType.id}`}
                        className={`btn-circle zoom color${teamLastClinicalEvent.Diagnostics[0].DiagnosticAvailabilities[0].AvailabilityCondition.AvailabilityConditionType.id}`}>
                      </div>
                      <ReactToolTip
                        id={`condition${teamLastClinicalEvent.Diagnostics[0].DiagnosticAvailabilities[0].AvailabilityCondition.AvailabilityConditionType.id}`}
                        place="top"
                        effect="solid"
                        type="info"
                      >
                        {teamLastClinicalEvent.Diagnostics[0].DiagnosticAvailabilities[0].AvailabilityCondition.AvailabilityConditionType.name_availability_condition_type}
                      </ReactToolTip></td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </AuthorizedElement>
        </div>
        <hr className="hr-text gradient mb-2 mt-2" data-content="Accesos"></hr>
        <div className="row col m-2 d-flex flex-wrap">
          <AuthorizedElement roles={['permission_team-show-players']}>
            <div
              onClick={() => history.push('/players')}
              className="col m-3 p-3 btn-cards list-item zoom">
              <FaClipboardCheck
                size="40"
              />
              <h6 className="mt-2">Planteles</h6>
            </div>
          </AuthorizedElement>
          <AuthorizedElement roles={['permission_calendar-list-events']}>
            <div
              onClick={() => history.push('/calendar/week')}
              className="col m-3 p-3 btn-cards list-item zoom">
              <FaCalendarAlt
                size="40"
              />
              <h6 className="mt-2">Calendario</h6>
            </div>
          </AuthorizedElement>
          <AuthorizedElement roles={['permission_team-show-massive-gps']}>
            <div
              onClick={() => history.push('/gps')}
              className="col m-3 p-3 btn-cards list-item zoom">
              <FaChartBar
                size="40"
              />
              <h6 className="mt-2">GPS</h6>
            </div>
          </AuthorizedElement>
          <AuthorizedElement roles={['permission_team-show-availabilities-overview']}>
            <div
              onClick={() => history.push('/summary')}
              className="col m-3 p-3 btn-cards list-item zoom">
              <BsListCheck
                size="40"
              />
              <h6 className="mt-2">Resumen diario</h6>
            </div>
          </AuthorizedElement>
          <AuthorizedElement roles={['permission_team-show-players']}>
            <div
              onClick={() => history.push('/analytics')}
              className="col m-3 p-3 btn-cards list-item zoom">
              <img
                width="40px"
                src={
                  `${constants.STATIC_IMAGES_URL}/investigation_icon2.svg`
                }
              />
              <h6 className="mt-3">Ciencia de datos</h6>
            </div>
          </AuthorizedElement>
        </div>
      </AuthorizedElement>
    </Styles>
  )
}

Home.propTypes = {
  data: PropTypes.shape({
    allDiagnostics: PropTypes.shape({
      soreness: PropTypes.array,
      injury: PropTypes.array,
      illness: PropTypes.array,
      surgery: PropTypes.array
    })
  }),
}

export default Home
