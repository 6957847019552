import styled from 'styled-components'

export default styled.div`
  position: fixed;
  top: 0px;
  bottom: 0;
  z-index: 900;

  .collapse-button {
    background: none;
    border: none;
    outline: none;
    color: #fff;
  }

  .svg {
    color : #28a745;
  }

  .pro-sidebar-inner {
    color: #fff;
    background-color: #142c59 !important;
  }

  .pro-menu-item, .pro-menu-item a {
    color: #fff !important;
  }

  .pro-icon {
    color: #28a745;
    background-color: #fff;
    border-radius: 50%;
  }

  .pro-sidebar .pro-menu ul .pro-sub-menu .pro-inner-list-item {
    background-color: #142c59;
  }

  .pro-sidebar.collapsed .pro-menu ul .pro-menu-item.pro-sub-menu .pro-inner-list-item .popper-inner{
    background-color: #142c59;
  }

  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus {
    color: #fff;
    background-color: #28a745;
  }

  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:active {
    color: #fff;
    background-color: #28a745;
  }

  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
    color: #fff;
    background-color: #28a745;
  }


  @media (max-width: 767px) {
    .aside-hidden {
      margin-left: -80px;
    }
    .popper-inner {
      display: none;
    }
  }

  .version{
    text-align: center;
    font-size: 10px;
    color: #fff !important;
    margin-bottom: 3px;
  }

  hr{
    border: 1px solid #ffffff1f;
  }

  a{
    color: white;
  }

  .active{
    color: #fff !important;
    background-color: #28a745;
  }

  .logoReconquer{
    padding-right: 20px;
    margin-right: 10px;
  }
`
