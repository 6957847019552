import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import AuthorizedElement from '../../../../../keycloak/utils/AuthorizedElement'
import { useQuery } from '@apollo/client'
import { GET_EVALUATION_OBJECTIVES } from '../../../../../apollo/queries'
import { Modal, Button } from 'react-bootstrap'
import { FaCheck } from 'react-icons/fa'
import { Loading } from '../../../../../components'
import { EvaluationForm } from './EvaluationForm'
import Styles from './EvaluationStyle'

const Evaluation = ({ diagnosticId, openModal, setOpenModal }) => {
  const id_diagnostic = parseInt(diagnosticId, 10)
  const [adding, setAdding] = React.useState(false)

  const handleClose = () => setOpenModal(false)

  const { data, loading, error } = useQuery(GET_EVALUATION_OBJECTIVES, {
    variables: {
      input: {
        id_diagnostic: parseInt(diagnosticId, 10)
      }
    },
    fetchPolicy: 'network-only'
  })

  if (loading) {
    return (
      <Loading />
    )
  }

  if (error) {
    return (
      <div>Error :(</div>
    )
  }

  return (
    <Styles>
      <Modal
        size="lg"
        show={openModal}
        dialogClassName="full-width"
        contentClassName="full-height"
        onHide={handleClose}
      >
        <Modal.Header closeButton className="bg-success text-white">
          <Modal.Title>
            <FaCheck className="mr-1 mb-2" color={'white'} />
              Evaluaciones / Objetivos
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {adding ?
            <div className="d-flex justify-content-center">
              <AuthorizedElement roles={['permission_team-create-player-eva-objectives']}>
                <EvaluationForm
                  idDiagnostic={id_diagnostic}
                  setAdding={setAdding}
                />
              </AuthorizedElement>
            </div> :
            <div className="d-flex align-items-baseline justify-content-between">
              {data.getEvaluationObjectives.evaluationObjectives.length ?
                'Lista de evaluaciones/objetivos agregados al diagnóstico.' :
                'No hay evaluaciones/objetivos agregados al diagnóstico.'
              }
              <AuthorizedElement roles={['permission_team-create-player-eva-objectives']}>
                <Button
                  variant="success"
                  type="button"
                  onClick={() => setAdding(true)}
                >
                  Agregar Evaluacion/Objetivo
                </Button>
              </AuthorizedElement>
            </div>
          }
          {data.getEvaluationObjectives.evaluationObjectives.map((evaluation, key) =>
            <div key={key}>
              <hr/>
              <EvaluationForm
                action="EDIT"
                idDiagnostic={id_diagnostic}
                values={{
                  id: parseInt(evaluation.id, 10),
                  date: new Date(moment(`${evaluation.date} 12:00`)),
                  text: evaluation.text,
                  id_return_play: evaluation.id_return_play
                }}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </Styles>
  )
}

Evaluation.propTypes = {
  diagnosticId: PropTypes.string,
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func
}

export default Evaluation
