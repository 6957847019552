import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ReactToolTip from 'react-tooltip'
import moment from 'moment'
import { Button } from 'react-bootstrap'
import { FaPen } from 'react-icons/fa'
import ClinicalEventDatesForm from './ClinicalEventDatesForm'
import { GET_CLINICAL_EVENT_DETAILS } from '../../../../apollo/queries'
import AuthorizedElement from '../../../../keycloak/utils/AuthorizedElement'

const ClinicalEventsDates = ({ clinicalEvent = {} }) => {

  const [datesModal, setDatesModal] = useState(false)

  const updateCache = (cache, { data }) => {
    cache.modify({
      fields: {
        getClinicalEventDetails(existingData = []) {
          const newData = data.getClinicalEventDetails
          cache.writeQuery({
            query: GET_CLINICAL_EVENT_DETAILS,
            data: {
              newData,
              ...existingData
            }
          })
        }
      }
    })
  }

  const initialValues = {
    start_date: clinicalEvent.ClinicalEventHistories.length
      ? new Date(`${clinicalEvent.ClinicalEventHistories[0]?.start_date} 12:00`)
      : new Date(clinicalEvent.createdAt),
    end_date: clinicalEvent.active
      ? undefined
      : (
        clinicalEvent.ClinicalEventHistories[0]?.end_date
          ? new Date(`${clinicalEvent.ClinicalEventHistories[0]?.end_date} 12:00`)
          : new Date(clinicalEvent.updatedAt)
      )
  }

  return (
    <>
      <div className="d-flex align-items-end">
        <h5 className="mr-3" style={{ marginLeft: '-15px' }}>
          {'Diagnóstico:'}
        </h5>
        <p className="mb-2 mr-2">
          {moment(initialValues.start_date).format('DD/MM/YYYY')}
        </p>
        <p className="mb-2 mr-2">
          {'-'}
        </p>
        <p className="mb-2 mr-2">
          {clinicalEvent.active
            ? 'Sin fecha de término'
            : moment(initialValues.end_date).format('DD/MM/YYYY')
          }
        </p>
        <AuthorizedElement roles={['permission_team-update-createdAt-clinical-event']}>
          <Button
            size="sm"
            variant="link"
            className="p-0"
            data-tip data-for="edit-dates"
            style={{ marginBottom: '12px' }}
            onClick={() => setDatesModal(true)}
          >
            <FaPen size="12" color={'#007bff'} />
            <ReactToolTip
              id="edit-dates"
              place="top"
              effect="solid"
              type="info"
            >
              {'Editar Fechas'}
            </ReactToolTip>
          </Button>
        </AuthorizedElement>
      </div>
      <ClinicalEventDatesForm
        clinicalEventId={clinicalEvent.id}
        initialValues={initialValues}
        openModal={datesModal}
        setOpenModal={setDatesModal}
        updateCache={updateCache}
      />
    </>
  )
}

ClinicalEventsDates.propTypes = {
  clinicalEvent: PropTypes.shape({})
}

export default ClinicalEventsDates
