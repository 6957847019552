import { useFormik } from 'formik'

const getMedicalStaffFormSchema = (initialValues, mutation, action) => {
  const validate = values => {
    const errors = {}

    if (!values.id_user_type) {
      errors.id_user_type = 'Debes ingresar un tipo de usuario.'
    }
    if (!values.name) {
      errors.name = 'Debes ingresar un nombre.'
    }
    if (!values.last_name) {
      errors.last_name = 'Debes ingresar un apellido.'
    }
    if (!values.email) {
      errors.email = 'Debes ingresar un email.'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Debes ingresar un email válido.'
    }

    return errors
  }

  const medicalStaffFormSchema = useFormik({
    initialValues,
    validate,
    onSubmit: (values, { resetForm }) => {
      mutation({
        variables: {
          input: {
            ...values,
            id_user_type: parseInt(values.id_user_type, 10),
            gender: parseInt(values.gender, 10)
          }
        }
      }).then(({ data }) => {
        if (action === 'CREATE' && data.createUser.status.success) {
          resetForm({ values: '' })
        }
      })
    },
  })

  return { medicalStaffFormSchema }
}

export { getMedicalStaffFormSchema }
