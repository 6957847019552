import { gql } from '@apollo/client'

const UPDATE_EXAM_IMAGE = gql`
  mutation updateExamImage($input: UpdateExamImageInput!) {
    updateExamImage(input: $input) {
      examImage {
        id
        id_exam
        name
        date
        image_key
        comments
      }
      status {
        success
        code
        message
      }
    }
  }
`

export default UPDATE_EXAM_IMAGE
