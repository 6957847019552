import { gql } from '@apollo/client'

const DELETE_BLOCK_SCHEDULE = gql`
  mutation deleteBlockSchedule($input: CancelScheduleInput!) {
    deleteBlockSchedule(input: $input) {
      status {
        success
        code
        message
      }
    }
  }
`

export default DELETE_BLOCK_SCHEDULE
