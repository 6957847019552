import { gql } from '@apollo/client'

const GET_USERS_TYPES = gql`
  {
    getUsersTypes {
      usersTypes {
        id
        name_user
      }
    }
  }
`

export default GET_USERS_TYPES
