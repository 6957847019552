import React from 'react'
import hasRole from '../../keycloak/utils/hasRole'
import AuthorizedElement from '../../keycloak/utils/AuthorizedElement'
import { useParams, useRouteMatch, useHistory } from 'react-router-dom'
import { Tab, Nav } from 'react-bootstrap'
import { PlayerLayout } from '../Layouts/PlayerLayout'
import { CustomBreadcrumb } from '../../components'
import { ClinicalEvents } from './ClinicalEvents'
import { DailyMeasurements } from './DailyMeasurements'
import { Nutrition } from './Nutrition'
import { AutoReport } from './AutoReport'
import { Masotherapy } from './Masotherapy'
import { TherapeucticActions } from './TherapeuticActions'
import { PlayerMedicines } from './PlayerMedicines'
import { MedicalStatistics } from './MedicalStatistics'
import { PlayerGps } from '../Gps/PlayerGps'
import { appStore } from '../../store/appStore'
import Styles from './PlayerInfoStyle'
import Biomechanics from './Biomechanics'
import KinesicEvaluations from './KinesicEvaluations'
import LegacyTherapeuticActions from './TherapeuticActions/LegacyTherapeuticActions'

const PlayerInfo = () => {
  let history = useHistory()
  let { url } = useRouteMatch()

  let { id_category_type, id_player, tab } = useParams()

  const { state } = React.useContext(appStore)

  const [tabState, setTabState] = React.useState(tab)

  React.useEffect(() => {
    setTabState(tab || 'autoreport')
  }, [tab])

  const idPlayer = parseInt((hasRole(['role_player']) ? state.user?.Player?.id : id_player), 10)
  const categoryType = parseInt((hasRole(['role_player']) ? state.user?.Player?.id_category_type : id_category_type), 10)

  return (
    <>
      <Styles>
        {hasRole(['permission_team-show-players']) &&
          <CustomBreadcrumb
            path={[{ label: 'Ver Deportistas', href: '/players' }]}
            active="Resumen Médico"
          />
        }

        <PlayerLayout url={url}>
          <div className="row">
            <div className="col">
              <Tab.Container
                id="menu"
                activeKey={tabState}
                onSelect={(eventKey) => history.push(`/player-info/${categoryType}/${idPlayer}/${eventKey}`)}
              >
                <Nav variant="tabs" className="nav nav-tabs">
                  <AuthorizedElement roles={['permission_team-show-player-clinic-events']}>
                    <Nav.Link
                      eventKey="clinical-event"
                    >
                      {'Evento Clínico'}
                    </Nav.Link>
                  </AuthorizedElement>
                  <AuthorizedElement roles={['permission_team-show-player-gps']}>
                    <Nav.Link eventKey="gps">
                      {'GPS'}
                    </Nav.Link>
                  </AuthorizedElement>
                  <AuthorizedElement roles={['permission_team-show-autoreport', 'permission_team-show-biomedical']}>
                    <Nav.Link
                      eventKey="autoreport"
                    >
                      {'Autoreporte'}
                    </Nav.Link>
                  </AuthorizedElement>
                  <AuthorizedElement roles={['permission_team-show-nutritional-new']}>
                    <Nav.Link
                      eventKey="nutrition-new"
                    >
                      {'Nutrición'}
                    </Nav.Link>
                  </AuthorizedElement>
                  <AuthorizedElement roles={['permission_team-show-masotherapy']}>
                    <Nav.Link
                      eventKey="masotherapy"
                    >
                      {'Masoterapia'}
                    </Nav.Link>
                  </AuthorizedElement>
                  <AuthorizedElement roles={['permission_team-show-player-medicines']}>
                    <Nav.Link
                      eventKey="medicines"
                    >
                      {'Medicamentos'}
                    </Nav.Link>
                  </AuthorizedElement>
                  <AuthorizedElement roles={['permission_team-show-player-kinesic-treatment', 'permission_team-show-kinesic-dimension']}>
                    <Nav.Link
                      eventKey="therapeuctic-actions"
                    >
                      {'Tratamiento Kinésico'}
                    </Nav.Link>
                  </AuthorizedElement>
                  <AuthorizedElement roles={['permission_team-show-player-medical-estatistics']}>
                    <Nav.Link
                      eventKey="medical-statistics"
                    >
                      {'Estadisticas Médicas'}
                    </Nav.Link>
                  </AuthorizedElement>
                  <AuthorizedElement roles={['permission_team-show-biomechanics']}>
                    <Nav.Link
                      eventKey="biomechanics"
                    >
                      {'Biomecánica'}
                    </Nav.Link>
                  </AuthorizedElement>
                  <AuthorizedElement roles={['permission_team-show-player-kinesic-evaluation']}>
                    <Nav.Link
                      eventKey="kinesic-evaluations"
                    >
                      {'Evaluaciones Kinésicas'}
                    </Nav.Link>
                  </AuthorizedElement>
                </Nav>

                <Tab.Content className="mt-5">
                  <AuthorizedElement roles={['permission_team-show-player-clinic-events']}>
                    <Tab.Pane
                      eventKey="clinical-event"
                    >
                      {tabState === 'clinical-event' &&
                        <ClinicalEvents
                          idPlayer={idPlayer}
                        />
                      }
                    </Tab.Pane>
                  </AuthorizedElement>
                  <AuthorizedElement roles={['permission_team-show-daily-player-measurements']}>
                    <Tab.Pane
                      eventKey="daily-measurements"
                    >
                      {tabState === 'daily-measurements' &&
                        <DailyMeasurements
                          idPlayer={idPlayer}
                          categoryType={categoryType}
                        />
                      }
                    </Tab.Pane>
                  </AuthorizedElement>
                  <AuthorizedElement roles={['permission_team-show-player-gps']}>
                    <Tab.Pane eventKey="gps">
                      {tabState === 'gps' &&
                        <PlayerGps
                          idPlayer={idPlayer}
                          categoryType={categoryType}
                        />
                      }
                    </Tab.Pane>
                  </AuthorizedElement>
                  <AuthorizedElement roles={['permission_team-show-autoreport', 'permission_team-show-biomedical']}>
                    <Tab.Pane
                      eventKey="autoreport"
                    >
                      {tabState === 'autoreport' &&
                        <AutoReport
                          idPlayer={idPlayer}
                        />
                      }
                    </Tab.Pane>
                  </AuthorizedElement>
                  <AuthorizedElement roles={['permission_team-show-nutritional-new']}>
                    <Tab.Pane
                      eventKey="nutrition-new"
                    >
                      {tabState === 'nutrition-new' &&
                        <Nutrition
                          idPlayer={idPlayer}
                          isLegacy={false}
                        />
                      }
                    </Tab.Pane>
                  </AuthorizedElement>
                  <AuthorizedElement roles={['permission_team-show-masotherapy']}>
                    <Tab.Pane
                      eventKey="masotherapy"
                    >
                      {tabState === 'masotherapy' &&
                        <Masotherapy
                          idPlayer={idPlayer}
                        />
                      }
                    </Tab.Pane>
                  </AuthorizedElement>
                  <AuthorizedElement roles={['permission_team-show-player-medicines']}>
                    <Tab.Pane
                      eventKey="medicines"
                    >
                      {tabState === 'medicines' &&
                        <PlayerMedicines
                          idPlayer={idPlayer}
                        />
                      }
                    </Tab.Pane>
                  </AuthorizedElement>
                  <AuthorizedElement roles={['permission_team-show-player-kinesic-treatment', 'permission_team-show-kinesic-dimension']}>
                    <Tab.Pane
                      eventKey="therapeuctic-actions"
                    >
                      {tabState === 'therapeuctic-actions' && (
                        (hasRole(['permission_team-show-kinesic-dimension']) ? (
                          <TherapeucticActions idPlayer={idPlayer} />
                        ) : (
                          <LegacyTherapeuticActions idPlayer={idPlayer}/>
                        ))
                      )}
                    </Tab.Pane>
                  </AuthorizedElement>
                  <AuthorizedElement roles={['permission_team-show-player-medical-estatistics']}>
                    <Tab.Pane
                      eventKey="medical-statistics"
                    >
                      {tabState === 'medical-statistics' &&
                          <MedicalStatistics
                            idPlayer={idPlayer}
                          />
                      }
                    </Tab.Pane>
                  </AuthorizedElement>
                  <AuthorizedElement roles={['permission_team-show-biomechanics']}>
                    <Tab.Pane
                      eventKey="biomechanics"
                    >
                      {tabState === 'biomechanics' &&
                          <Biomechanics
                            idPlayer={parseInt(id_player, 10)}
                          />
                      }
                    </Tab.Pane>
                  </AuthorizedElement>
                </Tab.Content>
                <AuthorizedElement roles={['permission_team-show-player-kinesic-evaluation']}>
                  <Tab.Pane
                    eventKey="kinesic-evaluations"
                  >
                    {tabState === 'kinesic-evaluations' &&
                          <KinesicEvaluations
                            idPlayer={parseInt(id_player, 10)}
                          />
                    }
                  </Tab.Pane>
                </AuthorizedElement>
              </Tab.Container>
            </div>
          </div>

        </PlayerLayout>
      </Styles>
    </>
  )
}

export default PlayerInfo
