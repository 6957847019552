import { gql } from '@apollo/client'

const GET_MEDICINE_VIAS = gql`
  {
    getMedicineVias {
      medicineVias {
        id
        name
      }
      status {
        success
        code
        message
      }
    }
  }
`

export default GET_MEDICINE_VIAS

