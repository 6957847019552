import styled from 'styled-components'
export default styled.div`

  table{
    font-size:14px;
  }

  .table thead th{
    font-size: 12px;
  }

`
