import moment from 'moment'

const useDateHelper = () => {
  const nowDate = moment().format('YYYY-MM-DD')

  const getDateFromTime = (time) => {
    return new Date(`${nowDate} ${time}Z`)
  }

  const getTimeFromISOString = (isoString) => {
    const [, timeOnly] = isoString.split('T')
    const [hours, minutes] = timeOnly.split(':')
    return `${hours}:${minutes}`
  }

  const getDateFromISOString = (isoString) => {
    const [date] = isoString.split('T')
    return `${date}`
  }

  const getDate = (date) => {
    return getDateFromISOString(date.toISOString())
  }

  const getTimeFromDate = (date) => {
    return getTimeFromISOString(date.toISOString())
  }

  const getFormattedDate = (date = new Date(), format = 'YYYY-MM-DD') => {
    return moment(date).format(format)
  }

  const getTimeFromLocaleString = (string) => {
    if (!string) return null
    const [hours, minutes] = string.toLocaleTimeString().split(':')
    return `${hours}:${minutes}`
  }

  const getTimeByType = (value) => {
    if (typeof value === 'string') {
      return getTimeFromDate(new Date(`${moment().format('YYYY-MM-DD')} ${value}`))
    }
    return getTimeFromDate(value)
  }

  const concatDateAndTime = (date, time) => {
    return new Date(`${date} ${time}Z`)
  }

  const isBeforeEndOfDay = (dateToCompare, limit = new Date()) => {
    const endOfDay = new Date(`${getFormattedDate(limit)} 23:59:59`)
    const date = new Date(`${getFormattedDate(dateToCompare)} ${getFormattedDate(dateToCompare, 'HH:mm')}`)

    if ((date.getTime()) > endOfDay.getTime()) {
      return true
    }
    return false
  }

  return {
    nowDate,
    getDateFromTime,
    getTimeFromDate,
    getDateFromISOString,
    getTimeFromISOString,
    getFormattedDate,
    getTimeFromLocaleString,
    getTimeByType,
    concatDateAndTime,
    getDate,
    isBeforeEndOfDay
  }
}

export default useDateHelper
