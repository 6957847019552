import styled from 'styled-components'

export default styled.div`
  .card {
    border: 1px solid #ccc;
    min-height: 379px;
    width: 100%;
  }

  .name {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #4e7ace;
    text-transform: capitalize;
  }

  .btn-link {
    color: #9b9b9b;
  }

  .bg-footer {
    background-color: #142c59;
  }

  .card {
    border: 1px solid #ccc;
  }

  .zoom {
    transition: transform .5s;
  }

  .zoom:hover {
    transform: scale( 1.05);
  }

  @media (min-width: 2000px) {
    .container-uhd {
      width: 80%;
      margin: 0 auto;
    }
  }
`
