import { gql } from '@apollo/client'

const DELETE_USER = gql`
  mutation deleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      status {
        success
        code
        message
      }
    }
  }
`

export default DELETE_USER
