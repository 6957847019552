import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'

const LabelForm = ({ label = '', style = {}, extraComponent, ...rest }) => {
  return (
    <Form.Label
      style={{
        backgroundColor: '#28a745',
        color: 'white',
        padding: '0 10px',
        width: '100%',
        ...style
      }}
      {...rest}
    >
      <div className="d-flex align-items-center">
        <div>
          {label}
        </div>
        {extraComponent}
      </div>
    </Form.Label>
  )
}

LabelForm.propTypes = {
  label: PropTypes.string,
  style: PropTypes.shape({}),
  extraComponent: PropTypes.node
}

export default LabelForm
