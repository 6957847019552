import { gql } from '@apollo/client'

const ACTIVATE_CLINICAL_EVENT = gql`
  mutation activateClinicalEvent($input: ActiveClinicalEventInput!) {
    activateClinicalEvent(input: $input) {
      status {
        success
        code
        message
      }
    }
  }
`

export default ACTIVATE_CLINICAL_EVENT
