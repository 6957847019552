import styled from 'styled-components'

export default styled.div`

@media (max-width: 768px) {
  .checkbox-center{
    margin-left: calc(94% / 2);
  }
}

`
