import styled from 'styled-components'

export default styled.div`
  .edit-input {
    border: 0;
    border-top: 1px solid #ced4da;
    border-radius: 0;
  }

  .date {
    width: 190px;
  }

  .text {
    width: 300px;
  }

  label{
    background-color: #28a745;
    color: white;
    padding: 0 10px;
    width: 100%;
  }
`
