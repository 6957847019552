import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'
import { MainLayout } from '../views'
import PrivateRoute from './PrivateRoute'
import { useCheckUser, useTenant } from '../hooks'
import { Loading } from '../components'

const Login = React.lazy(() => import('../views/Login/Login'))
const Calendar = React.lazy(() => import('../views/Calendar/Calendar'))
const MedicalStaff = React.lazy(() => import('../views/MedicalStaff/MedicalStaff'))
const Summary = React.lazy(() => import('../views/Summary/Summary'))
const Players = React.lazy(() => import('../views/Players/Players'))
const PlayerInfo = React.lazy(() => import('../views/PlayerInfo/PlayerInfo'))
const DailyMeasurements = React.lazy(() => import('../views/DailyMeasurements/DailyMeasurements'))
const Analytics = React.lazy(() => import('../views/Analytics/Analytics'))
const DashboardClinicalEvents = React.lazy(() => import('../views/DashboardClinicalEvents/DashboardClinicalEvents'))
const ClinicalEventsDetail = React.lazy(() => import('../views/PlayerInfo/ClinicalEvents/ClinicalEventsDetail/ClinicalEventsDetail'))
const ClinicalEventMainForm = React.lazy(() => import('../views/ClinicalEventMainForm/ClinicalEventMainForm'))
const DiagnosticMainForm = React.lazy(() => import('../views/DiagnosticMainForm/DiagnosticMainForm'))
const EditClinicalEvent = React.lazy(() => import('../views/PlayerInfo/ClinicalEvents/ClinicalEventsDetail/EditClinicalEvent/EditClinicalEvent'))
const NutritionTeam = React.lazy(() => import('../views/PlayerInfo/Nutrition/NutritionTeam/NutritionTeam'))
const NutritionDetail = React.lazy(() => import('../views/PlayerInfo/Nutrition/NutritionDetail/NutritionDetail'))
const Exams = React.lazy(() => import('../views/Exams/Exams'))
const ExamDetail = React.lazy(() => import('../views/Exams/ExamDetail/ExamDetail'))
const AutoReport = React.lazy(() => import('../views/AutoReport/'))
const Masotherapy = React.lazy(() => import('../views/PlayerInfo/ClinicalEvents/ClinicalEventsDetail/Masotherapy/Masotherapy'))
const Biomechanics = React.lazy(() => import('../views/PlayerInfo/Biomechanics/'))
const KinesicEvaluationDetail = React.lazy(() => import('../views/PlayerInfo/KinesicEvaluations/KinesicEvaluationDetail/KinesicEvaluationDetail'))
const DimensionHistories = React.lazy(() => import('../views/PlayerInfo/DimensionHistories/DimensionHistories'))
const MedicalHistoryDetails = React.lazy(() => import('../views/PlayerInfo/DimensionHistories/MedicalHistoryDetails'))
const MedicalHistoryFiles = React.lazy(() => import('../views/PlayerInfo/DimensionHistories/MedicalHistoryFiles'))
const Terms = React.lazy(() => import('../views/Terms/Terms'))
const Gps = React.lazy(() => import('../views/Gps/Gps'))
const NewGps = React.lazy(() => import('../views/Gps/NewGps/NewGps'))
const GpsConfiguration = React.lazy(() => import('../views/Gps/GpsConfiguration/GpsConfiguration'))
const GpsResults = React.lazy(() => import('../views/Gps/GpsResults/GpsResults'))
const MassiveLoads = React.lazy(() => import('../views/MassiveLoads/MassiveLoads'))
const MassiveLoadsForm = React.lazy(() => import('../views/MassiveLoads/MassiveLoadsForm/MassiveLoadsForm'))
const MassiveLoadResults = React.lazy(() => import('../views/MassiveLoads/MassiveLoadResults/MassiveLoadResults'))
const KinesicTreatmentTeam = React.lazy(() => import('../views/KinesicTreatmentTeam/KinesicTreatmentTeam'))
const KinesicTreatmentDetail = React.lazy(() => import('../views/PlayerInfo/ClinicalEvents/ClinicalEventsDetail/KinesicTreatment/KinesicTreatmentDetail/KinesicTreatmentDetail'))
const ScheduleConfig = React.lazy(() => import('../views/Calendar/Schedule/ScheduleConfig/ScheduleConfig'))
const AvailableSchedules = React.lazy(() => import('../views/Calendar/Schedule/AvailableSchedules/AvailableSchedules'))

const AppRoutes = ({ checkUser }) => {
  useTenant(checkUser)
  const { loading } = useCheckUser(checkUser)

  const { initialized } = useKeycloak()

  if (!initialized || loading) {
    return (
      <Loading
        containerStyles={{
          marginLeft: '50vw',
          marginTop: '50vh'
        }}
      />
    )
  }

  return (
    <Switch>
      <Route path="/login" component={Login} />
      <PrivateRoute path="/terms-and-conditions" component={Terms} roles={['permission_calendar-list-events']} />
      <MainLayout>
        <PrivateRoute path="/" />
        <PrivateRoute path="/calendar/:view" component={Calendar} roles={['permission_calendar-list-events']} />
        <PrivateRoute path="/available-schedules" component={AvailableSchedules} roles={['permission_calendar-create-schedule']} />
        <PrivateRoute path="/schedule-config" component={ScheduleConfig} roles={['permission_calendar-show-schedule-config']} />
        <PrivateRoute path="/staff" component={MedicalStaff} roles={['permission_staff-list-users']} />
        {/* <PrivateRoute path="/staff-form" component={MedicalStaffForm} roles={['permission_team-show-players']} /> */}
        <PrivateRoute path="/daily-measurements" component={DailyMeasurements} roles={['permission_team-show-daily-measurements']} />
        <PrivateRoute path="/players" component={Players} roles={['permission_team-show-players']} />
        <PrivateRoute path="/player-info/:id_category_type/:id_player/:tab" component={PlayerInfo} roles={['permission_team-show-player']} />
        <PrivateRoute path="/analytics" component={Analytics} roles={['permission_analytics-show-analytics']} />
        <PrivateRoute path="/create-clinical-event/:id_player" component={ClinicalEventMainForm} roles={['permission_team-create-player-clinic-event']} />
        <PrivateRoute path="/edit-clinical-event/:id_clinical_event" component={EditClinicalEvent} roles={['permission_team-update-player-clinic-event']} />
        <PrivateRoute path="/clinical-event-details/:id_player/:id_clinical_event" component={ClinicalEventsDetail} roles={['permission_team-show-player-diagnostics']} />
        <PrivateRoute path="/diagnostic/:action/:id_player/:id_clinical_event" component={DiagnosticMainForm} roles={['permission_team-create-player-diagnostic', 'permission_team-update-player-diagnostic']} />
        <PrivateRoute path="/summary" component={Summary} roles={['permission_team-show-availabilities-overview']} />
        <PrivateRoute path="/clinical-dashboard" component={DashboardClinicalEvents} roles={['permission_team-show-medical-estatistics']} />
        <PrivateRoute path="/nutrition-team" component={NutritionTeam} roles={['permission_team-show-massive-nutri']}/>
        <PrivateRoute path="/nutrition/:id_player/:id_player_dimension" component={NutritionDetail} roles={['permission_team-update-nutritional', 'permission_team-update-nutritional-new']}/>
        <PrivateRoute path="/exams/:id_player/:id_diagnostic" component={Exams} roles={['permission_team-show-player']} />
        <PrivateRoute path="/exam-detail/:id_player/:id_exam" component={ExamDetail} roles={['permission_team-show-player']} />
        <PrivateRoute path="/autoreport" component={AutoReport} roles={['permission_team-show-team-autoreport']} />
        <PrivateRoute path="/masotherapy/:id_player/:id_diagnostic" component={Masotherapy} roles={['permission_team-show-masotherapy']} />
        <PrivateRoute path="/biomechanic/:id_player/:id_diagnostic" component={Biomechanics} roles={['permission_team-show-biomechanics']} />
        <PrivateRoute path="/player/:id_player/medical-history" component={DimensionHistories} roles={['permission_team-show-medical-history-player']} />
        <PrivateRoute path="/player/:id_player/medical-history/:id_medical_history" component={MedicalHistoryDetails} roles={['permission_team-show-medical-history-player']} />
        <PrivateRoute path="/player/:id_player/medical-history/:id_medical_history_player/files" component={MedicalHistoryFiles} roles={['permission_team-show-history-files']} />
        <PrivateRoute path="/player/:id_player/kinesic-block/:id_kinesic_blocks/kinesic-body-zone/:id_kinesic_body_zone/kinesic-evaluation/:id" component={KinesicEvaluationDetail} roles={['permission_team-show-player-kinesic-evaluation']} />
        <PrivateRoute path="/gps" component={Gps} roles={['permission_team-show-massive-gps']} />
        <PrivateRoute path="/gps-new" component={NewGps} roles={['permission_team-create-massive-gps']} />
        <PrivateRoute path="/gps-configuration" component={GpsConfiguration} roles={['permission_team-show-config-gps']} />
        <PrivateRoute path="/gps-results" component={GpsResults} roles={['permission_team-create-massive-gps']} />
        <PrivateRoute path="/massive-loads" component={MassiveLoads} roles={['permission_team-show-massive-loads']}/>
        <PrivateRoute path="/create-massive-load" component={MassiveLoadsForm} roles={['permission_team-create-massive-loads']}/>
        <PrivateRoute path="/create-massive-load-results" component={MassiveLoadResults} roles={['permission_team-create-massive-loads']}/>
        <PrivateRoute path="/kinesic-treatment-team" component={KinesicTreatmentTeam} roles={['permission_team-show-player-kinesic-evaluation']} />
        <PrivateRoute path="/player-info/:id_player/diagnostic/:id_diagnostic/kinesic-treatments" component={KinesicTreatmentDetail} roles={['permission_team-show-player-kinesic-evaluation']} />

        {/* <Route component={() => <div>Página no encontrada</div>} /> */}
      </MainLayout>
    </Switch>
  )
}

AppRoutes.propTypes = {
  checkUser: PropTypes.bool
}

export default AppRoutes
