import { gql } from '@apollo/client'

const GET_LAST_CLINICAL_EVENT = gql`
  query getLastClinicalEvent($input: LastClinicalEventInput!) {
    getLastClinicalEvent(input: $input) {
      lastClinicalEvent {
        id
        active
        createdAt
        updatedAt
        ClinicalEventHistories {
          id
          start_date
          end_date
        }
        Diagnostics {
          id
          side
          createdAt
          DiagnosticType {
            name
          }
          DiagnosticComplement {
            name
          }
          Pathology {
            name
          }
          SkeletalMuscle {
            name
          }
          BodyZone {
            name
          }
          DiagnosticAvailabilities {
            id
            AvailabilityCondition {
              id
              main
              date
              AvailabilityConditionType {
                id
                name_availability_condition_type
              }
            }
          }
        }
      }
      status{
        success
        code
        message
      }
    }
  }
`

export default GET_LAST_CLINICAL_EVENT

