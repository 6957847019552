import styled from 'styled-components'
import constants from '../../config/constants'

export default styled.div`
  .img-1{
    background-image: url(${constants.STATIC_IMAGES_URL}/img-1.jpg);
    background-size: cover;
    background-position: center;
  }

  .img-2{
    background-image: url(${constants.STATIC_IMAGES_URL}/img-2.jpg);
    background-size: cover;
    background-position: center;
  }

  .img-3{
    background-image: url(${constants.STATIC_IMAGES_URL}/img-3.jpg);
    background-size: cover;
    background-position: center;
  }

  .img-4{
    background-image: url(${constants.STATIC_IMAGES_URL}/img-4.jpg);
    background-size: cover;
    background-position: center;
  }

`
