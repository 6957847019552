import styled from 'styled-components'

export default styled.div`

  .dateFilter {
    z-index: 2;
    margin: 0 20px;
  }

  div .form-group {
    display: inline-block;
  }

  .table .header .th, .header .player {
    background-color: #fff;
    color: #4e7ace;
    text-align: center;
    vertical-align: middle;
  }

  /* .table .th {
    min-width: 100px;
  } */

  .tr .th:nth-child(5), .tr .td:nth-child(5) {
    width: 200px !important;
  }

  textarea {
    font-size: 12px;
  }

  textarea, input, .react-select-container {
    border-radius: 8px;
  }

  .success {
    textarea, input, .react-select-container {
      border: green 2px solid;
    }
  }

  .error {
    textarea, input, .react-select-container {
      border: red 2px solid;
    }
  }

  .react-select__control, .input .react-select-container, .form-control{
    background-color: transparent;
  }

  .react-select__value-container{
    border: none;
  }

  input{
    text-align:center;
  }

`
