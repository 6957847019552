import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { CREATE_SINGLE_PLAYER_GPS } from '../../../apollo/mutations'
import { appStore } from '../../../store/appStore'
import GpsStyle from './GpsStyle'
import hasRole from '../../../keycloak/utils/hasRole'

const GpsInput = ({ cellInfo, column, filters }) => {
  const { state: { defaultCategoryType } } = useContext(appStore)

  const [existId, setExistId] = React.useState(cellInfo?.id ? true : false)
  const [inputValue, setInputValue] = React.useState(cellInfo?.cellValue)
  const [success, setSuccess] = React.useState(false)
  const [createGps, createResult] = useMutation(CREATE_SINGLE_PLAYER_GPS)

  const handleSuccess = () => {
    setSuccess(true)
    setExistId(true)
    setTimeout(() => setSuccess(false), 2000)
  }
  /// op1 crear otro hook usenutrition y cambiar el nombre del input que hice
  // op2  la funcion de  usenutrition pasarla a useplayerdimension

  const handleBlur = () => {
    if ((!inputValue && existId) || inputValue) {
      createGps({
        variables: {
          input: {
            id_player: parseInt(cellInfo.id_player, 10),
            id_category_type: defaultCategoryType,
            date: cellInfo.date,
            id_gps_moment: filters.id_gps_moment,
            id_gps_moment_desc: filters.id_gps_moment_desc,
            activity: filters.activity,
            gps_id: parseInt(column.id, 10),
            gps_value: inputValue
          }
        }
      }).then(({ data }) => {
        if (data.createSinglePlayerGps.status.success) {
          handleSuccess()
        }
      })
    }
  }

  if (createResult.loading) {
    return (
      <div>Cargando...</div>
    )
  }

  return (
    <GpsStyle>
      {hasRole(['permission_team-create-player-gps', 'permission_team-update-player-gps'])
        ? (
          <input
            type="text"
            placeholder="--"
            className={`
              ${success ? 'success' : ''}
              ${(createResult.error ) ? 'error' : ''}
            `}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onBlur={() => handleBlur()}
          />
        ) : <div>{inputValue}</div>
      }
    </GpsStyle>
  )
}

GpsInput.propTypes = {
  cellInfo: PropTypes.shape({
    id: PropTypes.string,
    cellValue: PropTypes.string,
    id_player: PropTypes.string,
    date: PropTypes.string
  }).isRequired,
  column: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  filters: PropTypes.shape({
    id_gps_moment: PropTypes.number,
    id_gps_moment_desc: PropTypes.number,
    activity: PropTypes.number
  }).isRequired
}

export default GpsInput
