import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import AuthorizedElement from '../../../../../keycloak/utils/AuthorizedElement'
import { useQuery } from '@apollo/client'
import { GET_KINESIC_TREATMENTS, } from '../../../../../apollo/queries'
import { Modal, Button } from 'react-bootstrap'
import { Loading } from '../../../../../components'
import { KinesicTreatmentForm } from './KinesicTreatmentForm'
import Styles from './KinesicTreatmentStyle'
import constants from '../../../../../config/constants'

const KinesicTreatment = ({ diagnosticId, openModal, setOpenModal }) => {
  const id_diagnostic = parseInt(diagnosticId, 10)
  const [adding, setAdding] = React.useState(false)

  const handleClose = React.useCallback(() => setOpenModal(false), [])

  const modalHeaderRef = React.useRef(null)

  const { data, loading, error } = useQuery(GET_KINESIC_TREATMENTS, {
    variables: { input: { id_diagnostic } },
    fetchPolicy: 'network-only'
  })

  if (loading) {
    return (
      <Loading />
    )
  }

  if (error) {
    return (
      <div>Error :(</div>
    )
  }

  return (
    <Styles>
      <Modal
        size="lg"
        show={openModal}
        dialogClassName="full-width"
        contentClassName="full-height"
        onHide={handleClose}
      >
        <Modal.Header
          className="bg-success text-white"
          ref={modalHeaderRef}
          closeButton
        >
          <Modal.Title>
            <img
              width="35px"
              className="mr-1 mb-2"
              src={
                `${constants.STATIC_IMAGES_URL}/kine-icon.svg`
              }
            />
              Tratamiento Kinésico
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {adding ?
            <div className="d-flex justify-content-center">
              <AuthorizedElement roles={['permission_team-create-player-kinesic-treatment']}>
                <KinesicTreatmentForm
                  idDiagnostic={id_diagnostic}
                  setAdding={setAdding}
                />
              </AuthorizedElement>
            </div> :
            <div className="d-flex align-items-baseline justify-content-between">
              {data.getKinesicTreatments.kinesicTreatments.length ?
                'Lista de tratamientos kinésicos agregados al diagnóstico.' :
                'No hay tratamientos kinésicos agregados al diagnóstico.'
              }
              <AuthorizedElement roles={['permission_team-create-player-kinesic-treatment']}>
                <Button
                  variant="success"
                  type="button"
                  onClick={() => setAdding(true)}
                >
                  Agregar Tratamiento
                </Button>
              </AuthorizedElement>
            </div>
          }
          {data.getKinesicTreatments.kinesicTreatments.map((treatment, key) =>
            <div key={key}>
              <hr/>
              <KinesicTreatmentForm
                action="EDIT"
                idDiagnostic={id_diagnostic}
                values={{
                  id: parseInt(treatment.id, 10),
                  date: new Date(moment(`${treatment.date} 12:00`)),
                  text: treatment.text
                }}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </Styles>
  )
}

KinesicTreatment.propTypes = {
  diagnosticId: PropTypes.number,
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func
}

export default KinesicTreatment
