import { gql } from '@apollo/client'

const GET_MATCH_MOMEMTS = gql`
  query getMatchMoments {
    getMatchMoments {
      matchMoments {
        id
        name
      }
      status{
        success
        code
        message
      }
    }
  }
`

export default GET_MATCH_MOMEMTS
