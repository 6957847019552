import React, { createContext, useReducer } from 'react'
import PropTypes from 'prop-types'
import { useKeycloak } from '@react-keycloak/web'

const initialState = {
  isLogged: false,
  isAdmin: false,
  user: null,
  asideCollapsed: true,
  player: null,
  tenant: null,
  defaultCategoryType: parseInt(localStorage.getItem('defaultCategoryType'), 10) || 10,
  customGoBack: '',
  locationReload: false
}

const appStore = createContext(initialState)
const { Provider } = appStore

const AppProvider = ({ children }) => {
  const { keycloak } = useKeycloak()

  if (keycloak && keycloak.token) {
    localStorage.setItem('token', keycloak.token)
  }

  const [state, dispatch] = useReducer((state, action) => {
    switch(action.type) {
    case 'login':
      return {
        ...state,
        isLogged: true,
        user: action.payload
      }
    case 'logout':
      return {
        ...state,
        isLogged: false
      }
    case 'collapseAside':
      return {
        ...state,
        asideCollapsed: !state.asideCollapsed
      }
    case 'savePlayer':
      return {
        ...state,
        player: action.payload
      }
    case 'updateImagePlayer': {
      return {
        ...state,
        player: {
          ...state.player,
          User: {
            ...state.player.User,
            image: action.payload
          }
        }
      }
    }
    case 'tenant': {
      let defaultCategoryType = parseInt(localStorage.getItem('defaultCategoryType'), 10)

      if (!defaultCategoryType) {
        const defaultTenantCategory = action.payload?.categoryTypes?.length ? parseInt(state.tenant?.CategoryTypes[0]?.id, 10) : 10
        defaultCategoryType = state.user?.UserCategoryTypes?.length ? state.user.UserCategoryTypes[0]?.id_category_type : defaultTenantCategory
        localStorage.setItem('defaultCategoryType', defaultCategoryType)
      }

      return {
        ...state,
        tenant: {
          ...action.payload.tenant,
          CategoryTypes: action.payload.tenant.categoryTypes,
        }

      }
    }
    case 'userChecked': {
      let defaultCategoryType = parseInt(localStorage.getItem('defaultCategoryType'), 10)

      if (!defaultCategoryType) {
        const defaultTenantCategory = state.tenant?.CategoryTypes?.length ? parseInt(state.tenant?.CategoryTypes[0]?.id, 10) : 10
        defaultCategoryType = action.payload.UserCategoryTypes[0]?.id_category_type || defaultTenantCategory
        localStorage.setItem('defaultCategoryType', defaultCategoryType)
      }

      return {
        ...state,
        defaultCategoryType,
        isLogged: true,
        user: action.payload,
      }
    }

    case 'setDefaultCategoryType': {
      localStorage.setItem('defaultCategoryType', action.payload)
      return {
        ...state,
        defaultCategoryType: action.payload
      }
    }

    case 'setCustomGoBack': {
      return {
        ...state,
        customGoBack: action.payload
      }
    }

    case 'setLocationReload': {
      return {
        ...state,
        locationReload: action.payload
      }
    }
    default:
      throw new Error()
    }
  }, initialState)

  return <Provider value={{ state, dispatch }}>{children}</Provider>
}

AppProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export { appStore, AppProvider }
