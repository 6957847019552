import React from 'react'
import PropTypes from 'prop-types'
import { PlayerInfoFilter, SelectFilter } from '../../../components'
import { Col } from 'react-bootstrap'
import { GET_GPS_MOMENTS, GET_GPS_MOMENT_DESCRIPTIONS } from '../../../apollo/queries'

export const GpsFilters = ({
  filters,
  gpsFilters,
  handleSetFilters
}) => {
  return (
    <PlayerInfoFilter
      filters={filters}
      setFilters={handleSetFilters}
    >
      <Col>
        <SelectFilter
          label="Actividad"
          value={filters.activity}
          handleChange={(e) => {
            const parseValue = parseInt(e.target.value, 10)
            const newFilters = {
              activity: parseValue
            }

            const activityExist = gpsFilters.activities.find((activity) => activity.id === parseValue)
            if (activityExist) {
              const [firstMoment] = activityExist.gpsMoments
              newFilters.id_gps_moment = firstMoment.id
              newFilters.id_gps_moment_desc = firstMoment.gpsMomentsDescs[0].id
            }

            handleSetFilters(
              (current) => ({
                ...current,
                ...newFilters
              })
            )
          }}
          options={
            gpsFilters?.activities.length ? (
              gpsFilters.activities.map((activity) =>
                <option key={activity.id} value={activity.id}>
                  {activity.name}
                </option>
              )
            ) : (
              <>
                <option value='1'>Entrenamiento</option>
                <option value='2'>Partido</option>
              </>
            )
          }
        />
      </Col>
      <Col>
        <SelectFilter
          label="Momento"
          queryName="getGpsMoments"
          mapName="gpsMoments"
          query={
            !gpsFilters?.activities.length ? GET_GPS_MOMENTS : undefined
          }
          value={filters.id_gps_moment}
          handleChange={
            (e) => {
              const parseValue = parseInt(e.target.value, 10)
              const newFilters = {
                id_gps_moment: parseValue
              }

              const activityExist = gpsFilters.activities.find((activity) => activity.id === filters.activity)
              if (activityExist) {
                const momentExist = activityExist.gpsMoments.find((moment) => moment.id === parseValue)
                if (momentExist) {
                  newFilters.id_gps_moment_desc = momentExist.gpsMomentsDescs[0].id
                }
              }

              handleSetFilters(
                (current) => ({
                  ...current,
                  ...newFilters
                })
              )
            }
          }
          options={
            gpsFilters?.activities.length ? (
              gpsFilters.activities.find(
                (activity) => activity.id === filters.activity
              )?.gpsMoments.map((gpsMoment) =>
                <option key={gpsMoment.id} value={gpsMoment.id}>
                  {gpsMoment.name}
                </option>
              )
            ) : undefined
          }
        />
      </Col>
      <Col>
        <SelectFilter
          label="Descripción Momento"
          queryName="getGpsMomentDescriptions"
          mapName="gpsMomentDescriptions"
          query={
            !gpsFilters?.activities.length ? GET_GPS_MOMENT_DESCRIPTIONS : undefined
          }
          value={filters.id_gps_moment_desc}
          handleChange={
            (e) => handleSetFilters(
              (current) => ({
                ...current,
                id_gps_moment_desc: parseInt(e.target.value, 10)
              })
            )
          }
          options={
            gpsFilters?.activities.length ? (
              <>
                <option value="">Todos</option>
                {
                  gpsFilters.activities.find(
                    (activity) => activity.id === filters.activity
                  )?.gpsMoments.find(
                    (gpsMoment) => gpsMoment.id === filters.id_gps_moment
                  )?.gpsMomentsDescs.map((gpsMomentsDesc) =>
                    <option key={gpsMomentsDesc.id} value={gpsMomentsDesc.id}>
                      {gpsMomentsDesc.name}
                    </option>
                  )
                }
              </>
            ) : undefined
          }
        />
      </Col>
    </PlayerInfoFilter>
  )
}

GpsFilters.propTypes = {
  filters: PropTypes.shape({
    activity: PropTypes.number,
    id_gps_moment: PropTypes.number,
    id_gps_moment_desc: PropTypes.number,
  }),
  setOpenModal: PropTypes.func,
  gpsFilters: PropTypes.shape({
    activities: PropTypes.array
  }),
  handleSetFilters: PropTypes.func,
}
