import { gql } from '@apollo/client'

const GET_PROCEDURE_MATERIALS = gql`
  query getProcedureMaterials($input: ProcedureMaterialInput!) {
    getProcedureMaterials(input: $input) {
      status {
        success
        code
        message
      }
      procedureMaterials {
        id
        name
      }
    }
  }
`

export default GET_PROCEDURE_MATERIALS
