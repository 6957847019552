import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { FaHome } from 'react-icons/fa'
import { Breadcrumb } from 'react-bootstrap'
import Styles from './CustomBreadcrumbStyle'

const CustomBreadcrumb = ({ path, active }) => {
  let history = useHistory()

  return (
    <Styles>
      <Breadcrumb
        style={{ background: 'none', marginBottom: 0 }}
      >
        <Breadcrumb.Item linkAs="button" onClick={() => history.push('/')}>
          <FaHome size={18}/>
          {/* Inicio */}
        </Breadcrumb.Item>
        {path &&
          path.map((crumb, key) =>
            <Breadcrumb.Item key={key} linkAs="button" onClick={() => history.push(crumb.href)}>
              {crumb.label}
            </Breadcrumb.Item>
          )
        }
        {active &&
          <Breadcrumb.Item linkAs="button" active>{active}</Breadcrumb.Item>
        }
      </Breadcrumb>
    </Styles>
  )
}

CustomBreadcrumb.propTypes = {
  path: PropTypes.array,
  active: PropTypes.string
}

export default CustomBreadcrumb
