import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import domainConfig from '../../../config/domains'
import constants from '../../../config/constants'
import { useMutation, useLazyQuery } from '@apollo/client'
import { GET_EXAMS } from '../../../apollo/queries'
import { DELETE_EXAM_IMAGE } from '../../../apollo/mutations'
import { Card, Button } from 'react-bootstrap'
import { FaTrash, FaPen } from 'react-icons/fa'
import AuthorizedElement from '../../../keycloak/utils/AuthorizedElement'

const ExamCard = ({
  imagesState,
  setImagesState,
  exam,
  examFile,
  setOpenModal,
  setUpdateValues
}) => {

  const isImage = ['image/jpeg','image/png','image/gif'].includes(examFile.mime_type)

  const [deleteExamImage] = useMutation(DELETE_EXAM_IMAGE)
  const [getExams] = useLazyQuery(GET_EXAMS, {
    fetchPolicy: 'network-only'
  })

  const handleDelete = (event, id) => {
    event.preventDefault()
    if (window.confirm('Esta acción es irreversible, ¿Estás seguro de borrar este imagen?')) {
      deleteExamImage({
        variables: {
          input: { id }
        }
      }).then(({ data }) => {
        const { examImage } = data.deleteExamImage

        setImagesState(imagesState.filter(({ id }) => id != examImage.id, 10 ))
        getExams({
          variables: {
            input: {
              id_diagnostic: parseInt(exam.id_diagnostic, 10)
            }
          }
        })
      })
    }
  }

  return (
    <div
      className="col-12 col-md-6 col-lg-3 mb-4 list-item zoom"
    >
      <Card>
        <a href={!isImage ? `${domainConfig.clinicalApiUri}/exam/${exam.id}/files/${examFile.id}`:null} >
          <Card.Img
            variant="top"
            src={
              isImage
                ? `${domainConfig.clinicalApiUri}/exam/${exam.id}/files/${examFile.id}`
                : `${constants.STATIC_IMAGES_URL}/file-cover.svg`
            }
          />
        </a>
        <Card.Body>
          <h6>
            {isImage ?
              examFile.name :
              <a href={`${domainConfig.clinicalApiUri}/exam/${exam.id}/files/${examFile.id}`}>
                {examFile.name}
              </a>
            }
          </h6>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-between align-items-center">
          <small className="text-muted mb-0">Fecha: {moment(examFile.date).format('YYYY-MM-DD')}</small>
          <div>
            <AuthorizedElement roles={['permission_team-update-exam-image']}>
              <Button
                variant="warning"
                size="sm"
                className="mr-1"
                onClick={() => {
                  setOpenModal(true)
                  setUpdateValues({
                    ...examFile,
                    date: new Date(examFile.date ||''),
                  })
                }}
              >
                <FaPen color={'#fff'} />
              </Button>
            </AuthorizedElement>
            <AuthorizedElement roles={['permission_team-delete-exam-image']}>
              <Button
                variant="danger"
                size="sm"
                className="ml-1"
                onClick={(event) => handleDelete(event, examFile.id)}
              >
                <FaTrash />
              </Button>
            </AuthorizedElement>
          </div>
        </Card.Footer>
      </Card>
    </div>
  )
}

ExamCard.propTypes = {
  imagesState: PropTypes.array,
  setImagesState: PropTypes.func.isRequired,
  exam: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    comments: PropTypes.string,
    ExamFiles: PropTypes.array,
    id_diagnostic: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    id_category_type: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ])
  }),
  examFile: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    date: PropTypes.string,
    mime_type: PropTypes.string
  }),
  setOpenModal: PropTypes.func.isRequired,
  setUpdateValues: PropTypes.func.isRequired
}

export default ExamCard
