import { gql } from '@apollo/client'

const CREATE_EVENT = gql`
  mutation createEvent($input: CreateEventInput!) {
    createEvent(input: $input) {
      status {
        success
        code
        message
      }
    }
  }
`

export default CREATE_EVENT
