import { gql } from '@apollo/client'

const DELETE_EXAM = gql`
  mutation deleteExam($input: DeleteExamInput!) {
    deleteExam(input: $input) {
      status {
        success
        code
        message
      }
    }
  }
`

export default DELETE_EXAM
