import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import hasRole from '../../../../../../keycloak/utils/hasRole'
import AuthorizedElement from '../../../../../../keycloak/utils/AuthorizedElement'
import { Formik } from 'formik'
import { useQuery, useMutation } from '@apollo/client'
import { GET_MEDICNES, GET_DIAGNOSTIC_MEDICINES, GET_MEDICINE_CLASIFICATIONS, GET_MEDICINE_VIAS } from '../../../../../../apollo/queries'
import { CREATE_DIAGNOSTIC_MEDICINE, UPDATE_DIAGNOSTIC_MEDICINE, DELETE_DIAGNOSTIC_MEDICINE } from '../../../../../../apollo/mutations'
import { Button, Form, Col } from 'react-bootstrap'
import { FaPen, FaTrash } from 'react-icons/fa'
import { SubmitButton } from '../../../../../../components'
import Styles from './MedicineFormStyle'

const MedicineForm = ({
  action = 'CREATE',
  idDiagnostic,
  values = {
    date: new Date(),
    id_medicine: '1',
    id_medicine_classification: '1',
    id_medicine_via: '1',
    dose: '0',
    comments: ''
  },
  setAdding = () => {}
}) => {
  const canUpdate = hasRole(['permission_team-update-player-medicines'])
  const dateRef = React.useRef(null)
  const medicineRef = React.useRef(null)
  const classificationRef = React.useRef(null)
  const viaRef = React.useRef(null)
  const doseRef = React.useRef(null)
  const commentsRef = React.useRef(null)

  const [deleted, setDeleted] = React.useState(false)
  const [editing, setEditing] = React.useState(false)
  // const refetchQueries = [{
  //   query: GET_DIAGNOSTIC_MEDICINES,
  //   variables: { input: { id_diagnostic: idDiagnostic } }
  // }]

  React.useEffect(() => {
    function handler(event) {
      if(
        canUpdate &&
        action === 'EDIT' &&
        (dateRef.current?.contains(event.target) ||
        medicineRef.current?.contains(event.target) ||
        classificationRef.current?.contains(event.target) ||
        viaRef.current?.contains(event.target) ||
        commentsRef.current?.contains(event.target) ||
        doseRef.current?.contains(event.target))
      ) {
        setEditing(true)
      }
    }
    window.addEventListener('click', handler)
    return () => window.removeEventListener('click', handler)
  }, [])

  const medicines = useQuery(GET_MEDICNES)
  const medicineClasifications = useQuery(GET_MEDICINE_CLASIFICATIONS)
  const medicinesVias = useQuery(GET_MEDICINE_VIAS)

  const [deleteTreatment] = useMutation(DELETE_DIAGNOSTIC_MEDICINE)

  const [mutation, mutationResult] = useMutation(
    action === 'CREATE' ? CREATE_DIAGNOSTIC_MEDICINE : UPDATE_DIAGNOSTIC_MEDICINE,
    {
      update: (cache, { data }) => {
        cache.modify({
          fields: {
            getDiagnosticMedicines(existingMedicines = []) {
              const newMedicines = data.getDiagnosticMedicines
              cache.writeQuery({
                query: GET_DIAGNOSTIC_MEDICINES,
                data: {
                  newMedicines,
                  ...existingMedicines
                }
              })
            }
          }
        })
      }
    }
    // action === 'CREATE' ? { refetchQueries } : {}
  )

  const handleDelete = (event) => {
    event.preventDefault()
    if (window.confirm('Esta acción es irreversible, ¿Estás seguro de continuar?')) {
      deleteTreatment({
        variables: {
          input: {
            id: values.id
          }
        }
      }).then(({ data }) => {
        if (data?.deleteDiagnosticMedicine.status.success) {
          setDeleted(true)
        }
      })
    }
  }

  return (
    <Styles>
      {action === 'CREATE' && <p>Agrega medicamentos al diagnóstico:</p>}
      {!deleted &&
        <Formik
          initialValues={values}
          validate={() => {
            const errors = {}
            return errors
          }}
          onSubmit={(values, { resetForm }) => {
            const diagnostic = action === 'CREATE' ? { id_diagnostic: idDiagnostic } : {}
            mutation({
              variables: {
                input: {
                  ...values,
                  ...diagnostic,
                  date: moment(values.date).format('YYYY-MM-DD'),
                  id_medicine: parseInt(values.id_medicine),
                  id_medicine_classification: parseInt(values.id_medicine_classification),
                  id_medicine_via: parseInt(values.id_medicine_via),
                  dose: parseFloat(values.dose)
                }
              }
            }).then(({ data }) => {
              if (action === 'CREATE') {
                if (data.createDiagnosticMedicine.status.success) {
                  resetForm({ values: '' })
                  setAdding(false)
                }
              } else {
                if (data.updateDiagnosticMedicine.status.success) {
                  setEditing(false)
                }
              }
              // modalHeaderRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
            })
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
            setFieldValue
          }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} className="date">
                  {<Form.Label>Fecha</Form.Label>}
                  {window.innerWidth > 576 ?
                    <div ref={dateRef}>
                      <DatePicker
                        showYearDropdown
                        name="date"
                        locale="es"
                        wrapperClassName="w-100"
                        dateFormat="dd/MM/yyyy"
                        readOnly={canUpdate ? false : true}
                        selected={values.date}
                        onChange={date => setFieldValue('date', date)}
                        onBlur={handleBlur}
                        customInput={
                          <Form.Control
                            required
                            as="input"
                            placeholder="Selecciona una fecha"
                            className={(action === 'EDIT' && !editing) && 'edit-input'}
                            isInvalid={touched.date && errors.date ? true : false}
                          />
                        }
                      />
                    </div>
                    :
                    <Form.Control
                      required
                      as="input"
                      type="date"
                      name="date"
                      placeholder="Selecciona una fecha"
                      plaintext={canUpdate ? false : true}
                      readOnly={canUpdate ? false : true}
                      ref={dateRef}
                      className={(action === 'EDIT' && !editing) && 'edit-input'}
                      value={
                        typeof values.date === 'string'
                          ? values.date
                          : new Date(
                            values.date - (values.date.getTimezoneOffset() * 60000)
                          ).toISOString().split('T')[0]
                      }
                      onChange={handleChange}
                      isInvalid={touched.date && errors.date ? true : false}
                    />
                  }
                  {/* {(touched.date && errors.date) &&
                          <div className="invalid-feedback d-block">
                            {errors.date}
                          </div>
                        } */}
                </Form.Group>

                <Form.Group as={Col}>
                  {<Form.Label>Medicamento</Form.Label>}
                  <Form.Control
                    as="select"
                    name="id_medicine"
                    placeholder="Selecciona un medicamento"
                    disabled={canUpdate ? false : true}
                    ref={medicineRef}
                    className={`${(action === 'EDIT' && !editing) && 'edit-input'} text`}
                    value={values.id_medicine ||''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      (touched.id_medicine && errors.id_medicine)
                        ? true
                        : false
                    }
                  >
                    {medicines.data?.getMedicines.medicines.map((medicine, key) =>
                      <option key={key} value={medicine.id}>{medicine.name}</option>
                    )}
                  </Form.Control>
                </Form.Group>

              </Form.Row>

              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Clasificación</Form.Label>
                  <Form.Control
                    as="select"
                    name="id_medicine_classification"
                    placeholder="Selecciona una clasificación"
                    disabled={canUpdate ? false : true}
                    ref={classificationRef}
                    className={`${(action === 'EDIT' && !editing) && 'edit-input'} text`}
                    value={values.id_medicine_classification ||''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      (touched.id_medicine_classification && errors.id_medicine_classification)
                        ? true
                        : false
                    }
                  >
                    {medicineClasifications.data?.getMedicineClassifications.medicineClassifications.map((clasification, key) =>
                      <option key={key} value={clasification.id}>{clasification.name}</option>
                    )}
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col}>
                  {<Form.Label>Vía de administración</Form.Label>}
                  <Form.Control
                    as="select"
                    name="id_medicine_via"
                    placeholder="Selecciona una vía"
                    disabled={canUpdate ? false : true}
                    ref={viaRef}
                    className={`${(action === 'EDIT' && !editing) && 'edit-input'} text`}
                    value={values.id_medicine_via || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      (touched.id_medicine_via && errors.id_medicine_via)
                        ? true
                        : false
                    }
                  >
                    {medicinesVias.data?.getMedicineVias.medicineVias.map((vias, key) =>
                      <option key={key} value={vias.id}>{vias.name}</option>
                    )}
                  </Form.Control>
                </Form.Group>

              </Form.Row>

              <Form.Row>
                <Form.Group as={Col}>
                  {<Form.Label>Dosis (ml/mg)</Form.Label>}
                  <Form.Control
                    type="number"
                    name="dose"
                    placeholder="Ingresa una dosis"
                    plaintext={canUpdate ? false : true}
                    readOnly={canUpdate ? false : true}
                    ref={doseRef}
                    className={`${(action === 'EDIT' && !editing) && 'edit-input'} text`}
                    value={values.dose}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      (touched.dose && errors.dose)
                        ? true
                        : false
                    }
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Comentario:</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="comments"
                    placeholder="Ingresa un comentario"
                    disabled={canUpdate ? false : true}
                    ref={commentsRef}
                    className={`${(action === 'EDIT' && !editing) && 'edit-input'} text`}
                    value={values.comments}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Form.Group>
              </Form.Row>

              {editing === true ?
                <div className="d-flex justify-content-end">
                  <AuthorizedElement roles={['permission_team-update-player-medicines']}>
                    <SubmitButton
                      action={action}
                      loading={mutationResult.loading}
                    />
                  </AuthorizedElement>
                  <Button
                    type="button"
                    variant="secondary"
                    className="ml-2"
                    onClick={() => setEditing(false)}
                  >
                    Cerrar
                  </Button>
                </div> :

                (action === 'CREATE' ?
                  <div className="d-flex justify-content-end">
                    <AuthorizedElement roles={['permission_team-create-player-medicines']}>
                      <SubmitButton
                        action={action}
                        loading={mutationResult.loading}
                      />
                    </AuthorizedElement>
                    <Button
                      type="button"
                      variant="secondary"
                      className="ml-2"
                      onClick={() => setAdding(false)}
                    >
                      Cerrar
                    </Button>
                  </div> :
                  <div className="d-flex justify-content-end">
                    <AuthorizedElement roles={['permission_team-update-player-medicines']}>
                      <Button
                        type="button"
                        variant="warning"
                        className="mr-2"
                        onClick={() => setEditing(true)}
                      >
                        <FaPen color="white"/>
                      </Button>
                    </AuthorizedElement>
                    <AuthorizedElement roles={['permission_team-delete-player-medicines']}>
                      <Button
                        type="button"
                        variant="danger"
                        onClick={(event) => handleDelete(event)}
                      >
                        <FaTrash />
                      </Button>
                    </AuthorizedElement>
                  </div>
                )
              }
            </Form>
          )}
        </Formik>
      }
    </Styles>
  )
}

MedicineForm.propTypes = {
  action: PropTypes.string,
  idDiagnostic: PropTypes.number,
  values: PropTypes.shape({
    date: PropTypes.instanceOf(Date),
    text: PropTypes.string
  }),
  setAdding: PropTypes.func
}

export default MedicineForm
