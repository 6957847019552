import React from 'react'
import PropTypes from 'prop-types'
import AuthorizedElement from '../../../keycloak/utils/AuthorizedElement'
import { useParams, useLocation } from 'react-router-dom'
import { Accordion, Button } from 'react-bootstrap'
import { FilterRow, DateFilter } from '../../../components'
import { BiomechanicsContext } from './BiomechanicsContext'
import BiomechanicsDimension from './BiomechanicsDimension'
import BiomechanicsForm from './BiomechanicsForm'
import BiomechanicsDetails from './BiomechanicsDetails'
import Styles from './BiomechanicsStyle'
import usePlayerDimension from '../usePlayerDimension'
import { FaPlus } from 'react-icons/fa'


const BiomechanicsIdsDimensions = [3, 4, 5, 6, 7, 8, 9, 10]

const Biomechanics = () => {
  const query = new URLSearchParams(useLocation().search)
  let { id_player } = useParams()

  const {
    filters,
    setFilters,
    action,
    setAction,
    openModal,
    setOpenModal,
    formValues,
  } = React.useContext(BiomechanicsContext)

  const { onSubmit } = usePlayerDimension(parseInt(id_player, 10), null)

  return (
    <Styles>
      {query.get('edit') ?
        <BiomechanicsDetails /> :
        <>
          <div className="row">
            <div className="col d-flex flex-wrap align-items-baseline justify-content">
              <h5 className="mr-auto text-secondary">
                  Biomecánica
              </h5>
              <FilterRow>
                <DateFilter
                  value={{
                    start_date: filters.start_date,
                    end_date: filters.end_date
                  }}
                  setValue={setFilters}
                />
              </FilterRow>
              <div className='d-lg-block d-none'>
                <AuthorizedElement roles={['permission_team-create-biomechanics']}>
                  <Button
                    className="mb-2"
                    onClick={() => {
                      setAction('CREATE')
                      setOpenModal(true)
                    }}
                  >
                    <FaPlus className="mb-1 mr-2" color={'white'} />
                    {'Crear Biomecánica'}
                  </Button>
                </AuthorizedElement>
              </div>
            </div>
          </div>
          <div className='d-block d-lg-none'>
            <AuthorizedElement roles={['permission_team-create-biomechanics']}>
              <Button
                className='mb-3' variant="primary" block
                onClick={() => {
                  setAction('CREATE')
                  setOpenModal(true)
                }}
              >
                <FaPlus className="mb-1 mr-2" color={'white'} />
                {'Crear Biomecánica'}
              </Button>
            </AuthorizedElement>
          </div>
          <div className="row mb-3">
            <div className="col">
              <Accordion
                defaultActiveKey={BiomechanicsIdsDimensions[0]}
              >
                {BiomechanicsIdsDimensions.map(id_dimension =>
                  <BiomechanicsDimension
                    key={id_dimension}
                    id_dimension={id_dimension}
                  />
                )}
              </Accordion>
            </div>
          </div>
        </>
      }

      <BiomechanicsForm
        openModal={openModal}
        setOpenModal={(value) => setOpenModal(value)}
        action={action}
        formValues={formValues}
        onSubmit={onSubmit}
      />
    </Styles>
  )
}

Biomechanics.propTypes = {
  idPlayer: PropTypes.number,
  id_player_dimension: PropTypes.string,
}

export default Biomechanics
