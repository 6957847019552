import React from 'react'
import AuthorizedElement from '../../../keycloak/utils/AuthorizedElement'
import moment from 'moment'
import ReactToolTip from 'react-tooltip'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from '@apollo/client'
import { GET_MEDICNES, GET_DIAGNOSTIC_MEDICINES, GET_MEDICINE_CLASIFICATIONS, GET_MEDICINE_VIAS } from '../../../apollo/queries'
import { DELETE_DIAGNOSTIC_MEDICINE } from '../../../apollo/mutations'
import { Button, Table } from 'react-bootstrap'
import { FaPen, FaTrash, FaPlus } from 'react-icons/fa'
import { FilterRow, DateFilter, Loading } from '../../../components'
import { PlayerMedicinesModal } from './PlayerMedicinesModal'
import Styles from './PlayerMedicinesStyle'

const PlayerMedicines = ({ idPlayer }) => {
  const [openModal, setOpenModal] = React.useState(false)
  const [action, setAction] = React.useState('')
  const [updateValues, setUpdateValues] = React.useState({})

  const [filters, setFilters] = React.useState({
    id_player: idPlayer,
    start_date: new Date(moment().subtract(6, 'months')),
    end_date: new Date()
  })

  const medicines = useQuery(GET_MEDICNES)
  const medicineClassifications = useQuery(GET_MEDICINE_CLASIFICATIONS)
  const medicinesVias = useQuery(GET_MEDICINE_VIAS)
  const { data, loading, error } = useQuery(GET_DIAGNOSTIC_MEDICINES, {
    variables: {
      input: filters
    }
  })

  const update = (cache, { data }) => {
    cache.modify({
      fields: {
        getDiagnosticMedicines(existingMedicines = []) {
          const newMedicines = data.getDiagnosticMedicines
          cache.writeQuery({
            query: GET_DIAGNOSTIC_MEDICINES,
            data: {
              newMedicines,
              ...existingMedicines
            }
          })
        }
      }
    })
  }

  const [deleteMedicine, deleteResult] = useMutation(DELETE_DIAGNOSTIC_MEDICINE, { update })


  const handleDelete = (event, id) => {
    event.preventDefault()
    if (window.confirm('Esta acción es irreversible, ¿Estás seguro de borrar este medicamento?')) {
      deleteMedicine({
        variables: {
          input: { id }
        }
      })
    }
  }

  if (loading) {
    return (
      <Loading />
    )
  }

  if (error || deleteResult.error) {
    return (
      <div>Error :(</div>
    )
  }

  return (
    <Styles>
      <div className="row">
        <div className="col d-flex flex-warp align-items-baseline justify-content">
          <h5 className="mr-auto text-secondary">
              Historial Medicamentos
          </h5>
          <AuthorizedElement roles={['permission_team-show-player-medicines']}>
            <FilterRow>
              <DateFilter
                value={{
                  start_date: filters.start_date,
                  end_date: filters.end_date
                }}
                setValue={setFilters}
              />
            </FilterRow>
          </AuthorizedElement>
          <div className='d-lg-block d-none'>
            <AuthorizedElement roles={['permission_team-create-player-medicines']}>
              <Button
                className="mb-2"
                onClick={() => {
                  setOpenModal(true)
                  setAction('CREATE')
                  setUpdateValues({
                    id_player: idPlayer,
                    date: new Date(),
                    id_medicine: '1',
                    id_medicine_classification: '1',
                    id_medicine_via: '1',
                    dose: '0'
                  })
                }}
              >
                <FaPlus className="mb-1 mr-2" color={'white'} />
                Agregar Medicamento
              </Button>
            </AuthorizedElement>
          </div>
        </div>
      </div>
      <div className='d-block d-lg-none'>
        <AuthorizedElement roles={['permission_team-create-player-medicines']}>
          <Button
            className='mb-3' variant="primary" block
            onClick={() => {
              setOpenModal(true)
              setAction('CREATE')
              setUpdateValues({
                id_player: idPlayer,
                date: new Date(),
                id_medicine: '1',
                id_medicine_classification: '1',
                id_medicine_via: '1',
                dose: '0'
              })
            }}
          >
            <FaPlus className="mb-1 mr-2" color={'white'} />
                Agregar Medicamento
          </Button>
        </AuthorizedElement>
      </div>
      <div className="row">
        <div className="col">
          <Table className='table-font table-responsive-lg' striped bordered hover size="sm" >
            <thead>
              <tr>
                <th className="date">Fecha</th>
                <th>Medicamento</th>
                <th>Clasificación</th>
                <th>Vía de administración</th>
                <th>Dosis (ml/mg)</th>
                <th>Comentarios</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {data.getDiagnosticMedicines.diagnosticMedicines.map((playerMedicine) =>
                <tr key={playerMedicine.id}>
                  <td className="text-no-break text-center">
                    {moment(playerMedicine.date).format('DD/MM/YYYY')}
                  </td>
                  <td className="text-no-break text-center">
                    {medicines.data?.getMedicines.medicines.find(
                      medicine => medicine.id == playerMedicine.id_medicine
                    )?.name}
                  </td>
                  <td className='text-center'>
                    {medicineClassifications.data?.getMedicineClassifications.medicineClassifications.find(
                      classification => classification.id == playerMedicine.id_medicine_classification
                    )?.name}
                  </td>
                  <td className='text-center'>
                    {medicinesVias.data?.getMedicineVias.medicineVias.find(
                      via => via.id == playerMedicine.id_medicine_via
                    )?.name}</td>
                  <td className='text-center'>{playerMedicine.dose}</td>
                  <td className="comments">
                    {playerMedicine.comments ? playerMedicine.comments : '--'}
                  </td>
                  <td>
                    <div className="d-flex justify-content-center">
                      <AuthorizedElement roles={['permission_team-update-player-medicines']}>
                        <Button
                          size="sm"
                          variant="warning"
                          className="mr-2"
                          data-tip data-for="medicines"
                          onClick={() => {
                            setOpenModal(true)
                            setAction('EDIT')
                            setUpdateValues({
                              id: parseInt(playerMedicine.id, 10),
                              date: new Date(moment(`${playerMedicine.date} 12:00:00`)),
                              id_medicine: playerMedicine.id_medicine,
                              id_medicine_classification: playerMedicine.id_medicine_classification,
                              id_medicine_via: playerMedicine.id_medicine_via,
                              dose: playerMedicine.dose,
                              comments: playerMedicine.comments
                            })
                          }}
                        >
                          <FaPen color={'white'} />
                          <ReactToolTip
                            id="medicines"
                            place="top"
                            effect="solid"
                            type="info"
                          >
                            Editar
                          </ReactToolTip>
                        </Button>
                      </AuthorizedElement>
                      <AuthorizedElement roles={['permission_team-delete-player-medicines']}>
                        <Button
                          variant="danger"
                          size="sm"
                          data-tip data-for="delete"
                          className="mx-1"
                          onClick={(event) => handleDelete(event, playerMedicine.id)}
                        >
                          <FaTrash color={'white'}/>
                          <ReactToolTip
                            id="delete"
                            place="top"
                            effect="solid"
                            type="info"
                          >
                            Eliminar
                          </ReactToolTip>
                        </Button>
                      </AuthorizedElement>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>

      {openModal &&
        <PlayerMedicinesModal
          openModal={openModal}
          setOpenModal={(value) => setOpenModal(value)}
          action={action}
          updateValues={updateValues}
        />
      }
    </Styles>
  )
}

PlayerMedicines.propTypes = {
  idPlayer: PropTypes.number
}

export default PlayerMedicines
