import { gql } from '@apollo/client'

const GET_HISTORY_CLINICAL_EVENTS = gql`
  query getHistoryClinicalEvents($input: HistoryClinicalEventInput!) {
    getHistoryClinicalEvents(input: $input) {
      historyClinicalEvents {
        id
        createdAt
        updatedAt
        ClinicalEventHistories {
          id
          start_date
          end_date
        }
        Diagnostics {
          id
          side
          createdAt
          id_diagnostic_type
          DiagnosticType {
            name
          }
          DiagnosticComplement {
            name
          }
          Pathology {
            name
          }
          SkeletalMuscle {
            name
          }
          BodyZone {
            name
          }
          JointLigament {
            id
            name
          }
          PlexoSystem {
            id
            name
          }
          DiagnosticAvailabilities {
            id
            AvailabilityCondition {
              AvailabilityConditionType {
                id
                name_availability_condition_type
              }
            }
          }
        }
      }
      status{
        success
        code
        message
      }
    }
  }
`

export default GET_HISTORY_CLINICAL_EVENTS

