import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { Form } from 'react-bootstrap'
import { useMutation } from '@apollo/client'
import { CREATE_AUTOREPORT, CREATE_TEAM_AUTOREPORT } from '../../apollo/mutations'
import { Loading } from '../../components'
import { AutoReportContext } from './AutoReportContext'

const AutoReport = ({ cell, column, rowState, setRowState }) => {
  const selectRef = React.useRef(null)
  const { wellness, biomedic, filters } = React.useContext(AutoReportContext)
  const [inputValue, setInputValue] = React.useState('')
  const [requestStatus, setRequestStatus] = React.useState('')
  const [selectChanged, setSelectChanged] = React.useState(false)

  React.useEffect(() => {
    setInputValue(rowState[cell?.id_player]?.[cell?.DimensionTest.id]?.meters[cell?.id_meter]?.value || '')
  }, [rowState, wellness, biomedic])

  const [create, createResult] = useMutation(CREATE_AUTOREPORT)
  const [update] = useMutation(CREATE_TEAM_AUTOREPORT)

  React.useEffect(() => {
    if (selectChanged) {
      handleBlur(inputValue)
      setSelectChanged(false)
      selectRef.current.blur()
    }
  }, [selectChanged])

  React.useEffect(() => {
    if (createResult.called) {
      setRowState((current) => {
        current[cell?.id_player].action = 'UPDATE'
        return current
      })
    }
  }, [createResult])

  const handleChange = (value) => {
    setInputValue(value)

    setRowState((current) => {
      current[cell?.id_player][cell?.DimensionTest.id].meters[column.id].value = value
      return current
    })
  }

  const handleBlur = (inputValue) => {
    if (rowState[cell?.id_player].action === 'UPDATE' || inputValue) {

      // const ids = cell?.id_meter ? { id_wellness: cell?.id_wellness, id_biomedic: cell?.id_biomedic } : { id_player: parseInt(cell?.id_player, 10) }
      // const dimension_test_ids = cell?.id_meter ? {} : { dimension_test_ids: filters.id_dimension_test }

      const wellness = {
        '1': rowState[cell?.id_player]['1'],
        '37': rowState[cell?.id_player]['37']
      }

      const biomedic = {
        '28': rowState[cell?.id_player]['28'],
        '29': rowState[cell?.id_player]['29']
      }

      if (rowState[cell?.id_player].action === 'CREATE') {
        setRequestStatus('loading')

        create({
          variables: {
            input: {
              id_player: parseInt(cell?.id_player, 10),
              dimension_test_ids: filters.id_dimension_test,
              meters: {
                wellness: JSON.stringify(wellness),
                biomedic: JSON.stringify(biomedic)
              },
              date: filters.start_date,
            }
          }
        }).then(({ data }) => {
          if (data.createAutoreport.status.success) {
            setRequestStatus('success')
          } else {
            setRequestStatus('error')
          }
        }).catch(() => setRequestStatus('error'))
      }

      if (rowState[cell?.id_player].action === 'UPDATE') {
        update({
          variables: {
            input: {
              id_player: parseInt(cell?.id_player, 10),
              // id_wellness: cell?.id_wellness,
              // id_biomedic: cell?.id_biomedic,
              meters: {
                wellness: JSON.stringify(wellness),
                biomedic: JSON.stringify(biomedic)
              },
              date: filters.start_date,
            }
          }
        }).then(({ data }) => {
          if (data.createTeamAutoreport.status.success) {
            setRequestStatus('success')
          } else {
            setRequestStatus('error')
          }
        }).catch(() => setRequestStatus('error'))
      }
    }
  }

  const handleSelectChange = (value) => {
    setInputValue(value)

    setRowState((current) => {
      current[cell?.id_player][cell?.DimensionTest.id].meters[column.id].value = value
      return current
    })

    setSelectChanged(!selectChanged)
  }

  const getOptions = () => {
    return cell?.MeterOptions?.reduce((acc, option) =>
      [...acc, { value: option.value, label: option.value }]
    ,[])
  }

  const getStyle = (value) => {
    if (column.id == 216 || column.id == 217) {
      if (['1', '2', '3'].includes(value)) {
        return ({
          backgroundColor: '#ff2600',
        })
      }
      if (['4', '5', '6'].includes(value)) {
        return ({
          backgroundColor: '#fefb00',
        })
      }
      if (['7', '8', '9', '10'].includes(value)) {
        return ({
          backgroundColor: '#86bc45',
        })
      }
    }

    if (column.id == 6 || column.id == 215) {
      if (['0', '1', '2', '3'].includes(value)) {
        return ({
          backgroundColor: '#86bc45',
        })
      }
      if (['4', '5', '6'].includes(value)) {
        return ({
          backgroundColor: '#fefb00',
        })
      }
      if (['7', '8', '9', '10'].includes(value)) {
        return ({
          backgroundColor: '#ff2600',
        })
      }
    }

    if (['0', '1', '2'].includes(value)) {
      return ({
        backgroundColor: '#ff2600',
      })
    }
    if (['3'].includes(value)) {
      return ({
        backgroundColor: '#fefb00',
      })
    }
    if (['4', '5'].includes(value)) {
      return ({
        backgroundColor: '#86bc45',
      })
    }
  }

  const getInput = (type) => {
    const customStyles = {
      container: (provided) => ({
        ...provided,
        position:  'absolute',
        width: '94px',
      }),
      valueContainer: (provided, { getValue }) => {
        const style = getStyle(getValue()[0]?.value)
        return ({
          ...provided,
          ...style,
          color: '#000',
          border: '0.01px solid #000'
        })
      },
      option: (provided, { data }) => {
        const style = getStyle(data.value)

        return ({
          ...provided,
          ...style,
          color: '#000',
          border: '0.01px solid #000'
        })
      }
    }

    switch(type) {
    case '1':
    case '2':
      return (
        <Form.Control
          type="number"
          placeholder="--"
          value={inputValue}
          onChange={(e) => handleChange(e.target.value)}
          onBlur={(e) => handleBlur(e.target.value)}
        />
      )
    case '4':
      return (
        <Select
          ref={selectRef}
          classNamePrefix="react-select"
          className='react-select-container'
          menuPlacement="auto"
          styles={customStyles}
          value={getOptions()?.find(option => option.value == inputValue) || ''}
          options={getOptions()}
          placeholder="--"
          onMenuClose
          // onChange={(option) => handleChange(option.value)}
          onChange={(option) => handleSelectChange(option.value)}
          // onBlur={(e) => handleBlur(e, inputValue)}
        />
      )
    default:
      return null
    }
  }

  if (requestStatus === 'loading') {
    return (
      <Loading
        containerStyles={{
          margin: '8px 0',
          textAlign: 'center'
        }}
      />
    )
  }

  return (
    <div className={`${requestStatus} mh-75 h-100`}>
      {(column.id == 218 || column.id == 219) ?
        <Form.Control
          as="textarea"
          rows={1}
          value={inputValue}
          onChange={(e) => handleChange(e.target.value)}
          onBlur={(e) => handleBlur(e.target.value)}
        />
        : (column.id == 138 || column.id == 143) ?
          <Select
            ref={selectRef}
            classNamePrefix="react-select"
            className='react-select-container'
            as="select"
            styles={{
              container: (provided) => ({
                ...provided,
                position: 'absolute',
                width: '94px'
              })
            }}
            placeholder="--"
            value={cell?.MeterOptions.map((current) => (current.id == inputValue) && ({ value: current.id, label: current.name }), {}) || ''}
            options={cell?.MeterOptions.reduce((acc, current) => [...acc, { value: current.id, label: current.name }], [{ value: '', label: 'Selecciona una opción' }])}
            onChange={(option) => handleSelectChange(option.value)}
            // onChange={(option) => handleChange(option.value)}
            // onBlur={() => handleBlur(inputValue)}
          >
          </Select>
          : getInput(cell?.MeterType.id)
      }
    </div>
  )
}

AutoReport.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.string,
    id_player: PropTypes.string,
    id_meter: PropTypes.string,
    DimensionTest: PropTypes.shape({
      id: PropTypes.string
    }),
    MeterOptions: PropTypes.array,
    MeterType: PropTypes.shape({
      id: PropTypes.string
    })
  }),
  column: PropTypes.object,
  rowState: PropTypes.object,
  setRowState: PropTypes.func
}

export default AutoReport
