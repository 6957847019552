import React from 'react'
import PropTypes from 'prop-types'
import AuthorizedElement from '../../../../keycloak/utils/AuthorizedElement'
import { Table, Button } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_PLAYER_KINESIC_EVALUATIONS } from '../../../../apollo/queries'
import { Loading } from '../../../../components'
import { KinesicEvaluationContext } from '../KinesicEvaluationContext'
import KinesicBlockRow from './KinesicBlockRow'
import KinesicEvaluationModal from '../KinesicEvaluationModal'

const KinesicMeasureTable = ({ kinesicBlock, kinesicBlockMeasures }) => {
  let { id_player } = useParams()
  const { filters } = React.useContext(KinesicEvaluationContext)

  const [action, setAction] = React.useState('CREATE')
  const [openModal, setOpenModal] = React.useState(false)
  const [formValues, setFormValues] = React.useState({ date: new Date() })
  const [measures] = React.useState(() => kinesicBlockMeasures.filter(({ id_kinesic_blocks }) => id_kinesic_blocks === kinesicBlock.id))

  const { data, loading, error } = useQuery(GET_PLAYER_KINESIC_EVALUATIONS, {
    variables: {
      input: {
        id_player: id_player,
        id_kinesic_blocks: kinesicBlock.id,
        ...filters
      }
    }
  })

  if (error) {
    return (
      <div>Error :(</div>
    )
  }

  if (loading) {
    return(
      <Loading/>
    )
  }

  return (
    <>
      <div className='d-flex justify-content-end my-1'>
        <AuthorizedElement roles={['permission_team-create-player-kinesic-evaluation']}>
          <Button
            className="btn btn-primary btn-sm"
            onClick={() => {
              setFormValues({ date: new Date() })
              setOpenModal(true)
            }}
          >
            {'Agregar'}
          </Button>
        </AuthorizedElement>
      </div>

      <Table responsive striped bordered hover size="sm text-center" >
        <thead>
          <tr>
            <th className="date">Fecha</th>
            {measures.map(kinesicBlockMeasure =>
              <th className='colspan' colSpan={kinesicBlockMeasure.KinesicMeasureOptions.length} key={kinesicBlockMeasure.id}>
                {kinesicBlockMeasure.name}
              </th>
            )}
            <th className="actions">Acciones</th>
          </tr>
          <tr>
            <th></th>
            {measures.map(kinesicBlockMeasure =>
              kinesicBlockMeasure.KinesicMeasureOptions.map(KinesicMeasureOptions=>
                <th key={KinesicMeasureOptions.id}>
                  {KinesicMeasureOptions.name}
                </th>
              )
            )}
            <th></th>
          </tr>
        </thead>
        <tbody className='text-center'>
          {data.getPlayerKinesicEvaluations.playerKinesicEvaluations.map(playerKinesicEvaluation => {
            if (kinesicBlock.id === '2') {
              const parseValues = JSON.parse(playerKinesicEvaluation.values)

              const kinesicOptionsOfThisBodyZone = measures.map(kbm =>
                kbm.KinesicMeasureOptions.map(kmo =>
                  parseValues[kmo.id]
                )
              ).flat().filter(v => v)

              if (!kinesicOptionsOfThisBodyZone.length) {
                return null
              }
            }

            return (
              <KinesicBlockRow
                key={playerKinesicEvaluation.id}
                playerKinesicEvaluation={playerKinesicEvaluation}
                kinesicBlockMeasures={measures}
                kinesicBlockid={kinesicBlock.id}
                setOpenModal={(value) => setOpenModal(value)}
                setAction={(value) => setAction(value)}
                setFormValues={(value) => setFormValues(value)}
              />
            )
          }
          )}
        </tbody>
      </Table>
      {openModal &&
        <KinesicEvaluationModal
          openModal={openModal}
          setOpenModal={(value) => setOpenModal(value)}
          action={action}
          setAction={(value) => setAction(value)}
          formValues={formValues}
          kinesicBlockid={kinesicBlock.id}
          kinesicBlockName={kinesicBlock.name}
          kinesicBlockMeasures={measures}
        />
      }
    </>
  )
}

KinesicMeasureTable.propTypes = {
  kinesicBlock: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  kinesicBlockMeasures: PropTypes.array.isRequired
}

export default KinesicMeasureTable
