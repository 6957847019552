import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useMutation } from '@apollo/client'
import { GET_KINESIC_TREATMENTS } from '../../../../apollo/queries'
import { CREATE_KINESIC_TREATMENT, UPDATE_KINESIC_TREATMENT } from '../../../../apollo/mutations'
import { Formik } from 'formik'
import { Modal, Form, Button } from 'react-bootstrap'
import { StatusAlert, SubmitButton, DatePickerInput } from '../../../../components'
import constants from '../../../../config/constants'
import Styles from './LegacyTherapeucticActionsModalStyle'

const LegacyTherapeucticActionsModal = ({
  openModal = false,
  setOpenModal,
  action = 'CREATE',
  updateValues = {},
}) => {
  const handleClose = () => setOpenModal(false)

  const update = (cache, { data }) => {
    cache.modify({
      fields: {
        getKinesicTreatments(existingTreatment = []) {
          const newTreatment = data.getKinesicTreatments
          cache.writeQuery({
            query: GET_KINESIC_TREATMENTS,
            data: {
              newTreatment,
              ...existingTreatment
            }
          })
        }
      }
    })
  }

  const [mutation, mutationResult] = useMutation(
    action === 'CREATE' ? CREATE_KINESIC_TREATMENT : UPDATE_KINESIC_TREATMENT,
    { update }
  )


  const modalTitle = action === 'CREATE' ? 'Agregar Acciones Terapéuticas': 'Editar Acciones Terapéuticas'
  const successMessage = action === 'CREATE' ? 'Accion Terapéutica agregada con éxito.' : 'Accion Terapéutica actualizada con éxito.'
  const statusResult = action === 'CREATE' ? mutationResult.data?.createKinesicTreatment.status : mutationResult.data?.updateKinesicTreatment.status

  if (mutationResult.error) {
    return (
      <div>Error :(</div>
    )
  }

  return (
    <Modal
      size="lg"
      show={openModal}
      dialogClassName="full-width"
      contentClassName="full-height"
      onHide={handleClose}
    >
      <Modal.Header closeButton className="bg-success text-white">
        <Modal.Title>
          <img
            width="35px"
            className="mr-1"
            src={
              `${constants.STATIC_IMAGES_URL}/kine-icon.svg`
            }
          />
          {modalTitle}
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={updateValues}
        validate={values => {
          const errors = {}
          if (!values.date) {
            errors.date = 'Debes ingresar una fecha.'
          }
          if (!values.text) {
            errors.text = 'Debes ingresar tratamiento.'
          }
          return errors
        }}
        onSubmit={(values, { resetForm }) => {
          mutation({
            variables: {
              input: {
                ...values,
                date: moment(values.date).format('YYYY-MM-DD'),
                text: values.text
              }
            }
          }).then(({ data }) => {
            if (action === 'CREATE') {
              if (data.createKinesicTreatment.status.success) {
                resetForm({ values: '' })
              }
            }
            // modalHeaderRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
          })
        }}
      >
        {({
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          setFieldValue,
          handleSubmit
        }) => (
          <Form onSubmit={handleSubmit}>
            {mutationResult.data &&
              <StatusAlert
                successMessage={successMessage}
                status={statusResult}
              />
            }
            <Styles>
              <Modal.Body>
                <Form.Row>
                  <DatePickerInput
                    label="Fecha"
                    name="date"
                    value={values.date}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    touched={touched.date ? true : false}
                    error={errors.date}
                    width="col-12 col-md-6"
                  />
                </Form.Row>
                <Form.Row>
                  <Form.Group className="col-12 col-md-12">
                    <Form.Label>Acciones Terapéuticas</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="text"
                      placeholder="Ingresa un texto"
                      value={values.text}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={
                        (touched.text && errors.text)
                          ? true
                          : false
                      }
                    />
                  </Form.Group>
                </Form.Row>
              </Modal.Body>
            </Styles>
            <Modal.Footer className="d-flex justify-content-end">
              <Button variant="secondary" onClick={handleClose}>
                  Cerrar
              </Button>
              <SubmitButton
                action={action}
                loading={mutationResult.loading}
                className="btn list-item px-6"
              />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

LegacyTherapeucticActionsModal.propTypes = {
  idPlayer: PropTypes.number,
  openModal:PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  action: PropTypes.string,
  updateValues: PropTypes.object,
  dimension: PropTypes.object
}

export default LegacyTherapeucticActionsModal
