import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import hasRole from '../../keycloak/utils/hasRole'
import { useParams, useHistory } from 'react-router-dom'
import { Formik } from 'formik'
import { Form } from 'react-bootstrap'
import { useMutation } from '@apollo/client'
import { CREATE_DIAGNOSTIC, UPDATE_DIAGNOSTIC } from '../../apollo/mutations'
import { DiagnosisForm, CustomBreadcrumb, FooterForm, TitleSection } from '../../components'
import { appStore } from '../../store/appStore'
import Styles from './DiagnosticMainFormStyle'

const DiagnosticMainForm = ({ location }) => {
  const { diagnostic } = location

  let history = useHistory()
  let { action, id_player, id_clinical_event } = useParams()

  const { state } = React.useContext(appStore)

  const breadcrumbPath = [
    { label: 'Deportistas', href: '/players' },
    { label: 'Resumen médico', href: `/player-info/${state.player?.id_category_type}/${id_player}/clinical-event` },
    { label: 'Detalle evento clínico', href: `/clinical-event-details/${id_player}/${id_clinical_event}` }
  ]


  const [mutation, { loading, error }] = useMutation(
    action === 'create' ? CREATE_DIAGNOSTIC : UPDATE_DIAGNOSTIC
  )

  if (error) {
    return (
      <div>Error :(</div>
    )
  }

  if ((!diagnostic || !state.player) && action === 'edit') {
    return (
      <div onClick={history.goBack()} />
    )
  }

  return (
    <React.Fragment>
      <Formik
        initialValues={{
          id_diagnostic_type: diagnostic?.DiagnosticType?.id || '',
          id_sub_diagnostic: diagnostic?.SubDiagnostic?.id || '',
          id_pathology: diagnostic?.Pathology?.id || '',
          id_diagnostic_complement: diagnostic?.DiagnosticComplement?.id || '',
          id_skeletal_muscle: (!diagnostic?.SubDiagnostic?.skeletal_muscle && diagnostic?.SubDiagnostic?.body_zone) ? diagnostic?.BodyZone?.id : (diagnostic?.SkeletalMuscle?.id || ''),
          side: diagnostic?.side || '1',
          id_joint_ligament: diagnostic?.JointLigament?.id || '',
          id_plexo_system: diagnostic?.PlexoSystem?.id || '',
          id_procedure_material: diagnostic?.ProcedureMaterial?.id || '',
          id_availability_condition_type: diagnostic?.DiagnosticAvailabilities[0].AvailabilityCondition.AvailabilityConditionType.id || '',
          comments: diagnostic?.comments|| '',
          injury_muscle_front_rear_dimension: diagnostic?.injury_muscle_front_rear_dimension|| '',
          injury_muscle_middle_side_dimension: diagnostic?.injury_muscle_middle_side_dimension|| '',
          injury_muscle_depth_dimension: diagnostic?.injury_muscle_depth_dimension|| '',
        }}
        validate={values => {
          const errors = {}

          if (!values.id_diagnostic_type) {
            errors.id_diagnostic_type = 'Debes ingresar una categoría.'
          }
          if (!values.id_sub_diagnostic) {
            errors.id_sub_diagnostic = 'Debes ingresar una categoría.'
          }
          if (!values.id_pathology) {
            errors.id_pathology = 'Debes ingresar una categoría.'
          }
          if (!values.id_availability_condition_type) {
            errors.id_availability_condition_type = 'Debes ingresar una condición.'
          }
          if (values.id_sub_diagnostic) {

            // if (
            //   !['1', '2', '3', '4', '14', '31', '33'].includes(values.id_sub_diagnostic)
            // ) {
            //   if (!values.id_diagnostic_complement) {
            //     errors.id_diagnostic_complement = 'Debes ingresar una categoría.'
            //   }
            // }

            if (
              !['12', '14', '15', '16', '18', '19', '20', '21', '23', '24', '25', '27', '28', '29'].includes(values.id_sub_diagnostic)
            ) {
              if (!values.id_skeletal_muscle) {
                errors.id_skeletal_muscle = 'Debes ingresar una categoría.'
              }
            }

            if (['3', '5', '11', '30'].includes(values.id_sub_diagnostic)) {
              // if (!values.id_joint_ligament) {
              //   errors.id_joint_ligament = 'Debes ingresar una articulación/ligamento.'
              // }
            }

            // if (values.id_sub_diagnostic === '7' && !values.id_plexo_system) {
            //   errors.id_plexo_system = 'Debes ingresar un sistema plexo.'
            // }
            // if (['7', '12', '30', '32'].includes(values.id_sub_diagnostic) && !values.id_procedure_material) {
            //   errors.id_procedure_material = 'Debes ingresar un procedimiento/materiales/reflejo.'
            // }
          }

          if (!values.id_availability_condition_type) {
            errors.id_availability_condition_type = 'Debes ingresar una condición.'
          }

          if (!values.side) {
            errors.side = 'Debes ingresar una lateralidad.'
          }

          return errors
        }}
        onSubmit={(values) => {
          const id = action === 'edit' ? { id: parseInt(diagnostic?.id, 10) } : {}
          mutation({
            variables: {
              input: {
                ...id,
                id_clinical_event: parseInt(id_clinical_event),
                id_diagnostic_type: parseInt(values.id_diagnostic_type),
                id_sub_diagnostic: parseInt(values.id_sub_diagnostic),
                id_pathology: parseInt(values.id_pathology),
                id_diagnostic_complement: parseInt(values.id_diagnostic_complement),
                id_skeletal_muscle: parseInt(values.id_skeletal_muscle),
                side: parseInt(values.side),
                id_joint_ligament: parseInt(values.id_joint_ligament),
                id_plexo_system: parseInt(values.id_plexo_system),
                id_procedure_material: parseInt(values.id_procedure_material),
                id_availability_condition_type: parseInt(values.id_availability_condition_type),
                comments: values.comments,
                date: moment().format('YYYY-MM-DD'),
                injury_muscle_front_rear_dimension: values.injury_muscle_front_rear_dimension,
                injury_muscle_middle_side_dimension: values.injury_muscle_middle_side_dimension,
                injury_muscle_depth_dimension: values.injury_muscle_depth_dimension,

              }
            }
          }).then(({ data }) => {
            if (action === 'create') {
              if (data?.createDiagnostic.status.success) {
                history.replace(`/clinical-event-details/${id_player}/${id_clinical_event}`)
              }
            } else {
              if (data?.updateDiagnostic.status.success) {
                history.replace(`/clinical-event-details/${id_player}/${id_clinical_event}`)
              }
            }
          })
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          setFieldValue,
          setTouched
        }) => (
          <Styles>
            <CustomBreadcrumb
              path={hasRole(['role_player']) ? breadcrumbPath.slice(1) : breadcrumbPath }
              active="Editar Diagnóstico"
            />
            <div className="container">
              <TitleSection title={'Editar Diagnóstico'} />
              <div className="mb-3">
                <Form onSubmit={handleSubmit}>
                  <DiagnosisForm
                    values={values}
                    touched={touched}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    setTouched={setTouched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    id_diagnostic_type={parseInt(diagnostic?.DiagnosticType?.id, 10) || null}
                    id_sub_diagnostic={parseInt(diagnostic?.SubDiagnostic?.id) || null}
                  />
                  <FooterForm
                    className="mt-3"
                    action={action === 'edit' ? 'EDIT' : 'CREATE'}
                    loading={loading}
                    handleCancel={() => history.goBack()}
                  />
                </Form>
              </div>
            </div>
          </Styles>
        )}
      </Formik>
    </React.Fragment>
  )
}

DiagnosticMainForm.propTypes = {
  location: PropTypes.shape({
    diagnostic: PropTypes.shape({
      id: PropTypes.string,
      side: PropTypes.number,
      id_joint_ligament: PropTypes.string,
      id_plexo_system: PropTypes.string,
      comments: PropTypes.string,
      injury_muscle_front_rear_dimension: PropTypes.string,
      injury_muscle_middle_side_dimension: PropTypes.string,
      injury_muscle_depth_dimension: PropTypes.string,
      DiagnosticType: PropTypes.shape({
        id: PropTypes.string
      }),
      SubDiagnostic: PropTypes.shape({
        id: PropTypes.string,
        skeletal_muscle: PropTypes.bool,
        body_zone: PropTypes.bool
      }),
      DiagnosticComplement: PropTypes.shape({
        id: PropTypes.string
      }),
      SkeletalMuscle: PropTypes.shape({
        id: PropTypes.string
      }),
      BodyZone: PropTypes.shape({
        id: PropTypes.string
      }),
      ProcedureMaterial: PropTypes.shape({
        id: PropTypes.string
      }),
      Pathology: PropTypes.shape({
        id: PropTypes.string
      }),
      JointLigament: PropTypes.shape({
        id: PropTypes.number
      }),
      PlexoSystem: PropTypes.shape({
        id: PropTypes.number
      }),
      DiagnosticAvailabilities: PropTypes.arrayOf(
        PropTypes.shape({
          AvailabilityCondition: PropTypes.shape({
            AvailabilityConditionType: PropTypes.shape({
              id: PropTypes.id
            })
          })
        })
      )
    })
  })
}

export default DiagnosticMainForm
