import { gql } from '@apollo/client'

const UPDATE_AUTOREPORT = gql`
  mutation updateAutoreport($input: UpdateAutoreportInput!) {
    updateAutoreport(input: $input) {
      status {
        success
        code
        message
      }
    }
  }
`

export default UPDATE_AUTOREPORT
