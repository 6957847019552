import React from 'react'
import PropTypes from 'prop-types'
import { StickyTable } from '../../../../components'
import useNutrition from '../useNutrition'

const NutritionTable = ({ meters, playerDimensions, jsonToMap }) => {
  const { data, columns } = useNutrition({ allMeters: meters, playerDimensions, jsonToMap })
  return (
    <div className="mt-5 ml-4 mb-5 mr-4">
      <StickyTable
        data={data}
        columns={columns}
        getCellProps={cellInfo => ({
          style: {
            background: cellInfo.row.index % 2 === 0 ? '#e0e6f2' : '#ffffff',
          },
        })}
      />
    </div>
  )
}

NutritionTable.propTypes = {
  meters: PropTypes.array,
  playerDimensions: PropTypes.array,
  jsonToMap: PropTypes.func.isRequired
}

export default NutritionTable
