import { gql } from '@apollo/client'

const UPDATE_DIAGNOSTIC = gql`
  mutation updateDiagnostic($input: UpdateDiagnosticInput!) {
    updateDiagnostic(input: $input) {
      status {
        success
        code
        message
      }
    }
  }
`

export default UPDATE_DIAGNOSTIC
