import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Tab, Tabs } from 'react-bootstrap'
import { FaCalendarCheck } from 'react-icons/fa'
import { ScheduleBlockForm } from '../Schedule/ScheduleBlockForm'
import { EventForm } from '../EventForm'
import hasRole from '../../../keycloak/utils/hasRole'

const CalendarModal = ({ singleEvent, openModal, setOpenModal, filter }) => {
  const [modalTitile, setModalTitle] = useState(
    singleEvent.action === 'CREATE'
      ? 'Crear Evento'
      : 'Editar Evento'
  )

  const handleClose = () => setOpenModal(false)

  const handleModalTitle = (eventKey) => {
    if (eventKey === 'event') {
      setModalTitle(
        singleEvent.action === 'CREATE'
          ? 'Crear Evento'
          : 'Editar Evento'
      )
    }
    if (eventKey === 'schedule') {
      setModalTitle('Bloquear Horario')
    }
  }

  const getTabs = () => {
    if (hasRole(['permission_calendar-create-block-schedule'])) {
      return (
        <Tabs onSelect={(k) => handleModalTitle(k)}>
          <Tab eventKey="event" title="Evento">
            <EventForm singleEvent={singleEvent} handleClose={handleClose} filter={filter} />
          </Tab>
          <Tab eventKey="schedule" title="Bloquear Horario">
            <ScheduleBlockForm singleEvent={singleEvent} handleClose={handleClose} />
          </Tab>
        </Tabs>
      )
    }

    return (
      <EventForm singleEvent={singleEvent} handleClose={handleClose} filter={filter} />
    )
  }

  return (
    <Modal
      show={openModal}
      onHide={handleClose}
      dialogClassName="full-width"
      contentClassName="full-height"
    >
      <Modal.Header closeButton className="bg-success text-white">
        <Modal.Title>
          <FaCalendarCheck className="mr-1 mb-2" color={'white'} />
          {modalTitile}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {getTabs()}
      </Modal.Body>
    </Modal>
  )
}

CalendarModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  singleEvent: PropTypes.shape({
    action: PropTypes.string.isRequired,
  }),
  filter: PropTypes.shape({})
}

export default CalendarModal
