import styled from 'styled-components'
export default styled.div`

  table{
    font-size:12px;
  }

  table th {
    min-width: 120px;
    /* max-width:100px; */
  }

  table td{
    text-align:center;
  }

  .date{
    width:90px;
    text-align: center;
  }

  .btn-level {
    color: white;
    border-radius: 5px;
    border: none;
    width:100%;
  }

  .btn-level:hover {
    background-color: #142c59;
    color: white;
  }

/* <---- Los colores antiguos----->

  .level-0 {
    background-color: #007fc6;
  }

  .level-1 {
    background-color: #009de2;
  }

  .level-2 {
    background-color: #e7d700;
  }

  .level-3 {
    background-color: #4fad31;
  }

  .level-4 {
    background-color: #afca09;
  }

  .level-5 {
    background-color: #fdeb01;
  }

  .level-6 {
    background-color: #fbcb00;
  }

  .level-7 {
    background-color: #f4a500;
  }

  .level-8 {
    background-color: #ee7c00;
  }

  .level-9 {
    background-color: #e74d0f;
  }

  .level-10 {
    background-color: #e20612;
  } */

  /* .level-01 {
    background-color: #e74d0f;
  }

  .level-02 {
    background-color: #f4a500;
  }

  .level-03 {
    background-color: #fbcb00;
  }

  .level-04 {
    background-color: #e7d700;
  }

  .level-05 {
    background-color: #afca09;
  }

  <--------------------------> */

  .level-0 {
    background-color: #86bc45;
    color: #000;
    /* border: 0.01px solid #000; */
  }

  .level-1 {
    background-color: #86bc45;
    color: #000;
    /* border: 0.01px solid #000; */
  }

  .level-2 {
    background-color: #86bc45;
    color: #000;
    /* border: 0.01px solid #000; */
  }

  .level-3 {
    background-color: #86bc45;
    color: #000;
    /* border: 0.01px solid #000; */
  }

  .level-4 {
    background-color: #fefb00;
    color: #000;
    /* border: 0.01px solid #000; */
  }

  .level-5 {
    background-color: #fefb00;
    color: #000;
    /* border: 0.01px solid #000; */
  }

  .level-6 {
    background-color: #fefb00;
    color: #000;
    /* border: 0.01px solid #000; */
  }

  .level-7 {
    background-color: #ff2600;
    color: #000;
    /* border: 0.01px solid #000; */
  }

  .level-8 {
    background-color: #ff2600;
    color: #000;
    /* border: 0.01px solid #000; */
  }

  .level-9 {
    background-color: #ff2600;
    color: #000;
    /* border: 0.01px solid #000; */
  }

  .level-10 {
    background-color: #ff2600;
    color: #000;
    /* border: 0.01px solid #000; */
  }

  .level-01 {
    background-color: #ff2600;
    color: #000;
    /* border: 0.01px solid #000; */
  }

  .level-02 {
    background-color: #ff2600;
    color: #000;
    /* border: 0.01px solid #000; */
  }

  .level-03 {
    background-color: #fefb00;
    color: #000;
    /* border: 0.01px solid #000; */
  }

  .level-04 {
    background-color: #86bc45;
    color: #000;
    /* border: 0.01px solid #000; */
  }

  .level-05 {
    background-color: #86bc45;
    color: #000;
    /* border: 0.01px solid #000; */
  }

  .TQR-1{
    background-color: #ff2600;
    color: #000;
    /* border: 0.01px solid #000; */
  }

  .TQR-2{
    background-color: #ff2600;
    color: #000;
    /* border: 0.01px solid #000; */
  }

  .TQR-3{
    background-color: #ff2600;
    color: #000;
    /* border: 0.01px solid #000; */
  }

  .TQR-4 {
    background-color: #fefb00;
    color: #000;
    /* border: 0.01px solid #000; */
  }
  .TQR-5 {
    background-color: #fefb00;
    color: #000;
    /* border: 0.01px solid #000; */
  }

  .TQR-6 {
    background-color: #fefb00;
    color: #000;
    /* border: 0.01px solid #000; */
  }

  .TQR-7 {
    background-color: #86bc45;
    color: #000;
    /* border: 0.01px solid #000; */
  }

  .TQR-8 {
    background-color: #86bc45;
    color: #000;
    /* border: 0.01px solid #000; */
  }

  .TQR-9 {
    background-color: #86bc45;
    color: #000;
    /* border: 0.01px solid #000; */
  }

  .TQR-10 {
    background-color: #86bc45;
    color: #000;
    /* border: 0.01px solid #000; */
  }


  .selected {
    background-color: #142c59;
    color: white;
    margin: 0px 4px;
  }

  .list-item {
    opacity: 0;
    animation: fade-in 900ms ease forwards;
  }

  .list-item:nth-child(2) {
    animation-delay: 100ms;
  }

  .list-item:nth-child(3) {
    animation-delay: 200ms;
  }

  .list-item:nth-child(4) {
    animation-delay: 300ms;
  }

  .list-item:nth-child(5) {
    animation-delay: 400ms;
  }

  .list-item:nth-child(6) {
    animation-delay: 500ms;
  }

  .list-item:nth-child(7) {
    animation-delay: 600ms;
  }

  .list-item:nth-child(8) {
    animation-delay: 700ms;
  }

  .list-item:nth-child(9) {
    animation-delay: 800ms;
  }

  .list-item:nth-child(10) {
    animation-delay: 900ms;
  }

  .list-item:nth-child(11) {
    animation-delay: 1000ms;
  }

  @keyframes fade-in {
    to {
      opacity: 1;
    }
  }

  .comments{
    min-width: 100px;
    max-width: 200px;
    text-align: justify;
    height: auto;
    word-wrap: break-word;
    text-align: center;
  }

  .chartjs-render-monitor{
    cursor: pointer;
  }

`
