import React from 'react'
import PropTypes from 'prop-types'
import { Form, Modal, Button } from 'react-bootstrap'
import { FaFilter } from 'react-icons/fa'
import Styles from './FilterRowStyle'

const FilterRow = ({ children, align = 'justify-content-start' }) => {
  const [openModalFilters, setOpenModalFilters] = React.useState(false)

  const handleClose = () => setOpenModalFilters(false)

  return (
    <Styles.Main>
      <div className={`d-flex ${align}`}>
        {/* <p className="mt-2 pr-3 d-none d-md-block"> <FaFilter/></p> */}
        <button
          type="button"
          className="p-0 mt-1 d-sm-block d-md-none button filter-btn"
          onClick={() => setOpenModalFilters(true)}
        >
          <FaFilter /> Filtrar
        </button>
        <Form className="pr-3 d-none d-md-block">
          <Form.Row>
            {children}
          </Form.Row>
        </Form>
      </div>

      {openModalFilters &&
        <Modal
          show={openModalFilters}
          onHide={handleClose}
          dialogClassName="full-width"
          contentClassName="full-height"
        >
          <Styles.Modal>
            <Modal.Header closeButton className="bg-success text-white">
              <Modal.Title>
                {'Filtros'}
              </Modal.Title>
            </Modal.Header>
            <Form>
              <Modal.Body>
                {React.Children.map(children || null, (child, key) =>
                  React.cloneElement(child, {
                    key,
                    modal: true
                  })
                )}
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-end">
                <Button
                  variant="success"
                  type="button"
                  onClick={() => handleClose()}
                >
                  {'Filtrar'}
                </Button>
              </Modal.Footer>
            </Form>
          </Styles.Modal>
        </Modal>
      }
    </Styles.Main>
  )
}

FilterRow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  align: PropTypes.string
}

export default FilterRow
