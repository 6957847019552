import React from 'react'
import PropTypes from 'prop-types'
import { CustomChart } from '../../../components'
import Styles from '../DashboardClinicalEventStyle'

const AllTab = ({ data }) => {

  return (
    <Styles>
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-12">
            <CustomChart
              title="Categorías"
              type="line"
              data={{
                datasets: [
                  {
                    label: '# Molestias',
                    fill: false,
                    backgroundColor: 'rgb(255, 99, 132)',
                    borderColor: '#98d4ed',
                    data: data?.allDiagnostics?.soreness
                  },
                  {
                    label: '# Lesiones',
                    fill: false,
                    backgroundColor: 'rgb(255, 99, 132)',
                    borderColor: '#a6efd6',
                    data: data?.allDiagnostics?.injury
                  },
                  {
                    label: '# Enfermedades',
                    fill: false,
                    backgroundColor: 'rgb(255, 99, 132)',
                    borderColor: '#f05fa3',
                    data: data?.allDiagnostics?.illness
                  },
                  {
                    label: '# Cirugías',
                    fill: false,
                    backgroundColor: 'rgb(255, 99, 132)',
                    borderColor: '#f2af73',
                    data: data?.allDiagnostics?.surgery
                  }
                ]
              }}
              options={{
                scales: {
                  xAxes: [{
                    type: 'time',
                    time: {
                      unit: 'day',
                    },
                  }],
                  yAxes: [
                    {
                      ticks: {
                        stepSize: 1,
                        beginAtZero: true,
                      },
                    },
                  ],
                },
              }}
            />
          </div>
        </div>
      </div>

    </Styles>
  )
}

AllTab.propTypes = {
  data: PropTypes.shape({
    allDiagnostics: PropTypes.shape({
      soreness: PropTypes.array,
      injury: PropTypes.array,
      illness: PropTypes.array,
      surgery: PropTypes.array
    })
  }),
}

export default AllTab
