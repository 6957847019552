import styled from 'styled-components'

export default {
  Modal: styled.div`
    .form-label {
      background-color: #28a745;
      color: white;
      padding: 3px 10px;
      width: 100%;
      font-size: 15px;
    }
  `
}
