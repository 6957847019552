import { gql } from '@apollo/client'

const GET_GPS_FILTERS = gql`
  query getGpsFilters($input: GpsFiltersInput!) {
    getGpsFilters(input: $input) {
      filters {
        activities {
          id
          name
          gpsMoments {
            id
            name
            gpsMomentsDescs {
              id
              name
            }
          }
        }
      }
      status {
        success
        code
        message
      }
    }
  }
`
export default GET_GPS_FILTERS

