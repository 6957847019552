import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useMutation, useApolloClient } from '@apollo/client'
import { GET_MEDICNES, GET_DIAGNOSTIC_MEDICINES, GET_MEDICINE_CLASIFICATIONS, GET_MEDICINE_VIAS } from '../../../../apollo/queries'
import { CREATE_DIAGNOSTIC_MEDICINE, UPDATE_DIAGNOSTIC_MEDICINE } from '../../../../apollo/mutations'
import { Formik } from 'formik'
import { Modal, Form, Button } from 'react-bootstrap'
import { FaPrescriptionBottleAlt } from 'react-icons/fa'
import { StatusAlert, SubmitButton, DatePickerInput } from '../../../../components'
import Styles from './PlayerMedicinesModalStyle'

const PlayerMedicinesModal = ({
  openModal = false,
  setOpenModal,
  action = 'CREATE',
  updateValues = {}
}) => {
  const handleClose = () => setOpenModal(false)

  const apolloClient = useApolloClient()
  const { getMedicines } = apolloClient.cache.readQuery({ query: GET_MEDICNES })
  const { getMedicineClassifications } = apolloClient.cache.readQuery({ query: GET_MEDICINE_CLASIFICATIONS })
  const { getMedicineVias } = apolloClient.cache.readQuery({ query: GET_MEDICINE_VIAS })

  const update = (cache, { data }) => {
    cache.modify({
      fields: {
        getDiagnosticMedicines(existingMedicines = []) {
          const newMedicines = data.getDiagnosticMedicines
          cache.writeQuery({
            query: GET_DIAGNOSTIC_MEDICINES,
            data: {
              newMedicines,
              ...existingMedicines
            }
          })
        }
      }
    })
  }

  const [mutation, mutationResult] = useMutation(
    action === 'CREATE' ? CREATE_DIAGNOSTIC_MEDICINE : UPDATE_DIAGNOSTIC_MEDICINE,
    { update }
  )

  const modalTitle = action === 'CREATE' ? 'Agregar Medicamento': 'Editar Medicamento'
  const successMessage = action === 'CREATE' ? 'Medicamento agregado con éxito.' : 'Medicamento actualizado con éxito.'
  const statusResult = action === 'CREATE' ? mutationResult.data?.createDiagnosticMedicine.status : mutationResult.data?.updateDiagnosticMedicine.status

  if (mutationResult.error) {
    return (
      <div>Error :(</div>
    )
  }

  return (

    <Modal
      size="lg"
      show={openModal}
      dialogClassName="full-width"
      contentClassName="full-height"
      onHide={handleClose}
    >
      <Modal.Header closeButton className="bg-success text-white">
        <Modal.Title>
          <FaPrescriptionBottleAlt className="mr-1" color={'white'} />
          {modalTitle}
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={updateValues}
        validate={values => {
          const errors = {}
          if (!values.date) {
            errors.date = 'Debes ingresar una fecha.'
          }
          return errors
        }}
        onSubmit={(values, { resetForm }) => {
          mutation({
            variables: {
              input: {
                ...values,
                date: moment(values.date).format('YYYY-MM-DD'),
                id_medicine: parseInt(values.id_medicine),
                id_medicine_classification: parseInt(values.id_medicine_classification),
                id_medicine_via: parseInt(values.id_medicine_via),
                dose: parseFloat(values.dose)
              }
            }
          }).then(({ data }) => {
            if (action === 'CREATE') {
              if (data.createDiagnosticMedicine.status.success) {
                resetForm({ values: '' })
              }
            }
            // modalHeaderRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
          })
        }}
      >
        {({
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          setFieldValue,
          handleSubmit
        }) => (
          <Form onSubmit={handleSubmit}>
            {mutationResult.data &&
              <StatusAlert
                successMessage={successMessage}
                status={statusResult}
              />
            }
            <Styles>
              <Modal.Body>
                <Form.Row>
                  <DatePickerInput
                    label="Fecha"
                    name="date"
                    className='label'
                    value={values.date}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    touched={touched.date ? true : false}
                    error={errors.date}
                    width="col-12 col-md-6"
                  />
                </Form.Row>
                <Form.Row>
                  <Form.Group className="col-12 col-md-6">
                    {<Form.Label>Medicamento</Form.Label>}
                    <Form.Control
                      as="select"
                      name="id_medicine"
                      value={values.id_medicine ||''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={
                        (touched.id_medicine && errors.id_medicine)
                          ? true
                          : false
                      }
                    >
                      {getMedicines.medicines.map((medicine, key) =>
                        <option key={key} value={medicine.id}>{medicine.name}</option>
                      )}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group className="col-12 col-md-6">
                    <Form.Label>Clasificación</Form.Label>
                    <Form.Control
                      as="select"
                      name="id_medicine_classification"
                      value={values.id_medicine_classification ||''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={
                        (touched.id_medicine_classification && errors.id_medicine_classification)
                          ? true
                          : false
                      }
                    >
                      {getMedicineClassifications.medicineClassifications.map((clasification, key) =>
                        <option key={key} value={clasification.id}>{clasification.name}</option>
                      )}
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group className="col-12 col-md-6" >
                    {<Form.Label>Vía de administración</Form.Label>}
                    <Form.Control
                      as="select"
                      name="id_medicine_via"
                      value={values.id_medicine_via || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={
                        (touched.id_medicine_via && errors.id_medicine_via)
                          ? true
                          : false
                      }
                    >
                      {getMedicineVias.medicineVias.map((vias, key) =>
                        <option key={key} value={vias.id}>{vias.name}</option>
                      )}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group className="col-12 col-md-6">
                    {<Form.Label>Dosis (ml/mg)</Form.Label>}
                    <Form.Control
                      type="number"
                      name="dose"
                      placeholder="Ingresa una dosis"
                      value={values.dose}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={
                        (touched.dose && errors.dose)
                          ? true
                          : false
                      }
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group className="col-12">
                    <Form.Label className="bg-success text-white px-3">Comentario:</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="comments"
                      placeholder="Ingresa un comentario"
                      value={values.comments}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Form.Group>
                </Form.Row>
              </Modal.Body>
            </Styles>
            <Modal.Footer className="d-flex justify-content-end">
              <Button variant="secondary" onClick={handleClose}>
                  Cerrar
              </Button>
              <SubmitButton
                action={action}
                loading={mutationResult.loading}
                className="btn list-item px-6"
              />
            </Modal.Footer>
          </Form>
        )}
      </Formik>

    </Modal>


  )
}

PlayerMedicinesModal.propTypes = {
  idPlayer: PropTypes.number,
  openModal:PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  action: PropTypes.string,
  updateValues: PropTypes.object,
  dimension: PropTypes.object
}

export default PlayerMedicinesModal
