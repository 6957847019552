import { gql } from '@apollo/client'

const GET_CATEGORY_TYPES = gql`
  {
    getCategoryTypes {
      status {
        success
        code
        message
      }
      categoryTypes {
        id
        name_category
      }
    }
  }
`

export default GET_CATEGORY_TYPES
