import { gql } from '@apollo/client'

const DELETE_PLAYER_KINESIC_EVALUATION = gql`
  mutation deletePlayerKinesicEvaluation($input: DeletePlayerKinesicEvaluationInput!) {
    deletePlayerKinesicEvaluation(input: $input) {
      status {
          success
          code
          message
      }
    }
  }
`

export default DELETE_PLAYER_KINESIC_EVALUATION
