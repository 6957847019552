import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { useQuery, useLazyQuery } from '@apollo/client'
import {
  GET_INSTANCES,
  GET_MATCH_MOMEMTS,
  GET_SEVERITIES,
  GET_SPECIFIC_MECHANISMS,
  GET_GENERAL_MECHANISMS
} from '../../apollo/queries'
import { Form, Col } from 'react-bootstrap'
import { appStore } from '../../store/appStore'
import Styles from './ClinicalEventFormStyle'

const ClinicalEventForm = ({
  values,
  touched,
  setFieldValue,
  idInstance = null,
  setTouched
}) => {

  const { state } = useContext(appStore)

  const [momentsState, setMoments] = React.useState([])

  const instances = useQuery(GET_INSTANCES)
  const severities = useQuery(GET_SEVERITIES)
  const specificMechanisms = useQuery(GET_SPECIFIC_MECHANISMS)
  const generalMechanisms = useQuery(GET_GENERAL_MECHANISMS)
  const [momentsQuery, moments] = useLazyQuery(GET_MATCH_MOMEMTS, { fetchPolicy: 'network-only' })

  React.useEffect(() => {
    if (moments.data) {
      setMoments(moments.data?.getMatchMoments.matchMoments)
    }
  }, [moments])

  React.useEffect(() => {
    if (idInstance === '2') {
      momentsQuery()
    }
  }, [])

  const errorStyle = {
    control: styles => ({
      ...styles,
      borderColor: '#dc3545 !important'
    })
  }

  const instancesOptions = instances.data?.getInstances.instances.reduce(
    (acc, current) => ([...acc, { value: current.id, label: current.name } ])
    , [{ value: '', label: 'Selecciona una instancia' }]
  )

  const matchMomentOptions = momentsState.reduce(
    (acc, current) => ([...acc, { value: current.id, label: current.name } ])
    , [{ value: '', label: 'Selecciona una instancia' }]
  ) || [{ value: '', label: 'Selecciona una instancia' }]

  const severityOptions = severities.data?.getSeverities.severities.reduce(
    (acc, current) => ([...acc, { value: current.id, label: current.name } ])
    , [{ value: '', label: 'Selecciona una presentación' }]
  )

  const specificMechanimsOptions = specificMechanisms.data?.getSpecificMechanisms.specificMechanisms.reduce(
    (acc, current) => ([...acc, { value: current.id, label: current.name } ])
    , [{ value: '', label: 'Selecciona un mecanismo' }]
  )

  const generalMechanismsOptions = generalMechanisms.data?.getGeneralMechanisms.generalMechanisms.reduce(
    (acc, current) => ([...acc, { value: current.id, label: current.name } ])
    , [{ value: '', label: 'Selecciona un mecanismo' }]
  )

  const [selectedValues, setSelectedValues] = React.useState(values)
  const isCoch = state?.tenant?.code === 'COCH' || state?.tenant?.code === 'TRICEPS-QA'

  return (
    <React.Fragment>
      <Styles>
        <p>(*): Obligatorio</p>
        <Form.Row>
          <Form.Group as={Col} controlId="Instance" className={`col-12 col-md-${isCoch ? '6' : '4'}`}>
            <Form.Label>Instancia*</Form.Label>
            <Select
              isClearable
              isSearchable
              placeholder=""
              name="id_instance"
              noOptionsMessage={() => 'No se encontraron coincidencias'}
              isLoading={instances.loading}
              value={instancesOptions?.find(option => option.value === values.id_instance )}
              styles={(touched.id_instance && !selectedValues.id_instance) ? errorStyle : {}}
              onChange={(option) => {
                setSelectedValues((prevState) => ({ ...prevState, 'id_instance': option?.value ||'' }))
                setTouched({ ...touched, 'id_instance': true })
                setFieldValue('id_instance', option?.value ||'')
                if (option?.value === '2') {
                  momentsQuery()
                } else {
                  setMoments([])
                  setFieldValue('id_match_moment', '')
                }
              }}
              onBlur={() => setTouched({ ...touched, 'id_instance': true })}
              options={instancesOptions}
            />
          </Form.Group>
          {!isCoch && (
            <Form.Group as={Col} controlId="MatchInstance" className={`col-12 col-md-${isCoch ? '6' : '4'}`}>
              <Form.Label>Instancia de Partido</Form.Label>
              <Select
                isClearable
                isSearchable
                placeholder=""
                name="id_match_moment"
                noOptionsMessage={() => 'No se encontraron coincidencias'}
                isLoading={moments.loading}
                value={matchMomentOptions?.find(option => option.value === values.id_match_moment )}
                isDisabled={!momentsState.length}
                styles={(touched.id_match_moment && !selectedValues.id_match_moment && selectedValues.id_instance === '2') ? errorStyle : {}}
                onChange={(option) => {
                  setSelectedValues((prevState) => ({ ...prevState, 'id_match_moment': option?.value ||'' }))
                  setFieldValue('id_match_moment', option?.value ||'')
                }}
                onBlur={() => setTouched({ ...touched, 'id_match_moment': true })}
                options={matchMomentOptions}
              />
            </Form.Group>
          )}
          <Form.Group as={Col} controlId="Severity" className={`col-12 col-md-${isCoch ? '6' : '4'}`}>
            <Form.Label>Presentación*</Form.Label>
            <Select
              isClearable
              isSearchable
              placeholder=""
              name="id_severity"
              noOptionsMessage={() => 'No se encontraron coincidencias'}
              isLoading={severities.loading}
              styles={(touched.id_severity && !selectedValues.id_severity) ? errorStyle : {}}
              value={severityOptions?.find(option => option.value === values.id_severity )}
              onChange={(option) => {
                setSelectedValues((prevState) => ({ ...prevState, 'id_severity': option?.value ||'' }))
                setFieldValue('id_severity', option?.value ||'')
              }}
              onBlur={() => setTouched({ ...touched, 'id_severity': true })}
              options={severityOptions}
            />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} controlId="SpecificMechanism" className="col-12 col-md-6">
            <Form.Label>Mecanismo Específico*</Form.Label>
            <Select
              isClearable
              isSearchable
              placeholder=""
              name="id_specific_mechanism"
              noOptionsMessage={() => 'No se encontraron coincidencias'}
              isLoading={specificMechanisms.loading}
              styles={(touched.id_specific_mechanism && !selectedValues.id_specific_mechanism) ? errorStyle : {}}
              value={specificMechanimsOptions?.find(option => option.value === values.id_specific_mechanism )}
              onChange={(option) => {
                setSelectedValues((prevState) => ({ ...prevState, 'id_specific_mechanism': option?.value ||'' }))
                setFieldValue('id_specific_mechanism', option?.value ||'')
              }}
              onBlur={() => setTouched({ ...touched, 'id_specific_mechanism': true })}
              options={specificMechanimsOptions}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="GeneralMechanism" className="col-12 col-md-6">
            <Form.Label>Mecanismo General*</Form.Label>
            <Select
              isClearable
              isSearchable
              placeholder=""
              name="id_general_mechanism"
              noOptionsMessage={() => 'No se encontraron coincidencias'}
              isLoading={generalMechanisms.loading}
              styles={(touched.id_general_mechanism && !selectedValues.id_general_mechanism) ? errorStyle : {}}
              value={generalMechanismsOptions?.find(option => option.value === values.id_general_mechanism )}
              onChange={(option) => {
                setSelectedValues((prevState) => ({ ...prevState, 'id_general_mechanism': option?.value ||'' }))
                setFieldValue('id_general_mechanism', option?.value ||'')
              }}
              onBlur={() => setTouched({ ...touched, 'id_general_mechanism': true })}
              options={generalMechanismsOptions}
            />
          </Form.Group>
        </Form.Row>
      </Styles>
    </React.Fragment>
  )
}

ClinicalEventForm.propTypes = {
  idInstance: PropTypes.string,
  values: PropTypes.shape({
    id_instance: PropTypes.string,
    id_match_moment: PropTypes.string,
    id_severity: PropTypes.string,
    id_specific_mechanism: PropTypes.string,
    id_general_mechanism: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
  }),
  touched: PropTypes.shape({
    id_instance: PropTypes.bool,
    id_match_moment: PropTypes.bool,
    id_severity: PropTypes.bool,
    id_specific_mechanism: PropTypes.bool,
    id_general_mechanism: PropTypes.bool
  }),
  errors: PropTypes.shape({
    id_instance: PropTypes.string,
    id_match_moment: PropTypes.string,
    id_severity: PropTypes.string,
    id_specific_mechanism: PropTypes.string,
    id_general_mechanism: PropTypes.string
  }),
  setFieldValue: PropTypes.func.isRequired,
  setTouched: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
}

export default ClinicalEventForm
