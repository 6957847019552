import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { UserImage } from '../UserImage'

const PlayerCell = ({ userId, name, gender, url = '' }) => {
  return (
    <Link to={url}>
      <UserImage userId={userId} userGender={gender} imageWidth="30px" />
      {name}
    </Link>
  )
}

PlayerCell.propTypes = {
  url: PropTypes.string,
  userId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  gender: PropTypes.number.isRequired
}

export default PlayerCell
