import Keycloak from 'keycloak-js'
import domainConfig from '../config/domains'

const keycloakConfig = {
  url: 'https://sso.reconquer.app/auth',
  realm: domainConfig.realm,
  clientId: 'triceps-reconquer-app',
}

const keycloak = new Keycloak(keycloakConfig)

export default keycloak
