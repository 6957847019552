import { gql } from '@apollo/client'

const GET_SPECIFIC_MECHANISMS = gql`
  query getSpecificMechanisms {
    getSpecificMechanisms {
      specificMechanisms {
        id
        name
      }
      status{
        success
        code
        message
      }
    }
  }
`

export default GET_SPECIFIC_MECHANISMS
