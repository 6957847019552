import React from 'react'
import moment from 'moment'
import hasRole from '../../../../keycloak/utils/hasRole'
import { useRouteMatch, useParams } from 'react-router-dom'
import { Container, Row, Col, Alert, Table } from 'react-bootstrap'
import { useQuery } from '@apollo/client'
import { GET_PLAYER_DIMENSIONS } from '../../../../apollo/queries'
import { CustomChart, CustomBreadcrumb, Loading } from './../../../../components'
import { PlayerLayout } from '../../../Layouts/PlayerLayout'
import { FatMassForm } from './FatMassForm'
import { BoneMassForm } from './BoneMassForm'
import { MuscleMassForm } from './MuscleMassForm'
import { PlayerNutritionalForm } from './PlayerNutritionalForm'
import { SomatotypeForm } from './SomatotypeForm'
import { SkinMassForm } from './SkinMassForm'
import { ResidualMassForm } from './ResidualMassForm'
import { appStore } from '../../../../store/appStore'
import { CLASSIFICATION } from '../constants'
import { useFormatsHelper } from '../../../../hooks'
import Styles from './NutritionDetailStyle'
import usePlayerDimension from '../../usePlayerDimension'

const NutritionDetail = () => {
  const { url } = useRouteMatch()
  const { id_player, id_player_dimension } = useParams()

  const [fatMassModal, setFatMassModal] = React.useState(false)
  const [boneMassModal, setBoneMassModal] = React.useState(false)
  const [muscleMassModal, setMuscleMassModal] = React.useState(false)
  const [playerNutritionalModal, setPlayerNutritionalModal] = React.useState(false)
  const [somatotypeModal, setSomatotypeModal] = React.useState(false)
  const [skinMassModal, setSkinMassModal] = React.useState(false)
  const [residualMassModal, setResidualMassModal] = React.useState(false)

  const [action, setAction] = React.useState('')
  const [updateValues, setUpdateValues] = React.useState({})

  const { state } = React.useContext(appStore)

  const breadcrumbPath = [
    { label: 'Ver Deportistas', href: '/players' },
    { label: 'Nutrición', href: `/player-info/${state.player?.id_category_type}/${state.player?.id}/nutrition` }
  ]

  const { data, loading , error } = useQuery(GET_PLAYER_DIMENSIONS, {
    variables: {
      input: {
        id: parseInt(id_player_dimension, 10)
      }
    },
    fetchPolicy: 'network-only'
  })

  const { parseFloatValue } = useFormatsHelper()
  const { dimension, parseMeters } = usePlayerDimension(null, null, 2)

  const handleUpdateValues = () => {
    setUpdateValues({
      id: playerDimensions[0]?.id,
      date: new Date(moment(`${playerDimensions[0]?.date} 12:00`)),
      ...generalNutricion.reduce((acc, current) =>
        ({ ...acc, [current.id]: current.value?.toString() })
      , {}),
      ...fatMass.reduce((acc, current) =>
        ({ ...acc, [current.id]: current.value?.toString() })
      , {}),
      ...muscleMass.reduce((acc, current) =>
        ({ ...acc, [current.id]: current.value?.toString() })
      , {}),
      ...boneMass.reduce((acc, current) =>
        ({ ...acc, [current.id]: current.value?.toString() })
      , {}),
      ...somatotype.reduce((acc, current) =>
        ({ ...acc, [current.id]: current.value?.toString() })
      , {}),
      ...skinMass.reduce((acc, current) =>
        ({ ...acc, [current.id]: current.value?.toString() })
      , {}),
      ...residualMass.reduce((acc, current) =>
        ({ ...acc, [current.id]: current.value?.toString() })
      , {}),
    })
  }

  if (loading || dimension.loading) {
    return (
      <Loading />
    )
  }

  if (error || dimension.error) {
    return (
      <div>Error :(</div>
    )
  }

  const { playerDimensions } = data.getPlayerDimensions
  const generalNutricion = parseMeters(playerDimensions[0]?.meters, 2)

  const fatMass = parseMeters(playerDimensions[0]?.meters, 3)
  const muscleMass = parseMeters(playerDimensions[0]?.meters, 4)
  const boneMass = parseMeters(playerDimensions[0]?.meters, 5)
  const somatotype = parseMeters(playerDimensions[0]?.meters, 6)
  const skinMass = parseMeters(playerDimensions[0]?.meters, 38)
  const residualMass = parseMeters(playerDimensions[0]?.meters, 39)

  return (
    <>
      <CustomBreadcrumb
        path={hasRole(['role_player']) ? breadcrumbPath.slice(1) : breadcrumbPath }
        active="Detalle Nutrición"
      />
      <PlayerLayout url={url}>
        <Styles>
          <div className="row">
            <div className="col-12 col-md-4 fadeInUp">
              <Alert
                variant='bg-info-nutrition'
                className="bg-info-nutrition mt-2"
                onClick={() => {
                  setPlayerNutritionalModal(true)
                  setAction('EDIT')
                  handleUpdateValues()
                }}
              >
                <h5>Fecha Evaluación: {playerDimensions[0]?.date}</h5>
                <hr/>
                <ul>
                  {generalNutricion.map((meter, key) =>
                    <li key={key}>{meter.name}: {meter.value}</li>
                  )}
                </ul>
              </Alert>
            </div>
            <div className="col-12 col-md-8">
              <h5 className="text-center scale-up-center text-secondary">Método	de Fraccionamiento	de la masa corporal en 5 componentes</h5>
              <Table className='table-font table-responsive-lg  fadeInUp' striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Kilogramos (Kg)</th>
                    <th scope="col">Porcentajes (%)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    onClick={() => {
                      setFatMassModal(true)
                      setAction('EDIT')
                      handleUpdateValues()
                    }}
                  >
                    <td scope="row" className='table-title'>Masa Grasa</td>
                    <td className='text-center'>{fatMass.find(meter => meter.id === 15)?.value || ''}</td>
                    <td className='text-center'>{fatMass.find(meter => meter.id === 14)?.value || ''}</td>
                  </tr>
                  <tr
                    onClick={() => {
                      setMuscleMassModal(true)
                      handleUpdateValues()
                    }}
                  >
                    <td scope="row" className='table-title'>Masa Muscular</td>
                    <td className='text-center'>{muscleMass.find(meter => meter.id === 19)?.value || ''}</td>
                    <td className='text-center'>{muscleMass.find(meter => meter.id === 18)?.value || ''}</td>
                  </tr>
                  <tr
                    onClick={() => {
                      setBoneMassModal(true)
                      setAction('EDIT')
                      handleUpdateValues()
                    }}
                  >
                    <td scope="row" className='table-title'>Masa Ósea</td>
                    <td className='text-center'>{boneMass.find(meter => meter.id === 23)?.value || ''}</td>
                    <td className='text-center'>{boneMass.find(meter => meter.id === 22)?.value || ''}</td>
                  </tr>
                  <tr
                    onClick={() => {
                      setSkinMassModal(true)
                      setAction('EDIT')
                      handleUpdateValues()
                    }}
                  >
                    <td scope="row" className='table-title'>Tejido Piel</td>
                    <td className='text-center'>{skinMass.find(meter => meter.id === 230)?.value || ''}</td>
                    <td className='text-center'>{skinMass.find(meter => meter.id === 229)?.value || ''}</td>
                  </tr>
                  <tr
                    onClick={() => {
                      setResidualMassModal(true)
                      setAction('EDIT')
                      handleUpdateValues()
                    }}
                  >
                    <td scope="row" className='table-title'>Residual</td>
                    <td className='text-center'>{residualMass.find(meter => meter.id === 232)?.value || ''}</td>
                    <td className='text-center'>{residualMass.find(meter => meter.id === 231)?.value || ''}</td>
                  </tr>
                </tbody>
              </Table>
              <h5 className="text-center fadeInUp text-secondary">Valoración Somatotipo</h5>
              <Table
                className='table-font table-responsive-lg fadeInUp' bordered hover size="sm"
                onClick={() => {
                  setSomatotypeModal(true)
                  setAction('EDIT')
                  handleUpdateValues()
                }}
              >
                <thead>
                  <tr>
                    <th scope="col">Endomorfismo</th>
                    <th scope="col">Mesomorfismo</th>
                    <th scope="col">Ectomorfismo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {somatotype.map((meter, key) =>
                      <td className='text-center' key={key}>
                        {meter.id === 29 ? CLASSIFICATION.get(meter.value) : meter.value}
                      </td>
                    )}
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          <hr />
          <Container>
            <Row>
              <Col>
                <CustomChart
                  title="Fraccionamiento por Kilogramos"
                  type="bar"
                  data={{
                    labels: ['Masa Grasa', 'Masa Muscular', 'Masa Ósea', 'Tejido Piel', 'Residual'],
                    datasets: [
                      {
                        label: 'Fraccionamiento por Kilogramos',
                        data: [
                          parseFloatValue(fatMass.find(current => current.id === 15)?.value),
                          parseFloatValue(muscleMass.find(current => current.id === 19)?.value),
                          parseFloatValue(boneMass.find(current => current.id === 23)?.value),
                          parseFloatValue(skinMass.find(current => current.id === 230)?.value),
                          parseFloatValue(residualMass.find(current => current.id === 232)?.value)
                        ],
                        backgroundColor: [
                          'rgba(255, 99, 132, 0.2)',
                          'rgba(54, 162, 235, 0.2)',
                          'rgba(255, 206, 86, 0.2)',
                          'rgba(34, 0, 255, 0.2)',
                          'rgba(43, 85, 11, 0.2)'
                        ],
                        borderColor: [
                          'rgba(255, 99, 132, 1)',
                          'rgba(54, 162, 235, 1)',
                          'rgba(255, 206, 86, 1)',
                          'rgba(34, 0, 255, 1)',
                          'rgba(43, 85, 11, 1)'
                        ],
                        borderWidth: 1,
                      },
                    ],
                  }}
                  options={{
                    legend: {
                      display: false,
                    },
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            beginAtZero: true,
                          },
                        },
                      ],
                    },
                  }}
                />
              </Col>
              <Col>
                <CustomChart
                  title="Fraccionamiento por Porcentajes"
                  type="pie"
                  data={{
                    labels: ['Masa Grasa', 'Masa Muscular', 'Masa Ósea','Tejido Piel', 'Residual'],
                    datasets: [
                      {
                        label: 'Fraccionamiento por Porcentajes',
                        data: [
                          parseFloatValue(fatMass.find(current => current.id === 14)?.value),
                          parseFloatValue(muscleMass.find(current => current.id === 18)?.value),
                          parseFloatValue(boneMass.find(current => current.id === 22)?.value),
                          parseFloatValue(skinMass.find(current => current.id === 229)?.value),
                          parseFloatValue(residualMass.find(current => current.id === 231)?.value),
                        ],
                        backgroundColor: [
                          'rgba(255, 99, 132, 0.2)',
                          'rgba(54, 162, 235, 0.2)',
                          'rgba(255, 206, 86, 0.2)',
                          'rgba(34, 0, 255, 0.2)',
                          'rgba(43, 85, 11, 0.2)'
                        ],
                        borderColor: [
                          'rgba(255, 99, 132, 1)',
                          'rgba(54, 162, 235, 1)',
                          'rgba(255, 206, 86, 1)',
                          'rgba(34, 0, 255, 1)',
                          'rgba(43, 85, 11, 1)'
                        ],
                        borderWidth: 1,
                      },
                    ],
                  }}
                />
              </Col>
            </Row>
          </Container>
          <hr/>
          {playerNutritionalModal &&
            <PlayerNutritionalForm
              idPlayer={id_player}
              openModal={playerNutritionalModal}
              setOpenModal={(value) => setPlayerNutritionalModal(value)}
              action={action}
              updateValues={updateValues}
              dimension={dimension.data.getDimension?.dimension}
            />
          }

          {fatMassModal &&
            <FatMassForm
              idPlayer={id_player}
              openModal={fatMassModal}
              setOpenModal={(value) => setFatMassModal(value)}
              action={action}
              updateValues={updateValues}
              dimension={dimension.data.getDimension?.dimension}
            />
          }

          {boneMassModal &&
            <BoneMassForm
              idPlayer={id_player}
              openModal={boneMassModal}
              setOpenModal={(value) => setBoneMassModal(value)}
              action={action}
              updateValues={updateValues}
              dimension={dimension.data.getDimension?.dimension}
            />
          }

          {muscleMassModal &&
            <MuscleMassForm
              idPlayer={id_player}
              openModal={muscleMassModal}
              setOpenModal={(value) => setMuscleMassModal(value)}
              action={action}
              updateValues={updateValues}
              dimension={dimension.data.getDimension?.dimension}
            />
          }

          {skinMassModal &&
            <SkinMassForm
              idPlayer={id_player}
              openModal={skinMassModal}
              setOpenModal={(value) => setSkinMassModal(value)}
              action={action}
              updateValues={updateValues}
              dimension={dimension.data.getDimension?.dimension}
            />
          }

          {residualMassModal &&
            <ResidualMassForm
              idPlayer={id_player}
              openModal={residualMassModal}
              setOpenModal={(value) => setResidualMassModal(value)}
              action={action}
              updateValues={updateValues}
              dimension={dimension.data.getDimension?.dimension}
            />
          }

          {somatotypeModal &&
            <SomatotypeForm
              idPlayer={id_player}
              openModal={somatotypeModal}
              setOpenModal={(value) => setSomatotypeModal(value)}
              action={action}
              updateValues={updateValues}
              dimension={dimension.data.getDimension?.dimension}
            />
          }
        </Styles>
      </PlayerLayout>
    </>

  )
}

export default NutritionDetail
