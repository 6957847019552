import React from 'react'
import PropTypes from 'prop-types'
import constants from '../../config/constants'
import { Image } from 'react-bootstrap'
import { DropZone } from '../../components'
import { useUserImage } from '../../hooks'
import { Loading } from '../Loading'
import { appStore } from '../../store/appStore'

const UserImage = ({
  userId = null,
  userGender = 1,
  imageWidth = '120px',
  withDropZone = false,
  isPlayer = false
}) => {
  const { state, dispatch } = React.useContext(appStore)

  const [dropZoneFiles, setDropZonesFiles] = React.useState([])
  const { userImage, request } = useUserImage(userId, dropZoneFiles, state.player?.User?.image)

  React.useEffect(() => {
    if (request.status === 'SUCCESS') {
      if (isPlayer && dropZoneFiles.length) {
        dispatch({ type: 'updateImagePlayer', payload: (Math.random() + 1).toString(36).substring(7) })
      }

      setDropZonesFiles([])
    }
  }, [request])

  React.useEffect(() => {
    if (isPlayer && dropZoneFiles.length) {
      dispatch({ type: 'updateImagePlayer', payload: (Math.random() + 1).toString(36).substring(7) })
    }
  }, [state])

  if (request.status === 'LOADING' && state.player?.User?.image) {
    return (
      <Loading containerStyles={{ margin: '0 35px' }} />
    )
  }

  return (
    <>
      {userImage ?
        <Image
          rounded
          alt="user profile image"
          width={imageWidth}
          height={imageWidth}
          src={URL.createObjectURL(userImage)}
        /> :
        <Image
          rounded
          width={imageWidth}
          height={imageWidth}
          src={
            `${constants.STATIC_IMAGES_URL}/player-${userGender === 2 ? 'femenino' : 'masculino'}.svg`
          }
        />
      }
      {request.status === 'PUT_ERROR' &&
        <a style={{ color: 'red' }}>Ocurrió un error, vuelve a intentar más tarde</a>
      }
      {withDropZone &&
        <DropZone
          setFiles={setDropZonesFiles}
          limit={1}
        />
      }
    </>
  )
}

UserImage.propTypes = {
  userId: PropTypes.string,
  userGender: PropTypes.number,
  imageWidth: PropTypes.string,
  withDropZone: PropTypes.bool,
  isPlayer: PropTypes.bool
}

export default UserImage
