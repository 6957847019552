import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import hasRole from '../../../keycloak/utils/hasRole'
import AuthorizedElement from '../../../keycloak/utils/AuthorizedElement'
import { useHistory, useParams, Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_PLAYER } from '../../../apollo/queries'
import { FaArrowLeft, FaPen, FaClipboardCheck } from 'react-icons/fa'
import { Button } from 'react-bootstrap'
import { Loading, UserImage } from '../../../components'
import { appStore } from '../../../store/appStore'
import Styles from './PlayerLayoutStyle'
import { PlayerForm } from '../../Players/PlayerForm'


const PlayerLayout = ({ url, children }) => {
  let history = useHistory()
  let { id_player } = useParams()
  const { state, dispatch } = React.useContext(appStore)

  const hasRolePlayer = hasRole(['role_player'])

  const idPlayer = parseInt((hasRolePlayer ? state.user?.Player.id : id_player), 10)

  const { data, loading, error } = useQuery(GET_PLAYER, {
    variables: {
      input: {
        id_player: idPlayer
      }
    },
    fetchPolicy: 'no-cache'
  })

  const [openModal, setOpenModal] = React.useState(false)
  const [formValues, setFormValues] = React.useState({})

  const handleModal = (player, action) => {
    const { id_category_type, id_position_type, side, height,jersey, initial_weight, birthday } = player

    setFormValues({
      action,
      player: {
        id: parseInt(player.id, 10),
        name: player.User.name,
        last_name: player.User.last_name,
        email: player.User.email,
        gender: player.User.gender.toString(),
        id_category_type,
        id_position_type,
        side,
        height,
        jersey,
        initial_weight,
        birthday: new Date(birthday)
      },
      user: {
        id: player.User.id
      }
    })
    setOpenModal(true)
  }

  // React.useEffect(() => {
  //   if (state.user) {
  //     getPlayer({
  //       variables: {
  //         input: {
  //           id_player: idPlayer
  //         }
  //       }
  //     })
  //   }
  // }, [state])

  React.useEffect(() => {
    if (data) {
      dispatch({ type: 'savePlayer', payload: data.getPlayer.player })
    }
  }, [data])

  if (loading || !state.user || !data) {
    return (
      <Loading />
    )
  }

  if (error) {
    return (
      <div>Error :(</div>
    )
  }

  if (!data.getPlayer.player) {
    return <div>Oops! Página no encontrada</div>
  }

  return (
    <Styles>
      <div>
        <fieldset className="fieldset p-2 container">
          {/* <legend className="legend float-none text-center w-auto p-2">Resumen Médico</legend> */}
          <div className="row d-flex align-items-center">
            <div className="col-sm-12 col-lg-3 text-center">
              <UserImage userId={data.getPlayer.player?.User.id} userGender={data.getPlayer.player?.User.gender} />
              <h3 className="zoom tracking-in-expand">{data.getPlayer.player?.User.name} {data.getPlayer.player?.User.last_name}</h3>
              <p className="card-subtitle">{data.getPlayer.player?.CategoryType.name_category}</p>

            </div>
            <div className="col-sm-12 col-lg-3">
              <ul className="mb-0 striped bordered hover">
                <li><span className="font-weight-bold text-secondary">Edad:</span> {moment().diff(moment(data.getPlayer.player?.birthday).format('YYYY-MM-DD'), 'year')} años</li>
                <li><span className="font-weight-bold text-secondary">Posición:</span> {data.getPlayer.player?.PositionType.name_position}</li>
                <li><span className="font-weight-bold text-secondary">Estatura:</span> {data.getPlayer.player?.height} cm.</li>
                <AuthorizedElement roles={['permission_team-show-player-email']}>
                  <li><span className="font-weight-bold text-secondary">E-mail:</span>
                    <a href={`mailto:${data.getPlayer.player?.User.email}`}>
                      {' '}{data.getPlayer.player?.User.email}
                    </a>
                  </li>
                </AuthorizedElement>
              </ul>
            </div>
            <div className="col-sm-12 col-lg-3">
              <ul className="mb-0">
                <li><span className="font-weight-bold text-secondary">Peso:</span>  {data.getPlayer.player?.initial_weight} Kg.</li>
                <li><span className="font-weight-bold text-secondary">Dominancia:</span>  {data.getPlayer.player?.side === 0 ? 'Derecha' : 'Izquierda'}</li>
                <li><span className="font-weight-bold text-secondary">Jersey:</span><span className='text-uppercase'> {data.getPlayer.player?.jersey || '--'}</span></li>
              </ul>
            </div>
            <div className="col text-center">
              <AuthorizedElement roles={['permission_team-show-medical-history-player']}>
                <Link to={`/player/${id_player}/medical-history`} >
                  <Button className="zoom btn-sm mt-2 button-secondary" variant="outline-primary">
                    <FaClipboardCheck className="mb-1 mr-2" />
                    {'Antecedentes Médicos'}
                  </Button>
                </Link>
              </AuthorizedElement>
              <AuthorizedElement roles={['permission_team-update-player']}>
                <Button
                  className="zoom btn-sm mt-2 button-secondary" variant="outline-primary"
                  onClick={() => handleModal(data.getPlayer.player, 'UPDATE')}
                >
                  <FaPen className="mb-1 mr-3" />
                  {'Editar Datos Jugador'}
                </Button>
              </AuthorizedElement>
            </div>
          </div>
        </fieldset>


        <div className="row">
          <div className="col">
            {url !== '/' &&
          <Button
            variant="link"
            className="mt-3"
            onClick={() => {
              if (state.customGoBack) {
                history.push({ pathname: state.customGoBack })
              } else if (state.locationReload) {
                history.push('/temp')
                history.goBack()
                dispatch({ type: 'setLocationReload', payload: false })
              } else {
                history.goBack()
              }
            }}
          >
            <FaArrowLeft className="mr-2" />
              Volver
          </Button>
            }
          </div>
        </div>
        {children}
      </div>

      {openModal &&
        <PlayerForm
          openModal={openModal}
          setOpenModal={(value) => setOpenModal(value)}
          values={formValues}
          origin="DETAILS"
        />
      }
    </Styles>
  )
}

PlayerLayout.propTypes = {
  children: PropTypes.node,
  url: PropTypes.string
}

export default PlayerLayout
