import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { Form } from 'react-bootstrap'
import { GET_EVENTS_TYPES } from '../../../apollo/queries'

const EventTypeFilter = ({ value, setValue, modal = false }) => {
  const eventsTypesQuery = useQuery(GET_EVENTS_TYPES)

  const handleValue = (filter) => {
    setValue((prevState)=>({
      ...prevState,
      id_event_type: parseInt(filter,10)
    }))
  }

  return (
    <Form.Group className={!modal && 'ml-3'}>
      {modal && <Form.Label>Tipo de Evento</Form.Label>}
      <Form.Control
        as="select"
        placeholder="Tipo de Evento"
        value={value}
        onChange={(e) => handleValue(e.target.value)}
      >
        {eventsTypesQuery.loading ?
          <option>Cargando...</option> :
          <React.Fragment>
            <option value="">Todos los tipos de Eventos</option>
            {eventsTypesQuery.data.getEventsTypes.eventsTypes.map((eventType, key) =>
              <option key={key} value={eventType.id}>
                {eventType.name_event_type}
              </option>
            )}
          </React.Fragment>
        }
      </Form.Control>
    </Form.Group>
  )
}

EventTypeFilter.propTypes = {
  value: PropTypes.number,
  setValue: PropTypes.func.isRequired,
  modal: PropTypes.bool
}

export default EventTypeFilter
