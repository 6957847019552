import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import ReactToolTip from 'react-tooltip'
import AuthorizedElement from '../../../keycloak/utils/AuthorizedElement'
import { useHistory, useParams, Link } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { FaArrowDown, FaCalendar, FaEye, FaTrash, FaPlus } from 'react-icons/fa'
import { Button, Form, Table } from 'react-bootstrap'
import { DiagnosisFilter, FilterRow, DateFilter, Loading, EmptyMessage } from '../../../components'
import { GET_LAST_CLINICAL_EVENT, GET_HISTORY_CLINICAL_EVENTS } from '../../../apollo/queries'
import { INACTIVATE_CLINICAL_EVENT, DELETE_CLINICAL_EVENT, TOGGLE_MAIN_AVAILABILITY } from '../../../apollo/mutations'
import Styles from './ClinicalEventsStyle'
import ClinicalEventDatesForm from './ClinicalEventsDetail/ClinicalEventDatesForm'

const ClinicalEvents = ({ idPlayer }) => {
  const history = useHistory()

  let { id_player } = useParams()

  const [datesModal, setDatesModal] = React.useState({
    openModal: false,
    clinicalEvent: null,
    initialValues: {},
    updateCache: () => {}
  })
  const [mainAvailabilityStates, setMainAvailabilityStates] = React.useState({})
  const [mainAvailabilityLoading, setMainAvailabilityLoading] = React.useState(false)
  const [filters, setFilters] = React.useState({
    id_player: idPlayer,
    start_date: new Date(moment().subtract(12, 'months')),
    end_date: new Date()
  })

  const lastClinicalEvent = useQuery(GET_LAST_CLINICAL_EVENT, {
    variables: { input: filters },
    fetchPolicy: 'network-only'
  })

  const historyCLinicalEvents = useQuery(GET_HISTORY_CLINICAL_EVENTS, {
    variables: { input: filters },
    fetchPolicy: 'network-only'
  })

  const refetchQueries = {
    refetchQueries: [
      {
        query: GET_LAST_CLINICAL_EVENT,
        variables: { input: filters }
      },
      {
        query: GET_HISTORY_CLINICAL_EVENTS,
        variables: { input: filters }
      }
    ]
  }

  const [deleteClinicalEvent, deleteResult] = useMutation(DELETE_CLINICAL_EVENT, refetchQueries)

  const [inactivateClinicalEvent, inactivateResult] = useMutation(
    INACTIVATE_CLINICAL_EVENT, refetchQueries
  )

  const [toggleMainAvailability, mainAvailabilityResult] = useMutation(
    TOGGLE_MAIN_AVAILABILITY, refetchQueries
  )

  const handleDelete = (event, id) => {
    event.preventDefault()
    if (window.confirm('Esta acción es irreversible, ¿Estás seguro de borrar este evento clínico?')) {
      deleteClinicalEvent({
        variables: {
          input: { id: parseInt(id, 10) }
        }
      })
    }
  }

  const handleHeal = (event, id) => {
    event.preventDefault()
    if (window.confirm('¿Estás seguro de pasar a historial este evento clínico?')) {
      inactivateClinicalEvent({
        variables: {
          input: {
            id: parseInt(id, 10),
            date: moment().format('YYYY-MM-DD')
          }
        }
      })
    }
  }

  const updateLastClinicalEvent = (cache, { data }) => {
    cache.modify({
      fields: {
        getLastClinicalEvent(existingData = []) {
          const newData = data.getLastClinicalEvent
          cache.writeQuery({
            query: GET_LAST_CLINICAL_EVENT,
            data: {
              newData,
              ...existingData
            }
          })
        }
      }
    })
  }

  const updateHistoryClinicalEvent = (cache, { data }) => {
    cache.modify({
      fields: {
        getHistoryClinicalEvents(existingData = []) {
          const newData = data.getHistoryClinicalEvents
          cache.writeQuery({
            query: GET_HISTORY_CLINICAL_EVENTS,
            data: {
              newData,
              ...existingData
            }
          })
        }
      }
    })
  }

  if (
    inactivateResult.loading ||
    deleteResult.loading
  ) {
    return (
      <Loading
        containerStyles={{ marginLeft: '50%', marginTop: 0 }}
      />
    )
  }

  if (
    inactivateResult.error ||
    deleteResult.error ||
    mainAvailabilityResult.error
  ) {
    return (
      <div>Error :(</div>
    )
  }

  return (
    <Styles>
      <div className="row">
        <div className="col d-flex flex-wrap align-items-baseline justify-content">
          <h5 className="mr-auto text-secondary">
              Eventos Clínicos Activos
          </h5>
          <FilterRow>
            <DiagnosisFilter
              value={filters.id_diagnostic_type}
              setValue={setFilters}
            />
            <DateFilter
              value={{
                start_date: filters.start_date,
                end_date: filters.end_date
              }}
              setValue={setFilters}
            />
          </FilterRow>
          <div className='d-lg-block d-none'>
            <AuthorizedElement roles={['permission_team-create-player-clinic-event']}>
              <Link to={`/create-clinical-event/${id_player}`}>
                <Button className="mb-2">
                  <FaPlus className="mb-1 mr-2" color={'white'} />
                  {'Crear evento clínico'}
                </Button>
              </Link>
            </AuthorizedElement>
          </div>
        </div>
      </div>
      <div className='d-block d-lg-none'>
        <AuthorizedElement roles={['permission_team-create-player-clinic-event']}>
          <Link to={`/create-clinical-event/${id_player}`}>
            <Button className='mb-3' variant="primary" block>
              <FaPlus className="mb-1 mr-2" color={'white'} />
              {'Crear evento clínico'}
            </Button>
          </Link>
        </AuthorizedElement>
      </div>

      {lastClinicalEvent.loading && <Loading />}
      {lastClinicalEvent.error && <div>Error :( </div>}
      {lastClinicalEvent.data &&
        <div className="row">
          <div className="col">
            <Table className='table-font table-responsive-lg' striped bordered hover size="sm">
              <thead>
                <tr>
                  <AuthorizedElement roles={['permission_team-show-main-player-availability']}>
                    <th className='titular'>Titular</th>
                  </AuthorizedElement>
                  <th className="date">Fecha de Inicio</th>
                  <th className="diagnosis">Diagnóstico</th>
                  <th>Lateralidad</th>
                  <th>Tipo</th>
                  <th>Condición</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {lastClinicalEvent.data.getLastClinicalEvent.lastClinicalEvent?.length ? (
                  lastClinicalEvent.data.getLastClinicalEvent.lastClinicalEvent?.map((clinicalEvent, key) =>
                    <tr key={key}>
                      <AuthorizedElement roles={['permission_team-show-main-player-availability']}>
                        <td className='text-center align-middle'>
                          <div className="d-flex justify-content-center">
                            {mainAvailabilityLoading ?
                              <Loading
                                containerStyles={{ margin: 0 }}
                              /> :
                              <Form.Check
                                type="radio"
                                name={clinicalEvent.Diagnostics[0].DiagnosticAvailabilities[0].AvailabilityCondition.id}
                                id={key}
                                checked={
                                  mainAvailabilityStates[clinicalEvent.Diagnostics[0].DiagnosticAvailabilities[0].AvailabilityCondition.id] ||
                                  (moment().format('YYYY-MM-DD') === clinicalEvent.Diagnostics[0].DiagnosticAvailabilities[0].AvailabilityCondition.date ?
                                    clinicalEvent.Diagnostics[0].DiagnosticAvailabilities[0].AvailabilityCondition.main : false)
                                }
                                onChange={(e) => {
                                  setMainAvailabilityLoading(true)
                                  setMainAvailabilityStates((current) =>
                                    ({ [e.target.name]: !current[e.target.name] })
                                  )
                                  toggleMainAvailability({
                                    variables: {
                                      input: {
                                        id: clinicalEvent.Diagnostics[0].DiagnosticAvailabilities[0].AvailabilityCondition.id,
                                        date: clinicalEvent.Diagnostics[0].DiagnosticAvailabilities[0].AvailabilityCondition.date,
                                        timeZoneOffset: new Date().getTimezoneOffset()

                                      }
                                    },
                                    onCompleted: setTimeout(() => { setMainAvailabilityLoading(false) }, 2500)
                                  })
                                }}
                              />
                            }
                          </div>
                        </td>
                      </AuthorizedElement>
                      <td className='text-center align-middle'>
                        {clinicalEvent.ClinicalEventHistories.length ?
                          moment(clinicalEvent.ClinicalEventHistories[0].start_date).format('DD/MM/YYYY') :
                          moment(clinicalEvent.createdAt).format('DD/MM/YYYY')
                        }
                      </td>
                      <td className="diagnosis">
                        <ul>
                          <li>{clinicalEvent.Diagnostics[0].Pathology.name}</li>
                          {clinicalEvent.Diagnostics[0].DiagnosticComplement && (
                            <li>{clinicalEvent.Diagnostics[0].DiagnosticComplement?.name}</li>
                          )}
                          {clinicalEvent.Diagnostics[0].SkeletalMuscle ? (
                            <li>{clinicalEvent.Diagnostics[0].SkeletalMuscle?.name}</li>
                          ) : (
                            clinicalEvent.Diagnostics[0].BodyZone && (
                              <li>{clinicalEvent.Diagnostics[0].BodyZone?.name}</li>
                            )
                          )}
                        </ul>
                      </td>
                      <td className='text-center align-middle'>{clinicalEvent.Diagnostics[0].side === 1 ? 'Derecha' : clinicalEvent.Diagnostics[0].side === 2 ? 'Izquierda': clinicalEvent.Diagnostics[0].side === 3 ? 'Derecha e izquierda' : 'No especificado'}</td>
                      <td className='text-center align-middle'>{clinicalEvent.Diagnostics[0].DiagnosticType.name}</td>
                      <td className='text-center align-middle'>
                        <div
                          data-tip data-for={`condition${clinicalEvent.Diagnostics[0].DiagnosticAvailabilities[0].AvailabilityCondition.AvailabilityConditionType.id}`}
                          className={`btn-circle zoom color${clinicalEvent.Diagnostics[0].DiagnosticAvailabilities[0].AvailabilityCondition.AvailabilityConditionType.id}`}>
                        </div>
                        <ReactToolTip
                          id={`condition${clinicalEvent.Diagnostics[0].DiagnosticAvailabilities[0].AvailabilityCondition.AvailabilityConditionType.id}`}
                          place="top"
                          effect="solid"
                          type="info"
                        >
                          {clinicalEvent.Diagnostics[0].DiagnosticAvailabilities[0].AvailabilityCondition.AvailabilityConditionType.name_availability_condition_type}
                        </ReactToolTip>
                      </td>
                      <td className='text-center align-middle'>
                        <div className="d-flex justify-content-center my-0">
                          <AuthorizedElement roles={['permission_team-show-player-diagnostics']}>
                            <Button
                              variant="primary"
                              size="sm"
                              data-tip data-for="detail"
                              className="mx-1"
                              onClick={() => history.push(`/clinical-event-details/${idPlayer}/${clinicalEvent.id}`)}
                            >
                              <FaEye color={'white'}/>
                              <ReactToolTip
                                id="detail"
                                place="top"
                                effect="solid"
                                type="info"
                              >
                                {'Ver detalle'}
                              </ReactToolTip>
                            </Button>
                          </AuthorizedElement>

                          <AuthorizedElement roles={['permission_team-update-createdAt-clinical-event']}>
                            <Button
                              variant="warning"
                              size="sm"
                              data-tip data-for="edit-dates"
                              className="mx-1"
                              onClick={() =>
                                setDatesModal({
                                  openModal: true,
                                  clinicalEventId: clinicalEvent.id,
                                  initialValues: {
                                    start_date: clinicalEvent.ClinicalEventHistories.length
                                      ? new Date(`${clinicalEvent.ClinicalEventHistories[0]?.start_date} 12:00`)
                                      : new Date(clinicalEvent.createdAt),
                                    end_date: clinicalEvent.active
                                      ? undefined
                                      : (
                                        clinicalEvent.ClinicalEventHistories[0]?.end_date
                                          ? new Date(`${clinicalEvent.ClinicalEventHistories[0]?.end_date} 12:00`)
                                          : new Date(clinicalEvent.updatedAt)
                                      )
                                  },
                                  updateCache: updateLastClinicalEvent
                                })
                              }
                            >
                              <FaCalendar color={'white'}/>
                              <ReactToolTip
                                id="edit-dates"
                                place="top"
                                effect="solid"
                                type="info"
                              >
                                {'Editar Fechas'}
                              </ReactToolTip>
                            </Button>
                          </AuthorizedElement>
                          <AuthorizedElement roles={['permission_team-inactivate-player-clinical-event']}>
                            <Button
                              variant="success"
                              size="sm"
                              data-tip data-for="heal"
                              className="mx-1"
                              onClick={event => handleHeal(event, clinicalEvent.id)}
                            >
                              <FaArrowDown color={'white'}/>
                              <ReactToolTip
                                id="heal"
                                place="top"
                                effect="solid"
                                type="info"
                              >
                                {'Pasar a historial'}
                              </ReactToolTip>
                            </Button>
                          </AuthorizedElement>
                          <AuthorizedElement roles={['permission_team-delete-player-clinic-event']}>
                            <Button
                              variant="danger"
                              size="sm"
                              data-tip data-for="delete"
                              className="mx-1"
                              onClick={event => handleDelete(event, clinicalEvent.id)}
                            >
                              <FaTrash color={'white'}/>
                              <ReactToolTip
                                id="delete"
                                place="top"
                                effect="solid"
                                type="info"
                              >
                                {'Eliminar'}
                              </ReactToolTip>
                            </Button>
                          </AuthorizedElement>
                        </div>
                      </td>
                    </tr>
                  )
                ): (
                  <EmptyMessage />
                )}
              </tbody>
            </Table>
          </div>
        </div>
      }
      <hr/>
      <div className="row">
        <div className="col d-flex align-items-baseline justify-content-end">
          <h5 className="mr-auto text-secondary">
              Historial de Eventos Clínicos
          </h5>
        </div>
      </div>
      {historyCLinicalEvents.loading && <Loading />}
      {historyCLinicalEvents.error && <div>Error :( </div>}
      {historyCLinicalEvents.data &&
        <div className="row">
          <div className="col mt-3">
            <Table className='table-font table-responsive-lg' striped bordered hover size="sm">
              <thead>
                <tr>
                  <th className="date">Fecha de Inicio</th>
                  <th className="date">Fecha de Término</th>
                  <th className="diagnosis">Diagnóstico</th>
                  <th>Lateralidad</th>
                  <th>Tipo</th>
                  <th>Condición</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {historyCLinicalEvents.data.getHistoryClinicalEvents.historyClinicalEvents?.length ? (
                  historyCLinicalEvents.data.getHistoryClinicalEvents.historyClinicalEvents?.map((clinicalEvent, key) =>
                    <tr key={key}>
                      <td className='text-center align-middle'>
                        {clinicalEvent.ClinicalEventHistories.length ?
                          moment(clinicalEvent.ClinicalEventHistories[0].start_date).format('DD/MM/YYYY') :
                          moment(clinicalEvent.createdAt).format('DD/MM/YYYY')
                        }
                      </td>
                      <td className='text-center align-middle'>
                        {clinicalEvent.ClinicalEventHistories.length ?
                          moment(clinicalEvent.ClinicalEventHistories[0].end_date).format('DD/MM/YYYY') :
                          moment(clinicalEvent.updatedAt).format('DD/MM/YYYY')
                        }
                      </td>
                      <td className="diagnosis">
                        <ul>
                          <li>{clinicalEvent.Diagnostics[0].Pathology.name}</li>
                          {clinicalEvent.Diagnostics[0].DiagnosticComplement &&
                          <li>{clinicalEvent.Diagnostics[0].DiagnosticComplement?.name}</li>
                          }
                          {clinicalEvent.Diagnostics[0].SkeletalMuscle ?
                            <li>{clinicalEvent.Diagnostics[0].SkeletalMuscle?.name}</li> :
                            <li>{clinicalEvent.Diagnostics[0].BodyZone?.name}</li>
                          }
                        </ul>
                      </td>
                      <td className='text-center align-middle'>{clinicalEvent.Diagnostics[0].side === 1 ? 'Derecha' : clinicalEvent.Diagnostics[0].side === 2 ? 'Izquierda': clinicalEvent.Diagnostics[0].side === 3 ? 'Derecha e izquierda' : 'No aplica'}</td>
                      <td className='text-center align-middle'>{clinicalEvent.Diagnostics[0].DiagnosticType.name}</td>
                      <td className='text-center align-middle'>
                        <div
                          data-tip data-for={`condition${clinicalEvent.Diagnostics[0].DiagnosticAvailabilities[0].AvailabilityCondition.AvailabilityConditionType.id}`}
                          className={`btn-circle zoom color${clinicalEvent.Diagnostics[0].DiagnosticAvailabilities[0].AvailabilityCondition.AvailabilityConditionType.id}`}>
                        </div>
                        <ReactToolTip
                          id={`condition${clinicalEvent.Diagnostics[0].DiagnosticAvailabilities[0].AvailabilityCondition.AvailabilityConditionType.id}`}
                          place="top"
                          effect="solid"
                          type="info"
                        >
                          {clinicalEvent.Diagnostics[0].DiagnosticAvailabilities[0].AvailabilityCondition.AvailabilityConditionType.name_availability_condition_type}
                        </ReactToolTip>
                      </td>
                      <td className='text-center align-middle'>
                        <div className="d-flex justify-content-center my-0">
                          <AuthorizedElement roles={['permission_team-show-player-diagnostics']}>
                            <Button
                              variant="primary"
                              size="sm"
                              data-tip data-for="edit"
                              className="mx-1"
                              onClick={() => history.push(`/clinical-event-details/${idPlayer}/${clinicalEvent.id}`)}
                            >
                              <FaEye color={'white'}/>
                              <ReactToolTip
                                id="edit"
                                place="top"
                                effect="solid"
                                type="info"
                              >
                                {'Ver detalle'}
                              </ReactToolTip>
                            </Button>
                          </AuthorizedElement>

                          <AuthorizedElement roles={['permission_team-update-createdAt-clinical-event']}>
                            <Button
                              variant="warning"
                              size="sm"
                              data-tip data-for="edit-dates"
                              className="mx-1"
                              onClick={() =>
                                setDatesModal({
                                  openModal: true,
                                  clinicalEventId: clinicalEvent.id,
                                  initialValues: {
                                    start_date: clinicalEvent.ClinicalEventHistories.length
                                      ? new Date(`${clinicalEvent.ClinicalEventHistories[0]?.start_date} 12:00`)
                                      : new Date(clinicalEvent.createdAt),
                                    end_date: clinicalEvent.active
                                      ? undefined
                                      : (
                                        clinicalEvent.ClinicalEventHistories[0]?.end_date
                                          ? new Date(`${clinicalEvent.ClinicalEventHistories[0]?.end_date} 12:00`)
                                          : new Date(clinicalEvent.updatedAt)
                                      )
                                  },
                                  updateCache: updateHistoryClinicalEvent
                                })
                              }
                            >
                              <FaCalendar color={'white'}/>
                              <ReactToolTip
                                id="edit-dates"
                                place="top"
                                effect="solid"
                                type="info"
                              >
                                {'Editar Fechas'}
                              </ReactToolTip>
                            </Button>
                          </AuthorizedElement>
                          {/* {clinicalEvent.Diagnostics[0].id_diagnostic_type === 1 &&
                          <AuthorizedElement roles={['permission_team-activate-player-clinical-event']}>
                            <Button
                              variant="success"
                              size="sm"
                              data-tip data-for="active"
                              className="mx-1"
                              onClick={() => {
                                setOpenModal(true)
                                setActivateId(parseInt(clinicalEvent.id, 10))
                              }}
                            >
                              <FaArrowUp color={'white'}/>
                              <ReactToolTip
                                id="active"
                                place="top"
                                effect="solid"
                                type="info"
                              >
                                {'Activar Evento Clínico'}
                              </ReactToolTip>
                            </Button>
                          </AuthorizedElement>
                          } */}
                        </div>
                      </td>
                    </tr>
                  )
                ) : (
                  <EmptyMessage />
                )}
              </tbody>
            </Table>
          </div>
        </div>
      }

      {/* {openModal &&
        <AvailabilityModal
          openModal={openModal}
          setOpenModal={(value) => setOpenModal(value)}
          onSubmit={({ id_availability_condition_type }) => {
            activateClinicalEvent({
              variables: {
                input: {
                  id: activateId,
                  date: moment().format('YYYY-MM-DD'),
                  id_availability_condition_type: parseInt(id_availability_condition_type, 10)
                }
              }
            }).then(() => setOpenModal(false))
          }}
        />
      } */}
      {datesModal &&
        <ClinicalEventDatesForm
          clinicalEventId={datesModal.clinicalEventId}
          initialValues={datesModal.initialValues}
          openModal={datesModal.openModal}
          setOpenModal={() => setDatesModal({
            openModal: false,
            clinicalEvent: null,
            initialValues: {},
            updateCache: () => {}
          })}
          updateCache={datesModal.updateCache}
        />
      }
    </Styles>
  )
}

ClinicalEvents.propTypes = {
  idPlayer: PropTypes.number
}

export default ClinicalEvents
