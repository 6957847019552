import { gql } from '@apollo/client'

const SEARCH_USERS = gql`
  query searchUsers($input: SearchUsersInput!) {
    searchUsers(input: $input) {
      users {
        id
        name
        last_name
        gender
      }
      status {
        success
        code
        message
      }
    }
  }
`

export default SEARCH_USERS
