import { gql } from '@apollo/client'

const GET_DAILY_MEASUREMENTS = gql`
  query getDailyMeasurements($input: DailyMeasurementInput!) {
    getDailyMeasurements (input: $input) {
      playersMeasurements {
        id
        id_category_type
        DailyMeasurements {
          id
          measurement_type
          measurement_moment
          value
          date
          createdAt
          MeasurementType {
            id
            name
          }
        }
        User {
          id
          name
          last_name
          gender
        }
      }
      status{
        success
        code
        message
      }
    }
  }
`

export default GET_DAILY_MEASUREMENTS

