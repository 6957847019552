import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Form, Modal } from 'react-bootstrap'
import { FaCalendar } from 'react-icons/fa'
import { Formik } from 'formik'
import { useMutation } from '@apollo/client'
import { UPDATE_CLINICAL_EVENT } from '../../../../apollo/mutations'
import { DatePickerInput, FooterForm, StatusAlert } from '../../../../components'
import Styles from './ClinicalEventsDatesStyle'

const ClinicalEventDatesForm = ({ clinicalEventId, initialValues, openModal, setOpenModal, updateCache }) => {
  const modalRef = useRef()
  const [updateClinicalEvent, { data, loading, error }] = useMutation(UPDATE_CLINICAL_EVENT, { update: updateCache })

  if (error) {
    return (
      <div>Error :(</div>
    )
  }

  return (
    <Modal
      size="lg"
      dialogClassName="full-width"
      contentClassName="full-height"
      show={openModal}
      onHide={() => setOpenModal(false)}
    >
      <Modal.Header
        closeButton
        ref={modalRef}
        className="bg-success text-white"
      >
        <div className="d-flex align-items-center">
          <FaCalendar className="mr-2" size="22" color={'white'} />
          <Modal.Title>
            {'Editar Fechas'}
          </Modal.Title>
        </div>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validate={values => {
          const errors = {}
          if (!values.start_date) {
            errors.start_date = 'Debes ingresar una fecha de inicio.'
          }
          return errors
        }}
        onSubmit={(values) => {
          updateClinicalEvent({
            variables: {
              input: {
                id: clinicalEventId,
                start_date: values.start_date,
                end_date: values.end_date
              }
            }
          }).then(() => {
            modalRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
          })
        }}
      >
        {({
          handleSubmit,
          values,
          touched,
          errors,
          setFieldValue,
        }) => (
          <Styles.Modal>
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                {data &&
                    <StatusAlert
                      status={data.updateClinicalEvent?.status}
                      successMessage={'Fechas editadas con éxito.'}
                    />
                }
                <Form.Row>
                  <DatePickerInput
                    width="col-12 col-md-6"
                    label="Fecha Inicio"
                    name="start_date"
                    value={values.start_date || ''}
                    setFieldValue={setFieldValue}
                    handleChange={(e) => setFieldValue('start_date', e.target.value)}
                    touched={touched.start_date}
                    error={errors.start_date}
                  />
                  <DatePickerInput
                    width="col-12 col-md-6"
                    label="Fecha Fin"
                    name="end_date"
                    value={values.end_date || ''}
                    setFieldValue={setFieldValue}
                    handleChange={(e) => setFieldValue('end_date', e.target.value)}
                    disabled={!values.end_date}
                  />
                </Form.Row>
              </Modal.Body>
              <Modal.Footer>
                <FooterForm
                  className="mt-3"
                  action={'UPDATE'}
                  loading={loading}
                  handleCancel={() => setOpenModal(false)}
                />
              </Modal.Footer>
            </Form>
          </Styles.Modal>
        )}
      </Formik>
    </Modal>
  )
}

ClinicalEventDatesForm.propTypes = {
  clinicalEventId: PropTypes.string.isRequired,
  initialValues: PropTypes.shape({}),
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  updateCache: PropTypes.func.isRequired
}

export default ClinicalEventDatesForm
