import { gql } from '@apollo/client'

const CREATE_MEDICAL_HISTORY_PLAYER = gql`
  mutation createMedicalHistoryPlayer($input: CreateMedicalHistoryPlayerInput!) {
    createMedicalHistoryPlayer(input: $input) {
      status {
        success
        code
        message
      }
    }
  }
`

export default CREATE_MEDICAL_HISTORY_PLAYER
