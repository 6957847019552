import { gql } from '@apollo/client'

const GET_MEASUREMENT_TYPES = gql`
  {
    getMeasurementTypes {
      measurementTypes {
        id
        name
        order_table
        order_graphic
        required_graphic
        grouper_graphic
        color_graphic
      }
      status {
        success
        code
        message
      }
    }
  }
`

export default GET_MEASUREMENT_TYPES

