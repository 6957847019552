import { useEffect, useContext } from 'react'
import { useLazyQuery } from '@apollo/client'
import { GET_TENANT } from '../apollo/queries'
import { appStore } from '../store/appStore'

const useTenant = (checkUser) => {
  const { dispatch } = useContext(appStore)
  const [getTenant, { data }] = useLazyQuery(GET_TENANT)

  useEffect(() => {
    if (checkUser) getTenant()
  }, [checkUser])

  useEffect(() => {
    if (data?.getTenant.status.success) {
      dispatch({ type: 'tenant', payload: data.getTenant })
    }
  }, [data])

  return true
}

export default useTenant
