import { gql } from '@apollo/client'

const GET_DIMENSION = gql`
  query getDimension($input: DimensionInput!) {
    getDimension(input: $input) {
      dimension {
        id
        name
        DimensionTests {
          id
          name
          Meters {
            id
            name
            subtitle
            order
            required_graphic
            MeterType {
              id
              name
            }
            MeterOptions {
              id
              name
              value
            }
          }
        }
      }
      status {
        success
        code
        message
      }
    }
  }
`

export default GET_DIMENSION
