import moment from 'moment'
import GpsInput from '../GpsInput/GpsInput'

const usePlayerGps = ({
  gps = [],
  playerGps = [],
  filters = {}
}) => {
  const columns = [
    {
      Header: ' ',
      sticky: 'left',
      className: 'player',
      columns: [
        {
          Header: 'Fecha',
          accessor: 'date',
          className: 'player'
        },
      ]
    },
    {
      Header: ' ',
      columns: gps?.map(gps => (
        {
          Header: gps.name,
          accessor: gps.id,
          Cell: ({ cell: { value, column } }) => GpsInput({ cellInfo: value, column, filters })
        }
      ))
    },
  ]

  const data = playerGps?.reduce((acc, { id, id_player, date, gps_values }) => {
    let rows = {}
    let gpsValues = {}

    try {
      gpsValues = JSON.parse(gps_values)
    } catch (e) {
      console.warn(e)
    }

    gps.forEach((column) => {
      const existValue = gpsValues[column.id] !== undefined
      rows = {
        ...rows,
        [column.id]: {
          id: existValue ? id : null,
          id_player,
          date,
          cellValue: existValue ? gpsValues[column.id] : ''
        }
      }
    })

    return [...acc, { date: moment(date).format('DD/MM/YYYY'), originalDate: date, ...rows }]
  }, [])

  return { data, columns }
}

export default usePlayerGps
