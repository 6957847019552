import React from 'react'
import { useQuery } from '@apollo/client'
import { GET_USERS } from '../../apollo/queries'
import { FaRegEnvelope } from 'react-icons/fa'
import { Loading, CustomBreadcrumb } from '../../components'
import Styles from './MedicalStaffStyle'
import constants from '../../config/constants'

const MedicalStaff = () => {

  // const [values, setValues] = React.useState({})
  // const [openModal, setOpenModal] = React.useState(false)

  const { loading, error, data } = useQuery(GET_USERS, {
    variables: {
      input: {
        id_user_type: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13]
      }
    }
  })

  // const handleModal = (user, action) => {
  //   const { id, email, name, last_name } = user
  //   setValues({
  //     action,
  //     user: {
  //       id,
  //       id_user_type: user.UserType.id,
  //       gender: user.gender.toString(),
  //       email,
  //       name,
  //       last_name
  //     }
  //   })
  //   setOpenModal(true)
  // }

  if (loading) {
    return (
      <Loading />
    )
  }

  if (error) {
    return (
      <div>Error :(</div>
    )
  }

  return (
    <Styles>
      <CustomBreadcrumb
        active="Equipo Médico"
      />
      <div className="container-fluid container-xl">
        <div className="row">
          {data.getUsers.users.map(user =>
            <div key={user.id} className="col-sm-12 col-md-6 col-lg-4 col-xl-3 d-flex align-items-strech">
              <div className="card my-3 zoom">
                {/* {state.isAdmin &&
                <button
                  type="button"
                  className="btn btn-link ml-auto mt-1"
                  onClick={() => handleModal({
                    ...user,
                    id_user_type: user.UserType.id,
                    gender: user.gender.toString()
                  }, 'UPDATE')}
                >
                  <FaPen size={20} className="mr-2 mb-1"/>
                </button>
              } */}
                <div className="pt-3 m-auto">
                  <img
                    width="140px"
                    src={
                      `${constants.STATIC_IMAGES_URL}/avatar-${user.gender === 2 ? 'femenino' : 'masculino'}.svg`
                    }
                  />
                </div>
                <div className="card-body text-center">
                  <h3 className="name">{user.name} {user.last_name}</h3>
                  <p className="card-subtitle text-capitalize text-muted mb-2">{user.UserType.name_user}</p>
                  <div className="flex-center">
                    <FaRegEnvelope color="#28a745" className="mr-1"/>
                    <a href="mailto:{user.email}" className="email">{user.email}</a>
                  </div>
                </div>
                {/* <div className="card-footer bg-footer">
                  <a href="#" className="btn btn-success btn-block">
                      Enviar Mensaje <FaComment className="mb-1" />
                  </a>
                </div> */}
              </div>
            </div>
          )}
        </div>
      </div>
      {/* {openModal &&
        <MedicalStaffForm
          openModal={openModal}
          setOpenModal={(value) => setOpenModal(value)}
          values={values}
        />
      } */}
    </Styles>
  )
}

export default MedicalStaff
