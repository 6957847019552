import { gql } from '@apollo/client'

const CREATE_CLINICAL_EVENT = gql`
  mutation createClinicalEvent($input: CreateClinicalEventInput!) {
    createClinicalEvent(input: $input) {
      clinicalEvent {
        id
      }
      status {
        success
        code
        message
      }
    }
  }
`

export default CREATE_CLINICAL_EVENT
