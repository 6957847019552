import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'react-bootstrap'

const StatusAlert = ({ successMessage = '', status }) => {

  const getStatusMessage = (status) => {
    switch (status?.message) {
    case 'DATA_ALREADY_EXIST':
      return 'Ya existe un registro para esta fecha.'

    case 'USER_ALREADY_HAS_PLAYER':
      return 'Ya existe un jugador con este email.'

    case 'GPS_ALREADY_EXIST':
      return 'Ya existe un GPS para esta columna Excel.'

    case 'Invalid interval':
      return 'Error en el intervalo de fechas. Revisa las fechas e intenta de nuevo.'

    case 'ALREADY_SCHEDULED':
      return 'Ya tienes una consulta agendada en este horario.'

    case 'SCHEDULE_NOT_AVAILABLE':
      return 'El horario no esta disponible.'

    default:
      return 'Ocurrió un error interno.'
    }
  }

  return (
    <Alert
      variant={
        status?.success
          ? 'success'
          : 'danger'
      }
    >
      {status?.success
        ? successMessage
        : getStatusMessage(status)
      }
    </Alert>
  )
}

StatusAlert.propTypes = {
  successMessage: PropTypes.string,
  status: PropTypes.shape({
    success: PropTypes.bool.isRequired,
    message: PropTypes.string
  }).isRequired
}

export default StatusAlert
