import styled from 'styled-components'

export default {

  Main: styled.div`
    .container {
      .slick-slider {
        .slick-prev:before, .slick-next:before {
          color: #007bffc9;
          cursor: pointer;
        }
      }
    }

    .chartjs-render-monitor {
      cursor: pointer;
    }

    div .player {
      width: 100px;
      min-width: 100px;
      padding: 8px !important;
      background-color: #FFFFFF;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .form-label {
      display: none;
    }

    @media (max-width: 768px) {
      .form-label {
        display: initial;
      }

      .col {
        padding: 0;
      }
    }
  `,
  Modal: styled.div`
    .form-label {
      background-color: #28a745;
      color: white;
      padding: 0 10px;
      width: 100%;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
    }
  `
}
