import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import AuthorizedElement from '../../../../../keycloak/utils/AuthorizedElement'
import { useQuery } from '@apollo/client'
import { GET_DIAGNOSTIC_MEDICINES } from '../../../../../apollo/queries'
import { Modal, Button } from 'react-bootstrap'
import { FaPrescriptionBottleAlt } from 'react-icons/fa'
import { Loading } from '../../../../../components'
import { MedicineForm } from './MedicineForm'
import Styles from './MedicinesStyle'

const Medicines = ({ diagnosticId, openModal, setOpenModal }) => {
  const id_diagnostic = parseInt(diagnosticId, 10)
  const [adding, setAdding] = React.useState(false)

  const handleClose = () => setOpenModal(false)

  const { data, loading, error } = useQuery(GET_DIAGNOSTIC_MEDICINES, {
    variables: {
      input: {
        id_diagnostic: parseInt(diagnosticId, 10)
      }
    },
    fetchPolicy: 'network-only'
  })

  if (loading) {
    return (
      <Loading />
    )
  }

  if (error) {
    return (
      <div>Error :(</div>
    )
  }

  return (
    <Styles>
      <Modal
        size="lg"
        show={openModal}
        dialogClassName="full-width"
        contentClassName="full-height"
        onHide={handleClose}
      >
        <Modal.Header closeButton className="bg-success text-white">
          <Modal.Title>
            <FaPrescriptionBottleAlt className="mr-1 mb-2" color={'white'} />
              Medicamentos
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {adding ?
            <div className="d-flex justify-content-center">
              <AuthorizedElement roles={['permission_team-create-player-medicines']}>
                <MedicineForm
                  idDiagnostic={id_diagnostic}
                  setAdding={setAdding}
                />
              </AuthorizedElement>
            </div> :
            <div className="d-flex align-items-baseline justify-content-between">
              {data.getDiagnosticMedicines.diagnosticMedicines.length ?
                'Lista de medicamentos agregados al diagnóstico.' :
                'No hay medicamentos agregados al diagnóstico.'
              }
              <AuthorizedElement roles={['permission_team-create-player-medicines']}>
                <Button
                  variant="success"
                  type="button"
                  onClick={() => setAdding(true)}
                  // style={{ marginTop: '32px' }}
                >
                  Agregar Medicamento
                </Button>
              </AuthorizedElement>
            </div>
          }
          {data.getDiagnosticMedicines.diagnosticMedicines.map((medicine, key) => {
            return (
              <React.Fragment key={key}>
                <hr/>
                <div className="d-flex justify-content-center">
                  <MedicineForm
                    action="EDIT"
                    idDiagnostic={id_diagnostic}
                    values={{
                      id: parseInt(medicine.id, 10),
                      date: new Date(moment(`${medicine.date} 12:00:00`)),
                      id_medicine: medicine.id_medicine,
                      id_medicine_classification: medicine.id_medicine_classification,
                      id_medicine_via: medicine.id_medicine_via,
                      dose: medicine.dose,
                      comments: medicine.comments
                    }}
                  />
                </div>
              </React.Fragment>
            )
          }
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </Styles>
  )
}

Medicines.propTypes = {
  diagnosticId: PropTypes.string,
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func
}

export default Medicines
