import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'

const MeasurementMomentFilter = ({ value, setValue, modal = false }) => {

  const handleValue = (filter) => {
    setValue((prevState)=>({
      ...prevState,
      measurement_moment: parseInt(filter,10)
    }))
  }

  return (
    <Form.Group className={!modal && 'ml-3'}>
      {modal && <Form.Label>Momento</Form.Label>}
      <Form.Control
        as="select"
        placeholder="Momento"
        value={value}
        onChange={(e) => handleValue(e.target.value)}
      >
        <option value="1">Entrenamiento</option>
        <option value="2">Partido</option>
      </Form.Control>
    </Form.Group>
  )
}

MeasurementMomentFilter.propTypes = {
  value: PropTypes.number,
  setValue: PropTypes.func.isRequired,
  modal: PropTypes.bool
}

export default MeasurementMomentFilter
