import { useEffect, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { CHECK_USER } from '../apollo/mutations'
import { appStore } from '../store/appStore'

const useCheckUser = (checkUser) => {
  const history = useHistory()

  const { dispatch } = useContext(appStore)
  const [checkUserMutation] = useMutation(CHECK_USER)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (checkUser) {
      setLoading(true)
      checkUserMutation().then(({ data }) => {
        const { user, status } = data?.checkUser || {}
        if (status.success) {
          if (!user?.terms_accepted) {
            history.replace('/terms-and-conditions')
          }
          dispatch({ type: 'userChecked', payload: data.checkUser.user })
        }
      }).finally(() => setLoading(false))
    }
  }, [checkUser])

  return { loading }
}

export default useCheckUser
