import styled from 'styled-components'

export default styled.div`
  width: 100%;
  height: calc(100vh - 70px);

  .rbc-calendar {
    width: 100%;
  }

  .menu-lateral {
    position: fixed;
    top: 0;
    height: 100vh;
    z-index: 9000;
  }

  // .rbc-month-header {
  //   background-color: #28a745;
  //   color: #fff;
  // }

  // .rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
  //   background-color: #28a745;
  //   color: #fff;
  // }

  .rbc-toolbar .rbc-toolbar-label{
    text-transform: capitalize;
  }

  .create-event-btn {
    float: right;
    text-decoration: underline;
    outline: none;
  }

  .rbc-agenda-date-cell,
  .rbc-agenda-time-cell,
  .rbc-agenda-table .rbc-header:first-child,
  .rbc-agenda-table .rbc-header:nth-child(2) {
    min-width: 130px;
  }

  table tr:nth-child(odd) td{
    color: #000000;
  }
  table tr:nth-child(even) td{
   color: #000000;
  }


  @media (max-width: 767px) {
    .rbc-toolbar span:nth-child(3) {
      display: none !important;
    }

    .rbc-toolbar {
      width: calc(100% - 62px);
    }
  }

  @media (max-width: 479px) {
    .filter-btn {
      top: 106px;
    }

    .rbc-toolbar {
      justify-content: initial;
      width: calc(100% - 62px);
    }

    .rbc-toolbar-label {
      position: absolute;

      left: calc(60% - 54px);
    }
  }
`
