/* eslint-disable react/display-name */
import React from 'react'
import PropTypes from 'prop-types'
import hasRole from '../../../keycloak/utils/hasRole'
import { useQuery } from '@apollo/client'
import { GET_DAILY_MEASUREMENTS, GET_MEASUREMENT_TYPES } from '../../../apollo/queries'
import { StickyTable, MeasurementInput, MeasurementMomentFilter, FilterRow, Loading } from '../../../components'
import { MeasurementsCharts } from './MeasurementsCharts'
import { getMeasurements } from './measurementsUtils'
import Styles from './DailyMeasurementsStyle'

const DailyMeasurements = React.memo(({ idPlayer, categoryType }) => {
  const [filters, setFilters] = React.useState({
    id_player: idPlayer,
    id_category_type: categoryType,
    measurement_moment: 1 // ENTRENAMIENTO
  })

  const getMeasurementTypes = useQuery(GET_MEASUREMENT_TYPES)
  const { data, loading, error } = useQuery(GET_DAILY_MEASUREMENTS, {
    variables: {
      input: filters
    },
    fetchPolicy: 'network-only'
  })

  const columns = React.useMemo(() => [
    {
      Header: ' ',
      sticky: 'left',
      className: 'player',
      columns: [
        {
          Header: 'Fecha',
          accessor: 'date',
          className: 'player'
        },
      ]
    },
    {
      Header: ' ',
      columns: getMeasurementTypes.data?.getMeasurementTypes.measurementTypes.map(type => (
        {
          Header: type.name,
          accessor: type.id,
          Cell: function measurementValue({ cell: { value, column } }) {
            if (hasRole(['permission_team-create-daily-player-measurements'])) {
              return (
                <MeasurementInput
                  measurement={{
                    id: value?.id,
                    id_player: idPlayer,
                    measurement_type: parseInt(column.id),
                    value: value?.measurement,
                    date: value?.date
                  }}
                  filters={filters}
                />
              )
            } else {
              return (
                value?.measurement
              )
            }
          }
        }
      ))
    },
  ], [getMeasurementTypes, filters])

  const renderMeasurements = () => {
    if (loading || getMeasurementTypes.loading) {
      return (
        <Loading />
      )
    }

    if (error || getMeasurementTypes.error) {
      return (
        <div>Error :(</div>
      )
    }

    const measurements = getMeasurements(idPlayer, data, getMeasurementTypes.data?.getMeasurementTypes.measurementTypes)

    return (
      <>
        <div>
          <div className="row">
            <div className="col align-self-center">
              <StickyTable
                columns={columns}
                data={measurements.current}
                getCellProps={cellInfo => ({
                  style: {
                    background: cellInfo.row.index % 2 === 0 ? '#e0e6f2' : '#ffffff',
                  },
                })}
              />
            </div>
          </div>

          <MeasurementsCharts
            measurements={measurements}
            measurementTypes={getMeasurementTypes.data?.getMeasurementTypes.measurementTypes}
          />

          <hr/>
          <div className="row">
            <div className="col align-self-center">
              <h5 className="mr-auto mt-2 text-secondary">
                Historial GPS
              </h5>
              <StickyTable
                columns={columns}
                data={measurements.history}
                getCellProps={cellInfo => ({
                  style: {
                    background: cellInfo.row.index % 2 === 0 ? '#e0e6f2' : '#ffffff',
                  },
                })}
              />
            </div>
          </div>
        </div>
      </>
    )
  }


  return (
    <Styles>
      <div className="row">
        <div className="col d-flex align-items-baseline justify-content-end">
          <h5 className="mr-auto mt-2 text-secondary">
              GPS de Hoy
          </h5>
          <FilterRow>
            <MeasurementMomentFilter
              value={filters.measurement_moment}
              setValue={setFilters}
            />
          </FilterRow>
        </div>
      </div>
      {renderMeasurements()}
    </Styles>
  )
})

DailyMeasurements.propTypes = {
  idPlayer: PropTypes.number.isRequired,
  categoryType: PropTypes.number.isRequired
}

export default DailyMeasurements
