import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useMutation } from '@apollo/client'
import { GET_PLAYER_KINESIC_TREATMENTS } from '../../../../apollo/queries'
import { CREATE_MASSIVE_KINESIC_TREATMENT } from '../../../../apollo/mutations'
import { Formik } from 'formik'
import { Modal, Form } from 'react-bootstrap'
import { StatusAlert, DatePickerInput, FooterForm } from '../../../../components'
import constants from '../../../../config/constants'
import Styles from './TherapeucticActionsModalStyle'

const TherapeucticActionsModal = ({
  openModal = false,
  setOpenModal,
  initValues = {},
  allMeters = [],
  clinicalEvents = []
}) => {
  const handleClose = () => setOpenModal(false)

  const modalHeaderRef = useRef()

  const update = (cache, { data }) => {
    cache.modify({
      fields: {
        getPlayerKinesicTreatments(existingTreatment = []) {
          const newTreatment = data.getPlayerKinesicTreatments
          cache.writeQuery({
            query: GET_PLAYER_KINESIC_TREATMENTS,
            data: {
              newTreatment,
              ...existingTreatment
            }
          })
        }
      }
    })
  }

  const [mutation, mutationResult] = useMutation(CREATE_MASSIVE_KINESIC_TREATMENT, { update })

  const modalTitle = 'Agregar Tratamiento Kinésico'
  const successMessage = 'Tratamiento Kinésico agregado con éxito.'
  const statusResult = mutationResult.data?.createMassiveKinesicTreatment.status

  if (mutationResult.error) {
    return (
      <div>Error :(</div>
    )
  }

  return (
    <Modal
      size="lg"
      show={openModal}
      dialogClassName="full-width"
      contentClassName="full-height"
      onHide={handleClose}
    >
      <Modal.Header
        closeButton
        className="bg-success text-white"
        ref={modalHeaderRef}
      >
        <Modal.Title>
          <img
            width="35px"
            className="mr-1"
            src={
              `${constants.STATIC_IMAGES_URL}/kine-icon.svg`
            }
          />
          {modalTitle}
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initValues}
        validate={values => {
          const errors = {}
          if (!values.date) {
            errors.date = 'Debes ingresar una fecha.'
          }
          return errors
        }}
        onSubmit={(values, { resetForm }) => {
          const meters = {}
          allMeters.forEach(meter => {
            if (values[meter.id]) {
              meters[meter.id] = values[meter.id]
            }
          })
          mutation({
            variables: {
              input: {
                id_player: values.id_player,
                id_diagnostic: values.id_diagnostic ? parseInt(values.id_diagnostic) : null,
                meters: JSON.stringify(meters),
                date: moment(values.date).format('YYYY-MM-DD'),
              }
            }
          }).then(({ data }) => {
            if (data.createMassiveKinesicTreatment.status.success) {
              resetForm({ values: '' })
            }
            modalHeaderRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
          })
        }}
      >
        {({
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          setFieldValue,
          handleSubmit
        }) => (
          <Form onSubmit={handleSubmit}>
            {mutationResult.data &&
              <StatusAlert
                successMessage={successMessage}
                status={statusResult}
              />
            }
            <Styles>
              <Modal.Body>
                <Form.Row>
                  <DatePickerInput
                    label="Fecha"
                    name="date"
                    value={values.date}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    touched={touched.date ? true : false}
                    error={errors.date}
                    width="col-12 col-md-6"
                  />
                  <Form.Group className="col-12 col-md-6">
                    <h6 className="form-label">{'Evento Clínico'}</h6>
                    {/* <Form.Label>{'Evento Clínico'}</Form.Label> */}
                    <Form.Control
                      as="select"
                      id="id_diagnostic"
                      name="id_diagnostic"
                      className='text-center'
                      value={values.id_diagnostic}
                      onChange={handleChange}
                    >
                      <option value=''>{'Selecciona un evento clínico'}</option>
                      {clinicalEvents.map(({ Diagnostics }) => {
                        const [Diagnostic] = Diagnostics
                        return (
                          <option
                            key={Diagnostic.id}
                            value={Diagnostic.id}
                          >
                            {Diagnostic.DiagnosticType.name} - {Diagnostic.Pathology.name}
                          </option>
                        )
                      })}
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  {allMeters.map(meter => (
                    <Form.Group key={meter.id} className='col-6 col-md-3 text-center' controlId="category">
                      <Form.Label>{meter.name}</Form.Label>
                      <Form.Check
                        type="checkbox"
                        id={meter.id}
                        name={meter.id}
                        value={true}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  ))}
                </Form.Row>
              </Modal.Body>
            </Styles>
            <Modal.Footer className="d-flex justify-content-end">
              <FooterForm
                className="mt-3"
                action="CREATE"
                loading={mutationResult.loading}
                handleCancel={() => handleClose()}
              />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

TherapeucticActionsModal.propTypes = {
  openModal:PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  initValues: PropTypes.object,
  allMeters: PropTypes.array,
  clinicalEvents: PropTypes.array
}

export default TherapeucticActionsModal
