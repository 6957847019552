import { gql } from '@apollo/client'

const GET_DIMENSION_HISTORY = gql`
  query getDimensionHistory {
    getDimensionHistory {
      dimensionHistories {
        id
        name
        Histories {
          id
          name
          Backgrounds {
            id
            name
            id_background_type
            BackgroundOptions {
              id
              name
            }
            Background {
              id
              name
            }
          }
        }
      }
      status{
        success
        code
        message
      }
    }
  }
`

export default GET_DIMENSION_HISTORY
