import styled from 'styled-components'

export default {
  Main: styled.div`
    .filter-btn {
      position: absolute;
      right: 18px;
      text-decoration: none;
      color: #142c59;
      border: none;
      outline: none;
      margin: 0 !important;
      padding-bottom: 14px !important;
      background: none;
      z-index: 800;

      &:active {
        box-shadow: none !important;
      }
    }
  `,
  Modal: styled.div`
    .col {
      padding: 0;
    }
  `
}
