import moment from 'moment'
import { ClinicalEventSelect } from '../../../components'

const useTherapeuticActions = ({
  idPlayer,
  allMeters = [],
  playerDimensions = [],
  InputCell,
  clinicalEvents = []
}) => {
  const columns = [
    {
      Header: ' ',
      sticky: 'left',
      className: 'player',
      columns: [
        {
          Header: 'Fecha',
          accessor: 'date',
          className: 'player'
        },
      ]
    },
    {
      Header: ' ',
      columns: [{
        Header: 'Evento Clínico',
        accessor: 'clinicalEventsColumn',
        Cell: ({ cell: { value, column } }) => {
          return (
            ClinicalEventSelect({
              clinicalEvents: value?.clinicalEvents,
              idDiagnostic: value?.id_diagnostic,
              idPlayerDimension: value?.id,
              idPlayer: value?.id_player,
              date: value?.date,
              column,
            })
          )
        }
      }].concat(
        allMeters.map(c => (
          {
            Header: c.name,
            accessor: c.id,
            Cell: ({ cell: { value, column } }) => InputCell({ cellInfo: value, column })
          }
        ))
      )
    },
  ]

  const data = playerDimensions?.reduce((acc, { id, id_diagnostic, date, meters_flat }) => {
    let rows = {}
    let metersValues = {}

    try {
      metersValues = JSON.parse(meters_flat)
    } catch (e) {
      console.warn(e)
    }

    allMeters.forEach((column) => {
      const existValue = metersValues[column.id] !== undefined
      rows = {
        ...rows,
        [column.id]: {
          id,
          id_player: idPlayer,
          cellValue: existValue ? metersValues[column.id] : false,
          id_diagnostic,
          date,
        }
      }
    })

    if (clinicalEvents.length) {
      rows = {
        ...rows,
        clinicalEventsColumn: {
          id,
          id_player: idPlayer,
          id_diagnostic,
          date,
          clinicalEvents
        }
      }
    }

    return [
      ...acc,
      {
        date: moment(date).format('DD/MM/YYYY'),
        originalDate: date,
        ...rows
      }
    ]
  }, [])

  return { data, columns }
}

export default useTherapeuticActions
