import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import ReactToolTip from 'react-tooltip'
import AuthorizedElement from '../../../keycloak/utils/AuthorizedElement'
import { useQuery, useMutation } from '@apollo/client'
import { GET_DIMENSION, GET_AUTOREPORT } from '../../../apollo/queries'
import { DELETE_AUTOREPORT } from '../../../apollo/mutations'
import { FaTrash, FaPen, FaPlus } from 'react-icons/fa'
import { Button, Table, DropdownButton, Dropdown, ButtonGroup } from 'react-bootstrap'
import { FilterRow, DateFilter, CustomChart, Loading, MeasurementMomentFilter } from '../../../components'
import { AUTOREPORT_TIMES } from './constants'
import AutoReportDetail from './AutoReportDetail/AutoReportDetail'
import usePlayerDimension from '../usePlayerDimension'
import Styles from './AutoReportStyle'

const dimensionTestsByMoment = new Map([
  [1, ['1', '28']],
  [2, ['37', '29']]
])

const AutoReport = ({ idPlayer }) => {
  const [openModal, setOpenModal] = React.useState(false)
  const [action, setAction] = React.useState('')
  const [updateValues, setUpdateValues] = React.useState({})
  const [selectedDimensionTest, setSelectedDimensionTest] = React.useState({ measurement_moment: 1 })
  const [autoreportTime, setAutoreportTime] = React.useState([])

  const [filters, setFilters] = React.useState({
    id_player: idPlayer,
    id_dimension: 1, //AUTOREPORT
    start_date: new Date(moment().subtract(6, 'months')),
    end_date: new Date()
  })

  const [deletePlayerDimension] = useMutation(DELETE_AUTOREPORT, {
    refetchQueries: [
      {
        query: GET_AUTOREPORT,
        variables: {
          input: {
            id_player: idPlayer,
            start_date: moment(filters.start_date).format('YYYY-MM-DD'),
            end_date: moment(filters.end_date).format('YYYY-MM-DD')
          }
        }
      }
    ]
  })

  const autoreport = useQuery(GET_AUTOREPORT, {
    variables: {
      input: {
        id_player: idPlayer,
        start_date: moment(filters.start_date).format('YYYY-MM-DD'),
        end_date: moment(filters.end_date).format('YYYY-MM-DD')
      }
    },
  })

  const biomedic = useQuery(GET_DIMENSION, {
    variables: {
      input: {
        id: 12 // BIOMEDICA
      }
    }
  })

  const handleDelete = (event, ids) => {
    event.preventDefault()
    if (window.confirm('Esta acción es irreversible, ¿Estás seguro de borrar este autoreporte?')) {

      const dimension_test_ids = dimensionTestsByMoment.get(selectedDimensionTest.measurement_moment)

      deletePlayerDimension({
        variables: {
          input: {
            id: ids.id_wellness,
            id_dimension_test: dimension_test_ids[0]
          }
        }
      })

      deletePlayerDimension({
        variables: {
          input: {
            id: ids.id_biomedic,
            id_dimension_test: dimension_test_ids[1]
          }
        }
      })
    }
  }

  const handleCreate = (time) => {
    setOpenModal(true)
    setAction('CREATE')
    setUpdateValues({})
    setAutoreportTime(time)
  }

  const { meterColors, dimension } = usePlayerDimension()

  if (autoreport.loading) {
    return (
      <Loading />
    )
  }

  if (autoreport.error) {
    return (
      <div>Error :(</div>
    )
  }

  const { getAutoreport } = autoreport.data

  return (
    <Styles>
      <div>
        <div className="row">
          <div className="col d-flex flex-wrap align-items-baseline justify-content">
            <h5 className="mr-auto text-secondary">
              {'Historial Autoreporte'}
            </h5>
            <AuthorizedElement roles={['permission_team-show-autoreport']}>
              <FilterRow>
                <DateFilter
                  value={{
                    start_date: filters.start_date,
                    end_date: filters.end_date
                  }}
                  setValue={setFilters}
                />
                <MeasurementMomentFilter
                  value={selectedDimensionTest.measurement_moment}
                  setValue={setSelectedDimensionTest}
                />
              </FilterRow>
            </AuthorizedElement>
          </div>
          <div className='d-lg-block d-none'>
            <AuthorizedElement roles={['permission_team-create-autoreport', 'permission_team-create-biomedical']}>
              <DropdownButton as={ButtonGroup} title="Crear Autoreporte">
                <Dropdown.Item onClick={() => handleCreate(AUTOREPORT_TIMES.checkIn)}>
                  <FaPlus className="mb-1 mr-2" />
                  {'Ingreso'}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleCreate(AUTOREPORT_TIMES.checkOut)}>
                  <FaPlus className="mb-1 mr-2" />
                  {'Egreso'}
                </Dropdown.Item>
              </DropdownButton>
            </AuthorizedElement>
          </div>
        </div>
        <div className='d-block d-lg-none'>
          <AuthorizedElement roles={['permission_team-create-autoreport', 'permission_team-create-biomedical']}>
            <DropdownButton as={ButtonGroup} title="Crear Autoreporte" className='btn-block'>
              <Dropdown.Item block onClick={() => handleCreate(AUTOREPORT_TIMES.checkIn)}>
                <FaPlus className="mb-1 mr-2" />
                {'Ingreso'}
              </Dropdown.Item>
              <Dropdown.Item block onClick={() => handleCreate(AUTOREPORT_TIMES.checkOut)}>
                <FaPlus className="mb-1 mr-2" />
                {'Egreso'}
              </Dropdown.Item>
            </DropdownButton>
          </AuthorizedElement>
        </div>
      </div>
      <div className="row d-flex flex-wrap align-items-center">
        <div className="col col-lg-6">
          <CustomChart
            title="Bienestar"
            type="line"
            data={{
              labels: getAutoreport.autoreport.reduce((acc, current) => [...acc, current.date], []),
              datasets: getAutoreport.allMeters.filter(_meter =>
                dimensionTestsByMoment.get(selectedDimensionTest.measurement_moment).includes(_meter.id_dimension_test) &&
                (['1','3','4','5','211','212','213','214'].includes(_meter.id))
              ).map(meter => ({
                label: `# ${meter.name}`,
                fill: false,
                backgroundColor: meterColors.get(parseInt(meter.id, 10)),
                borderColor: meterColors.get(parseInt(meter.id, 10)),
                data: getAutoreport.autoreport.reduce((acc, current) => {
                  if (current.meters.find(m => m.id_meter == meter.id)?.value) {
                    return [...acc, { x: current.date, y: current.meters.find(m => m.id_meter == meter.id)?.value }]
                  }
                  return acc
                }
                , [])
              }))
            }}
            options={{
              legend: {
                labels: {
                  boxHeight: 4,
                  boxWidth: 4,
                  fontSize: 10,
                }
              },
              scales: {
                xAxes: [{
                  ticks: {
                    fontSize: 10
                  },
                  type: 'time',
                  time: {
                    unit: 'day',
                  },
                }],
                yAxes: [
                  {
                    ticks: {
                      stepSize: 1,
                      beginAtZero: true,
                    },
                  },
                ],
              },
            }}
          />
        </div>
        <div className="col col-lg-6">
          <CustomChart
            title="Nivel de percepción por el esfuerzo/T.Q.R"
            type="line"
            data={{
              labels: getAutoreport.autoreport.reduce((acc, current) => [...acc, current.date], []) || '',
              datasets: getAutoreport.allMeters.filter(_meter =>
                dimensionTestsByMoment.get(selectedDimensionTest.measurement_moment).includes(_meter.id_dimension_test) &&
                (['6', '215', '216', '217'].includes(_meter.id))
              ).map(meter => ({
                label: `# ${meter.name}`,
                fill: false,
                backgroundColor: meterColors.get(parseInt(meter.id, 10)),
                borderColor: meterColors.get(parseInt(meter.id, 10)),
                data: getAutoreport.autoreport.reduce((acc, current) => {
                  if (current.meters.find(m => m.id_meter == meter.id)?.value) {
                    return [...acc, { x: current.date, y: current.meters.find(m => m.id_meter == meter.id)?.value }]
                  }
                  return acc
                }
                , [])
              }))
            }}
            options={{
              legend: {
                labels: {
                  boxHeight: 4,
                  boxWidth: 4,
                  fontSize: 10,
                }
              },
              scales: {
                xAxes: [{
                  ticks: {
                    fontSize: 10
                  },
                  type: 'time',
                  time: {
                    unit: 'day',
                  },
                }],
                yAxes: [
                  {
                    ticks: {
                      stepSize: 1,
                      beginAtZero: true,
                    },
                  },
                ],
              },
            }}
          />
        </div>
      </div>

      <hr />
      <div className="row">
        <div className="col">
          <Table className='table-font table-responsive' striped bordered hover size="sm">
            <thead>
              <tr>
                <th className="date">Fecha</th>
                {getAutoreport.allMeters.map((meter, key) => {
                  if (dimensionTestsByMoment.get(selectedDimensionTest.measurement_moment).includes(meter.id_dimension_test)) {
                    return (
                      <React.Fragment key={key}>
                        {(meter.id == 6 ||meter.id == 215) &&
                          <React.Fragment>
                            <th>Suma Total Wellness</th>
                            {/* <th>Promedio Wellness</th> */}
                          </React.Fragment>
                        }
                        {(meter.id == 138 ||meter.id == 143) &&
                          <th>Carga de Entrenamiento (UA)</th>
                        }
                        <th className="text-center">{meter.name}</th>
                      </React.Fragment>
                    )
                  }
                  return null
                })}
                {/* {allMeters.map((meter, key) =>
                  <th key={key} className="text-center">{meter.name}</th>
                )} */}
                <th className="actions">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {getAutoreport.autoreport.map((playerAutoReport, key) => {

                const isEmptyTest = playerAutoReport.meters.filter(({ id_dimension_test }) =>
                  dimensionTestsByMoment.get(selectedDimensionTest.measurement_moment).includes(id_dimension_test)
                ).every(({ value }) => value === '')

                if (isEmptyTest) {
                  return null
                }
                return (
                  <tr key={key}>
                    <td className="date">
                      {moment(playerAutoReport.date).format('DD/MM/YYYY')}
                    </td>
                    {getAutoreport.allMeters.map((meter, index) => {
                      if (dimensionTestsByMoment.get(selectedDimensionTest.measurement_moment).includes(meter.id_dimension_test)) {

                        const playerMeter = playerAutoReport.meters.find(m =>
                          m.id_meter === meter.id && dimensionTestsByMoment.get(selectedDimensionTest.measurement_moment).includes(m.id_dimension_test)
                        )

                        const aritmeticValues = playerAutoReport.aritmeticValues.find(a => dimensionTestsByMoment.get(selectedDimensionTest.measurement_moment).includes(a.idDimentsionTest.toString()))

                        return (
                          <React.Fragment key={index}>
                            {(meter.id == 6 ||meter.id == 215) &&
                            <React.Fragment>
                              <td>{aritmeticValues?.totalSumWellness || '--'}</td>
                              {/* <td>{aritmeticValues?.averageWellness || '--'}</td> */}
                            </React.Fragment>
                            }
                            {(meter.id == 138 ||meter.id == 143) &&
                            <td>{aritmeticValues?.multiplicationPSE || '--'}</td>
                            }
                            {meter.id_meter_type === '4' ?
                              (meter.id == 138 ||meter.id == 143) ?
                                <td>
                                  {meter.MeterOptions?.find(
                                    option => parseInt(option.id, 10) == playerMeter?.value
                                  )?.name || '--'}
                                </td> :
                                <td>
                                  {playerMeter?.value ?
                                    <Button
                                      value={playerMeter?.value}
                                      variant="success"
                                      size="sm"
                                      data-tip data-for={`1-level-${playerMeter?.id}${playerAutoReport.date}`}
                                      className={`btn-level ${(meter.id == 216 ||meter.id == 217) ? 'TQR' : 'level' }-${meter.MeterOptions.length > 5 ? '' : '0'}${playerMeter?.value}`}
                                    >
                                      {playerMeter?.value}
                                      <ReactToolTip
                                        id={`1-level-${playerMeter?.id}${playerAutoReport.date}`}
                                        place="top"
                                        effect="solid"
                                        type="info"
                                      >
                                        {meter.MeterOptions?.find(
                                          option => parseInt(option.value, 10) == playerMeter?.value
                                        )?.name}
                                      </ReactToolTip>
                                    </Button> : '--'
                                  }
                                </td> :
                              <td className="comments">{playerMeter?.value || '--'}</td>
                            }
                          </React.Fragment>
                        )
                      }
                      return null
                    })}
                    {/* {meters.map((meter, index) =>
                      <td key={index}>
                        {meter.value &&
                          <Button
                            value={meter.value}
                            variant="success"
                            size="sm"
                            data-tip data-for={`1-level-${meter.id}${autoreport.date}`}
                            className={`btn-level level-${index === 5 ? '' : '0' }${meter.value}`}
                          >
                            {meter.value}
                            <ReactToolTip
                              id={`1-level-${meter.id}${autoreport.date}`}
                              place="top"
                              effect="solid"
                              type="info"
                            >
                              {
                                allMeters.find(
                                  m => parseInt(m.id, 10) === meter.id
                                )?.MeterOptions?.find(
                                  option => parseInt(option.value, 10) == meter.value
                                )?.name
                              }
                            </ReactToolTip>
                          </Button>
                        }
                      </td>
                    )} */}
                    <td>
                      <div className="d-flex justify-content-center">
                        <AuthorizedElement roles={['permission_team-update-autoreport', 'permission_team-update-biomedical']}>
                          <Button
                            size="sm"
                            variant="warning"
                            className="mr-2"
                            data-tip data-for="edit"
                            onClick={() => {
                              setOpenModal(true)
                              setAction('EDIT')
                              setAutoreportTime([AUTOREPORT_TIMES.checkIn, AUTOREPORT_TIMES.checkOut])
                              setUpdateValues({
                                id_wellness: playerAutoReport.id_wellness,
                                id_biomedic: playerAutoReport.id_biomedic,
                                date: new Date(moment(`${playerAutoReport.date} 12:00`)),
                                ...playerAutoReport.meters.reduce((acc, current) =>
                                  ({ ...acc, [current.id_meter]: current.value?.toString() })
                                , {})
                              })
                            }}
                          >
                            <FaPen color={'white'} />
                            <ReactToolTip
                              id="edit"
                              place="top"
                              effect="solid"
                              type="info"
                            >
                              {'Editar'}
                            </ReactToolTip>
                          </Button>
                        </AuthorizedElement>
                        <AuthorizedElement roles={['permission_team-delete-autoreport']}>
                          <Button
                            variant="danger"
                            size="sm"
                            data-tip data-for="delete"
                            className="mx-1"
                            onClick={(event) => handleDelete(
                              event,
                              {
                                id_wellness: playerAutoReport.id_wellness,
                                id_biomedic: playerAutoReport.id_biomedic,
                              }
                            )}
                          >
                            <FaTrash color={'white'}/>
                            <ReactToolTip
                              id="delete"
                              place="top"
                              effect="solid"
                              type="info"
                            >
                              {'Eliminar'}
                            </ReactToolTip>
                          </Button>
                        </AuthorizedElement>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>
      </div>

      {openModal &&
        <AutoReportDetail
          idPlayer={idPlayer}
          openModal={openModal}
          setOpenModal={(value) => setOpenModal(value)}
          action={action}
          updateValues={updateValues}
          autoreport={dimension.data?.getDimension?.dimension}
          biomedic={biomedic.data?.getDimension?.dimension}
          selectedDimensionTest={selectedDimensionTest}
          setSelectedDimensionTest={setSelectedDimensionTest}
          filters={filters}
          dimensionTestsByMoment={dimensionTestsByMoment}
          autoreportTime={autoreportTime}
          setAutoreportTime={setAutoreportTime}
        />
      }
    </Styles>
  )
}

AutoReport.propTypes = {
  idPlayer: PropTypes.number
}

export default AutoReport
