import { gql } from '@apollo/client'

const GET_PLAYERS_GPS = gql`
  query getPlayersGps($input:PlayersGpsInput!) {
    getPlayersGps (input: $input) {
      players{
        id
        id_category_type
        PlayerGps{
          id
          id_category_type
          id_gps_moment
          id_gps_moment_desc
          activity
          gps_values
          date
          GpsMoment{
            id
            name
          }
          GpsMomentDescription{
            id
            name
          }
        }
        User {
          id
          name
          last_name
          gender
        }
      }
      status{
        success
        code
        message
      }
    }
  }

`
export default GET_PLAYERS_GPS

