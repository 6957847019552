import styled from 'styled-components'

export default styled.div`
  .breadcrumb {
    background: none;
    margin-bottom: 10px;
    padding: 0px;
  }

  nav {
    padding-bottom: 0%;
  }

  button {
    border: none;
    background: none;
  }
`
