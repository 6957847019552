import React from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { GET_PLAYER_DIMENSIONS } from '../../../../../apollo/queries'
import { UPDATE_PLAYER_DIMENSION } from '../../../../../apollo/mutations'
import { Formik } from 'formik'
import { Form, Modal, Col } from 'react-bootstrap'
import { FaCheck } from 'react-icons/fa'
import { StatusAlert, FooterForm } from '../../../../../components'
import usePlayerDimension from '../../../usePlayerDimension'
import Styles from './SkinMassFormStyle'

const SkinMassForm = ({
  idPlayer,
  openModal,
  setOpenModal,
  action = 'CREATE',
  updateValues
}) => {
  const handleClose = () => setOpenModal(false)

  const updateCache = (cache, { data }) => {
    cache.modify({
      fields: {
        getPlayerDimensions(existingPlayerDimensions = []) {
          const newPlayerDimensions = data.getPlayerDimensions
          cache.writeQuery({
            query: GET_PLAYER_DIMENSIONS,
            data: {
              newPlayerDimensions,
              ...existingPlayerDimensions
            }
          })
        }
      }
    })
  }

  const [updatePlayerDimension, updateResult] = useMutation(
    UPDATE_PLAYER_DIMENSION, {
      update: updateCache
    }
  )

  const successMessage = action === 'CREATE' ? 'Antropometría creada con éxito.' : 'Antropometría actualizada con éxito.'
  const statusResult = action === 'CREATE' ? updateResult.data?.createPlayerDimension.status : updateResult.data?.updatePlayerDimension.status

  const { getInitialValues, onSubmit, allMeters } = usePlayerDimension(idPlayer, action, 2)

  return (
    <Styles>
      <Modal
        size="md"
        show={openModal}
        dialogClassName="full-width"
        contentClassName="full-height"
        onHide={handleClose}
      >
        <Modal.Header closeButton className="bg-success text-white">
          <Modal.Title>
            <FaCheck className="mr-1" color={'white'} />
            {action === 'CREATE'
              ? 'Crear Tejido Piel'
              : 'Editar Tejido Piel'
            }
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={
            getInitialValues(allMeters, updateValues)
          }
          onSubmit={(values) => {
            onSubmit(updatePlayerDimension, values)
          }}
          validate={values => {
            const errors = {}
            if (!values.date) {
              errors.date = 'Debes ingresar una fecha'
            }
            return errors
          }}
        >
          {({
            handleChange,
            handleBlur,
            values,
            handleSubmit
          }) => (
            <Form onSubmit={handleSubmit}>
              {updateResult.data &&
                <StatusAlert
                  successMessage={successMessage}
                  status={statusResult}
                />
              }
              <Modal.Body>
                <Form.Row>
                  <Form.Group as={Col} controlId="Kilogramos">
                    <Form.Label>Kilogramos (kg)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ingrese kilogramos"
                      name="230"
                      value={values[230]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="Porcentaje">
                    <Form.Label>Porcentaje (%)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ingrese %."
                      name="229"
                      value={values[229]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Form.Group>
                </Form.Row>
              </Modal.Body>
              <Modal.Footer>
                <FooterForm
                  action={action}
                  loading={updateResult.loading}
                  handleCancel={handleClose}
                />
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </Styles>
  )
}

SkinMassForm.propTypes = {
  idPlayer: PropTypes.number,
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  action: PropTypes.string,
  updateValues: PropTypes.object
}

export default SkinMassForm
