import styled from 'styled-components'

export default styled.div`

  .responsive-iframe{
    width: 100%;
    height: calc(100vh - 1px);
    border: none;
  }

`

