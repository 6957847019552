import { gql } from '@apollo/client'

const SEARCH_PLAYERS = gql`
  query searchPlayers($input: SearchPlayersInput!) {
    searchPlayers(input: $input) {
      players {
        id
        id_category_type
        id_position_type
        birthday
        side
        height
        initial_weight
        jersey
        User {
          id
          name
          last_name
          gender
          email
          active
          image
        }
      }
      status {
        success
        code
        message
      }
    }
  }
`

export default SEARCH_PLAYERS
