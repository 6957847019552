import { gql } from '@apollo/client'

const GET_PLEXO_SYSTEMS = gql`
  query getPlexoSystems($input: PlexoSystemInput!) {
    getPlexoSystems(input: $input) {
      status {
        success
        code
        message
      }
      plexoSystems {
        id
        name
      }
    }
  }
`

export default GET_PLEXO_SYSTEMS
