import { gql } from '@apollo/client'

const GET_RETURN_PLAY = gql`
  {
    getReturnPlay {
      returnPlay {
        id
        name
      }
      status {
        success
        code
        message
      }
    }
  }
`

export default GET_RETURN_PLAY

