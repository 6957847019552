import { gql } from '@apollo/client'

const TOGGLE_MAIN_AVAILABILITY = gql`
  mutation toggleMainAvailability($input: MainAvailabilityInput!) {
    toggleMainAvailability(input: $input) {
      status {
        success
        code
        message
      }
    }
  }
`

export default TOGGLE_MAIN_AVAILABILITY
