/* eslint-disable no-unused-vars */
import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import ReactToolTip from 'react-tooltip'
import AuthorizedElement from '../../../keycloak/utils/AuthorizedElement'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { GET_PLAYER_DIMENSIONS } from '../../../apollo/queries'
import { DELETE_PLAYER_DIMENSION } from '../../../apollo/mutations'
import { FaEye, FaTrash } from 'react-icons/fa'
import { Button, Table, Card, Accordion } from 'react-bootstrap'
import { Loading } from '../../../components'
import { BiomechanicsContext } from './BiomechanicsContext'
import usePlayerDimension from '../usePlayerDimension'
import Styles from './BiomechanicsDimensionStyle'

const BiomechanicsDimension = ({ id_dimension }) => {
  const history = useHistory()
  const location = useLocation()
  let { id_player, id_diagnostic } = useParams()
  const {
    filters,
    setAction,
    setFormValues,
    setCurrentDimension
  } = React.useContext(BiomechanicsContext)

  const {
    dimension,
    getInitialValues,
    allMeters,
    parseAllMetersInObject
  } = usePlayerDimension(
    parseInt(id_player, 10),
    'CREATE',
    id_dimension
  )

  const updateCache = (cache, { data }) => {
    cache.modify({
      fields: {
        getPlayerDimensions(existingPlayerDimensions = []) {
          const newPlayerDimensions = data.getPlayerDimensions
          cache.writeQuery({
            query: GET_PLAYER_DIMENSIONS,
            data: {
              newPlayerDimensions,
              ...existingPlayerDimensions
            }
          })
        }
      }
    })
  }

  const { data, loading, error } = useQuery(GET_PLAYER_DIMENSIONS, {
    variables: {
      input: {
        ...filters,
        id_dimension,
        start_date: moment(filters.start_date).format('YYYY-MM-DD'),
        end_date: moment(filters.end_date).format('YYYY-MM-DD')
      }
    }
  })

  const [deletePlayerDimension] = useMutation(DELETE_PLAYER_DIMENSION, {
    update: updateCache
  })

  const handleDelete = (event, id) => {
    event.preventDefault()
    if (window.confirm('Esta acción es irreversible, ¿Estás seguro de borrar esta medición?')) {
      deletePlayerDimension({
        variables: {
          input: { id }
        }
      })
    }
  }

  React.useEffect(() => {
    if (allMeters.length) {
      setFormValues(getInitialValues(allMeters))
    }
  }, [allMeters])

  const handleAction = (editFormValues = {}) => {
    setAction('EDIT')
    setCurrentDimension({
      id: parseInt(dimension.data?.getDimension.dimension.id, 10),
      name: dimension.data?.getDimension.dimension.name,
      dimensionTests: dimension.data?.getDimension.dimension.DimensionTests
    })

    history.push(`${location.pathname}?edit=true`)
    setFormValues(editFormValues)
  }

  if (error) {
    return (
      <div>Error :(</div>
    )
  }

  if (loading) {
    return (
      <Loading />
    )
  }

  return (
    <Styles>
      <Card>
        <Accordion.Toggle
          as={Card.Header}
          eventKey={id_dimension}
          className="header-accordion d-flex"
        >
          <p className="mb-0 text-white">{dimension.data?.getDimension.dimension.name}</p>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={id_dimension}>
          <Card.Body className="mt-0">
            {data.getPlayerDimensions.playerDimensions.length > 0 ?
              <Table className='table-font table-responsive-lg' striped bordered hover size="sm">
                <thead>
                  <tr>
                    {/* date */}
                    <th></th>
                    {dimension.data?.getDimension.dimension.DimensionTests.map(test =>
                      <th key={test.id} colSpan={test.Meters.length}>{test.name}</th>
                    )}
                    {/* actions */}
                    <th></th>
                  </tr>
                  <tr>
                    <th className="date">Fecha</th>
                    {allMeters.map(meter =>
                      <th key={meter.id}>{meter.name}</th>
                    )}
                    <th className="actions">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {data.getPlayerDimensions.playerDimensions.map(playerDimension =>
                    <tr key={playerDimension.id}>
                      <td className='text-center'>{moment(playerDimension.date).format('DD/MM/YYYY')}</td>
                      {allMeters.map(meter => {
                        const metersValues = parseAllMetersInObject(playerDimension.meters)
                        return(
                          <td className='text-center' key={meter.id}>{metersValues[meter.id] || '--'}</td>
                        )
                      }
                      )}
                      <td>
                        <div className=" d-flex justify-content-center">
                          <AuthorizedElement roles={['permission_team-update-biomechanics']}>
                            <Button
                              variant="primary"
                              size="sm"
                              data-tip data-for="detail"
                              className="mx-1"
                              onClick={() => handleAction({
                                id: playerDimension.id,
                                date: new Date(`${playerDimension.date} `),
                                ...parseAllMetersInObject(playerDimension.meters)
                              })}
                            >
                              <FaEye color={'white'}/>
                              <ReactToolTip
                                id="detail"
                                place="top"
                                effect="solid"
                                type="info"
                              >
                                {'Ver detalle'}
                              </ReactToolTip>
                            </Button>
                          </AuthorizedElement>
                          <AuthorizedElement roles={['permission_team-delete-biomechanics']}>
                            <Button
                              variant="danger"
                              size="sm"
                              data-tip data-for="delete"
                              className="mx-1"
                              onClick={(event) => handleDelete(event, playerDimension.id)}
                            >
                              <FaTrash color={'white'}/>
                              <ReactToolTip
                                id="delete"
                                place="top"
                                effect="solid"
                                type="info"
                              >
                                {'Eliminar'}
                              </ReactToolTip>
                            </Button>
                          </AuthorizedElement>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table> : 'No hay registros.'
            }
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Styles>

  )
}

BiomechanicsDimension.propTypes = {
  id_dimension: PropTypes.number.isRequired
}

export default BiomechanicsDimension
