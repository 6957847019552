import React from 'react'
import PropTypes from 'prop-types'
import { Accordion, Card } from 'react-bootstrap'
import KinesicBodyZone from './KinesicBodyZone'

const KinesicBlock = ({ kinesicBlock }) => (
  <Accordion>
    <Card>
      <Accordion.Toggle as={Card.Header} eventKey={kinesicBlock.id} className="header-accordion d-flex">
        <p className='mb-0 text-white'>{kinesicBlock.name}</p>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={kinesicBlock.id}>
        <Card.Body className="mt-0">
          {kinesicBlock.KinesicBlockBodyZones.map((bodyZone, key) =>
            <KinesicBodyZone
              key={key}
              kinesicBlock={kinesicBlock}
              bodyZone={bodyZone}
            />
          )}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  </Accordion>
)

KinesicBlock.propTypes = {
  kinesicBlock: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    KinesicBlockBodyZones: PropTypes.array.isRequired
  }).isRequired
}

export default KinesicBlock
