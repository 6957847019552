import moment from 'moment'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_DIMENSION } from '../../apollo/queries'
import { PlayerCell } from '../../components'

const usePlayerDimension = (
  idPlayer,
  action,
  id_dimension = 1
) => {

  let history = useHistory()

  const [allMeters, setAllMeters] = useState([])
  const [allOptions, setAllOptions] = useState(new Map())

  const meterColors = new Map([
    [1, '#98d4ed'],
    [2, '#a6efd6'],
    [3, '#f05fa3'],
    [4, '#f2af73'],
    [5, '#d53f3f'],
    [211, '#98d4ed'],
    [212, '#f05fa3'],
    [213, '#f2af73'],
    [214, '#d53f3f'],
    [6, '#a6efd6'],
    [215, '#a6efd6'],
    [216, '#5a18d3'],
    [217, '#5a18d3']
  ])

  const dimension = useQuery(GET_DIMENSION, {
    variables: {
      input: {
        id: id_dimension
      }
    }
  })

  useEffect(() => {
    if (dimension.data) {
      setAllMeters(
        dimension.data.getDimension.dimension.DimensionTests.reduce((acc, current) =>
          [...acc, ...current.Meters]
        , []).sort((a, b) => a.order - b.order)
      )
    }
  }, [dimension.data])

  useEffect(() => {
    setAllOptions(
      new Map(
        allMeters.reduce((acc, current) =>
          [
            ...acc,
            ...current.MeterOptions.map(option =>
              ([option.id.toString(), option.name])
            )
          ]
        , [])
      )
    )
  }, [allMeters])

  const getInitialValues = (meters, updateValues) => {
    let initialValues = updateValues

    if (action === 'CREATE') {
      initialValues = {
        date: new Date(),
        comments: '',
        ...meters.reduce((acc, current) =>
          ({ ...acc, [current.id]: '' })
        , {})
      }
    }

    return initialValues
  }

  const validate = (values, meters) => {
    const errors = {}
    if (!values.date) {
      errors.date = 'Debes ingresar una fecha'
    }

    meters.forEach(meter => {
      if (!values[meter.id] && meter.id !== '6') {
        errors[meter.id] = `Debes ingresar un valor de ${meter.name}`
      }
    })

    return errors
  }

  const onSubmit = (mutation, values, resetForm, redirect = '', thisAction = '', thisDimension = null, updateValuesState = null, id_diagnostic = null) => {
    const idDimension = thisDimension ? thisDimension.id : id_dimension
    const dimensionTests = thisDimension ? thisDimension.dimensionTests : dimension.data.getDimension.dimension.DimensionTests
    const queryAction = thisAction || action
    const ids = queryAction === 'CREATE' ? { id_player: idPlayer } : { id: values.id }
    const idDiagnostic = queryAction === 'CREATE' ? { id_diagnostic: parseInt(id_diagnostic, 10) } : {}

    const meters = dimensionTests?.reduce((stack, test) => ({
      ...stack,
      [test.id]: {
        id: test.id,
        name: test.name,
        meters: test.Meters.reduce((acc, meter) =>
          ({
            ...acc,
            [meter.id]: {
              id: parseInt(meter.id, 10),
              name: meter.name,
              value: values[meter.id] ||''
            }
          })
        , {})
      }
    }), {})

    mutation({
      variables: {
        input: {
          ...ids,
          ...idDiagnostic,
          id_dimension: idDimension,
          meters: JSON.stringify(meters),
          date: moment(values.date).format('YYYY-MM-DD'),
          comments: values.comments
        }
      }
    }).then(({ data }) => {
      if (queryAction === 'CREATE' && data.createPlayerDimension.status.success) {
        resetForm({ values: '' })

        if (redirect) {
          history.push(`${redirect}/${data.createPlayerDimension.playerDimension.id}`)
        }
      }

      if (updateValuesState) {
        updateValuesState(values)
      }
    })
  }

  const parseMeters = (json = '', id_test = 1) => {
    let metersArray = []

    if (json) {
      const meters = JSON.parse(json)[id_test]?.meters

      if (meters) {
        Object.keys(meters).forEach(key => {
          metersArray.push({
            id: meters[key].id,
            name: meters[key].name,
            value: meters[key].value
          })
        })
      }
    }

    return metersArray
  }

  const parseAllMeters = (json = '', thisTests = null) => {
    let metersArray = []

    const dimensionTests = thisTests || dimension.data?.getDimension?.dimension?.DimensionTests

    if (json) {
      dimensionTests?.forEach(test => {
        const meters = JSON.parse(json)[test.id]?.meters

        if (meters) {
          Object.keys(meters).forEach(key => {
            metersArray.push({
              id: meters[key].id,
              name: meters[key].name,
              value: meters[key].value
            })
          })
        }
      })
    }

    return metersArray
  }

  const parseAllMetersInObject = (json = '', thisTests = null) => {
    let metersObject = {}

    const dimensionTests = thisTests || dimension.data?.getDimension?.dimension?.DimensionTests

    if (json) {
      dimensionTests?.forEach(test => {
        const meters = JSON.parse(json)[test.id]?.meters

        if (meters) {
          Object.keys(meters).forEach(key => {
            metersObject[meters[key].id] = meters[key].value
          })
        }
      })
    }

    return metersObject
  }

  // Convert meters json (legacy: json with dimension test) to Map
  const jsonToMap = (meters = '{}') => {
    const metersMap = new Map()
    const metersParsed = JSON.parse(meters)

    Object.values(metersParsed).forEach(meterParsed => {
      Object.values(meterParsed.meters).forEach(({ id, value }) => {
        metersMap.set(id, value)
      })
    })

    return metersMap
  }

  const getPlayerDimensionTeamTable = ({
    players = [],
    dimensionTests,
    date,
    columnsObject = [],
    InputCell,
    filters,
    playerInfoTab = ''
  }) => {

    const columns = [{
      Header: ' ',
      sticky: 'left',
      className: 'player',
      columns: [
        {
          Header: 'Deportista',
          accessor: 'player',
          className: 'player',
          Cell: ({ cell: { value } }) => PlayerCell({ ...value, url: `/player-info/${value.idCategoryType}/${value.playerId}/${playerInfoTab}` })
        },
      ]
    },
    {
      Header: moment(date).format('LL'),
      columns: columnsObject.map(c => (
        {
          Header: c.name,
          accessor: c.id,
          Cell: ({ cell: { value, column } }) => InputCell({ cellInfo: value, column, filters })
        }
      ))
    }]

    const data = players.reduce((acc, { id, id_category_type, id_position_type, PlayerDimensions, User }) => {
      let rows = {}
      let meterValues = {}

      try {
        const parseMeters = JSON.parse(PlayerDimensions[0]?.meters)
        dimensionTests?.forEach(test => {
          const meters = parseMeters[test.id]?.meters
          if (meters) {
            Object.keys(meters).forEach(key => {
              meterValues[meters[key].id] = meters[key].value
            })
          }
        })
      } catch (e) {
        console.warn(e)
      }

      columnsObject.forEach((column) => {
        const existValue = meterValues[column.id] !== undefined
        rows = {
          ...rows,
          [column.id]: {
            id: existValue ? PlayerDimensions[0]?.id : null,
            id_player: id,
            cellValue: existValue ? meterValues[column.id] : '',
            date,
            categoryId: id_category_type,
            positionTypeId: id_position_type,
          }
        }
      })

      return [
        ...acc,
        {
          player: {
            playerId: id,
            name: `${User.last_name} ${User.name} `,
            gender: User.gender,
            userId: User.id,
            idCategoryType: id_category_type
          },
          ...rows,
        }
      ]
    }, [])

    return { data, columns }
  }

  return {
    dimension,
    allMeters,
    allOptions,
    getInitialValues,
    validate,
    onSubmit,
    parseMeters,
    parseAllMeters,
    meterColors,
    parseAllMetersInObject,
    jsonToMap,
    getPlayerDimensionTeamTable
  }
}

export default usePlayerDimension
