import styled from 'styled-components'

export default styled.div`
  .dropzone {
    background-color: #f7f7f7;
    border: 3px dashed #d0d0d0;
    padding: 3px;
    margin: 3px;
    display: flex;
    flex-direction: row;
    flex-wrap: row wrap;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .zoom {
    transition: transform .5s;
  }

  .zoom:hover {
    transform: scale(1.02);
  }

  p{
    font-size: 11px;
  }
`
