import React from 'react'
import PropTypes from 'prop-types'

const TitleSection = ({ title = '', extras, ...rest }) => {
  return (
    <>
      <div className="row" {...rest}>
        <div className="col d-flex align-items-baseline justify-content-start">
          <h5 className="mr-auto text-secondary">{title}</h5>
          {extras}
        </div>
      </div>
    </>
  )
}

TitleSection.propTypes = {
  title: PropTypes.string,
  extras: PropTypes.node
}

export default TitleSection
