import styled from 'styled-components'

export default styled.div`
  label {
    background-color: #28a745;
    color: white;
    padding: 0 10px;
    width: 100%;
  }

  .form-check-label {
    background-color: transparent;
    color: black;
  }
`
