import React, { useContext } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_DAILY_MEASUREMENTS, GET_MEASUREMENT_TYPES } from '../../apollo/queries'
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa'
import {
  FilterRow,
  CategoryFilter,
  MeasurementMomentFilter,
  StickyTable,
  MeasurementInput,
  Loading,
  CustomBreadcrumb,
  UserImage
} from '../../components'
import Styles from './DailyMeasurementsStyle'
import { appStore } from '../../store/appStore'

const DailyMeasurements = () => {
  const initialWindow = React.useMemo(() => ({
    name: moment().format('LL'),
    completeDate: moment().format('YYYY-MM-DD')
  }), [])

  const { state } = useContext(appStore)

  const [windowDate, setWindowDate] = React.useState(initialWindow)
  const [filters, setFilters] = React.useState({
    id_category_type: state.defaultCategoryType,
    measurement_moment: 1, // Entrenamiento
    start_date: moment().startOf('day').format('YYYY-MM-DD'),
    end_date: moment().startOf('day').format('YYYY-MM-DD')
  })

  const getMeasurementTypes = useQuery(GET_MEASUREMENT_TYPES)
  const { data, loading, error } = useQuery(GET_DAILY_MEASUREMENTS, {
    variables: {
      input: filters
    },
    fetchPolicy: 'network-only'
  })

  React.useEffect(() => {
    setFilters((prevState) => ({
      ...prevState,
      start_date: moment(windowDate.completeDate).startOf('day').format('YYYY-MM-DD'),
      end_date: moment(windowDate.completeDate).startOf('day').format('YYYY-MM-DD')
    }))
  }, [windowDate])

  const handleDay = (action) => {
    if (action === 'TODAY') {
      setWindowDate(initialWindow)
      setFilters((prevState) => ({
        ...prevState,
        start_date: moment().startOf('day').format('YYYY-MM-DD'),
        end_date: moment().startOf('day').format('YYYY-MM-DD')
      }))
    } else {
      setWindowDate((prevState) => {
        const newDate = moment(prevState.completeDate).add(
          (action === 'PREV' ? -1 : 1),
          'days'
        )
        return {
          name: newDate.format('LL'),
          completeDate: newDate.format('YYYY-MM-DD')
        }
      })
    }
  }

  const getRows = (playerMeasurements, measurementTypes) => {
    let rows = {}

    const rowConditions = playerMeasurements.DailyMeasurements.reduce((acc, measurements) => {
      return {
        ...acc,
        [measurements.measurement_type]: {
          id: measurements.id,
          id_player: playerMeasurements.id,
          measurement: measurements.value.toString()
        }
      }
    }, {})


    measurementTypes.forEach((type) => {
      if (rowConditions[type.id]) {
        rows = {
          ...rows,
          [type.id]: rowConditions[type.id]
        }
      } else {
        rows = {
          ...rows,
          [type.id]: {
            id: null,
            id_player: playerMeasurements.id,
            measurement: ''
          }
        }
      }
    })

    // for(let i = measurementTypes[0].id; i <= measurementTypes[measurementTypes.length -1].id; i++) {
    //   if (rowConditions[i]) {
    //     rows = {
    //       ...rows,
    //       [i]: rowConditions[i]
    //     }
    //   } else {
    //     rows = {
    //       ...rows,
    //       [i]: {
    //         id: null,
    //         id_player: playerMeasurements.id,
    //         measurement: ''
    //       }
    //     }
    //   }
    // }

    return rows
  }

  const columns = React.useMemo(() => [
    {
      Header: ' ',
      sticky: 'left',
      className: 'player',
      columns: [
        {
          Header: 'Deportista',
          accessor: 'player',
          className: 'player',
          Cell: function playerName({ cell: { value } }) {
            return(
              <Link to={`/player-info/${filters.id_category_type}/${value.id}/daily-measurements`}>
                <UserImage userId={value.userId} userGender={value.gender} imageWidth="30px" />
                {value.name}
              </Link>
            )
          }
        },
      ]
    },
    {
      Header: windowDate.name,
      columns: getMeasurementTypes.data?.getMeasurementTypes.measurementTypes.map(type => (
        {
          Header: type.name,
          accessor: type.id,
          Cell: function measurementValue({ cell: { value, column } }) {
            return (
              <MeasurementInput
                measurement={{
                  id: value?.id,
                  id_player: parseInt(value?.id_player, 10),
                  measurement_type: parseInt(column.id),
                  value: value?.measurement,
                  date: windowDate.completeDate
                }}
                filters={filters}
              />
            )
          }
        }
      ))
    },
  ], [getMeasurementTypes, windowDate, filters])

  const renderTable = () => {
    if (loading || getMeasurementTypes.loading) {
      return (
        <Loading />
      )
    }

    if (error || getMeasurementTypes.error) {
      return (
        <div>Error :(</div>
      )
    }

    let tableData = []
    const { measurementTypes } = getMeasurementTypes.data.getMeasurementTypes
    if (data && getMeasurementTypes.data) {
      tableData = data.getDailyMeasurements.playersMeasurements.reduce((acc, playerMeasurements) => {
        const values = getRows(playerMeasurements, measurementTypes)
        return [
          ...acc,
          {
            player: {
              id: playerMeasurements.id,
              name: `${playerMeasurements.User.last_name} ${playerMeasurements.User.name} `,
              gender: playerMeasurements.User.gender,
              userId: playerMeasurements.User.id
            },
            ...values
          }
        ]
      }, [])

      // let columns = []
      // if (getMeasurementTypes.data) {
      //   columns = columns(measurementTypes)
      // }

      return (
        <StickyTable
          columns={columns}
          data={tableData}
          getCellProps={cellInfo => ({
            style: {
              background: cellInfo.row.index % 2 === 0 ? '#e0e6f2' : '#ffffff',
            },
          })}
        />
      )
    }
  }

  return (
    <Styles>
      <CustomBreadcrumb
        active="GPS"
      />
      <FilterRow>
        <CategoryFilter
          value={filters.id_category_type}
          setValue={setFilters}
        />
        <MeasurementMomentFilter
          value={filters.measurement_moment}
          setValue={setFilters}
        />
      </FilterRow>

      <div className="col-12 mb-3">
        <div className="d-flex justify-content-between">
          <div className="rbc-toolbar">
            <span className="rbc-btn-group">
              <button type="button" onClick={() => handleDay('PREV')}>
                <FaArrowLeft size={15}/>
              </button>
              <button type="button" onClick={() => handleDay('TODAY')}>
                Hoy
              </button>
              {!moment(windowDate.completeDate).isSame(moment().format('YYYY-MM-DD')) &&
                <button type="button" onClick={() => handleDay('NEXT')}>
                  <FaArrowRight size={15}/>
                </button>
              }
            </span>
          </div>
        </div>
        {renderTable()}
      </div>

    </Styles>
  )
}

export default DailyMeasurements
