import React from 'react'
import { useHistory } from 'react-router-dom'
// import { Toast } from 'react-bootstrap'
import { appStore } from '../../store/appStore'
// import { FiAlertTriangle } from 'react-icons/fi'
import Styles from './AnalyticsStyle'

const Analytics = () => {
  let history = useHistory()
  const { state: { tenant, user } } = React.useContext(appStore)

  // const [show, setShow] = React.useState(false)

  React.useEffect(() => {
    // setTimeout(() => setShow(true), 300)
  }, [])

  if (!tenant) {
    return (
      <div onClick={history.goBack()} />
    )
  }

  return (
    <Styles className="m-0 p-0 body">
      {/* <Toast
        autohide
        animation
        delay={10000}
        show={show}
        onClose={() => setShow(false)}
        style={{
          position: 'absolute',
          top: '25%',
          right: '43%'
        }}
      >
        <Toast.Header style={{ justifyContent: 'space-between' }}>
          <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
          <div>
            <FiAlertTriangle size={24} style={{ marginBottom: 4 }} />
            <strong className="me-auto" style={{ padding: 8, fontSize: 18 }}>IMPORTANTE</strong>
          </div>
        </Toast.Header>
        <Toast.Body style={{ fontSize: 22 }}>Cargando la biblioteca de datos. Esto puede tardar un momento.</Toast.Body>
      </Toast> */}
      <iframe
        className="responsive-iframe"
        src={`${tenant.shinny_url}?benutzer=${user.id}`}
      />
    </Styles>
  )
}

export default Analytics
