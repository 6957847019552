import { gql } from '@apollo/client'

const GET_TEAM_KINESIC_TREATMENTS = gql`
  query getTeamKinesicTreatments($input:TeamKinesicTreatmentsInput!){
    getTeamKinesicTreatments (input: $input) {
      teamKinesicTreatments {
        id
        id_category_type
        User {
          id
          name
          last_name
          gender
        }
        PlayerDimensions {
          id
          meters
          meters_flat
          date
          id_diagnostic
          KinesicTreatment {
            id
            id_diagnostic
            id_player_dimension
          }
        }
        ClinicalEvents {
          id
          active
          Diagnostics {
            id,
            id_diagnostic_type
            id_pathology
            DiagnosticType {
              id
              name
            }
            Pathology {
              id
              name
            }
          }
        }
    }
    status {
      success
      code
      message
    }
  }
}

`
export default GET_TEAM_KINESIC_TREATMENTS

