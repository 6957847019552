import { gql } from '@apollo/client'

const GET_GPS = gql`
  query getGps($input: GpsInput) {
    getGps(input: $input) {
      gps {
        id
        id_gps_grouper
        name
        excel_column
        required_graphic
        tenant_code
        GpsGrouper {
          id
          name
        }
        GpsCategoryTypes {
          id
          id_gps
          id_category_type
          CategoryType {
            id
            name_category
          }
        }
      }
      status {
        success
        code
        message
      }
    }
  }
`
export default GET_GPS

