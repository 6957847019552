import { gql } from '@apollo/client'

const GET_AVAILABILITY_CONDITIONS = gql`
query getAvailabilityConditions($input: AvailabilityConditionInput!) {
    getAvailabilityConditions(input: $input) {
      playersConditions {
        id
        id_category_type
        AvailabilityConditions {
          id
          id_availability_condition_type
          date
          createdAt
          AvailabilityConditionType {
            id
            name_availability_condition_type
          }
          DiagnosticAvailabilities {
            id
            Diagnostic {
              id
              ClinicalEvent {
                id
                active
              }
            }
          }
        }
        User {
          id
          name
          last_name
          gender
        }
      }
      status {
        success
        code
        message
      }
    }
  }
`

export default GET_AVAILABILITY_CONDITIONS
