import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import hasRole from '../../../../../../keycloak/utils/hasRole'
import AuthorizedElement from '../../../../../../keycloak/utils/AuthorizedElement'
import { Formik } from 'formik'
import { useMutation } from '@apollo/client'
import { GET_KINESIC_TREATMENTS, } from '../../../../../../apollo/queries'
import { CREATE_KINESIC_TREATMENT, UPDATE_KINESIC_TREATMENT, DELETE_KINESIC_TREATMENT } from '../../../../../../apollo/mutations'
import { Button, Form, Col, Spinner } from 'react-bootstrap'
import { FaPen, FaTrash } from 'react-icons/fa'
import Styles from './KinesicTreatmentFormStyle'

const KinesicTreatmentForm = ({
  action = 'CREATE',
  idDiagnostic,
  values = {
    date: new Date(),
    text: ''
  },
  setAdding = () => {}
}) => {
  const canUpdate = hasRole(['permission_team-update-player-kinesic-treatment'])
  const dateRef = React.useRef(null)
  const textRef = React.useRef(null)

  const [deleted, setDeleted] = React.useState(false)
  const [editing, setEditing] = React.useState(false)
  const refetchQueries = [{
    query: GET_KINESIC_TREATMENTS,
    variables: { input: { id_diagnostic: idDiagnostic } }
  }]

  React.useEffect(() => {
    function handler(event) {
      if(
        canUpdate &&
        action === 'EDIT' &&
        (dateRef.current?.contains(event.target) ||
        textRef.current?.contains(event.target))
      ) {
        setEditing(true)
      }
    }
    window.addEventListener('click', handler)
    return () => window.removeEventListener('click', handler)
  }, [])

  const [deleteTreatment] = useMutation(DELETE_KINESIC_TREATMENT)

  const [mutation, mutationResult] = useMutation(
    action === 'CREATE' ? CREATE_KINESIC_TREATMENT : UPDATE_KINESIC_TREATMENT,
    action === 'CREATE' ? { refetchQueries } : {}
  )

  const handleDelete = (event) => {
    event.preventDefault()
    if (window.confirm('Esta acción es irreversible, ¿Estás seguro de continuar?')) {
      deleteTreatment({
        variables: {
          input: {
            id: values.id
          }
        }
      }).then(({ data }) => {
        if (data?.deleteKinesicTreatment.status.success) {
          setDeleted(true)
        }
      })
    }
  }

  return (
    <Styles>
      {action === 'CREATE' && <p>Agrega un tratamiento kinesico al diagnóstico:</p>}
      {!deleted &&
        <Formik
          initialValues={values}
          validate={values => {
            const errors = {}
            if (!values.date) {
              errors.date = 'Debes ingresar una fecha.'
            }
            if (!values.text) {
              errors.text = 'Debes ingresar una descripcion.'
            }
            return errors
          }}
          onSubmit={(values, { resetForm }) => {
            const diagnostic = action === 'CREATE' ? { id_diagnostic: idDiagnostic } : {}
            mutation({
              variables: {
                input: {
                  ...values,
                  ...diagnostic,
                  date: moment(values.date).format('YYYY-MM-DD')
                }
              }
            }).then(({ data }) => {
              if (action === 'CREATE') {
                if (data.createKinesicTreatment.status.success) {
                  resetForm({ values: '' })
                  setAdding(false)
                }
              } else {
                if (data.updateKinesicTreatment.status.success) {
                  setEditing(false)
                }
              }
              // modalHeaderRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
            })
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
            setFieldValue
          }) => (
            <Form>
              <Form.Row>
                <Form.Group as={Col} className="date">
                  <Form.Label>Fecha</Form.Label>
                  {window.innerWidth > 576 ?
                    <div ref={dateRef}>
                      <DatePicker
                        showYearDropdown
                        name="date"
                        locale="es"
                        wrapperClassName="w-100"
                        dateFormat="dd/MM/yyyy"
                        readOnly={canUpdate ? false : true}
                        selected={values.date}
                        onChange={date => setFieldValue('date', date)}
                        onBlur={handleBlur}
                        customInput={
                          <Form.Control
                            required
                            as="input"
                            placeholder="Selecciona una fecha"
                            className={(action === 'EDIT' && !editing) && 'edit-input'}
                            isInvalid={touched.date && errors.date ? true : false}
                          />
                        }
                      />
                    </div>
                    :
                    <Form.Control
                      required
                      as="input"
                      type="date"
                      name="date"
                      placeholder="Selecciona una fecha"
                      plaintext={canUpdate ? false : true}
                      readOnly={canUpdate ? false : true}
                      ref={dateRef}
                      className={(action === 'EDIT' && !editing) && 'edit-input'}
                      value={
                        typeof values.date === 'string'
                          ? values.date
                          : new Date(
                            values.date - (values.date.getTimezoneOffset() * 60000)
                          ).toISOString().split('T')[0]
                      }
                      onChange={handleChange}
                      isInvalid={touched.date && errors.date ? true : false}
                    />
                  }
                  {/* {(touched.date && errors.date) &&
                          <div className="invalid-feedback d-block">
                            {errors.date}
                          </div>
                        } */}
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>Tratamiento Kinésico</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="text"
                    placeholder="Ingresa un texto"
                    plaintext={canUpdate ? false : true}
                    readOnly={canUpdate ? false : true}
                    ref={textRef}
                    rows={editing || action === 'CREATE' ? 4 : 1}
                    className={`${(action === 'EDIT' && !editing) && 'edit-input'} text`}
                    value={values.text}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      (touched.text && errors.text)
                        ? true
                        : false
                    }
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group>
                  {/* {action === 'CREATE' && <Form.Label>Acciones</Form.Label>} */}
                  {editing === true ?
                    <div style={{ marginTop: '32px' }}>
                      <AuthorizedElement roles={['permission_team-delete-player-eva-objectives']}>
                        <Button
                          type="button"
                          variant="success"
                          className="mr-2"
                          onClick={() => handleSubmit()}
                        >
                          {(mutationResult.loading ?
                            <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            /> : 'Guardar')
                          }
                        </Button>
                      </AuthorizedElement>
                      <Button
                        type="button"
                        variant="secondary"
                        onClick={() => setEditing(false)}
                      >
                        Cerrar
                      </Button>
                    </div> :

                    (action === 'CREATE' ?
                      <div style={{ marginTop: '32px' }}>
                        <Button
                          type="button"
                          variant="secondary"
                          className="mr-2"
                          onClick={() => setAdding(false)}
                        >
                          Cerrar
                        </Button>
                        <AuthorizedElement roles={['permission_team-update-player-kinesic-treatment']}>
                          <Button
                            variant="success"
                            type="button"
                            onClick={() => handleSubmit()}
                          >
                            {(mutationResult.loading ?
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              /> : 'Crear')
                            }
                          </Button>
                        </AuthorizedElement>
                      </div> :
                      <div style={{ marginTop: '32px' }}>
                        <AuthorizedElement roles={['permission_team-update-player-kinesic-treatment']}>
                          <Button
                            type="button"
                            variant="warning"
                            className="mr-2"
                            onClick={() => setEditing(true)}
                          >
                            <FaPen color="white"/>
                          </Button>
                        </AuthorizedElement>
                        <AuthorizedElement roles={['permission_team-delete-player-kinesic-treatment']}>
                          <Button
                            type="button"
                            variant="danger"
                            onClick={(event) => handleDelete(event)}
                          >
                            <FaTrash />
                          </Button>
                        </AuthorizedElement>
                      </div>
                    )
                  }
                </Form.Group>
              </Form.Row>
            </Form>
          )}
        </Formik>
      }
    </Styles>
  )
}

KinesicTreatmentForm.propTypes = {
  action: PropTypes.string,
  idDiagnostic: PropTypes.number,
  values: PropTypes.shape({
    date: PropTypes.string,
    text: PropTypes.string
  }),
  setAdding: PropTypes.func
}

export default KinesicTreatmentForm
