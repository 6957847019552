import { gql } from '@apollo/client'

const GET_SCHEDULE_CONFIG = gql`
  query getScheduleConfig {
    getScheduleConfig {
      scheduleConfig {
        active
        working_hours_start
        working_hours_end
        working_days
        schedule_duration
      }
      status {
        success
        code
        message
      }
    }
  }
`

export default GET_SCHEDULE_CONFIG
