import React from 'react'
import axios from 'axios'
import domainConfig from '../config/domains'

const useUserImage = (userId, newUserImage, customRefreshImage) => {
  const [userImage, setUserImage] = React.useState(null)
  const [refreshUserImage, setRefreshUserImage] = React.useState(false)
  const [request, setRequest] = React.useState({ status: 'NOT_SEND' })

  React.useEffect(() => {
    if (userId) {
      setRequest({ status: 'LOADING' })

      axios.get(`${domainConfig.clinicalApiUri}/user/${userId}/profile-image`, {
        responseType: 'blob'
      }).then(response => {
        if (response.data) {
          setUserImage(response.data)
          setRequest({ status: 'SUCCESS' })
        } else {
          setRequest({ status: 'IMAGE_NOT_FOUND' })
        }
      }).catch(() => {
        setRequest({ status: 'IMAGE_NOT_FOUND' })
      })
    }
  }, [refreshUserImage, customRefreshImage])

  React.useEffect(() => {
    if (userId && newUserImage.length) {
      setUserImage(null)
      setRequest({ status: 'LOADING' })

      const formData = new FormData()
      for (let i = 0; i < newUserImage.length; i++) {
        formData.append('image', newUserImage[i])
      }

      axios.put(`${domainConfig.clinicalApiUri}/user/${userId}/profile-image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(() => {
        setRefreshUserImage(!refreshUserImage)
      }).catch(() => setRequest({ status: 'PUT_ERROR' }))
    }
  }, [newUserImage])

  return {
    userImage,
    request
  }
}

export default useUserImage
