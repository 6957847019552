import { gql } from '@apollo/client'

const GET_DIAGNOSTIC_INJURY_COUNTERS = gql`
  query getDiagnosticInjuryCounters($input: DiagnosticInjuryCountersInput!) {
    getDiagnosticInjuryCounters(input: $input) {
      diagnosticInjuryCounters {
        injuries
        muscleInjuries
        jointInjuries
      }
      status {
        success
        code
        message
      }
    }
  }
`
export default GET_DIAGNOSTIC_INJURY_COUNTERS
