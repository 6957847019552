import styled from 'styled-components'
export default styled.div`

  .list-item {
    opacity: 0;
    animation: fade-in 900ms ease forwards;
  }

  .list-item:nth-child(2) {
    animation-delay: 100ms;
  }

  .list-item:nth-child(3) {
    animation-delay: 200ms;
  }

  .list-item:nth-child(4) {
    animation-delay: 300ms;
  }

  .list-item:nth-child(5) {
    animation-delay: 400ms;
  }

  .list-item:nth-child(6) {
    animation-delay: 500ms;
  }

  .list-item:nth-child(7) {
    animation-delay: 600ms;
  }

  .list-item:nth-child(8) {
    animation-delay: 700ms;
  }

  .list-item:nth-child(9) {
    animation-delay: 800ms;
  }

  .list-item:nth-child(10) {
    animation-delay: 900ms;
  }

  @keyframes fade-in {
    to {
      opacity: 1;
    }
  }

  .zoom {
    transition: transform .5s;
  }

  .zoom:hover {
    transform: scale(1.05);
  }

  .card-img-top {
    width: 100%;
    height: 25vh;
    object-fit: cover;
    cursor: pointer;
    /* object-position: 150% 0%; */
  }

`
