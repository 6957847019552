import { gql } from '@apollo/client'

const GET_MEDICNES = gql`
  {
    getMedicines {
      medicines {
        id
        name
      }
      status {
        success
        code
        message
      }
    }
  }
`

export default GET_MEDICNES

