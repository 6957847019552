import { gql } from '@apollo/client'

const GET_EVENTS_TYPES = gql`
  {
    getEventsTypes {
      eventsTypes {
        id
        name_event_type
      }
      status {
        success
      }
    }
  }
`

export default GET_EVENTS_TYPES
