import styled from 'styled-components'

export default styled.div`
  table {
    font-size:14px;
  }

  div .player {
    width: 100px;
    min-width: 100px;
    padding: 8px !important;
    background-color: #FFFFFF;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .container {
    .slick-slider {
      .slick-prev:before, .slick-next:before {
        color: #007bffc9;
        cursor: pointer;
      }
    }
  }

  .chartjs-render-monitor{
    cursor: pointer;
  }

`
