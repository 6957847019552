import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import AuthorizedElement from '../../../../keycloak/utils/AuthorizedElement'
import { useMutation } from '@apollo/client'
import { GET_AUTOREPORT } from '../../../../apollo/queries'
import { CREATE_AUTOREPORT, UPDATE_AUTOREPORT } from '../../../../apollo/mutations'
import { Formik } from 'formik'
import { Modal, Form, Button, Row, Col } from 'react-bootstrap'
import { FaCheck } from 'react-icons/fa'
import { StatusAlert, SubmitButton, DatePickerInput, MeterInput } from '../../../../components'
import { AUTOREPORT_TIMES } from '../constants'
import AutoReportInput from './AutoReportInput'
import Styles, { ModalHeaderStyle } from './AutoReportDetailStyle'

const AutoReportDetail= ({
  idPlayer,
  openModal = false,
  setOpenModal,
  action = 'CREATE',
  updateValues = {},
  autoreport,
  biomedic,
  selectedDimensionTest,
  setSelectedDimensionTest,
  filters,
  dimensionTestsByMoment,
  autoreportTime,
  setAutoreportTime
}) => {
  const formikRef = React.useRef()

  const handleClose = () => {
    setOpenModal(false)
    setAutoreportTime(null)
  }

  const [createPlayerDimension, createResult] = useMutation(
    action === 'CREATE' ? CREATE_AUTOREPORT : UPDATE_AUTOREPORT, {
      refetchQueries: [
        {
          query: GET_AUTOREPORT,
          variables: {
            input: {
              id_player: idPlayer,
              start_date: moment(filters.start_date).format('YYYY-MM-DD'),
              end_date: moment(filters.end_date).format('YYYY-MM-DD')
            }
          }
        }
      ]
    }
  )

  const modalTitle = action === 'CREATE' ? 'Crear Autoreporte': 'Editar Autoreporte'
  const successMessage = action === 'CREATE' ? 'Autoreporte creado con éxito.' : 'Autoreporte actualizado con éxito.'
  const statusResult = action === 'CREATE' ? createResult.data?.createAutoreport.status : createResult.data?.updateAutoreport.status

  if (createResult.error) {
    return (
      <div>Error :(</div>
    )
  }

  return (
    <div className="row">
      <Modal
        size="lg"
        show={openModal}
        dialogClassName="full-width"
        contentClassName="full-height"
        onHide={handleClose}
      >
        <ModalHeaderStyle>
          <Modal.Header
            closeButton
            className="bg-success text-white align-items-center justify-content-space-between"
          >
            <Modal.Title>
              <FaCheck className="mr-1" color={'white'} />
              {modalTitle}
            </Modal.Title>
            <div className="ml-auto">
              <Form.Check
                inline
                label="Entrenamiento"
                name="group1"
                type="radio"
                id={'inline-radio-1'}
                onChange={() => {
                  if (action === 'CREATE') {
                    formikRef.current?.resetForm()
                  }
                  setSelectedDimensionTest({ measurement_moment: 1 })
                }}
                checked={selectedDimensionTest.measurement_moment === 1}
              />
              <Form.Check
                inline
                label="Partido"
                name="group1"
                type="radio"
                id={'inline-radio-2'}
                onChange={() => {
                  if (action === 'CREATE') {
                    formikRef.current?.resetForm()
                  }
                  setSelectedDimensionTest({ measurement_moment: 2 })
                }}
                checked={selectedDimensionTest.measurement_moment === 2}
              />
            </div>
          </Modal.Header>
        </ModalHeaderStyle>
        <Formik
          innerRef={formikRef}
          initialValues={{
            date: new Date(),
            ...autoreport?.DimensionTests[0]?.Meters.reduce((acc, current) =>
              ({ ...acc, [current.id]: '' })
            , {}),
            ...autoreport?.DimensionTests[1]?.Meters.reduce((acc, current) =>
              ({ ...acc, [current.id]: '' })
            , {}),
            ...biomedic?.DimensionTests[0]?.Meters.reduce((acc, current) =>
              ({ ...acc, [current.id]: '' })
            , {}),
            ...biomedic?.DimensionTests[1]?.Meters.reduce((acc, current) =>
              ({ ...acc, [current.id]: '' })
            , {}),
            ...updateValues
            // ...getInitialValues(autoreport?.DimensionTests[0]?.Meters, updateValues),
            // ...getInitialValues(biomedic?.DimensionTests[0]?.Meters, updateValues)
          }}
          validate={values => {
            const errors = {}

            if (!values.date) {
              errors.date = 'Debes ingresar una fecha'
            }

            return errors
          }}
          onSubmit={(values, { resetForm }) => {
            // onSubmit(createPlayerDimension, values, resetForm)

            const ids = action === 'CREATE' ? { id_player: idPlayer } : { id_wellness: values.id_wellness, id_biomedic: values.id_biomedic }
            const dimension_test_ids = action === 'CREATE' ? { dimension_test_ids: dimensionTestsByMoment.get(selectedDimensionTest.measurement_moment) } : {}
            const time = action === 'CREATE' ? { time: autoreportTime } : {}

            const metersWellness = autoreport.DimensionTests.reduce((stack, test) => ({
              ...stack,
              [test.id]: {
                id: test.id,
                name: test.name,
                meters: test.Meters.reduce((acc, meter) =>
                  ({
                    ...acc,
                    [meter.id]: {
                      id: parseInt(meter.id, 10),
                      name: meter.name,
                      value: values[meter.id] ||''
                    }
                  })
                , {})
              }
            }), {})

            const metersBiomedic = biomedic.DimensionTests.reduce((stack, test) => ({
              ...stack,
              [test.id]: {
                id: test.id,
                name: test.name,
                meters: test.Meters.reduce((acc, meter) =>
                  ({
                    ...acc,
                    [meter.id]: {
                      id: parseInt(meter.id, 10),
                      name: meter.name,
                      value: values[meter.id] ||''
                    }
                  })
                , {})
              }
            }), {})

            createPlayerDimension({
              variables: {
                input: {
                  ...ids,
                  ...dimension_test_ids,
                  ...time,
                  meters: {
                    wellness: JSON.stringify(metersWellness),
                    biomedic: JSON.stringify(metersBiomedic)
                  },
                  date: moment(values.date).format('YYYY-MM-DD'),
                }
              }
            }).then(({ data }) => {
              if (action === 'CREATE' && data.createAutoreport.status.success) {
                resetForm({ values: '' })
              }
            })
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
            setFieldValue,
            resetForm
          }) => (
            <Form onSubmit={handleSubmit}>
              {createResult.data &&
                <StatusAlert
                  successMessage={successMessage}
                  status={statusResult}
                />
              }
              <Styles>
                <Modal.Body>
                  <div className="row">
                    <div className="col-12 col-lg-6">
                      <DatePickerInput
                        label="Fecha"
                        name="date"
                        value={values.date}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        touched={touched.date ? true : false}
                        error={errors.date}
                      />
                      <Row>
                        <AuthorizedElement roles={['permission_team-create-autoreport', 'permission_team-update-autoreport']}>
                          {/* Peso Inicial */}
                          {autoreportTime.includes(AUTOREPORT_TIMES.checkIn) && (
                            <MeterInput
                              meter={autoreport?.DimensionTests[selectedDimensionTest.measurement_moment-1]?.Meters.find(
                                m => m.id === (selectedDimensionTest.measurement_moment === 1 ? '220' : '221')
                              )}
                            />
                          )}
                          {/* Peso Final */}
                          {autoreportTime.includes(AUTOREPORT_TIMES.checkOut) && (
                            <MeterInput
                              meter={autoreport?.DimensionTests[selectedDimensionTest.measurement_moment-1]?.Meters.find(
                                m => m.id === (selectedDimensionTest.measurement_moment === 1 ? '222' : '223')
                              )}
                            />
                          )}
                        </AuthorizedElement>
                      </Row>
                      {autoreportTime.includes(AUTOREPORT_TIMES.checkIn) && (
                        <Row>
                          <AuthorizedElement roles={['permission_team-create-biomedical', 'permission_team-update-biomedical']}>
                            {/* Orina */}
                            <MeterInput
                              meter={biomedic?.DimensionTests[selectedDimensionTest.measurement_moment-1]?.Meters.find(
                                m => m.id === (selectedDimensionTest.measurement_moment === 1 ? '136' : '141')
                              )}
                            />
                            {/* CK */}
                            <MeterInput
                              meter={biomedic?.DimensionTests[selectedDimensionTest.measurement_moment-1]?.Meters.find(
                                m => m.id === (selectedDimensionTest.measurement_moment === 1 ? '137' : '142')
                              )}
                            />
                          </AuthorizedElement>
                        </Row>
                      )}
                      <Row>
                        {/* Pcr */}
                        {autoreportTime.includes(AUTOREPORT_TIMES.checkIn) && (
                          <AuthorizedElement roles={['permission_team-create-biomedical', 'permission_team-update-biomedical']}>
                            <MeterInput
                              meter={biomedic?.DimensionTests[selectedDimensionTest.measurement_moment-1]?.Meters.find(
                                m => m.id === (selectedDimensionTest.measurement_moment === 1 ? '138' : '143')
                              )}
                            />
                          </AuthorizedElement>
                        )}
                        {/* Minutos de exposicion */}
                        {autoreportTime.includes(AUTOREPORT_TIMES.checkOut) && (
                          <AuthorizedElement roles={['permission_team-create-autoreport', 'permission_team-update-autoreport']}>
                            <MeterInput
                              meter={autoreport?.DimensionTests[selectedDimensionTest.measurement_moment-1]?.Meters.find(
                                m => m.id === (selectedDimensionTest.measurement_moment === 1 ? '224' : '225')
                              )}
                            />
                          </AuthorizedElement>
                        )}
                      </Row>
                      {autoreportTime.includes(AUTOREPORT_TIMES.checkIn) && (
                        <Row>
                          <AuthorizedElement roles={['permission_team-create-autoreport', 'permission_team-update-autoreport']}>
                            <Form.Group as={Col} className="mb-3" controlId="textarea">
                              <Form.Label>
                                {autoreport?.DimensionTests[selectedDimensionTest.measurement_moment-1]?.Meters.find(
                                  m => m.id === (selectedDimensionTest.measurement_moment === 1 ? '218' : '219')
                                )?.name}
                              </Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                name={autoreport?.DimensionTests[selectedDimensionTest.measurement_moment-1]?.Meters.find(
                                  m => m.id === (selectedDimensionTest.measurement_moment === 1 ? '218' : '219'))?.id
                                }
                                value={values[autoreport?.DimensionTests[selectedDimensionTest.measurement_moment-1]?.Meters.find(
                                  m => m.id === (selectedDimensionTest.measurement_moment === 1 ? '218' : '219'))?.id
                                ]}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Form.Group>
                          </AuthorizedElement>
                        </Row>
                      )}
                    </div>
                    <div className="col-12 col-lg-6">
                      <AuthorizedElement roles={['permission_team-create-autoreport', 'permission_team-update-autoreport']}>
                        {autoreportTime.includes(AUTOREPORT_TIMES.checkIn) && (
                          <>
                            <AutoReportInput
                              meter={autoreport?.DimensionTests[selectedDimensionTest.measurement_moment-1]?.Meters.find(
                                m => m.id === (selectedDimensionTest.measurement_moment === 1 ? '1' : '211')
                              )}
                            />
                            <AutoReportInput
                              meter={autoreport?.DimensionTests[selectedDimensionTest.measurement_moment-1]?.Meters.find(
                                m => m.id === (selectedDimensionTest.measurement_moment === 1 ? '3' : '212')
                              )}
                            />
                            <AutoReportInput
                              meter={autoreport?.DimensionTests[selectedDimensionTest.measurement_moment-1]?.Meters.find(
                                m => m.id === (selectedDimensionTest.measurement_moment === 1 ? '4' : '213')
                              )}
                            />
                            <AutoReportInput
                              meter={autoreport?.DimensionTests[selectedDimensionTest.measurement_moment-1]?.Meters.find(
                                m => m.id === (selectedDimensionTest.measurement_moment === 1 ? '5' : '214')
                              )}
                            />
                            {/* TQR */}
                            <AutoReportInput
                              meter={autoreport?.DimensionTests[selectedDimensionTest.measurement_moment-1]?.Meters.find(
                                m => m.id === (selectedDimensionTest.measurement_moment === 1 ? '216' : '217')
                              )}
                              styleId="TQR"
                            />
                          </>
                        )}
                        {autoreportTime.includes(AUTOREPORT_TIMES.checkOut) && (
                          <AutoReportInput
                            meter={autoreport?.DimensionTests[selectedDimensionTest.measurement_moment-1]?.Meters.find(
                              m => m.id === (selectedDimensionTest.measurement_moment === 1 ? '6' : '215')
                            )}
                          />
                        )}
                      </AuthorizedElement>
                    </div>
                  </div>
                </Modal.Body>
              </Styles>
              <Modal.Footer>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={handleClose}
                >
                  {'Cerrar'}
                </Button>
                <Button
                  type="button"
                  variant="none"
                  className="btn-outline-success list-item"
                  onClick={() => resetForm({ values: '' })}
                >
                  {'Limpiar'}
                </Button>
                <SubmitButton
                  action={action}
                  loading={createResult.loading}
                  className="btn list-item px-6"
                />
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  )
}

AutoReportDetail.propTypes = {
  idPlayer: PropTypes.number.isRequired,
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func.isRequired,
  action: PropTypes.string,
  updateValues: PropTypes.object,
  autoreport: PropTypes.shape({
    DimensionTests: PropTypes.arrayOf(
      PropTypes.shape({
        Meters: PropTypes.array
      })
    )
  }).isRequired,
  biomedic: PropTypes.shape({
    DimensionTests: PropTypes.arrayOf(
      PropTypes.shape({
        Meters: PropTypes.array
      })
    )
  }).isRequired,
  selectedDimensionTest: PropTypes.shape({
    measurement_moment: PropTypes.number
  }),
  setSelectedDimensionTest: PropTypes.func,
  filters: PropTypes.shape({
    start_date: PropTypes.instanceOf(Date),
    end_date: PropTypes.instanceOf(Date)
  }),
  dimensionTestsByMoment: PropTypes.instanceOf(Map),
  autoreportTime: PropTypes.arrayOf([
    PropTypes.string
  ]),
  setAutoreportTime: PropTypes.func
}

export default AutoReportDetail
