import styled from 'styled-components'

export default styled.div`
  .success {
    border: green 2px solid;
  }

  .error {
    border: red 2px solid;
  }
`
