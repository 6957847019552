import React from 'react'
import PropTypes from 'prop-types'
import ReactToolTip from 'react-tooltip'
import AuthorizedElement from '../../../../../../keycloak/utils/AuthorizedElement'
import { Button } from 'react-bootstrap'
import constants from '../../../../../../config/constants'

const KinesicTreatmentButton = ({ roles, onClick }) => {
  return (
    <AuthorizedElement roles={roles}>
      <Button
        variant="info"
        className="mr-2"
        data-tip data-for="kinesic"
        onClick={onClick}
      >
        <img
          width={20}
          src={
            `${constants.STATIC_IMAGES_URL}/kine-icon.svg`
          }
        />
        <ReactToolTip
          id="kinesic"
          place="top"
          effect="solid"
          type="info"
        >
          {'Tratamiento Kinésico'}
        </ReactToolTip>
      </Button>
    </AuthorizedElement>
  )
}

KinesicTreatmentButton.propTypes = {
  roles: PropTypes.arrayOf(['string']),
  onClick: PropTypes.func
}

export default KinesicTreatmentButton
