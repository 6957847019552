import React, { useRef } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Modal, Button, Spinner } from 'react-bootstrap'
import { FaCalendarDay } from 'react-icons/fa'
import { useMutation } from '@apollo/client'
import { GET_SCHEDULES } from '../../../../apollo/queries'
import { DELETE_BLOCK_SCHEDULE } from '../../../../apollo/mutations'
import { StatusAlert } from '../../../../components'
import Styles from './ScheduleBlockInfoStyle'

const ScheduleBlockInfo = ({ schedule = {}, openModal, setOpenModal }) => {
  const modalHeaderRef = useRef(null)

  const [deleteBlockSchedule, deleteResults] = useMutation(DELETE_BLOCK_SCHEDULE, {
    refetchQueries: [
      { query: GET_SCHEDULES }
    ]
  })

  const handleDeleteSchedule = (event) => {
    event.preventDefault()
    deleteBlockSchedule({
      variables: {
        input: {
          id: schedule.id
        }
      }
    })
  }

  const handleClose = () => setOpenModal(false)

  if (deleteResults.error) {
    return (
      <div>Error :(</div>
    )
  }

  return (
    <Modal
      size="lg"
      show={openModal}
      onHide={handleClose}
      dialogClassName="full-width"
      contentClassName="full-height"
    >
      <Styles>
        <Modal.Header closeButton ref={modalHeaderRef} className="bg-success text-white">
          <Modal.Title>
            <FaCalendarDay className="mr-1 mb-2" color={'white'} />
            {'No Disponible'}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="pb-0">
          {deleteResults.data ? (
            <StatusAlert
              successMessage={'Bloqueo borrado con exito.'}
              status={deleteResults.data.deleteBlockSchedule.status}
            />
          ) : (
            <>
              <h4>Desde {moment(schedule.start).calendar()} - Hasta {moment(schedule.end).calendar()}</h4>
              <p>No estas disponible para recibir consultas en este horario.</p>
            </>
          )}
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-end">
          <Button
            variant="secondary"
            onClick={() => handleClose()}
          >
            {'Cerrar'}
          </Button>
          {!deleteResults?.data?.deleteBlockSchedule.status.success && (
            <Button
              variant="outline-danger"
              onClick={(event) => handleDeleteSchedule(event)}
            >
              {deleteResults.loading ?
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                : ('Borrar')
              }
            </Button>
          )}
        </Modal.Footer>
      </Styles>
    </Modal>
  )
}

ScheduleBlockInfo.propTypes = {
  schedule: PropTypes.shape({}),
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired
}

export default ScheduleBlockInfo
