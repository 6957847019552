import { gql } from '@apollo/client'

const GET_PLAYERS_DIMENSIONS = gql`
  query getPlayersDimensions($input: PlayersDimensionsInput!) {
    getPlayersDimensions(input: $input) {
      players {
        id
        id_category_type
        id_position_type
        User {
          id
          name
          last_name
          gender
        }
        PlayerDimensions {
          date
          meters
        }
      }
      status {
        success
        code
        message
      }
    }
  }
`

export default GET_PLAYERS_DIMENSIONS
