import styled from 'styled-components'

export default styled.div`

  /* ----------------------------------------------
  * Generated by Animista on 2021-3-30 17:4:5
  * Licensed under FreeBSD License.
  * See http://animista.net/license for more info.
  * w: http://animista.net, t: @cssanimista
  * ---------------------------------------------- */

  /**
  * ----------------------------------------
  * animation scale-up-center
  * ----------------------------------------
  */

  @-webkit-keyframes scale-up-center {
      0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
      }
      100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      }
    }
    @keyframes scale-up-center {
      0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
      }
      100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      }
    }

    /* Fade in */

    .fadeInUp {
      -webkit-animation-name: fadeInUp;
      animation-name: fadeInUp;
      -webkit-animation-duration: 1s;
      animation-duration: 1s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
    }

    @-webkit-keyframes fadeInUp {
      0% {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
      }
      100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
          }
      }
      @keyframes fadeInUp {
        0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
      }
      100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
      }
    }

    .scale-up-center {
	  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    }



    .zoom {
    transition: transform .5s;
    }

    .zoom:hover {
    transform: scale( 1.05);
    cursor: pointer;
    }

    table tbody tr {
      cursor: pointer;
    }

    table thead, tbody tr {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .comments{
      text-align: justify;
      height: auto;
      word-wrap: break-word;
    }

    .bg-info-nutrition{
      background-color: #e0e6f2;
      cursor: pointer;
    }

    h5, .text-secondary {
      color: #6c757d!important;
    }

    table tr:nth-child(odd) td{
      background: #e0e6f2;
    }

    table tr:nth-child(even) td{
      background:#ffffff;
    }

    .table-title{
      color: #4e7ace;
      font-weight: bold;
    }

    label{
      background-color: #28a745;
      color: white;
      padding: 0 10px;
      width: 100%;
    }

  .form-label{
    background-color: #28a745;
    color: white;
    padding: 0 10px;
    width: 100%;
  }
`
