import { gql } from '@apollo/client'

const CREATE_MASSIVE_KINESIC_TREATMENT = gql`
  mutation createMassiveKinesicTreatment($input: CreateMassiveKinesicTreatmentInput!) {
    createMassiveKinesicTreatment(input: $input) {
      status {
        success
        code
        message
      }
    }
  }
`

export default CREATE_MASSIVE_KINESIC_TREATMENT
