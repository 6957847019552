import { useMutation } from '@apollo/client'

const useDeleteMutation = (mutation, update) => {
  const [deleteMutation, deleteResult] = useMutation(mutation, {
    update
  })

  const handleDelete = (event, id) => {
    event.preventDefault()
    if (window.confirm('Esta acción es irreversible, ¿Estás seguro de continuar?')) {
      deleteMutation({
        variables: {
          input: { id }
        }
      })
    }
  }

  return {
    deleteResult,
    handleDelete
  }
}

export default useDeleteMutation
