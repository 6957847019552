import { gql } from '@apollo/client'

const GET_MASSIVE_LOADS = gql`
  query getMassiveLoads {
    getMassiveLoads {
      massiveLoads {
        id
        success
        createdAt
        MassiveLoadConfig {
          name
        }
        User {
          name
          last_name
        }
      }
      status {
        success
        code
        message
      }
    }
  }
`

export default GET_MASSIVE_LOADS
