import moment from 'moment'
import { ClinicalEventSelect, PlayerCell } from '../../components'

const useKinesicTreatment = ({
  allMeters = [],
  teamKinesicTreatments = [],
  InputCell,
  date,
}) => {
  const columns = [{
    Header: ' ',
    sticky: 'left',
    className: 'player',
    columns: [
      {
        Header: 'Deportista',
        accessor: 'player',
        className: 'player',
        Cell: ({ cell: { value } }) => PlayerCell({ ...value, url: `/player-info/${value.idCategoryType}/${value.playerId}/therapeuctic-actions` })
      },
    ]
  },
  {
    Header: moment(date).format('LL'),
    columns: [{
      Header: 'Evento Clínico',
      accessor: 'clinicalEventsColumn',
      Cell: ({ cell: { value, column } }) => {
        return (
          ClinicalEventSelect({
            clinicalEvents: value?.clinicalEvents,
            idDiagnostic: value?.id_diagnostic,
            idPlayerDimension: value?.id,
            idPlayer: value?.id_player,
            date: value?.date,
            column,
          })
        )
      }
    }
      //   handleChangeCallback:  (idDiagnostic) => handleAssociateDiagnostic({
      //     id_diagnostic: idDiagnostic,
      //     id_player_dimension: value.id,
      //     id_player: value.id_player,
      //     date: value.date
      //   }),
      //   loading: associateDiagnosticResults.loading
      // })
    ].concat(
      allMeters.map(c => (
        {
          Header: c.name,
          accessor: c.id,
          Cell: ({ cell: { value, column } }) => InputCell({ cellInfo: value, column })
        }
      ))
    )
  }]

  const data = teamKinesicTreatments.reduce((acc, { id, id_category_type, PlayerDimensions, User, ClinicalEvents }) => {
    let rows = {}
    let metersValues = {}

    try {
      metersValues = JSON.parse(PlayerDimensions[0]?.meters_flat)
    } catch (e) {
      console.warn(e)
    }

    allMeters.forEach((column) => {
      const existValue = metersValues[column.id] !== undefined
      rows = {
        ...rows,
        [column.id]: {
          id: PlayerDimensions[0]?.id,
          id_player: id,
          cellValue: existValue ? metersValues[column.id] : false,
          id_diagnostic: PlayerDimensions[0]?.id_diagnostic,
          date,
        }
      }
    })

    if (ClinicalEvents.length) {
      rows = {
        ...rows,
        clinicalEventsColumn: {
          id: PlayerDimensions[0]?.id,
          id_player: id,
          id_diagnostic: PlayerDimensions[0]?.id_diagnostic,
          date,
          clinicalEvents: ClinicalEvents
        }
      }
    }

    return [
      ...acc,
      {
        player: {
          playerId: id,
          name: `${User.last_name} ${User.name} `,
          gender: User.gender,
          userId: User.id,
          idCategoryType: id_category_type
        },
        ...rows
      }
    ]
  }, [])

  return { data, columns }
}

export default useKinesicTreatment
