import React from 'react'
import PropTypes from 'prop-types'
import { useLazyQuery } from '@apollo/client'
import { GET_DASHBOARD_SUB_DIAGNOSTICS } from '../../../apollo/queries'
import { Form } from 'react-bootstrap'
import { CustomChart } from '../../../components'
import Styles from '../DashboardClinicalEventStyle'

const InjuryTab = ({ data, filters }) => {

  const [bodyZones, setBodyZones] = React.useState([])
  const [skeletalMuscles, setSkeletalMuscles] = React.useState([])
  const [idSubDiagnostic, setIdSubDiagnostic] = React.useState(5)
  const [getSubDiagnostics, subDiagnostics] = useLazyQuery(GET_DASHBOARD_SUB_DIAGNOSTICS)

  React.useEffect(() => {
    getSubDiagnostics({
      variables: {
        input: {
          id_player: filters.id_player,
          id_category_type: filters.id_category_type,
          start_date: filters.start_date,
          end_date: filters.end_date,
          id_sub_diagnostic: 5 // LESIÓN ARTICULAR
        }
      }
    })
  }, [filters])

  React.useEffect(() => {
    if (subDiagnostics.data) {
      setBodyZones(subDiagnostics.data.getDashboardSubDiagnostics.dashboardData.bodyZones)
      if ([5, 6].includes(idSubDiagnostic)) { // ARTICULAR O MUSCULAR
        setSkeletalMuscles(subDiagnostics.data.getDashboardSubDiagnostics.dashboardData.groupers)
      } else {
        setSkeletalMuscles(subDiagnostics.data.getDashboardSubDiagnostics.dashboardData.skeletalMuscles)
      }
    }
  }, [subDiagnostics])

  return (
    <Styles>
      <div className="container">
        <div className="container">
          <div className="d-flex flex-wrap align-items-center">
            <div className="col-12 col-lg-6">
              <CustomChart
                title="Categoría"
                type="line"
                data={{
                  datasets: [{
                    label: '# Lesiones',
                    fill: false,
                    backgroundColor: 'rgb(255, 99, 132)',
                    borderColor: '#98d4ed',
                    data: data?.diagnosticTypes
                  }]
                }}
                options={
                  {
                    scales: {
                      xAxes: [{
                        type: 'time',
                        time: {
                          unit: 'day',
                        },
                      }],
                      yAxes: [
                        {
                          ticks: {
                            stepSize: 1,
                            beginAtZero: true,
                          },
                        },
                      ],
                    },
                  }
                }
              />

              <CustomChart
                title="Tipo"
                type="bar"
                data={{
                  labels: data?.subDiagnostics.reduce((acc, value) =>
                    [...acc, value.x]
                  ,[]),
                  datasets: [{
                    label: 'Lesiones',
                    data: data?.subDiagnostics.reduce((acc, value) =>
                      [...acc, value.y]
                    ,[]),
                    backgroundColor: [
                      'rgba(255, 99, 132, 0.2)',
                      'rgba(54, 162, 235, 0.2)',
                      'rgba(255, 206, 86, 0.2)',
                      'rgba(75, 192, 192, 0.2)',
                      'rgba(153, 102, 255, 0.2)',
                      'rgba(255, 159, 64, 0.2)',
                    ]
                  }]
                }}
                options={{
                  legend: {
                    display: false,
                  },
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          stepSize: 1,
                          beginAtZero: true,
                        },
                      },
                    ],
                  },
                }}
              />
            </div>
            <div className="col-12 col-lg-6">
              <div className="m-3 p-3">
                <Form.Group>
                  <Form.Control
                    as="select"
                    placeholder="Categoría"
                    defaultValue="1"
                    onChange={(e) => {
                      setIdSubDiagnostic(parseInt(e.target.value))
                      getSubDiagnostics({
                        variables: {
                          input: {
                            id_player: filters.id_player,
                            id_category_type: filters.id_category_type,
                            id_sub_diagnostic: parseInt(e.target.value)
                          }
                        },
                      })
                    }}
                  >
                    <option value="5">Articular</option>
                    <option value="6">Muscular Fascia</option>
                    <option value="10">Óseo</option>
                    <option value="13">Tendones</option>
                  </Form.Control>
                </Form.Group>
                {subDiagnostics.loading ?
                  'Cargando...' :
                  bodyZones.length > 0 ?
                    <CustomChart
                      title="Zona Corporal"
                      type="pie"
                      data={{
                        labels: bodyZones.reduce((acc, value) =>
                          [...acc, value.x]
                        ,[]),
                        datasets: [{
                          label: 'Molestias',
                          data: bodyZones.reduce((acc, value) =>
                            [...acc, value.y]
                          ,[]),
                          backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)',
                          ],
                          borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                          ],
                          borderWidth: 1,
                        }]
                      }}
                      options={{
                        legend: {
                          labels: {
                            boxHeight: 4,
                            boxWidth: 4,
                            fontSize: 10,
                          }
                        }
                      }}
                    /> : 'No existen zonas coporales'
                }
              </div>
              <div className="m-3 p-3">
                {subDiagnostics.loading ?
                  'Cargando...' :
                  skeletalMuscles?.length > 0 ?
                    <CustomChart
                      title="Complemento II"
                      type="pie"
                      data={{
                        labels: skeletalMuscles.reduce((acc, value) =>
                          [...acc, value.x]
                        ,[]),
                        datasets: [{
                          label: 'Molestias',
                          data: skeletalMuscles.reduce((acc, value) =>
                            [...acc, value.y]
                          ,[]),
                          backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)',
                          ],
                          borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                          ],
                          borderWidth: 1,
                        }]
                      }}
                      options={{
                        legend: {
                          labels: {
                            boxHeight: 4,
                            boxWidth: 4,
                            fontSize: 10,
                          }
                        }
                      }}
                    /> : 'No existen complementos'
                }
              </div>
            </div>
          </div>
        </div>
      </div>

    </Styles>
  )
}

InjuryTab.propTypes = {
  data: PropTypes.shape({
    diagnosticTypes: PropTypes.array.isRequired,
    subDiagnostics: PropTypes.array.isRequired
  }),
  filters: PropTypes.shape({
    id_player: PropTypes.number,
    id_category_type: PropTypes.number,
    start_date: PropTypes.instanceOf(Date),
    end_date: PropTypes.instanceOf(Date)
  }).isRequired
}

export default InjuryTab
