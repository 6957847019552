import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import AuthorizedElement from '../../../keycloak/utils/AuthorizedElement'
import { useHistory, useLocation } from 'react-router-dom'
import { Button, Table, Container, Row, Col } from 'react-bootstrap'
import { BiomechanicsContext } from './BiomechanicsContext'
import BiomechanicsChart from './BiomechanicsChart'

const BiomechanicsDetails = () => {
  let history = useHistory()
  const { pathname } = useLocation()

  const { currentDimension, formValues, setOpenModal } = React.useContext(BiomechanicsContext)

  const getChartData = (dimensionId) => {
    switch (dimensionId) {
    case 3:
      return(
        <BiomechanicsChart
          title={'Asimetria %'}
          labels={
            currentDimension.dimensionTests.find(test => test.id === '9').Meters.map(meter => meter.name)
          }
          data={[formValues[36], formValues[37], formValues[38]]}
        />
      )

    case 4:
      return(
        <BiomechanicsChart
          title={'Valor N'}
          labels={[
            `${currentDimension.dimensionTests.find(test => test.id === '10').Meters.find(meter => meter.id === '39').name} ${currentDimension.dimensionTests.find(test => test.id === '10').name}`,
            `${currentDimension.dimensionTests.find(test => test.id === '11').Meters.find(meter => meter.id === '41').name} ${currentDimension.dimensionTests.find(test => test.id === '11').name}`,
            currentDimension.dimensionTests.find(test => test.id === '12').Meters.find(meter => meter.id === '43').name,
          ]}
          data={[formValues[39], formValues[41], formValues[43]]}
        />
      )

    case 5:
      return(
        <>
          <Row>
            <Col>
              <BiomechanicsChart
                title={'Isquiotibiales'}
                labels={[
                  'Derecha 30º valor N/m',
                  'Izquierda 30º valor N/m',
                  '30º asimetria %',
                  '30º relación Qcps/lqt'
                // currentDimension.dimensionTests.find(test => test.id === '13').Meters.find(meter => meter.id === '44').name,
                // currentDimension.dimensionTests.find(test => test.id === '14').Meters.find(meter => meter.id === '46').name,
                // currentDimension.dimensionTests.find(test => test.id === '15').Meters.find(meter => meter.id === '48').name,
                // currentDimension.dimensionTests.find(test => test.id === '15').Meters.find(meter => meter.id === '50').name
                ]}
                data={[formValues[44], formValues[46], formValues[48], formValues[50]]}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <BiomechanicsChart
                title={'Cuádriceps'}
                labels={[
                  'Derecha 60º valor N/m',
                  'Izquierda 60º valor N/m',
                  '60º asimetria %',
                  '60º relación Qcps/lqt'
                // currentDimension.dimensionTests.find(test => test.id === '13').Meters.find(meter => meter.id === '45').name,
                // currentDimension.dimensionTests.find(test => test.id === '14').Meters.find(meter => meter.id === '47').name,
                // currentDimension.dimensionTests.find(test => test.id === '15').Meters.find(meter => meter.id === '49').name,
                // currentDimension.dimensionTests.find(test => test.id === '15').Meters.find(meter => meter.id === '51').name
                ]}
                data={[formValues[45], formValues[47], formValues[49], formValues[51]]}
              />
            </Col>
          </Row>
        </>
      )

    case 9:
      return(
        <>
          <Row>
            <Col>
              <BiomechanicsChart
                title={currentDimension.dimensionTests.find(test => test.id === '22').Meters.find(meter => meter.id === '94').name}
                labels={[
                  `${currentDimension.dimensionTests.find(test => test.id === '22').Meters.find(meter => meter.id === '94').name} ${currentDimension.dimensionTests.find(test => test.id === '22').name}`,
                  `${currentDimension.dimensionTests.find(test => test.id === '23').Meters.find(meter => meter.id === '98').name} ${currentDimension.dimensionTests.find(test => test.id === '23').name}`,
                  currentDimension.dimensionTests.find(test => test.id === '24').Meters.find(meter => meter.id === '102').name,
                ]}
                data={[formValues[94], formValues[98], formValues[102]]}
              />
            </Col>
            <Col>
              <BiomechanicsChart
                title={currentDimension.dimensionTests.find(test => test.id === '22').Meters.find(meter => meter.id === '95').name}
                labels={[
                  `${currentDimension.dimensionTests.find(test => test.id === '22').Meters.find(meter => meter.id === '95').name} ${currentDimension.dimensionTests.find(test => test.id === '22').name}`,
                  `${currentDimension.dimensionTests.find(test => test.id === '23').Meters.find(meter => meter.id === '99').name} ${currentDimension.dimensionTests.find(test => test.id === '23').name}`,
                  currentDimension.dimensionTests.find(test => test.id === '24').Meters.find(meter => meter.id === '103').name,
                ]}
                data={[formValues[95], formValues[99], formValues[103]]}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <BiomechanicsChart
                title={currentDimension.dimensionTests.find(test => test.id === '22').Meters.find(meter => meter.id === '96').name}
                labels={[
                  `${currentDimension.dimensionTests.find(test => test.id === '22').Meters.find(meter => meter.id === '96').name} ${currentDimension.dimensionTests.find(test => test.id === '22').name}`,
                  `${currentDimension.dimensionTests.find(test => test.id === '23').Meters.find(meter => meter.id === '100').name} ${currentDimension.dimensionTests.find(test => test.id === '23').name}`,
                  currentDimension.dimensionTests.find(test => test.id === '24').Meters.find(meter => meter.id === '226').name,
                ]}
                data={[formValues[96], formValues[100], formValues[226]]}
              />
            </Col>
            <Col>
              <BiomechanicsChart
                title={currentDimension.dimensionTests.find(test => test.id === '22').Meters.find(meter => meter.id === '97').name}
                labels={[
                  `${currentDimension.dimensionTests.find(test => test.id === '22').Meters.find(meter => meter.id === '97').name} ${currentDimension.dimensionTests.find(test => test.id === '22').name}`,
                  `${currentDimension.dimensionTests.find(test => test.id === '23').Meters.find(meter => meter.id === '101').name} ${currentDimension.dimensionTests.find(test => test.id === '23').name}`,
                  currentDimension.dimensionTests.find(test => test.id === '24').Meters.find(meter => meter.id === '227').name,
                ]}
                data={[formValues[97], formValues[101], formValues[227]]}
              />
            </Col>
          </Row>
          <Container>
            <Row>
              <Col>
                <BiomechanicsChart
                  title={'Relación'}
                  labels={[
                    currentDimension.dimensionTests.find(test => test.id === '24').Meters.find(meter => meter.id === '104').name,
                    currentDimension.dimensionTests.find(test => test.id === '24').Meters.find(meter => meter.id === '105').name,
                  ]}
                  data={[formValues[104], formValues[105]]}
                />
              </Col>
            </Row>
          </Container>
        </>
      )

    default:
      return null
    }
  }

  if (!currentDimension.dimensionTests.length) {
    history.replace(pathname)
  }

  return (
    <>
      <div className="row">
        <div className="col d-flex align-items-baseline justify-content-end mb-2">
          <h4 className="mr-auto">
            {currentDimension.name} | {moment(formValues.date).format('YYYY-MM-DD')}
          </h4>
          <AuthorizedElement roles={['permission_team-update-biomechanics']}>
            <Button
              className="ml-1"
              onClick={() => {
                setOpenModal(true)
              }}
            >
              {'Editar'}
            </Button>
          </AuthorizedElement>
        </div>
      </div>

      {currentDimension.dimensionTests?.map(test =>
        <div key={test.id} className="row">
          <div className="col">
            <h5 className="mr-auto">
              {test.name}
            </h5>
            <Table striped bordered hover>
              <thead>
                <tr>
                  {test.Meters.map(meter =>
                    <th key={meter.id}>{meter.name}</th>
                  )}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {test.Meters.map(meter =>
                    <td key={meter.id}>{formValues[meter.id] || '--'}</td>
                  )}
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      )}
      {getChartData(currentDimension.id)}
    </>
  )
}

BiomechanicsDetails.propTypes = {
  idPlayer: PropTypes.number,
}

export default BiomechanicsDetails

