import styled from 'styled-components'

export default styled.div`
  .navbar {
    height: 56px;
    justify-content: flex-end;
    box-shadow: 0 4px 2px -2px rgba(0,0,0,.2);
  }

  .search-player {
    width: 20%;
  }

  @media only screen and (max-width: 767px) {
    .navbar {
      justify-content: space-between;
    }
    .search-player {
      width: 50%;
    }
  }
`
