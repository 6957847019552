import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { GET_PLAYER_KINESIC_EVALUATIONS } from '../../../../apollo/queries'
import { CREATE_PLAYER_KINESIC_EVALUATION, UPDATE_PLAYER_KINESIC_EVALUATION } from '../../../../apollo/mutations'
import { Modal, Form, Button } from 'react-bootstrap'
import { FaCheck } from 'react-icons/fa'
import { SubmitButton, DatePickerInput, StatusAlert } from '../../../../components'
import Styles from './KinesicEvaluationModalStyle'

const KinesicEvaluationsDetailModal = ({
  openModal,
  setOpenModal,
  formValues = {
    date: new Date()
  },
  kinesicBlockid,
  kinesicBlockName,
  kinesicBlockMeasures,
  action = 'CREATE',
  setAction
}) => {
  const modalHeaderRef = React.useRef(null)
  let { id_player } = useParams()
  const handleClose = () => { setOpenModal(false); setAction('CREATE') }
  const modalTitle = `${(action === 'CREATE' ? 'Agregar': 'Editar')} ${kinesicBlockName}`

  const updateCache = (cache, { data }) => {
    cache.modify({
      fields: {
        getPlayerKinesicEvaluations(existingPlayerKinesicEvaluations = []) {
          const newPlayerKinesicEvaluation = data.getPlayerKinesicEvaluations
          cache.writeQuery({
            query: GET_PLAYER_KINESIC_EVALUATIONS,
            data: {
              newPlayerKinesicEvaluation,
              ...existingPlayerKinesicEvaluations
            }
          })
        }
      }
    })
  }

  const [playerKinesicMutation, mutationResult] = useMutation(
    action === 'CREATE' ? CREATE_PLAYER_KINESIC_EVALUATION : UPDATE_PLAYER_KINESIC_EVALUATION, {
      update: updateCache
    }
  )

  const successMessage = `${kinesicBlockName} ${(action === 'CREATE') ? 'creado' : 'actualizado'} con éxito`

  const statusResult = action === 'CREATE'
    ? mutationResult.data?.createPlayerKinesicEvaluation?.status
    : mutationResult.data?.updatePlayerKinesicEvaluation?.status

  return (
    <Modal
      size="lg"
      show={openModal}
      dialogClassName="full-width"
      contentClassName="full-height"
      onHide={handleClose}
    >
      <Modal.Header
        closeButton
        className="bg-success text-white"
        ref={modalHeaderRef}
      >
        <Modal.Title>
          <FaCheck className="mr-1 mb-1" color={'white'} />
          {modalTitle}
        </Modal.Title>
      </Modal.Header>
      <Styles>
        <Formik
          initialValues={formValues}
          validate={values => {
            const errors = {}
            if (!values.date) {
              errors.date = 'Debes ingresar una fecha'
            }
            return errors
          }}
          onSubmit={(values, { resetForm }) => {
            const ids = action === 'CREATE' ? { id_player: parseInt(id_player, 10) } : { id: values.id }

            playerKinesicMutation({
              variables: {
                input: {
                  ...ids,
                  id_kinesic_blocks: kinesicBlockid,
                  values: JSON.stringify(values),
                  date: moment(values.date).format('YYYY-MM-DD'),
                }
              }
            }).then(({ data }) => {
              if (action === 'CREATE' && data.createPlayerKinesicEvaluation.status.success) {
                resetForm({ values: '' })
                modalHeaderRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
              }
            })
          }}
        >
          {({
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
            setFieldValue,
            handleSubmit
          }) =>
            <Form onSubmit={handleSubmit}>
              {mutationResult.data &&
                <StatusAlert
                  successMessage={successMessage}
                  status={statusResult}
                />
              }
              <Modal.Body>
                <DatePickerInput
                  label="Fecha"
                  name="date"
                  value={values.date}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  touched={touched.date ? true : false}
                  error={errors.date}
                />
                {kinesicBlockMeasures.map(KinesicBlockMeasure =>
                  <React.Fragment key={KinesicBlockMeasure.id} >
                    <p className="label">{KinesicBlockMeasure.name}</p>
                    <Form.Row >
                      {KinesicBlockMeasure.KinesicMeasureOptions.map(KinesicMesureOption =>
                        <Form.Group key= {KinesicMesureOption.id} className="col-12 col-md-4" controlId="formGridCity">
                          <Form.Label>
                            {KinesicMesureOption.name}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name={KinesicMesureOption.id}
                            placeholder="Ingrese un valor"
                            value={values[KinesicMesureOption.id]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={KinesicMesureOption.desabled || false}
                            isInvalid={
                              (touched[KinesicMesureOption.id] && errors[KinesicMesureOption.id])
                                ? true
                                : false
                            }
                          />
                        </Form.Group>
                      )}
                    </Form.Row>
                  </React.Fragment>
                )}
              </Modal.Body>

              <Modal.Footer className="d-flex justify-content-end">
                <Button variant="secondary" onClick={handleClose}>
                  {'Cerrar'}
                </Button>
                <SubmitButton
                  action={action}
                  loading={mutationResult.loading}
                  className="btn list-item px-6"
                />
              </Modal.Footer>
            </Form>
          }
        </Formik>
      </Styles>
    </Modal>
  )
}

KinesicEvaluationsDetailModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setAction: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  action: PropTypes.string,
  formValues: PropTypes.object,
  kinesicBlockid: PropTypes.string.isRequired,
  kinesicBlockName: PropTypes.string.isRequired,
  kinesicBlockMeasures: PropTypes.array.isRequired
}

export default KinesicEvaluationsDetailModal
