import { gql } from '@apollo/client'

const CREATE_PLAYER_GPS = gql`
  mutation createPlayerGps($input: CreatePlayerGpsInput!) {
    createPlayerGps(input: $input) {
      status {
        success
        code
        message
      }
    }
  }
`
export default CREATE_PLAYER_GPS

