import { gql } from '@apollo/client'

const GET_PLAYER_DIMENSIONS = gql`
  query getPlayerDimensions($input: PlayerDimensionInput!) {
    getPlayerDimensions(input: $input) {
      playerDimensions {
        id
        id_player
        date
        meters
        comments
        Player {
          id_category_type
          id_position_type
        }
        aritmeticValues {
          idDimentsionTest
          totalSumWellness
          averageWellness
          multiplicationPSE
        }
      }
      status {
        success
        code
        message
      }
    }
  }
`

export default GET_PLAYER_DIMENSIONS
