import styled from 'styled-components'

export default styled.div`

  table{
    font-size:14px;
  }

  .filter-btn {
    position: initial;
  }

  .exams-link a {
    color: #007bff;
  }

  .table-striped {
    background-color: #f2f2f2;
  }

  .table td, .table th {
    vertical-align: inherit;
  }

  .filters div:nth-child(2) select{
    margin-left: 10px;
  }

`
