import React from 'react'
import moment from 'moment'
import constants from '../../config/constants'
import domainConfig from '../../config/domains'
import hasRole from '../../keycloak/utils/hasRole'
import AuthorizedElement from '../../keycloak/utils/AuthorizedElement'
import { useQuery, useMutation } from '@apollo/client'
import { GET_EXAMS } from '../../apollo/queries'
import { DELETE_EXAM } from '../../apollo/mutations'
import { Card, Button } from 'react-bootstrap'
import { useHistory, useParams, Link } from 'react-router-dom'
import { FaTrash, FaPen, FaFileMedicalAlt, FaPlus } from 'react-icons/fa'
import { PlayerLayout } from '../Layouts/PlayerLayout'
import { CustomBreadcrumb, Loading } from '../../components'
import { appStore } from '../../store/appStore'
import ExamsModal from './ExamsModal/ExamsModal'
import Styles from './ExamsStyles'

const Exams = () => {
  const history = useHistory()
  const { id_player, id_diagnostic } = useParams()

  const { state } = React.useContext(appStore)

  const [openModal, setOpenModal] = React.useState(false)
  const [action, setAction] = React.useState('')
  const [updateValues, setUpdateValues] = React.useState({})
  const [request, setRequest] = React.useState({
    status: 'NOT_SEND',
    data: []
  })

  const id_category_type = state.player?.id_category_type

  const breadcrumbPath = [
    { label: 'Ver Deportistas', href: '/players' },
    { label: 'Resumen médico', href: `/player-info/${id_category_type}/${id_player}/clinical-event` }
  ]

  const update = (cache, { data }) => {
    cache.modify({
      fields: {
        getExams(existingData = []) {
          const newData = data.getExams
          cache.writeQuery({
            query: GET_EXAMS,
            data: {
              newData,
              ...existingData
            }
          })
        }
      }
    })
  }

  const [deleteExam] = useMutation(DELETE_EXAM, { update })

  const handleDelete = (event, id) => {
    event.preventDefault()
    if (window.confirm('Esta acción es irreversible, ¿Estás seguro de borrar este examen?')) {
      deleteExam({
        variables: {
          input: { id }
        }
      })
    }
  }

  const { data, loading, error, refetch } = useQuery(GET_EXAMS, {
    variables: {
      input: {
        id_diagnostic: parseInt(id_diagnostic, 10)
      }
    }
  })

  if (loading) {
    return (
      <Loading />
    )
  }

  if (error) {
    return (
      <div>Error :(</div>
    )
  }

  return (
    <Styles>
      <CustomBreadcrumb
        path={hasRole(['role_player']) ? breadcrumbPath.slice(1) : breadcrumbPath }
        active="Exámenes"
      />
      <PlayerLayout>
        <div className='container'>
          <div className='d-flex'>
            <h5 className="mr-auto text-secondary">
              Exámenes
            </h5>
            <div className="d-flex justify-content-end">
              <AuthorizedElement roles={['permission_team-create-exam']}>
                <Button
                  className="ml-1 mb-2"
                  onClick={() => {
                    setOpenModal(true)
                    setAction('CREATE')
                    setUpdateValues({
                      date: new Date(),
                      title: '',
                      comments: ''
                    })
                  }}
                >
                  <FaPlus className="mb-1 mr-2" color={'white'} />
                  Agregar Examen
                </Button>
              </AuthorizedElement>
            </div>
          </div>

          <div className="row">
            {
              data.getExams.exams.length > 0 ?
                data.getExams.exams.map(exam =>
                  <div key={exam.id} className="col-12 col-md-6 col-lg-3 mb-4 list-item zoom">
                    <Card>
                      <Link to={{
                        pathname: `/exam-detail/${id_player}/${exam.id}`,
                        exam: { ...exam, id_diagnostic, id_category_type }
                      }}>
                        <Card.Img
                          variant="top"
                          src={
                            ((request.status === 'SUCCESS' || request.status === 'NOT_SEND') &&
                              request.data &&
                              request.data[0]?.id_exam === exam.id
                            )
                              ? `${domainConfig.clinicalApiUri}/exam/${request.data[0]?.id_exam}/files/${request.data[0]?.id}`
                              : (
                                exam.ExamFiles?.length && ['image/jpeg','image/png','image/gif'].includes(exam.ExamFiles[0]?.mime_type)
                                  ? `${domainConfig.clinicalApiUri}/exam/${exam.id}/files/${exam.ExamFiles[0]?.id}`
                                  : `${constants.STATIC_IMAGES_URL}/exam-cover.svg`
                              )
                          }
                        />
                      </Link>
                      <Link to={{
                        pathname: `/exam-detail/${id_player}/${exam.id}`,
                        exam: { ...exam, id_diagnostic, id_category_type }
                      }}>
                        <Card.Body>
                          <Card.Title
                            className="mb-0"
                          >
                            {exam.title}
                          </Card.Title>
                        </Card.Body>
                      </Link>
                      <Card.Footer className="d-flex justify-content-between align-items-center">
                        <Link to={{
                          pathname: `/exam-detail/${id_player}/${exam.id}`,
                          exam: { ...exam, id_diagnostic, id_category_type }
                        }}>
                          <small
                            onClick={() => history.push(`/exam-detail/${id_player}/${exam.id}`)}
                            className="text-muted mb-0"
                          >
                            {moment(exam.date).format('YYYY-MM-DD')}
                          </small>
                        </Link>
                        <div>
                          <AuthorizedElement roles={['permission_team-update-exam']}>
                            <Button
                              variant="warning"
                              size="sm"
                              className="mr-1"
                              onClick={() => {
                                setOpenModal(true)
                                setAction('EDIT')
                                setUpdateValues({
                                  ...exam,
                                  date: new Date(exam.date),
                                })
                              }}
                            >
                              <FaPen color={'#fff'} />
                            </Button>
                          </AuthorizedElement>
                          <AuthorizedElement roles={['permission_team-delete-exam']}>
                            <Button
                              variant="danger"
                              size="sm"
                              className="ml-1"
                              onClick={(event) => handleDelete(event, parseInt(exam.id, 10))}
                            >
                              <FaTrash />
                            </Button>
                          </AuthorizedElement>
                        </div>
                      </Card.Footer>
                    </Card>
                  </div>
                ) :
                <AuthorizedElement roles={['permission_team-create-exam']}>
                  <div
                    className="col noExams zoom"
                    onClick={() => {
                      setOpenModal(true)
                      setAction('CREATE')
                      setUpdateValues({
                        date: new Date(),
                        title: '',
                        comments: ''
                      })
                    }}
                  >
                    <FaFileMedicalAlt className="mr-2" size={50} color={'#848484'} />
                      El diagnóstico no presenta exámenes. Agrega un nuevo examen aquí
                  </div>
                </AuthorizedElement>
            }
          </div>

          {openModal &&
            <ExamsModal
              idPlayer={id_player}
              idDiagnostic={id_diagnostic}
              openModal={openModal}
              setOpenModal={(value) => setOpenModal(value)}
              action={action}
              updateValues={updateValues}
              request={request}
              setRequest={setRequest}
              refetch={refetch}
            />
          }
        </div>
      </PlayerLayout>
    </Styles>
  )
}

export default Exams
