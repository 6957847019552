import { gql } from '@apollo/client'

const DELETE_GPS = gql`
  mutation deleteGps($input: DeleteGpsInput!) {
    deleteGps(input: $input) {
      status {
        success
        code
        message
      }
    }
  }
`
export default DELETE_GPS
