import { gql } from '@apollo/client'

const CREATE_DAILY_MEASUREMENT = gql`
  mutation createDailyMeasurement($input: CreateDailyMeasurementInput!) {
    createDailyMeasurement(input: $input) {
      dailyMeasurements {
        id
      }
      status {
        success
        code
        message
      }
    }
  }
`

export default CREATE_DAILY_MEASUREMENT
