import React from 'react'
import PropTypes from 'prop-types'
import ReactToolTip from 'react-tooltip'
import { Button } from 'react-bootstrap'
import { useFormikContext } from 'formik'

const AutoReportInput = ({ meter, styleId = 'level' }) => {
  const { values, setFieldValue, touched, errors } = useFormikContext()

  return (
    meter ?
      <div key={meter.id} className={'col-12  mt-3'}>
        <h6>{meter.name}</h6>
        <p>{meter.subtitle}</p>
        <div className="d-flex">
          {meter.MeterOptions.map(option =>
            <Button
              key={option.id}
              value={option.value}
              variant="success"
              size="sm"
              data-tip data-for={`1-${styleId}-${option.id}`}
              className={`${styleId}-${meter.MeterOptions.length > 5 ? '' : '0'}${option.value} btn-level list-item ${values[meter.id] === option.value ? 'selected' : ''}`}
              onClick={({ target: { value } }) => {
                setFieldValue(meter.id, value)
              }}
            >
              {option.value}
              <ReactToolTip
                id={`1-${styleId}-${option.id}`}
                place="top"
                effect="solid"
                type="info"
              >
                {option.name}
              </ReactToolTip>
            </Button>
          )}
        </div>
        {(touched[meter.id] && errors[meter.id]) &&
        <div className="invalid-feedback d-block">
          {errors[meter.id]}
        </div>
        }
      </div> : null
  )
}

AutoReportInput.propTypes = {
  meter: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    MeterOptions: PropTypes.array
  }),
  styleId: PropTypes.string
}

export default AutoReportInput
