import { gql } from '@apollo/client'

const CREATE_GPS = gql`
  mutation createGps($input: CreateGpsInput!) {
    createGps(input: $input) {
      status {
        success
        code
        message
      }
    }
  }
`
export default CREATE_GPS

