import { gql } from '@apollo/client'

const GET_KINESIC_BLOCK_MEASURES = gql`
  query getKinesicBlockMeasures($input: KinesicBlockMeasureInput!) {
    getKinesicBlockMeasures(input: $input) {
      kinesicBlockMeasures {
        id
        id_kinesic_blocks
        id_kinesic_body_zone
        id_kinesic_measures_type
        name
        tenant_code
        KinesicBlock {
          id
          name
          tenant_code
        }
        KinesicBodyZone {
          id
          name
          tenant_code
        }
        KinesicMeasureType {
          id
          name
        }
        KinesicMeasureOptions {
          id
          name
          value
          desabled
        }
      }
      status {
        success
        code
        message
      }
    }
  }
`
export default GET_KINESIC_BLOCK_MEASURES

