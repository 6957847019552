import { CustomLink } from '../../../components/CustomLink'
import { NutritionInput } from './NutritionInput'
import { Tooltip } from './../../../components/Tooltip'
import moment from 'moment'


const useNutrition = ({
  allMeters = [],
  playerDimensions = [],
  jsonToMap
}) => {
  const columns = [
    {
      Header: ' ',
      sticky: 'left',
      className: 'player',
      columns: [
        {
          Header: 'Fecha',
          accessor: 'date',
          className: 'player',
          Cell: ({ cell: { value } }) => (
            CustomLink({ label: value?.label, to: `/nutrition/${value?.idPlayer}/${value?.id}` })
          )
        },
      ]
    },
    {
      Header: ' ',
      columns: allMeters.map(meter => ({
        Header: meter.name,
        accessor: meter.id,
        Cell: ({ cell: { value, column } }) => NutritionInput({ cellInfo: value, column })
      }))
    },
  ]

  const data = playerDimensions?.reduce((acc, { id, id_player, date, meters, Player }) => {
    let rows = {}
    const metersMap = jsonToMap(meters)

    allMeters.forEach((column) => {
      const existValue = metersMap.get(parseInt(column.id, 10)) !== undefined
      rows = {
        ...rows,
        [column.id]: {
          id: existValue ? id : null,
          id_player,
          date,
          categoryId: Player.id_category_type,
          positionTypeId: Player.id_position_type,
          cellValue: existValue ? metersMap.get(parseInt(column.id, 10)) : ''
        }
      }
    })

    return [
      ...acc,
      {
        date: {
          id,
          label: Tooltip ({
            id,
            label: moment(date).format('DD/MM/YYYY'),
            tooltipPlace: 'right',
            tooltipText: 'Ver detalle'
          }),
          idPlayer: id_player
        },
        ...rows
      }
    ]
  }, [])

  return { data, columns }
}

export default useNutrition
