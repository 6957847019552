import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import domainConfig from '../../../config/domains'
import SimpleReactLightbox from 'simple-react-lightbox'
import hasRole from '../../../keycloak/utils/hasRole'
import AuthorizedElement from '../../../keycloak/utils/AuthorizedElement'
import { useLazyQuery } from '@apollo/client'
import { GET_EXAMS } from '../../../apollo/queries'
import { useParams, useHistory } from 'react-router-dom'
import { Tabs, Tab } from 'react-bootstrap'
import { SRLWrapper } from 'simple-react-lightbox'
import { CustomBreadcrumb, DropZone, Loading } from '../../../components'
import { PlayerLayout } from '../../Layouts/PlayerLayout'
import ExamsModal from '../ExamsModal/ExamsModal'
import ExamsCard from './ExamCard'
import Styles from './ExamDetailStyles'

const ACCEPTED_MIMETYPES = `
  image/jpeg,
  image/png,
  image/gif,
  application/pdf,
  application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document,
  application/vnd.ms-excel,
  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
`

const ExamDetail = ({ location }) => {
  const { exam } = location

  let history = useHistory()
  const { id_player } = useParams()
  const [imagesState, setImagesState] = React.useState(exam?.ExamFiles)
  const [openModal, setOpenModal] = React.useState(false)
  const [selectedTab, setSelectedTab] = React.useState('images')
  const [files, setFiles] = React.useState([])
  const [updateValues, setUpdateValues] = React.useState({})
  const [request, setRequest] = React.useState({
    status: 'NOT_SEND',
    data: []
  })

  const breadcrumbPath = [
    { label: 'Ver Deportistas', href: '/players' },
    { label: 'Resumen médico', href: `/player-info/${exam?.id_category_type}/${id_player}/clinical-event` },
    { label: 'Exámenes', href: `/exams/${id_player}/${exam?.id_diagnostic}` }
  ]

  const [getExams] = useLazyQuery(GET_EXAMS, {
    fetchPolicy: 'network-only'
  })

  React.useEffect(() => {
    if (files.length) {
      setRequest({ status: 'LOADING' })

      const formData = new FormData()

      for (let i = 0; i < files.length; i++) {
        formData.append('files[]', files[i])
      }

      axios.post(`${domainConfig.clinicalApiUri}/exam/${exam?.id}/files`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(({ data }) => {
        setImagesState([...imagesState, ...data])
        setFiles([])
        setRequest({ status: 'SUCCESS' })
        getExams({
          variables: {
            input: {
              id_diagnostic: parseInt(exam.id_diagnostic, 10)
            }
          }
        })
      }).catch(() => setRequest({ status: 'ERROR' }))
    }
  }, [files])

  if (!exam) {
    return (
      <div onClick={history.goBack()} />
    )
  }

  if (request.status === 'LOADING') {
    return (
      <Loading />
    )
  }

  if (request.status === 'ERROR') {
    return (
      <div>Error :(</div>
    )
  }

  return (
    <SimpleReactLightbox>
      <Styles>
        <CustomBreadcrumb
          path={hasRole(['role_player']) ? breadcrumbPath.slice(1) : breadcrumbPath }
          active="Detalle Examen"
        />
        <PlayerLayout>
          <div className="container">

            <div className="row">
              <div className="col my-3">
                <h4 className="mb-3">Detalle examen: {exam.title}</h4>
                <span className="font-weight-bold text-white bg-success px-2 mb-3">
                  Comentario:
                </span>
                <p className="text-justify">
                  {exam.comments}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <AuthorizedElement roles={['permission_team-create-exam-image']}>
                  <div className="mb-3">
                    <DropZone
                      accept={ACCEPTED_MIMETYPES}
                      setFiles={setFiles}
                    />
                  </div>
                </AuthorizedElement>
              </div>
            </div>
            <Tabs
              className="mb-3"
              activeKey={selectedTab}
              onSelect={(k) => setSelectedTab(k)}
            >
              <Tab eventKey="images" title="Imágenes">
                <SRLWrapper>
                  <div className="row">
                    {imagesState.filter(f => ['image/jpeg','image/png','image/gif'].includes(f.mime_type)).length > 0
                      ? imagesState.filter(f => ['image/jpeg','image/png','image/gif'].includes(f.mime_type)).map((file, key) =>
                        <ExamsCard
                          key={key}
                          imagesState={imagesState}
                          setImagesState={setImagesState}
                          exam={exam}
                          examFile={file}
                          setOpenModal={setOpenModal}
                          setUpdateValues={setUpdateValues}
                        />
                      )
                      : <p className="ml-3">No hay imágenes cargadas</p>
                    }
                  </div>
                </SRLWrapper>
              </Tab>
              <Tab eventKey="files" title="Archivos">
                <div className="row">
                  {imagesState.filter(f => !['image/jpeg','image/png','image/gif'].includes(f.mime_type)).length > 0
                    ? imagesState.filter(f => !['image/jpeg','image/png','image/gif'].includes(f.mime_type)).map((file, key) =>
                      <ExamsCard
                        key={key}
                        imagesState={imagesState}
                        setImagesState={setImagesState}
                        exam={exam}
                        examFile={file}
                        setOpenModal={setOpenModal}
                        setUpdateValues={setUpdateValues}
                      />
                    )
                    : <p className="ml-3">No hay archivos cargados</p>
                  }
                </div>
              </Tab>
            </Tabs>
          </div>

          {openModal &&
            <ExamsModal
              openModal={openModal}
              setOpenModal={(value) => setOpenModal(value)}
              action={'EDIT'}
              origin={'DETAILS'}
              updateValues={updateValues}
              request={request}
              setRequest={setRequest}
              setImagesState={setImagesState}
            />
          }
        </PlayerLayout>
      </Styles>
    </SimpleReactLightbox>
  )
}

ExamDetail.propTypes = {
  location: PropTypes.shape({
    exam: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      comments: PropTypes.string,
      ExamFiles: PropTypes.array,
      id_diagnostic: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ]),
      id_category_type: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ])
    })
  })
}

export default ExamDetail
