import React from 'react'
import AuthorizedElement from '../../../keycloak/utils/AuthorizedElement'
import moment from 'moment'
import ReactToolTip from 'react-tooltip'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from '@apollo/client'
import { GET_KINESIC_TREATMENTS } from '../../../apollo/queries'
import { DELETE_KINESIC_TREATMENT } from '../../../apollo/mutations'
import { Button, Table } from 'react-bootstrap'
import { FaPen, FaTrash, FaPlus } from 'react-icons/fa'
import { FilterRow, DateFilter, Loading } from '../../../components'
import { LegacyTherapeucticActionsModal } from './LegacyTherapeucticActionsModal'
import Styles from './TherapeuticActionsStyle'

const LegacyTherapeuticActions = ({ idPlayer }) => {
  const [openModal, setOpenModal] = React.useState(false)
  const [action, setAction] = React.useState('')
  const [updateValues, setUpdateValues] = React.useState({})

  const [filters, setFilters] = React.useState({
    id_player: idPlayer,
    start_date: new Date(moment().subtract(6, 'months')),
    end_date: new Date()
  })

  const { data, loading, error } = useQuery(GET_KINESIC_TREATMENTS, {
    variables: {
      input: filters
    }
  })

  const update = (cache, { data }) => {
    cache.modify({
      fields: {
        getKinesicTreatments(existingTreatment = []) {
          const newTreatment = data.getKinesicTreatments
          cache.writeQuery({
            query: GET_KINESIC_TREATMENTS,
            data: {
              newTreatment,
              ...existingTreatment
            }
          })
        }
      }
    })
  }

  const [deleteMutation, deleteResult] = useMutation(DELETE_KINESIC_TREATMENT, { update })

  const handleDelete = (event, id) => {
    event.preventDefault()
    if (window.confirm('Esta acción es irreversible, ¿Estás seguro de borrar este tratamiento?')) {
      deleteMutation({
        variables: {
          input: { id }
        }
      })
    }
  }

  if (loading) {
    return (
      <Loading />
    )
  }

  if (error || deleteResult.error) {
    return (
      <div>Error :(</div>
    )
  }

  return (

    <Styles>
      <div>
        <div className="row">
          <div className="col d-flex align-items-baseline justify-content-end">
            <h5 className="mr-auto text-secondary">
                Historial Acciones Terapéuticas
            </h5>
            <AuthorizedElement roles={['permission_team-show-player-kinesic-treatment']}>
              <FilterRow>
                <DateFilter
                  value={{
                    start_date: filters.start_date,
                    end_date: filters.end_date
                  }}
                  setValue={setFilters}
                />
              </FilterRow>
            </AuthorizedElement>
            <div className='d-md-block d-none'>
              <AuthorizedElement roles={['permission_team-create-player-kinesic-treatment']}>
                <Button
                  className="mb-2"
                  onClick={() => {
                    setOpenModal(true)
                    setAction('CREATE')
                    setUpdateValues({
                      id_player: idPlayer,
                      date: new Date(),
                      text: ''
                    })
                  }}
                >
                  <FaPlus className="mb-1 mr-2" color={'white'} />
                  Agregar Acciones Terapéuticas
                </Button>
              </AuthorizedElement>
            </div>
          </div>
        </div>
        <div className='d-block d-md-none'>
          <AuthorizedElement roles={['permission_team-create-player-kinesic-treatment']}>
            <Button
              className='mb-3' variant="primary" block
              onClick={() => {
                setOpenModal(true)
                setAction('CREATE')
                setUpdateValues({
                  id_player: idPlayer,
                  date: new Date(),
                  text: ''
                })
              }}
            >
              <FaPlus className="mb-1 mr-2" color={'white'} />
                  Agregar Acciones Terapéuticas
            </Button>
          </AuthorizedElement>
        </div>
        <div className="row">
          <div className="col">
            <Table className='table-font table-responsive-lg' striped bordered hover size="sm" >
              <thead>
                <tr>
                  <th style={{ width: '150px' }}>Fecha</th>
                  <th>Acciones Terapéuticas</th>
                  <th style={{ width: '100px' }}>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {data.getKinesicTreatments.kinesicTreatments.map((treatment) =>
                  <tr key={treatment.id}>
                    <td className="text-no-break text-center">
                      {moment(treatment.date).format('DD/MM/YYYY')}
                    </td>
                    <td className="comment">
                      {treatment.text}
                    </td>
                    <td>
                      <div className="d-flex justify-content-center">
                        <AuthorizedElement roles={['permission_team-update-player-kinesic-treatment']}>
                          <Button
                            size="sm"
                            variant="warning"
                            className="mr-2"
                            data-tip data-for="medicines"
                            onClick={() => {
                              setOpenModal(true)
                              setAction('EDIT')
                              setUpdateValues({
                                id: parseInt(treatment.id, 10),
                                date: new Date(moment(`${treatment.date} 12:00:00`)),
                                text: treatment.text,
                              })
                            }}
                          >
                            <FaPen color={'white'} />
                            <ReactToolTip
                              id="medicines"
                              place="top"
                              effect="solid"
                              type="info"
                            >
                              Editar
                            </ReactToolTip>
                          </Button>
                        </AuthorizedElement>
                        <AuthorizedElement roles={['permission_team-delete-player-kinesic-treatment']}>
                          <Button
                            variant="danger"
                            size="sm"
                            data-tip data-for="delete"
                            className="mx-1"
                            onClick={(event) => handleDelete(event, treatment.id)}
                          >
                            <FaTrash color={'white'}/>
                            <ReactToolTip
                              id="delete"
                              place="top"
                              effect="solid"
                              type="info"
                            >
                            Eliminar
                            </ReactToolTip>
                          </Button>
                        </AuthorizedElement>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>

      {openModal &&
        <LegacyTherapeucticActionsModal
          openModal={openModal}
          setOpenModal={(value) => setOpenModal(value)}
          action={action}
          updateValues={updateValues}
        />
      }
    </Styles>
  )
}

LegacyTherapeuticActions.propTypes = {
  idPlayer: PropTypes.number
}

export default LegacyTherapeuticActions
