import React from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from '@apollo/client'
import { GET_USERS_TYPES, GET_USERS } from '../../../apollo/queries'
import { CREATE_USER, UPDATE_USER, DELETE_USER } from '../../../apollo/mutations'
import { getMedicalStaffFormSchema } from './MedicalStaffFormSchema'
import { Modal, Form, Col, Row, Button } from 'react-bootstrap'
import { FaTrash } from 'react-icons/fa'
import { StatusAlert } from '../../../components'
import Styles from './MedicalStaffFormStyle'

const MedicalStaffForm = ({
  openModal = false,
  setOpenModal,
  values = {
    action: 'CREATE',
    user: {
      id_user_type: 1,
      email: '',
      name: '',
      last_name: '',
      gender: '1',
    }
  }
}) => {
  const { action, user } = values
  const { loading, error, data } = useQuery(GET_USERS_TYPES)
  const refetchQueries = [{
    query: GET_USERS,
    variables: {
      input: {
        id_user_type: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
      }
    }
  }]

  const [userMutation, userMutationResult] = useMutation(
    action === 'CREATE' ? CREATE_USER : UPDATE_USER,
    { refetchQueries }
  )

  const { medicalStaffFormSchema } = getMedicalStaffFormSchema(user, userMutation, action)

  const [deleteUser] = useMutation(DELETE_USER, { refetchQueries })

  const handleClose = () => setOpenModal(false)

  const handleDelete = (event) => {
    event.preventDefault()
    if (window.confirm('Esta acción es irreversible, ¿Estás seguro de borrar este usuario?')) {
      deleteUser({
        variables: {
          input: {
            id: user.id
          }
        }
      }).then(() => handleClose())
    }
  }

  if (error || userMutationResult.error) {
    return (
      <div>Error :(</div>
    )
  }

  return (
    <Styles>
      <Modal
        show={openModal}
        onHide={handleClose}
        dialogClassName="full-width"
        contentClassName="full-height"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {action === 'CREATE'
              ? 'Crear Usuario'
              : 'Editar Usuario'
            }
          </Modal.Title>
        </Modal.Header>
        {loading || userMutationResult.loading
          ? 'Cargando...'
          : <Form onSubmit={medicalStaffFormSchema.handleSubmit}>
            {userMutationResult.data &&
              <StatusAlert
                successMessage={
                  action === 'CREATE'
                    ? 'Usuario creado con éxito.'
                    : 'Usuario actualizado con éxito.'
                }
                status={
                  action === 'CREATE'
                    ? userMutationResult.data.createUser.status
                    : userMutationResult.data.updateUser.status
                }
              />
            }
            <Modal.Body>
              <Form.Group>
                <Form.Label>
                  Tipo de usuario
                </Form.Label>
                <Form.Control
                  as="select"
                  name="id_user_type"
                  value={medicalStaffFormSchema.values.id_user_type}
                  onChange={medicalStaffFormSchema.handleChange}
                  onBlur={medicalStaffFormSchema.handleBlur}
                  isInvalid={
                    (medicalStaffFormSchema.touched.id_user_type && medicalStaffFormSchema.errors.id_user_type) ||
                    userMutationResult.error
                      ? true
                      : false
                  }
                >
                  <option value="">Selecciona un tipo de usuario</option>
                  {data.getUsersTypes.usersTypes.map(userType =>
                    <option key={userType.id} value={userType.id}>
                      {userType.name_user}
                    </option>
                  )}
                </Form.Control>
              </Form.Group>

              <Form.Group>
                <Form.Label>
                  Nombre
                </Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Nombre"
                  value={medicalStaffFormSchema.values.name}
                  onChange={medicalStaffFormSchema.handleChange}
                  onBlur={
                    e => {
                      medicalStaffFormSchema.setFieldValue('name', e.target.value.trim())
                      medicalStaffFormSchema.handleBlur(e)
                    }
                  }
                  isInvalid={
                    (medicalStaffFormSchema.touched.name && medicalStaffFormSchema.errors.name) ||
                    userMutationResult.error
                      ? true
                      : false
                  }
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>
                  Apellidos
                </Form.Label>
                <Form.Control
                  type="text"
                  name="last_name"
                  placeholder="Apellidos"
                  value={medicalStaffFormSchema.values.last_name}
                  onChange={medicalStaffFormSchema.handleChange}
                  onBlur={
                    e => {
                      medicalStaffFormSchema.setFieldValue('last_name', e.target.value.trim())
                      medicalStaffFormSchema.handleBlur(e)
                    }
                  }
                  isInvalid={
                    (medicalStaffFormSchema.touched.last_name && medicalStaffFormSchema.errors.last_name) ||
                    userMutationResult.error
                      ? true
                      : false
                  }
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>
                  Email
                </Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={medicalStaffFormSchema.values.email}
                  onChange={medicalStaffFormSchema.handleChange}
                  onBlur={medicalStaffFormSchema.handleBlur}
                  isInvalid={
                    (medicalStaffFormSchema.touched.email && medicalStaffFormSchema.errors.email) ||
                    userMutationResult.error
                      ? true
                      : false
                  }
                />
              </Form.Group>

              <fieldset>
                <Form.Group as={Row}>
                  <Form.Label as="legend" column sm={2}>
                    Género
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Check
                      type="radio"
                      label="Masculino"
                      name="gender"
                      value="1"
                      checked={medicalStaffFormSchema.values.gender === '1'}
                      onChange={medicalStaffFormSchema.handleChange}
                      onBlur={medicalStaffFormSchema.handleBlur}
                      isInvalid={
                        (medicalStaffFormSchema.touched.gender && medicalStaffFormSchema.errors.gender) ||
                        userMutationResult.error
                          ? true
                          : false
                      }
                    />
                    <Form.Check
                      type="radio"
                      label="Femenino"
                      name="gender"
                      value="2"
                      checked={medicalStaffFormSchema.values.gender === '2'}
                      onChange={medicalStaffFormSchema.handleChange}
                      onBlur={medicalStaffFormSchema.handleBlur}
                    />
                  </Col>
                </Form.Group>
              </fieldset>
            </Modal.Body>

            <Modal.Footer className="d-flex justify-content-end">
              {action === 'UPDATE' &&
                <Button
                  variant="danger"
                  onClick={(event) => handleDelete(event)}
                >
                  <FaTrash/>
                </Button>
              }
              <Button
                variant="secondary"
                onClick={() => handleClose()}
              >
                Cancelar
              </Button>
              <Button variant="success" type="submit">
                {action === 'CREATE'
                  ? 'Crear'
                  : 'Guardar'
                }
              </Button>
            </Modal.Footer>
          </Form>
        }
      </Modal>
    </Styles>
  )
}

MedicalStaffForm.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  values: PropTypes.shape({
    action: PropTypes.string.isRequired,
    user: PropTypes.shape({
      id: PropTypes.string,
      id_user_type: PropTypes.string,
      email: PropTypes.string,
      name: PropTypes.string,
      last_name: PropTypes.string,
      gender: PropTypes.string
    })
  })
}

export default MedicalStaffForm
