import React from 'react'
import AuthorizedElement from '../../../keycloak/utils/AuthorizedElement'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { GET_PLAYER_KINESIC_TREATMENTS } from '../../../apollo/queries'
import { Button } from 'react-bootstrap'
import { FaPlus } from 'react-icons/fa'
import { FilterRow, DateFilter, Loading, StickyTable } from '../../../components'
import { TherapeucticActionsModal } from './TherapeucticActionsModal'
import Styles from './TherapeuticActionsStyle'
import PlayerGpsCharts from '../../Gps/PlayerGps/PlayerGpsCharts'
import usePlayerDimension from '../usePlayerDimension'
import useTherapeuticActions from './useTherapeuticActions'
import { KinesicTreatmentTeamInput } from '../../KinesicTreatmentTeam/KinesicTreatmentTeamInput'

const TherapeucticActions = ({ idPlayer , idDiagnostic = '' }) => {
  const [openModal, setOpenModal] = React.useState(false)
  const [initValues, setInitValues] = React.useState({})
  const [filters, setFilters] = React.useState({
    id_player: idPlayer,
    start_date: new Date(moment().subtract(6, 'months')),
    end_date: new Date()
  })

  const diagnosticInput = idDiagnostic ? { id_diagnostic: idDiagnostic } : {}

  const { data: { getPlayerKinesicTreatments } = {}, loading, error } = useQuery(GET_PLAYER_KINESIC_TREATMENTS, {
    variables:{
      input: {
        ...diagnosticInput,
        id_player: idPlayer,
        start_date: moment(filters.start_date).format('YYYY-MM-DD'),
        end_date: moment(filters.end_date).format('YYYY-MM-DD'),
      },
    },
    fetchPolicy: 'network-only'
  })

  const { allMeters } = usePlayerDimension(null, null, 16)

  const tableInputs = {
    idPlayer,
    allMeters,
    InputCell: KinesicTreatmentTeamInput,
    playerDimensions: getPlayerKinesicTreatments?.playerKinesicTreatments?.PlayerDimensions,
    clinicalEvents: getPlayerKinesicTreatments?.playerKinesicTreatments?.ClinicalEvents,
  }

  const { data, columns } = useTherapeuticActions(tableInputs)

  if (error) {
    return (
      <div>Error :(</div>
    )
  }

  if (loading) {
    return (
      <Loading />
    )
  }

  return (
    <Styles>
      <div>
        <div className="row">
          <div className="col d-flex flex-wrap align-items-baseline justify-content">
            <h5 className="mr-auto text-secondary">
              {'Historial Tratamiento Kinésico'}
            </h5>
            <AuthorizedElement roles={['permission_team-show-kinesic-dimension']}>
              <FilterRow>
                <DateFilter
                  value={{
                    start_date: filters.start_date,
                    end_date: filters.end_date
                  }}
                  setValue={setFilters}
                />
              </FilterRow>
            </AuthorizedElement>
            <div className='d-md-block d-none'>
              <AuthorizedElement roles={['permission_team-create-kinesic-dimension']}>
                <Button
                  className="mb-2"
                  onClick={() => {
                    setOpenModal(true)
                    setInitValues({
                      id_player: idPlayer,
                      id_diagnostic: '',
                      date: new Date(),
                    })
                  }}
                >
                  <FaPlus className="mb-1 mr-2" color={'white'} />
                  {'Agregar Tratamiento Kinésico'}
                </Button>
              </AuthorizedElement>
            </div>
          </div>
        </div>
        {/* <div className='d-flex flex-wrap align-items-baseline'>
          <h6 className="mr-1 mb-0 text-muted">Diagnóstico:</h6>
          <p mb-0 mr-1 text-muted>{diagnostic.Pathology.name}{', '}</p>
          <p mb-0 mr-1 text-muted>{diagnostic.DiagnosticComplement?.name ? diagnostic.DiagnosticComplement?.name + ', ' : ''}</p>
          <p mb-0 mr-1 text-muted>{diagnostic.SkeletalMuscle ? diagnostic.SkeletalMuscle?.name : diagnostic.BodyZone?.name} </p>
        </div> */}
        <div className='d-block d-md-none'>
          <AuthorizedElement roles={['permission_team-create-kinesic-dimension']}>
            <Button
              className='mb-3' variant="primary" block
              onClick={() => {
                setOpenModal(true)
                setInitValues({
                  id_player: idPlayer,
                  date: new Date(),
                  id_diagnostic: '',
                })
              }}
            >
              <FaPlus className="mb-1 mr-2" color={'white'} />
              {'Agregar Tratamiento Kinésico'}
            </Button>
          </AuthorizedElement>
        </div>

        <PlayerGpsCharts
          gps={allMeters}
          playerGps={data}
        />

        <div className="mt-5 ml-4 mb-5 mr-4">
          <StickyTable
            columns={columns}
            data={data}
            getCellProps={cellInfo => ({
              style: {
                background: cellInfo.row.index % 2 === 0 ? '#e0e6f2' : '#ffffff',
              },
            })}
          />
        </div>
      </div>

      {openModal &&
        <TherapeucticActionsModal
          openModal={openModal}
          setOpenModal={(value) => setOpenModal(value)}
          initValues={initValues}
          allMeters={allMeters}
          clinicalEvents={tableInputs.clinicalEvents}
        />
      }
    </Styles>
  )
}

TherapeucticActions.propTypes = {
  idPlayer: PropTypes.number,
  idDiagnostic: PropTypes.number,
  diagnostic: PropTypes.string,
}

export default TherapeucticActions
