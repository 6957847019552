import React, { useContext } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { appStore } from '../../store/appStore'

export const AutoReportContext = React.createContext()

const AutoReportProvider = ({ children, wellness, biomedic }) => {
  const dimensionTestsByMoment = new Map([
    [1, ['1', '28']],
    [2, ['37', '29']]
  ])

  const { state } = useContext(appStore)

  const initialWindow = React.useMemo(() => ({
    name: moment().format('LL'),
    completeDate: moment().format('YYYY-MM-DD')
  }), [])

  const [selectedDimensionTest, setSelectedDimensionTest] = React.useState({ measurement_moment: 1 })

  const [windowDate, setWindowDate] = React.useState(initialWindow)
  const [filters, setFilters] = React.useState({
    id_category_type: state.defaultCategoryType,
    start_date: moment().startOf('day').format('YYYY-MM-DD'),
    end_date: moment().startOf('day').format('YYYY-MM-DD'),
    id_dimension_test: dimensionTestsByMoment.get(1)
  })

  React.useEffect(() => {
    setFilters((prevState) => ({
      ...prevState,
      start_date: moment(windowDate.completeDate).startOf('day').format('YYYY-MM-DD'),
      end_date: moment(windowDate.completeDate).startOf('day').format('YYYY-MM-DD')
    }))
  }, [windowDate])

  const setFieldValue = React.useCallback((name, value) => {
    if (name === 'start_date') {
      setWindowDate({
        name: moment(value).format('LL'),
        completeDate: moment(value).format('YYYY-MM-DD')
      })
      setFilters((current) => ({
        ...current,
        start_date: moment(value).format('YYYY-MM-DD'),
        end_date: moment(value).format('YYYY-MM-DD')
      }))
    } else {
      setFilters((current) => ({
        ...current,
        [name]: value
      }))
    }
  }, [])

  return (
    <AutoReportContext.Provider
      value={{
        wellness,
        biomedic,
        dimensionTestsByMoment,
        initialWindow,
        selectedDimensionTest,
        setSelectedDimensionTest,
        windowDate,
        setWindowDate,
        filters,
        setFilters,
        setFieldValue
      }}
    >
      {children}
    </AutoReportContext.Provider>
  )
}

AutoReportProvider.propTypes = {
  children: PropTypes.node.isRequired,
  wellness: PropTypes.object,
  biomedic: PropTypes.object,
  teamAutoreport: PropTypes.func
}

export { AutoReportProvider }
