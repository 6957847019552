import React from 'react'
import PropTypes from 'prop-types'
import { Button, Spinner } from 'react-bootstrap'

const SubmitButton = ({ action = 'CREATE', loading = false }) => {
  return (
    <Button variant="success" type="submit">
      {loading ?
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        : (action === 'CREATE')
          ? 'Crear'
          : 'Guardar'
      }
    </Button>
  )
}

SubmitButton.propTypes = {
  action: PropTypes.string,
  loading: PropTypes.bool
}

export default SubmitButton
