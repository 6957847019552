import styled from 'styled-components'

export default styled.div`

  .player-name{
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: middle
  }

  .td-diagnostic {
    min-width: 330px;
    max-width: 330px;
    text-align: left;
    vertical-align: middle
  }

  .date {
    width: 120px;
    min-width: 120px !important;
    max-width: 120px !important;
    text-align: center;
    vertical-align: middle
  }

  .btn-cards {
    background-color: #e0e6f2;
    color: #4e7ace;
    width:100%;
    height:auto;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    margin:5px;
    cursor: pointer;
  }

  .btn-cards-condition-1 {
    background-color:#7cd83a;
    width:100%;
    color: white;
    text-align: center;
  }

  .btn-cards-condition-2 {
    background-color: #fbff00;
    width:100%;
    color: black;
    text-align: center;
  }

  .btn-cards-condition-3 {
    background-color: #ffc200;
    width:100%;
    color: white;
    text-align: center;
    border-radius: 5px;
  }

  .btn-cards-condition-4 {
    background-color: #ca0000;
    width:100%;
    color: white;
    text-align: center;
    border-radius: 5px;
  }

  .btn-cards-condition-5 {
    background-color: #ff0000;
    width:100%;
    color: white;
    text-align: center;
  }

`
