import { gql } from '@apollo/client'

const GET_METERS = gql`
  query getMeters($input: MeterInput!) {
    getMeters(input: $input) {
      meters {
        id
        name
        DimensionTest {
          id
          name
        }
        MeterType {
          id
        }
        MeterOptions {
          id
          name
          value
        }
      }
      status {
        success
        code
        message
      }
    }
  }
`

export default GET_METERS
