import styled from 'styled-components'

export default styled.div`

  h3{
    color: #4e7ace;
  }
  .zoom {
    transition: transform .5s;
  }

  .zoom:hover {
    transform: scale( 1.05);
  }

  .button-secondary{
    width: 100%;
    max-width: 100%;
  }

  .button-secondary:hover{
    background-color: #fff;
    color: #007bff;
  }


  .fade-in-top {
	-webkit-animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }

  .fieldset{
    border: 1px solid #4e7ace;
    border-radius: 5px;
    padding: 10px;
  }

  .legend{
    color: #4e7ace;
  }

    /* ----------------------------------------------
  * Generated by Animista on 2021-11-10 11:25:25
  * Licensed under FreeBSD License.
  * See http://animista.net/license for more info.
  * w: http://animista.net, t: @cssanimista
  * ---------------------------------------------- */

  /**
  * ----------------------------------------
  * animation fade-in-top
  * ----------------------------------------
  */

  @-webkit-keyframes fade-in-top {
    0% {
      -webkit-transform: translateY(-50px);
              transform: translateY(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-top {
    0% {
      -webkit-transform: translateY(-50px);
              transform: translateY(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }

  .tracking-in-expand {
    -webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) 0.5s both;
            animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) 0.5s both;
  }

    /* ----------------------------------------------
  * Generated by Animista on 2021-11-10 11:28:17
  * Licensed under FreeBSD License.
  * See http://animista.net/license for more info.
  * w: http://animista.net, t: @cssanimista
  * ---------------------------------------------- */

  /**
  * ----------------------------------------
  * animation tracking-in-expand
  * ----------------------------------------
  */
  @-webkit-keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }

`
