const EVENTS_COLORS = {
  1:  '8bc53f', // Entrenamiento
  2:  '8bc53f', // Partido Amistoso
  3:  '335da9', // Super Copa
  4:  '335da9', // Copa Libertadores
  5:  '335da9', // Copa Sudamericana
  6:  '335da9', // Copa Chile
  7:  '335da9', // Campeonato Nacional
  8:  '8bc53f', // Charla Tecnica
  9:  '8bc53f', // Inicio pretemporada/intertemporada
  10: '858585', // Descanso / Anteriormente el color era #f98806
  11: 'f98806', // Administrativo
  12: 'f98806', // Translado / Viaje
  13: 'ff0000', // Borrar no existe (Otros)
  14: '335da9', // Copa America
  15: '335da9', // Copa Mundial
  16: 'ff0000', // Borrar no existe
  17: '335da9', // eliminatorias
  18: '4f4f4f', // Otros
}

const CALENDAR_MESSAGES = {
  today: 'Hoy',
  month: 'Mes',
  week:'Semana',
  day:'Día',
  date: 'Fecha',
  time: 'Hora',
  event: 'Evento',
  allDay: 'Todo el Día',
  work_week: 'Semana Laboral',
  yesterday: 'Ayer',
  tomorrow: 'Mañana',
  agenda: 'Agenda',
  noEventsInRange: 'No se han registrado eventos para esta fecha.',
  showMore: total => `+${total} eventos más`,
}

export {
  EVENTS_COLORS,
  CALENDAR_MESSAGES
}
