import { gql } from '@apollo/client'

const GET_CLINICAL_EVENT_DETAILS = gql`
  query getClinicalEventDetails($input: ClinicalEventDetailInput!) {
    getClinicalEventDetails(input: $input) {
      clinicalEventDetails {
        id
        id_player
        active
        createdAt
        updatedAt
        Instance {
          id
          name
        }
        MatchMoment {
          id
          name
        }
        Severity {
          id
          name
        }
        SpecificMechanism {
          id
          name
        }
        GeneralMechanism {
          id
          name
        }
        Diagnostics {
          id
          side
          injury_muscle_front_rear_dimension
          injury_muscle_middle_side_dimension
          injury_muscle_depth_dimension
          comments
          createdAt
          DiagnosticType {
            id
            name
          }
          SubDiagnostic {
            id
            name
            skeletal_muscle
            body_zone
          }
          DiagnosticComplement {
            id
            name
          }
          Pathology {
            id
            name
          }
          SkeletalMuscle {
            id
            name
          }
          BodyZone {
            id
            name
          }
          ProcedureMaterial {
            id
            name
          }
          JointLigament {
            id
            name
          }
          PlexoSystem {
            id
            name
          }
          DiagnosticAvailabilities {
            id
            AvailabilityCondition {
              AvailabilityConditionType {
                id
                name_availability_condition_type
              }
            }
          }
        }
        ClinicalEventHistories {
          start_date
          end_date
        }
      }
      status {
        success
        code
        message
      }
    }
  }
`

export default GET_CLINICAL_EVENT_DETAILS

