import styled from 'styled-components'

export const ModalHeaderStyle = styled.div`
  .close {
    margin-left: 0
  }
`

export default styled.div`

  .btn-level {
    color: white;
    padding: 10px 10px;
    border-radius: 0px;
    border: none;
  }

  .btn-level:hover {
    background-color: #142c59;
    color: #fff;
  }

  .level-0 {
    background-color: #86bc45;
    color: #000;
    border: 0.01px solid #000;
  }

  .level-1 {
    background-color: #86bc45;
    color: #000;
    border: 0.01px solid #000;
  }

  .level-2 {
    background-color: #86bc45;
    color: #000;
    border: 0.01px solid #000;
  }

  .level-3 {
    background-color: #86bc45;
    color: #000;
    border: 0.01px solid #000;
  }

  .level-4 {
    background-color: #fefb00;
    color: #000;
    border: 0.01px solid #000;
  }

  .level-5 {
    background-color: #fefb00;
    color: #000;
    border: 0.01px solid #000;
  }

  .level-6 {
    background-color: #fefb00;
    color: #000;
    border: 0.01px solid #000;
  }

  .level-7 {
    background-color: #ff2600;
    color: #000;
    border: 0.01px solid #000;
  }

  .level-8 {
    background-color: #ff2600;
    color: #000;
    border: 0.01px solid #000;
  }

  .level-9 {
    background-color: #ff2600;
    color: #000;
    border: 0.01px solid #000;
  }

  .level-10 {
    background-color: #ff2600;
    color: #000;
    border: 0.01px solid #000;
  }

  /*  */

  .TQR-1{
    background-color: #ff2600;
    color: #000;
    border: 0.01px solid #000;
  }

  .TQR-2{
    background-color: #ff2600;
    color: #000;
    border: 0.01px solid #000;
  }

  .TQR-3{
    background-color: #ff2600;
    color: #000;
    border: 0.01px solid #000;
  }

  .TQR-4 {
    background-color: #fefb00;
    color: #000;
    border: 0.01px solid #000;
  }
  .TQR-5 {
    background-color: #fefb00;
    color: #000;
    border: 0.01px solid #000;
  }

  .TQR-6 {
    background-color: #fefb00;
    color: #000;
    border: 0.01px solid #000;
  }

  .TQR-7 {
    background-color: #86bc45;
    color: #000;
    border: 0.01px solid #000;
  }

  .TQR-8 {
    background-color: #86bc45;
    color: #000;
    border: 0.01px solid #000;
  }

  .TQR-9 {
    background-color: #86bc45;
    color: #000;
    border: 0.01px solid #000;
  }

  .TQR-10 {
    background-color: #86bc45;
    color: #000;
    border: 0.01px solid #000;
  }

  /*  */

  .level-01 {
    background-color: #ff2600;
    color: #000;
    border: 0.01px solid #000;
  }

  .level-02 {
    background-color: #ff2600;
    color: #000;
    border: 0.01px solid #000;
  }

  .level-03 {
    background-color: #fefb00;
    color: #000;
    border: 0.01px solid #000;
  }

  .level-04 {
    background-color: #86bc45;
    color: #000;
    border: 0.01px solid #000;
  }

  .level-05 {
    background-color: #86bc45;
    color: #000;
    border: 0.01px solid #000;
  }

  .selected {
    background-color: #142c59;
    color: white;
    margin: 0px 4px;
  }

  .list-item {
    opacity: 0;
    animation: fade-in 900ms ease forwards;
  }

  .list-item:nth-child(2) {
    animation-delay: 100ms;
  }

  .list-item:nth-child(3) {
    animation-delay: 200ms;
  }

  .list-item:nth-child(4) {
    animation-delay: 300ms;
  }

  .list-item:nth-child(5) {
    animation-delay: 400ms;
  }

  .list-item:nth-child(6) {
    animation-delay: 500ms;
  }

  .list-item:nth-child(7) {
    animation-delay: 600ms;
  }

  .list-item:nth-child(8) {
    animation-delay: 700ms;
  }

  .list-item:nth-child(9) {
    animation-delay: 800ms;
  }

  .list-item:nth-child(10) {
    animation-delay: 900ms;
  }

  .list-item:nth-child(11) {
    animation-delay: 1000ms;
  }

  @keyframes fade-in {
    to {
      opacity: 1;
    }
  }

  .autoreport_date{
    width:310px;
    margin-left: 13px;
    margin-top: 10px;
  }

  label{
    background-color: #28a745;
    color: white;
    padding: 0 10px;
    width: 100%;
  }

  .form-label{
    background-color: #28a745;
    color: white;
    padding: 0 10px;
    width: 100%;
  }
`
