import React from 'react'
import { useQuery } from '@apollo/client'
import { GET_DIMENSION } from '../../apollo/queries'
import { Loading } from '../../components'
import AutoReport from './AutoReport'
import { AutoReportProvider } from './AutoReportContext'

// eslint-disable-next-line react/display-name
export default () => {
  const wellness = useQuery(GET_DIMENSION, {
    variables: {
      input: {
        id: 1
      }
    },
  })

  const biomedic = useQuery(GET_DIMENSION, {
    variables: {
      input: {
        id: 12
      }
    },
  })

  if (wellness.error || biomedic.error) {
    return (
      <div>Error :(</div>
    )
  }

  if (wellness.loading || biomedic.loading) {
    return (
      <Loading />
    )
  }

  return (
    <AutoReportProvider
      wellness={wellness.data?.getDimension.dimension}
      biomedic={biomedic.data?.getDimension.dimension}
    >
      <AutoReport />
    </AutoReportProvider>
  )
}
