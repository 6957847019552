import React, { Suspense } from 'react'
import AppRoutes from './routes/AppRoutes'
import keycloak from './keycloak/config'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { client } from './apollo/client'
import { AppProvider } from './store/appStore.js'
import { Loading } from './components'

const App = () => {

  const [checkUser, setCheckUser] = React.useState(false)

  return (
    <ReactKeycloakProvider authClient={keycloak} onEvent={() => setCheckUser(true)}>
      <AppProvider>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <Suspense fallback={<Loading/>}>
              <AppRoutes checkUser={checkUser}/>
            </Suspense>
          </BrowserRouter>
        </ApolloProvider>
      </AppProvider>
    </ReactKeycloakProvider>
  )
}

export default App
