import { gql } from '@apollo/client'

const DELETE_KINESIC_TREATMENT = gql`
  mutation deleteKinesicTreatment($input: DeleteKinesicTreatmentInput!) {
    deleteKinesicTreatment(input: $input) {
      status {
        success
        code
        message
      }
    }
  }
`

export default DELETE_KINESIC_TREATMENT
