import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { useQuery, useMutation } from '@apollo/client'
import {
  GET_AVAILABILITY_CONDITIONS,
  GET_AVAILABILITY_CONDITIONS_TYPES,
  GET_PLAYER
} from '../../../apollo/queries'
import { CREATE_AVAILABILITY_CONDITION, UPDATE_AVAILABILITY_CONDITION } from '../../../apollo/mutations'
import { Button, Modal, Form, Alert, Spinner } from 'react-bootstrap'
import { appStore } from '../../../store/appStore'
// import { FaPen } from 'react-icons/fa'


const AvailabilityForm = ({
  openModal,
  setOpenModal,
  availabilityCondition,
  filters
}) => {
  const { action, initialValues } = availabilityCondition

  const { dispatch } = React.useContext(appStore)

  const [forbidden, setForbidden] = React.useState('')
  const { data } = useQuery(GET_PLAYER, {
    variables: {
      input: { id_player: parseInt(initialValues.id_player, 10) }
    }
  })

  React.useEffect(() => {
    if (data) {
      dispatch({ type: 'savePlayer', payload: data.getPlayer.player })
    }
  }, [data])

  const availabilityTypesQuery = useQuery(GET_AVAILABILITY_CONDITIONS_TYPES)
  const [availabilityMutation, availabilityMutationResult] = useMutation(
    action === 'CREATE' ? CREATE_AVAILABILITY_CONDITION : UPDATE_AVAILABILITY_CONDITION,
    {
      refetchQueries: [
        {
          query: GET_AVAILABILITY_CONDITIONS,
          variables: {
            input: filters
          }
        }
      ]
    }
  )

  const handleClose = () => setOpenModal(false)

  const handleForbidden = (redirect) => {
    switch (redirect) {
    case 'create-clinical-event':
      return(
        <Alert variant="warning">
          <p>Para asociar esta condición al jugador, debes crear un evento clínico.</p>
          <p>¿Deseas crear un evento clinico? <Link to={`/create-clinical-event/${initialValues.id_player}`}>Crear evento clínico</Link></p>
        </Alert>
      )
    case 'player-info':
      return(
        <Alert variant="warning">
          <p>Este jugador cuenta con un evento clínico activo. Para cambiar su disponibilidad debes editar su diagnóstico actual o crear uno nuevo.</p>
          <p>¿Deseas ver el evento clínico activo? <Link to={`/clinical-event-details/${initialValues.id_player}/${initialValues.id_clinical_event}`}>Ver evento clínico</Link></p>
        </Alert>
      )
    case 'no-redirect':
      return(
        <Alert variant="warning">
          <p>Este jugador cuenta con un evento clínico activo. No puedes cambiar su disponibilidad a esta condición.</p>
        </Alert>
      )
    }
  }

  const summarySchema = useFormik({
    initialValues,
    validate: (values) => {
      const errors = {}

      if (!values.id_availability_condition_type) {
        errors.id_availability_condition_type = 'Debes ingresar un tipo de condición.'
      }

      return errors
    },
    onSubmit: (values) => {
      const { id, id_player, id_availability_condition_type } = values

      if (initialValues.clinicalEventActive) {
        if (
          (id_availability_condition_type === '2' ||
          id_availability_condition_type === '3' ||
          id_availability_condition_type === '5' ||
          id_availability_condition_type === '6')
        ) {
          return (
            setForbidden('player-info')
          )
        } else if (
          (id_availability_condition_type === '7' ||
          id_availability_condition_type === '8' ||
          id_availability_condition_type === '9' ||
          id_availability_condition_type === '20' ||
          id_availability_condition_type === '21')
        ) {
          return (
            setForbidden('no-redirect')
          )
        } else if (id_availability_condition_type === '1') {
          if (!window.confirm('Esta acción es irreversible, ¿Estás seguro de sanar a este jugador?')) {
            return false
          }
        }
      }

      if (
        action === 'UPDATE' && (
          id_availability_condition_type === '2' ||
          id_availability_condition_type === '3' ||
          id_availability_condition_type === '5' ||
          id_availability_condition_type === '6'
        )
      ) {
        return (
          setForbidden('create-clinical-event')
        )
      }

      const input = {
        id_availability_condition_type: parseInt(id_availability_condition_type, 10),
        date: moment().format('YYYY-MM-DD')
      }

      if (action === 'CREATE') {
        input.id_player = parseInt(id_player, 10)
      } else {
        input.id = parseInt(id, 10)
      }

      availabilityMutation({
        variables: { input }
      }).then(() => handleClose())

    }
  })

  return (
    <Modal
      show={openModal}
      onHide={handleClose}
      dialogClassName="full-width"
      contentClassName="full-height"
    >
      {/* <Modal.Header closeButton>
        <Modal.Title>
          {action === 'CREATE'
            ? 'Agregar '
            : 'Modificar '
          }
          disponibilidad
        </Modal.Title>
      </Modal.Header> */}

      <Modal.Header closeButton className="bg-success text-white">
        <Modal.Title>
          {/* <FaPen className="mr-1 mb-0" color={'white'} /> */}
          {action === 'CREATE'
            ? 'Agregar '
            : 'Modificar '
          }
          disponibilidad
        </Modal.Title>
      </Modal.Header>
      <Form noValidate onSubmit={summarySchema.handleSubmit}>
        {forbidden ?
          handleForbidden(forbidden) :
          (initialValues.day !== new Date().getDate().toString() ?
            <Alert variant="warning">
              Solo puedes modificar la condición del día de hoy: {moment(new Date()).format('DD/MM/YYYY')}
            </Alert> :
            <React.Fragment>
              <Modal.Body>
                <Form.Group>
                  <Form.Label>Condiciones de disponibilidad</Form.Label>
                  <Form.Control
                    required
                    as="select"
                    name="id_availability_condition_type"
                    placeholder="Selecciona un tipo de condición"
                    value={summarySchema.values.id_availability_condition_type}
                    onChange={summarySchema.handleChange}
                    onBlur={summarySchema.handleBlur}
                    isInvalid={
                      summarySchema.touched.id_availability_condition_type && summarySchema.errors.id_availability_condition_type
                        ? true : false
                    }
                  >
                    <option value="">Selecciona un tipo de condición</option>
                    {availabilityTypesQuery.data.getAvailabilityConditionsTypes
                      .availabilityConditionsTypes.map(
                        (availabilityType, key) =>
                          <option key={key} value={availabilityType.id}>
                            {availabilityType.name_availability_condition_type}
                          </option>
                      )
                    }
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {summarySchema.errors.id_availability_condition_type}
                  </Form.Control.Feedback>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-end">
                <Button variant="success" type="submit">
                  {availabilityMutationResult.loading
                    ? <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    : (action === 'CREATE')
                      ? 'Agregar'
                      : 'Modificar'
                  }
                </Button>
              </Modal.Footer>
            </React.Fragment>)
        }
      </Form>
    </Modal>
  )
}

AvailabilityForm.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  availabilityCondition: PropTypes.shape({
    action: PropTypes.string.isRequired,
    initialValues: PropTypes.shape({
      id: PropTypes.string,
      id_player: PropTypes.string,
      id_availability_condition_type: PropTypes.string,
      day: PropTypes.string,
      clinicalEventActive: PropTypes.bool,
      id_clinical_event: PropTypes.number
    })
  }),
  filters: PropTypes.shape({
    id_category_type: PropTypes.number.isRequired,
    id_availability_condition_type: PropTypes.number
  })
}

export default AvailabilityForm
