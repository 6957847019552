import React from 'react'
import moment from 'moment'
import Styles from './MedicalStatisticsStyle'
import PropTypes from 'prop-types'
import { useLazyQuery } from '@apollo/client'
import { GET_DASHBOARD_DIAGNOSTICS } from '../../../apollo/queries'
import { Card , Accordion } from 'react-bootstrap'
import { SorenessTab } from '../../DashboardClinicalEvents/SorenessTab'
import { InjuryTab } from '../../DashboardClinicalEvents/InjuryTab'
import { SurgeryTab } from '../../DashboardClinicalEvents/SurgeryTab'
import { AllTab } from '../../DashboardClinicalEvents/AllTab'
import { IlnessTab } from '../../DashboardClinicalEvents/IlnessTab'
import { FilterRow, DateFilter } from '../../../components'

const MedicalStatistics = ({ idPlayer }) => {

  const [filters, setFilters] = React.useState({
    id_player: idPlayer,
    id_diagnostic_type: 1,
    start_date: new Date(moment().subtract(6, 'months')),
    end_date: new Date()
  })

  const [getDiagnostics, diagnostics] = useLazyQuery(GET_DASHBOARD_DIAGNOSTICS)

  React.useEffect(() => {
    getDiagnostics({
      variables: {
        input: filters
      }
    })
  }, [filters])

  return (
    <Styles>
      <div className="row">
        <div className="col d-flex flex-wrap align-items-baseline justify-content-end">
          <h5 className="mr-auto text-secondary">
              Estadísticas Médicas
          </h5>
          <FilterRow>
            <DateFilter
              value={{
                start_date: filters.start_date,
                end_date: filters.end_date
              }}
              setValue={setFilters}
            />
          </FilterRow>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Accordion
            className="mb-4"
            id="dashboard-menu"
          >
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="1"
                className="header-accordion d-flex"
                onClick={() => {
                  setFilters({ ...filters, id_diagnostic_type: 1 })
                  getDiagnostics({
                    variables: {
                      input: { ...filters, id_diagnostic_type: 1 }
                    }
                  })
                }}
              >
                <p className='text-white mb-0'>Molestia</p>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body className="mt-0">
                  <SorenessTab
                    data={diagnostics?.data?.getDashboardDiagnostics?.dashboardData}
                    filters={filters}
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="2"
                className="header-accordion d-flex"
                onClick={() => {
                  setFilters({ ...filters, id_diagnostic_type: 2 })
                  getDiagnostics({
                    variables: {
                      input: { ...filters, id_diagnostic_type: 2 }
                    }
                  })
                }}
              >
                <p className="mb-0 text-white">Lesión</p>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <InjuryTab
                    data={diagnostics?.data?.getDashboardDiagnostics?.dashboardData}
                    filters={filters}
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="3"
                className="header-accordion d-flex"
                onClick={() => {
                  setFilters({ ...filters, id_diagnostic_type: 3 })
                  getDiagnostics({
                    variables: {
                      input: { ...filters, id_diagnostic_type: 3 }
                    }
                  })
                }}
              >
                <p className="mb-0 text-white">Enfermedad</p>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <IlnessTab
                    data={diagnostics?.data?.getDashboardDiagnostics?.dashboardData}
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="4"
                className="header-accordion d-flex"
                onClick={() => {
                  setFilters({ ...filters, id_diagnostic_type: 4 })
                  getDiagnostics({
                    variables: {
                      input: { ...filters, id_diagnostic_type: 4 }
                    }
                  })
                }}
              >
                <p className="mb-0 text-white">Cirugía</p>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="4">
                <Card.Body>
                  <SurgeryTab
                    data={diagnostics?.data?.getDashboardDiagnostics?.dashboardData}
                    filters={filters}
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="5"
                className="header-accordion d-flex"
                onClick={() => {
                  getDiagnostics({
                    variables: {
                      input: {
                        id_player: idPlayer
                      }
                    }
                  })
                }}
              >
                <p className="mb-0 text-white">Todos</p>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="5">
                <Card.Body>
                  <AllTab
                    data={diagnostics?.data?.getDashboardDiagnostics?.dashboardData}
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      </div>
    </Styles>
  )
}

MedicalStatistics.propTypes = {
  idPlayer: PropTypes.number
}

export default MedicalStatistics
