import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { Form } from 'react-bootstrap'
import { GET_CATEGORY_TYPES } from '../../../apollo/queries'
import { appStore } from '../../../store/appStore'

const CategoryFilter = ({ value, setValue, modal = false }) => {
  const categoryTypesQuery = useQuery(GET_CATEGORY_TYPES)

  const { dispatch } = useContext(appStore)

  const handleValue = (filter) => {
    dispatch({ type: 'setDefaultCategoryType', payload: parseInt(filter, 10) })
    setValue((prevState)=>({
      ...prevState,
      id_category_type: parseInt(filter,10)
    }))
  }

  return (
    <Form.Group>
      {modal && <Form.Label>Tipo de Categoría</Form.Label>}
      <Form.Control
        as="select"
        placeholder="Categoría"
        value={value}
        onChange={(e) => handleValue(e.target.value)}
      >
        {categoryTypesQuery.loading ?
          <option>Cargando...</option> :
          <React.Fragment>
            {categoryTypesQuery.data.getCategoryTypes.categoryTypes.map((categoryType, key) =>
              <option key={key} value={categoryType.id}>
                {categoryType.name_category}
              </option>
            )}
          </React.Fragment>
        }
      </Form.Control>
    </Form.Group>
  )
}


CategoryFilter.propTypes = {
  value: PropTypes.number,
  setValue: PropTypes.func.isRequired,
  modal: PropTypes.bool
}

export default CategoryFilter
