import { gql } from '@apollo/client'

const GET_KINESIC_BLOCK_BODY_ZONES = gql`
  query getKinesicBlockBodyZones($input: KinesicBlockBodyZoneInput!) {
    getKinesicBlockBodyZones(input: $input) {
      kinesicBlockBodyZones {
        id
        id_kinesic_blocks
        id_kinesic_body_zone
        tenant_code
        KinesicBlock {
          id
          name
          tenant_code
        }
        KinesicBodyZone {
          id
          name
          tenant_code
        }
      }
      status {
        success
        code
        message
      }
    }
  }
`
export default GET_KINESIC_BLOCK_BODY_ZONES

