import React from 'react'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import { Form, Col } from 'react-bootstrap'

const MeterInput = ({ meter }) => {
  const { values, handleChange, handleBlur, touched, errors } = useFormikContext()

  const getInput = (type) => {
    switch(type) {
    case '1':
    case '2':
      return (
        <Form.Control
          type="number"
          name={meter.id}
          value={values[meter.id]}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      )
    case '4':
      return (
        <Form.Control
          as="select"
          name={meter.id}
          value={values[meter.id]}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          <option value="">Selecciona una opción</option>
          {meter.MeterOptions.map(option =>
            <option key={option.id} value={option.id}>{option.name}</option>
          )}
        </Form.Control>
      )
    default:
      return null
    }
  }

  return (
    meter ?
      <Form.Group as={Col}>
        <Form.Label>{meter.name}</Form.Label>
        {getInput(meter.MeterType.id)}
        {(touched[meter.id] && errors[meter.id]) &&
          <div className="invalid-feedback d-block">
            {errors[meter.id]}
          </div>
        }
      </Form.Group> : null
  )
}

MeterInput.propTypes = {
  meter: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    MeterType: PropTypes.shape({
      id: PropTypes.string.isRequired
    }),
    MeterOptions: PropTypes.array
  })
}

export default MeterInput
