import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'

const useExcelUtils = () => {
  const escapeJson = (json) =>{
    return json.replace(/\\n/g, '\\n')
      .replace(/\\'/g, '\\\'')
      .replace(/\\"/g, '\\"')
      .replace(/\\&/g, '\\&')
      .replace(/\\r/g, '\\r')
      .replace(/\\t/g, '\\t')
      .replace(/\\b/g, '\\b')
      .replace(/\\f/g, '\\f')
  }

  const exportToExcel = (excelData, fileName, fileExtension = 'xlsx') => {
    const sheetData = XLSX.utils.json_to_sheet(excelData)
    const sheetConfig = { Sheets: { 'data': sheetData }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(sheetConfig, { bookType: fileExtension, type: 'array' })
    const excelFile = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(excelFile, `${fileName}.${fileExtension}`)
  }

  return{
    escapeJson,
    exportToExcel
  }
}

export default useExcelUtils
