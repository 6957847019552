import { gql } from '@apollo/client'

const GET_SUB_DIAGNOSTICS = gql`
  query getSubDiagnostics($input: SubDiagnosticInput!) {
    getSubDiagnostics(input: $input) {
      status {
        success
        code
        message
      }
      subDiagnostics {
        id
        name
      }
    }
  }
`

export default GET_SUB_DIAGNOSTICS
