import React, { useContext } from 'react'
import moment from 'moment'
import { useLocation, useHistory } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { GET_DASHBOARD_DIAGNOSTICS } from '../../apollo/queries'
import { Tabs, Tab } from 'react-bootstrap'
import { FilterRow, CategoryFilter, Loading, CustomBreadcrumb, DateFilter, TitleSection } from '../../components'
import { SorenessTab } from './SorenessTab'
import { InjuryTab } from './InjuryTab'
import { IlnessTab } from './IlnessTab'
import { SurgeryTab } from './SurgeryTab'
import { AllTab } from './AllTab'
import Styles from './DashboardClinicalEventStyle'
import { appStore } from '../../store/appStore'

const DashboardClinicalEvents = () => {
  let history = useHistory()
  let query = new URLSearchParams(useLocation().search)

  const { state } = useContext(appStore)

  const [filters, setFilters] = React.useState({
    id_category_type: state.defaultCategoryType,
    start_date: new Date(moment().subtract(6, 'months')),
    end_date: new Date()
  })

  const [getDiagnostics, diagnostics] = useLazyQuery(GET_DASHBOARD_DIAGNOSTICS)

  React.useEffect(() => {
    if (query.get('diagnosticType') === 'All') {
      getDiagnostics({
        variables: {
          input: {
            id_category_type: filters.id_category_type,
            start_date: filters.start_date,
            end_date: filters.end_date
          }
        },
        fetchPolicy: 'network-only'
      })
    } else {
      getDiagnostics({
        variables: {
          input: {
            id_category_type: filters.id_category_type, // PLANTEL ADULTO
            id_diagnostic_type: parseInt(query.get('diagnosticType')) || 1, // MOLESTIA
            start_date: filters.start_date,
            end_date: filters.end_date
          }
        },
        fetchPolicy: 'network-only'
      })
    }
  }, [filters])

  if (getDiagnostics.loading) {
    return (
      <Loading />
    )
  }

  if (getDiagnostics.error) {
    return (
      <div>Error :(</div>
    )
  }

  return (
    <Styles>
      <CustomBreadcrumb
        active="Estadísticas médicas"
      />
      <div className='d-flex flex-wrap aling-item-baseline justify-content-between'>
        <TitleSection title={'Estadísticas médicas'} />
        <FilterRow>
          <CategoryFilter
            value={filters.id_category_type}
            setValue={setFilters}
          />
          <DateFilter
            value={{
              start_date: filters.start_date,
              end_date: filters.end_date
            }}
            setValue={setFilters}
          />
        </FilterRow>
      </div>
      <div className="row">
        <div className="col">
          {diagnostics?.data &&
            <Tabs
              id="dashboard-menu"
              defaultActiveKey={query.get('diagnosticType') || '1'}
              onSelect={
                (eventKey) => {
                  if (eventKey === 'All') {
                    setFilters({
                      id_category_type: filters.id_category_type,
                      start_date: filters.start_date,
                      end_date: filters.end_date
                    })
                  } else {
                    setFilters((prevState) => ({
                      ...prevState,
                      id_diagnostic_type: parseInt(eventKey)
                    }))
                  }
                  history.push(`/clinical-dashboard?diagnosticType=${eventKey}`)
                }
              }
            >
              <Tab eventKey="1" title="Molestia">
                <SorenessTab
                  data={diagnostics?.data?.getDashboardDiagnostics?.dashboardData}
                  filters={filters}
                />
              </Tab>
              <Tab eventKey="2" title="Lesión">
                <InjuryTab
                  data={diagnostics?.data?.getDashboardDiagnostics?.dashboardData}
                  filters={filters}
                />
              </Tab>
              <Tab eventKey="3" title="Enfermedad">
                <IlnessTab
                  data={diagnostics?.data?.getDashboardDiagnostics?.dashboardData}
                />
              </Tab>
              <Tab eventKey="4" title="Cirugía">
                <SurgeryTab
                  data={diagnostics?.data?.getDashboardDiagnostics?.dashboardData}
                  filters={filters}
                />
              </Tab>
              <Tab eventKey="All" title="Todos">
                <AllTab
                  data={diagnostics?.data?.getDashboardDiagnostics?.dashboardData}
                />
              </Tab>
            </Tabs>
          }
        </div>
      </div>
    </Styles>
  )
}

export default DashboardClinicalEvents
