const useFormatsHelper = () => {
  const parseFloatValue = (value = '') => {
    if(isNaN(value)) {
      return parseFloat(value.replace(/,/g, '.'))
    }
    return value
  }

  return { parseFloatValue }
}

export default useFormatsHelper
