import React, { createContext } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

export const KinesicEvaluationContext = createContext()

const KinesicEvaluationProvider = ({ children }) => {
  const [filters, setFilters] = React.useState({
    start_date: new Date(moment().subtract(12, 'months')),
    end_date: new Date()
  })

  return (
    <KinesicEvaluationContext.Provider
      value={{
        filters,
        setFilters,
      }}
    >
      {children}
    </KinesicEvaluationContext.Provider>
  )
}

KinesicEvaluationProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default KinesicEvaluationProvider
