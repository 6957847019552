import { gql } from '@apollo/client'

const CREATE_MASSIVE_LOAD = gql`
  mutation createMassiveLoad($input: CreateMassiveLoadInput!) {
    createMassiveLoad(input: $input) {
      status {
        success
        code
        message
      }
      errors {
        row
        message
        index
      }
    }
  }
`

export default CREATE_MASSIVE_LOAD
