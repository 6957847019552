import { gql } from '@apollo/client'

const DELETE_HISTORY_FILE = gql`
  mutation deleteHistoryFile($input: DeleteMedicalHistoryPlayerInput!) {
    deleteHistoryFile(input: $input) {
      status {
        success
        code
        message
      }
    }
  }
`

export default DELETE_HISTORY_FILE
