import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'

const GetSelectOptions = ({ placeholder = '', query, queryName, mapName }) => {
  const { data, loading, error } = useQuery(query)

  if (error) {
    return (
      <option value="">Ocurrió un error. Intenta nuevamente.</option>
    )
  }

  if (loading) {
    return (
      <option value="">Cargando...</option>
    )
  }

  return (
    <>
      {placeholder && <option value="">{placeholder}</option>}
      {data[queryName][mapName].map(option =>
        <option value={option?.id || ''} key={option.id}>{option.name}</option>
      )}
    </>
  )
}

GetSelectOptions.propTypes = {
  placeholder: PropTypes.string,
  query: PropTypes.object.isRequired,
  queryName: PropTypes.string.isRequired,
  mapName: PropTypes.string.isRequired,
}

export default GetSelectOptions
