import { gql } from '@apollo/client'

const UPDATE_EXAM = gql`
  mutation updateExam($input: UpdateExamInput!) {
    updateExam(input: $input) {
      exam {
        id
      }
      status {
        success
        code
        message
      }
    }
  }
`

export default UPDATE_EXAM
