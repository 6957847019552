import { gql } from '@apollo/client'

const CREATE_SINGLE_PLAYER_DIMENSION = gql`
  mutation createSinglePlayerDimension($input: CreateSinglePlayerDimensionInput!) {
    createSinglePlayerDimension(input: $input) {
      status {
        success
        code
        message
      }
    }
  }
`
export default CREATE_SINGLE_PLAYER_DIMENSION

