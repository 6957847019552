import { gql } from '@apollo/client'

const DELETE_PLAYER_GPS = gql`
  mutation deletePlayerGps($input:DeletePlayerGpsInput!) {
    status {
      success
      code
    }
  }

  # {
  #   "input": {
  #     "id": 4
  #   }
  # }
`
export default DELETE_PLAYER_GPS
