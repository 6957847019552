const NUTRITION_DIMENSION = 2

const CLASSIFICATION = new Map([
  ['37', 'Excelente'],
  ['38', 'Bueno'],
  ['39', 'Aceptable'],
  ['40', 'Elevado'],
  ['41', 'Muy Elevado'],
  ['42', 'Excelente'],
  ['43', 'Bueno'],
  ['44', 'Aceptable'],
  ['45', 'Elevado'],
  ['46', 'Muy Elevado'],
  ['47', 'Excelente'],
  ['48', 'Bueno'],
  ['49', 'Aceptable'],
  ['50', 'Elevado'],
  ['51', 'Muy Elevado'],
  ['52', 'Muy Elevado'],
  ['53', 'Endoformo'],
  ['54', 'Ectomorfo'],
  ['55', 'Mesoectoformo'],
  ['56', 'Endoectoformo'],
  ['57', 'Mesoendoformo'],
  ['53', 'Endoformo'],
  ['54', 'Ectomorfo'],
  ['55', 'Mesoectoformo'],
  ['56', 'Endoectoformo'],
  ['57', 'Mesoendoformo'],
])

export {
  NUTRITION_DIMENSION,
  CLASSIFICATION
}
