import { gql } from '@apollo/client'

const GET_GPS_GROUPERS = gql`
  query getGpsGroupers {
    getGpsGroupers {
      gpsGroupers {
        id
        name
      }
      status {
        success
        code
        message
      }
    }
  }
`
export default GET_GPS_GROUPERS

