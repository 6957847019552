import { gql } from '@apollo/client'

const GET_SKELETAL_MUSCLES = gql`
  query getSkeletalMuscles($input: SkeletalMuscleInput!) {
    getSkeletalMuscles(input: $input) {
      status {
        success
        code
        message
      }
      skeletalMuscles {
        id
        name
      }
    }
  }
`

export default GET_SKELETAL_MUSCLES
