import React from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { GET_PLAYER_DIMENSIONS } from '../../../../../apollo/queries'
import { CREATE_PLAYER_DIMENSION, UPDATE_PLAYER_DIMENSION } from '../../../../../apollo/mutations'
import { Formik } from 'formik'
import { Modal, Form, Button,Col } from 'react-bootstrap'
import { FaCheck } from 'react-icons/fa'
import { StatusAlert, SubmitButton, DatePickerInput } from '../../../../../components'
import usePlayerDimension from '../../../usePlayerDimension'
import Styles from './PlayerNutritionalFormStyle'

const PlayerNutritionalForm = ({
  idPlayer,
  openModal = false,
  setOpenModal,
  dimension,
  action = 'CREATE',
  updateValues = {}
}) => {

  const handleClose = () => setOpenModal(false)

  const updateCache = (cache, { data }) => {
    cache.modify({
      fields: {
        getPlayerDimensions(existingPlayerDimensions = []) {
          const newPlayerDimensions = data.getPlayerDimensions
          cache.writeQuery({
            query: GET_PLAYER_DIMENSIONS,
            data: {
              newPlayerDimensions,
              ...existingPlayerDimensions
            }
          })
        }
      }
    })
  }

  const [createPlayerDimension, createResult] = useMutation(
    action === 'CREATE' ? CREATE_PLAYER_DIMENSION : UPDATE_PLAYER_DIMENSION, {
      update: updateCache
    }
  )

  const modalTitle = action === 'CREATE' ? 'Crear Antropometría': 'Editar Antropometría'
  const successMessage = action === 'CREATE' ? 'Antropometría creada con éxito.' : 'Antropometría actualizada con éxito.'
  const statusResult = action === 'CREATE' ? createResult.data?.createPlayerDimension.status : createResult.data?.updatePlayerDimension.status

  const { getInitialValues, onSubmit, allMeters } = usePlayerDimension(idPlayer, action, 2)

  return (
    <Modal
      size="lg"
      show={openModal}
      dialogClassName="full-width"
      contentClassName="full-height"
      onHide={handleClose}
    >
      <Modal.Header closeButton className="bg-success text-white">
        <Modal.Title>
          <FaCheck className="mr-1" color={'white'} />
          {modalTitle}
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={
          getInitialValues(allMeters, updateValues)
        }
        onSubmit={(values, { resetForm }) => {
          onSubmit(createPlayerDimension, values, resetForm, `/nutrition/${idPlayer}`)
        }}
        validate={values => {
          const errors = {}
          if (!values.date) {
            errors.date = 'Debes ingresar una fecha'
          }
          return errors
        }}
      >
        {({
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          setFieldValue,
          handleSubmit
        }) => (
          <Form onSubmit={handleSubmit}>
            {createResult.data &&
                <StatusAlert
                  successMessage={successMessage}
                  status={statusResult}
                />
            }
            <Styles>
              <Modal.Body>
                <div className="row">
                  <Form.Group as={Col} className="mb-0">
                    <DatePickerInput
                      className="col-12 col-md-6"
                      label="Fecha"
                      name="date"
                      value={values.date}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      touched={touched.date ? true : false}
                      error={errors.date}
                    />
                  </Form.Group>

                  {dimension?.DimensionTests[0]?.Meters.map((meter =>
                    <Form.Group key={meter.id} className="Col-12 col-md-6">
                      <Form.Label>{meter.name}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={`Ingrese ${meter.name}`}
                        name={meter.id}
                        value={values[meter.id] || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={
                          (touched[meter.id] && errors[meter.id])
                            ? true
                            : false
                        }
                      />
                    </Form.Group>
                  ))}
                </div>
              </Modal.Body>
            </Styles>
            <Modal.Footer className="d-flex justify-content-end">
              <Button variant="secondary" onClick={handleClose}>
                Cerrar
              </Button>
              <SubmitButton
                action={action}
                loading={createResult.loading}
                className="btn list-item px-6"
              />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

PlayerNutritionalForm.propTypes = {
  dimension: PropTypes.shape({
    DimensionTests: PropTypes.arrayOf(
      PropTypes.shape({
        Meters: PropTypes.array
      })
    )
  }),
  idPlayer: PropTypes.number,
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  action: PropTypes.string,
  updateValues: PropTypes.object
}

export default PlayerNutritionalForm
