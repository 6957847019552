import { gql } from '@apollo/client'

const GET_INSTANCES = gql`
  query getInstances {
    getInstances {
      instances {
        id
        name
      }
      status{
        success
        code
        message
      }
    }
  }
`

export default GET_INSTANCES
