import styled from 'styled-components'

export default styled.div`

  Table{
    font-size: 13px;
  }

  th{
    min-width:110px;
  }

  .header-accordion{
    background-color: #ececec !important;
  }

  .header-accordion:hover {
    background-color: #28a745 !important;
    color: white;
    cursor: pointer;
  }

`
