import { gql } from '@apollo/client'

const GET_DIAGNOSTIC_MEDICINES = gql`
  query getDiagnosticMedicines($input: DiagnosticMedicineInput!) {
    getDiagnosticMedicines(input: $input) {
      diagnosticMedicines {
        id
        dose
        date
        id_medicine
        id_medicine_classification
        id_medicine_via
        comments
      }
      status {
        success
        code
        message
      }
    }
  }
`

export default GET_DIAGNOSTIC_MEDICINES

