import React from 'react'
import PropTypes from 'prop-types'
import hasRole from '../../../../../keycloak/utils/hasRole'
import { useParams, useHistory } from 'react-router-dom'
import { Formik } from 'formik'
import { Form } from 'react-bootstrap'
import { useMutation } from '@apollo/client'
import { UPDATE_CLINICAL_EVENT } from '../../../../../apollo/mutations'
import { ClinicalEventForm, CustomBreadcrumb, FooterForm, StatusAlert, TitleSection } from '../../../../../components'
import { appStore } from '../../../../../store/appStore'
import Styles from './EditClinicalEventStyle'

const EditClinicalEvent = ({ location }) => {
  const { clinicalEvent } = location

  let history = useHistory()
  let { id_clinical_event } = useParams()

  const containerRef = React.useRef()
  const { state } = React.useContext(appStore)

  const [updateClinicalEvent, { data, loading, error }] = useMutation(UPDATE_CLINICAL_EVENT)

  if (!state) {
    history.goBack()
  }

  if (error) {
    return (
      <div>Error :(</div>
    )
  }

  if (!clinicalEvent || !state.player) {
    return (
      <div onClick={history.goBack()} />
    )
  }

  const isCoch = state?.tenant?.code === 'COCH' || state?.tenant?.code === 'TRICEPS-QA'

  const breadcrumbPath = [
    { label: 'Ver Deportistas', href: '/players' },
    { label: 'Resumen médico', href: `/player-info/${state.player?.id_category_type}/${clinicalEvent.id_player}/clinical-event` },
    { label: 'Detalle evento clínico', href: `/clinical-event-details/${clinicalEvent.id_player}/${id_clinical_event}` }
  ]

  return (
    <React.Fragment>
      <CustomBreadcrumb
        path={hasRole(['role_player']) ? breadcrumbPath.slice(1) : breadcrumbPath }
        active="Editar Antecedentes"
      />
      <Formik
        initialValues={{
          id_instance: clinicalEvent.Instance?.id || '',
          id_match_moment: clinicalEvent.MatchMoment?.id || '',
          id_severity: clinicalEvent.Severity?.id || '',
          id_specific_mechanism: clinicalEvent.SpecificMechanism?.id || '',
          id_general_mechanism: clinicalEvent.GeneralMechanism?.id || '',
          // start_date: new Date(clinicalEvent.createdAt),
          // end_date: clinicalEvent.ClinicalEventHistories[0]?.end_date ? new Date(clinicalEvent.ClinicalEventHistories[0]?.end_date) : clinicalEvent.updatedAt,
        }}
        validate={values => {
          const errors = {}

          if (!values.id_instance) {
            errors.id_instance = 'Debes ingresar una instancia.'
          }
          if (!values.id_match_moment && values.id_instance === '2' && !isCoch) {
            errors.id_match_moment = 'Debes ingresar una instancia de partido.'
          }
          if (!values.id_severity) {
            errors.id_severity = 'Debes ingresar una instancia.'
          }
          if (!values.id_specific_mechanism) {
            errors.id_specific_mechanism = 'Debes ingresar una instancia.'
          }
          if (!values.id_general_mechanism) {
            errors.id_general_mechanism = 'Debes ingresar una instancia.'
          }

          return errors
        }}
        onSubmit={(values) => {
          updateClinicalEvent({
            variables: {
              input: {
                id: clinicalEvent.id,
                id_instance: parseInt(values.id_instance),
                id_match_moment: parseInt(values.id_match_moment),
                id_severity: parseInt(values.id_severity),
                id_specific_mechanism: parseInt(values.id_specific_mechanism),
                id_general_mechanism: parseInt(values.id_general_mechanism),
                start_date: values.start_date,
                end_date: values.end_date
              }
            }
          }).then(({ data }) => {
            if (data?.updateClinicalEvent.status.success) {
              history.replace(`/clinical-event-details/${clinicalEvent.id_player}/${id_clinical_event}`)
            }
            containerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
          })
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          setFieldValue,
          setTouched
        }) => (
          <Styles>
            <div className="container" ref={containerRef}>
              {data &&
                <StatusAlert
                  status={{ success: data.updateClinicalEvent.status.success }}
                />
              }
              <TitleSection title={'Editar Antecedentes'} />
              <div className="mb-3">
                <Form onSubmit={handleSubmit}>
                  <ClinicalEventForm
                    values={values}
                    touched={touched}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    setTouched={setTouched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    idInstance={clinicalEvent.Instance?.id}
                  />
                  <FooterForm
                    className="mt-3"
                    action={'EDIT'}
                    loading={loading}
                    handleCancel={() => history.goBack()}
                  />
                </Form>
              </div>
            </div>
          </Styles>
        )}
      </Formik>
    </React.Fragment>
  )
}

EditClinicalEvent.propTypes = {
  location: PropTypes.shape({
    clinicalEvent: PropTypes.shape({
      id: PropTypes.number,
      id_player: PropTypes.number,
      createdAt: PropTypes.instanceOf(Date),
      updatedAt: PropTypes.instanceOf(Date),
      Instance: PropTypes.shape({
        id: PropTypes.number
      }),
      MatchMoment: PropTypes.shape({
        id: PropTypes.number
      }),
      Severity: PropTypes.shape({
        id: PropTypes.number
      }),
      SpecificMechanism: PropTypes.shape({
        id: PropTypes.number
      }),
      GeneralMechanism: PropTypes.shape({
        id: PropTypes.number
      }),
      ClinicalEventHistories: PropTypes.shape({
        end_date: PropTypes.string
      })
    })
  })
}

export default EditClinicalEvent
