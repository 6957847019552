import { gql } from '@apollo/client'

const GET_EXAMS = gql`
  query getExams($input: ExamInput!) {
    getExams(input: $input) {
      exams {
        id
        date
        title
        id_player
        id_diagnostic
        comments
        ExamImages {
          id
          image_key
          name
          comments
          date
        }
        ExamFiles {
          id
          key
          name
          comments
          date
          mime_type
        }
      }
      status {
        success
        code
        message
      }
    }
  }
`

export default GET_EXAMS
