import React from 'react'
import PropTypes from 'prop-types'
import DatePicker, { registerLocale } from 'react-datepicker'
import { Form } from 'react-bootstrap'
import es from 'date-fns/locale/es'

registerLocale('es', es)

const DateFilter = ({ value, setValue, modal = false }) => {
  const handleStartDate = (date) => {
    setValue((prevState)=>({
      ...prevState,
      start_date: date,
    }))
  }

  const handleEndDate = (date) => {
    setValue((prevState)=>({
      ...prevState,
      end_date: date,
    }))
  }

  return (
    <>
      {modal && <Form.Label>Rango de Fecha</Form.Label>}
      <br/>
      <DatePicker
        locale="es"
        className={!modal && 'ml-3'}
        selected={value.start_date}
        onChange={date => handleStartDate(date)}
        selectsStart
        dateFormat="dd/MM/yyyy"
        startDate={value.start_date}
        endDate={value.end_date}
        customInput={
          <Form.Control
            required
            as="input"
            placeholder="Selecciona una fecha de inicio"
            style={!modal ? { width: '120px' } : {}}
          />
        }
      />
      <DatePicker
        selected={value.end_date}
        onChange={date => handleEndDate(date)}
        selectsEnd
        dateFormat="dd/MM/yyyy"
        startDate={value.start_date}
        endDate={value.end_date}
        minDate={value.start_date}
        customInput={
          <Form.Control
            required
            as="input"
            placeholder="Selecciona una fecha de inicio"
            style={!modal ? { marginLeft: '1rem', width: '120px' } : {}}
          />
        }
      />
    </>
  )
}

DateFilter.propTypes = {
  value: PropTypes.shape({
    start_date: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date)
    ]),
    end_date: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date)
    ])
  }),
  setValue: PropTypes.func,
  modal: PropTypes.bool
}

export default DateFilter
