import React from 'react'
import PropTypes from 'prop-types'
import { CustomChart } from '../../../components'
import Styles from '../DashboardClinicalEventStyle'

const IlnessTab = ({ data }) => {
  return (
    <Styles>
      <div className="row d-flex flex-wrap align-items-center">
        <div className="col-12 col-lg-6">
          <CustomChart
            title="Categoría"
            type="line"
            data={{
              datasets: [{
                label: '# Enfermedades',
                fill: false,
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: '#f05fa3',
                data: data?.diagnosticTypes
              }]
            }}
            options={{
              scales: {
                xAxes: [{
                  type: 'time',
                  time: {
                    unit: 'day',
                  },
                }],
                yAxes: [
                  {
                    ticks: {
                      stepSize: 1,
                      beginAtZero: true,
                    },
                  },
                ],
              },
            }}
          />
        </div>
        <div className="col-12 col-lg-6">
          <CustomChart
            title="Tipo"
            type="bar"
            data={{
              labels: data?.subDiagnostics.reduce((acc, value) =>
                [...acc, value.x]
              ,[]),
              datasets: [{
                label: 'Enfermedades',
                data: data?.subDiagnostics.reduce((acc, value) =>
                  [...acc, value.y]
                ,[]),
                backgroundColor: [
                  'rgba(255, 99, 132, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(255, 206, 86, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(153, 102, 255, 0.2)',
                  'rgba(255, 159, 64, 0.2)',
                ]
              }]
            }}
            options={{
              legend: {
                display: false,
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      stepSize: 1,
                      beginAtZero: true,
                    },
                  },
                ],
              },
            }}
          />
        </div>
      </div>

    </Styles>
  )
}

IlnessTab.propTypes = {
  data: PropTypes.shape({
    diagnosticTypes: PropTypes.array.isRequired,
    subDiagnostics: PropTypes.array.isRequired
  })
}

export default IlnessTab
