import { gql } from '@apollo/client'

const CHECK_USER = gql`
  mutation checkUser {
    checkUser {
      user {
        id
        id_user_type
        name
        last_name
        terms_accepted
        UserType {
          id
          name_user
        }
        Player {
          id
          id_category_type
        }
        UserCategoryTypes {
          id_category_type
        }
      }
      status {
        success
        code
        message
      }
    }
  }
`

export default CHECK_USER

