import { gql } from '@apollo/client'

const GET_GPS_MOMENTS = gql`
  query getGpsMoments {
    getGpsMoments {
      gpsMoments {
        id
        name
      }
      status {
        success
        code
        message
      }
    }
  }
`
export default GET_GPS_MOMENTS

