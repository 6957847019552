import moment from 'moment'
import { useContext } from 'react'
import { appStore } from '../../store/appStore'
import { EVENTS_COLORS } from './constants'
import { useDateHelper } from '../../hooks'

const useCalendar = (events = [], schedules = [], mainFilter, currentView) => {
  const { state } = useContext(appStore)
  const { getFormattedDate } = useDateHelper()
  const showEvents = ['ALL', 'EVENTS'].includes(mainFilter)
  const showSchedule = ['ALL', 'SCHEDULE'].includes(mainFilter)

  const _loggedUserIsScheduleProfesional = (profesionalId) => (profesionalId === state.user.id)

  const _getScheduleColor = (schedule) => {
    if (schedule.id_schedule_type === 4) {
      return 'ca0000'
    }
    return _loggedUserIsScheduleProfesional(schedule.Profesional.id) ? '335da9' : '68b3ff'
  }

  const _getScheduleTitle = (schedule) => {
    if (schedule.id_schedule_type === 4) {
      return 'No Disponible'
    }

    if (currentView === 'agenda') {
      return (
        `
          Profesional:${' '}
          ${schedule.Profesional.name} ${schedule.Profesional.last_name} -
          Paciente:${' '}
          ${schedule.Patient.name} ${schedule.Patient.last_name}
        `
      )
    }

    const scheduleUser = _loggedUserIsScheduleProfesional(schedule.Profesional.id) ? 'Patient' : 'Profesional'
    return `${schedule[scheduleUser].name} ${schedule[scheduleUser].last_name}`
  }

  const parseEvents = showEvents ? events.map((event) => ({
    id: event.id,
    title: event.EventType.name_event_type,
    id_event_type: event.EventType.id,
    id_category_type: event.CategoryType.id,
    start: new Date(event.start_date),
    end: new Date(event.end_date),
    user: `${event.User.name} ${event.User.last_name}`,
    userType: event.User.UserType.name_user,
    releases: event.releases || '',
    hexColor: EVENTS_COLORS[event.EventType.id]
  })) : []

  const parseSchedules = showSchedule ? schedules.map(schedule => {
    // const loggedUserIsScheduleProfesional = (schedule.Profesional.id === state.user.id)
    let endDate = new Date(moment(schedule.datetime).add(schedule.duration, 'minutes'))
    if (getFormattedDate(endDate, 'HH:mm') === '00:00') {
      endDate = new Date(moment(endDate).subtract(1, 'minutes'))
    }
    return ({
      isSchedule: true,
      id: schedule.id,
      title: _getScheduleTitle(schedule),
      start: new Date(schedule.datetime),
      end: endDate,
      hexColor: _getScheduleColor(schedule),
      datetime: schedule.datetime,
      duration: schedule.duration,
      Profesional: schedule.Profesional,
      Patient: schedule.Patient,
      id_schedule_type: schedule.id_schedule_type
    })
  }
  ) : []

  return {
    allEvents: [...parseEvents, ...parseSchedules]
  }
}

export default useCalendar
