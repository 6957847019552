import { gql } from '@apollo/client'

const GET_KINESIC_BLOCKS = gql`
  query getKinesicBlocks {
    getKinesicBlocks {
      kinesicBlocks {
        id
        name
        tenant_code
        KinesicBlockBodyZones{
          id
          id_kinesic_blocks
          id_kinesic_body_zone
          tenant_code
          KinesicBodyZone{
            id
            name
            KinesicBlockMeasures{
              id
              id_kinesic_blocks
              id_kinesic_body_zone
              name
              KinesicMeasureOptions {
                id
                name
                desabled
              }
            }
          }
        }
      }
      status {
        success
        code
        message
      }
    }
  }
`
export default GET_KINESIC_BLOCKS
