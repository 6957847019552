import styled from 'styled-components'

export default styled.div`
  .form-label {
    background-color: #28a745;
    color: white;
    padding: 0 10px;
    width: 100%;
    line-height: 1.5;
    font-size: 1rem;
    font-weight: 400;
  }
`
