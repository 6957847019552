import { gql } from '@apollo/client'

const UPDATE_MEDICAL_HISTORY_PLAYER = gql`
  mutation updateMedicalHistoryPlayer($input: UpdateMedicalHistoryPlayerInput!) {
    updateMedicalHistoryPlayer(input: $input) {
      status {
        success
        code
        message
      }
    }
  }
`

export default UPDATE_MEDICAL_HISTORY_PLAYER
