import React from 'react'
import PropTypes from 'prop-types'
import { Aside, Header, } from '../../../components'
import { appStore } from '../../../store/appStore'
import Styles from './MainLayoutStyle'

const MainLayout = ({ children }) => {
  const { state: { asideCollapsed } } = React.useContext(appStore)

  return (
    <React.Fragment>
      <Header />
      <Styles>
        <Aside />
        <div className={`content ${asideCollapsed ? 'aside-collapsed' : 'aside-expanded'}`}>
          {children}
        </div>
      </Styles>
    </React.Fragment>
  )
}

MainLayout.propTypes = {
  children: PropTypes.node,
}

export default MainLayout
