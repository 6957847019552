import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import { FaChartLine } from 'react-icons/fa'
import Styles from './ChartModalStyle'

const ChartModal = ({
  children,
  openModal,
  setOpenModal,
  title
}) => {

  const handleClose = () => setOpenModal(false)

  return (
    <Styles>
      <Modal
        size="xl"
        show={openModal}
        dialogClassName="full-width"
        contentClassName="full-height"
        onHide={handleClose}
      >
        <Modal.Header closeButton className="bg-success text-white">
          <Modal.Title>
            <FaChartLine className="mr-1" color={'white'} />
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {children}
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </Styles>
  )
}

ChartModal.propTypes = {
  children: PropTypes.node,
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  title: PropTypes.string
}

export default ChartModal
