import { gql } from '@apollo/client'

const CREATE_DIAGNOSTIC = gql`
  mutation createDiagnostic($input: CreateDiagnosticInput!) {
    createDiagnostic(input: $input) {
      diagnostic {
        id
      }
      status {
        success
        code
        message
      }
    }
  }
`

export default CREATE_DIAGNOSTIC
