import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useQuery } from '@apollo/client'
import { GET_PLAYER_GPS, GET_GPS, GET_GPS_FILTERS } from '../../../apollo/queries'
import { Loading, StickyTable } from '../../../components'
import { GpsFilters } from '../GpsFilters'
import { FaPlus } from 'react-icons/fa'

import PlayerGpsModal from './PlayerGpsModal'
import PlayerGpsStyle from './PlayerGpsStyle'
import PlayerGpsCharts from './PlayerGpsCharts'
import usePlayerGps from './usePlayerGps'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import AuthorizedElement from '../../../keycloak/utils/AuthorizedElement'
import { Button } from 'react-bootstrap'

const PlayerGps = ({ idPlayer, categoryType }) => {

  const { data: gpsFiltersData, loading: GpsFiltersloading, error: GpsFiltersError, refetch } = useQuery(GET_GPS_FILTERS, {
    variables: {
      input:{
        id_player: idPlayer,
        id_category_type: categoryType,
        start_date: moment().subtract(6, 'months').format('YYYY-MM-DD'),
        end_date: moment().startOf('day').format('YYYY-MM-DD'),
      }
    },
    fetchPolicy: 'no-cache'
  })

  const [openModal, setOpenModal] = React.useState(false)
  const [filters, setFilters] = React.useState({
    id_gps_moment: null,
    id_gps_moment_desc: null,
    activity: 1,
    start_date: new Date(moment().subtract(6, 'months')),
    end_date: new Date(),
  })

  const { data: { getPlayerGps } = {}, loading, error } = useQuery(GET_PLAYER_GPS, {
    variables:{
      input:{
        id_player: idPlayer,
        id_gps_moment: filters.id_gps_moment,
        id_gps_moment_desc: filters.id_gps_moment_desc,
        activity: filters.activity,
        start_date: moment(filters.start_date).format('YYYY-MM-DD'),
        end_date: moment(filters.end_date).format('YYYY-MM-DD')
      }
    }
  })

  const { data: { getGps } = {} } = useQuery(GET_GPS, {
    variables:{
      input: { id_category_type: categoryType }
    }
  })

  const { data, columns } = usePlayerGps({ gps: getGps?.gps, playerGps: getPlayerGps?.playerGps, filters })

  useEffect(() => {
    if (gpsFiltersData) {
      const [firstFilter] = gpsFiltersData?.getGpsFilters.filters.activities || []
      if (firstFilter) {
        const { id, gpsMoments: [firstMoment] } = firstFilter
        handleSetFilters((current) => ({
          ...current,
          activity: id,
          id_gps_moment: firstMoment.id,
        }))
      }
    }
  }, [gpsFiltersData])

  const handleSetFilters = (setFiltersFunction) => {
    setFilters(setFiltersFunction)

    const filtersObj = setFiltersFunction()
    if (filtersObj.start_date || filtersObj.end_date) {
      refetch({
        input:{
          id_player: idPlayer,
          id_category_type: categoryType,
          start_date: moment(filtersObj.start_date || filters.start_date).format('YYYY-MM-DD'),
          end_date: moment(filtersObj.end_date || filters.end_date,).format('YYYY-MM-DD'),
        }
      })
    }
  }

  if (error || GpsFiltersError) {
    <div>Error :(</div>
  }

  if (loading || GpsFiltersloading) {
    return <Loading />
  }

  return (
    <PlayerGpsStyle.Main>
      <div className='d-flex flex-wrap align-items-baseline justify-content'>
        <h5 className="mr-auto mt-2 text-secondary">
          {'GPS'}
        </h5>
        <GpsFilters
          filters={filters}
          setFilters={setFilters}
          // setOpenModal={setOpenModal}
          gpsFilters={gpsFiltersData?.getGpsFilters.filters}
          handleSetFilters={handleSetFilters}
        />
        <div className='d-lg-block d-none'>
          <AuthorizedElement roles={['permission_team-create-player-gps']}>
            <Button
              className="ml-1"
              style={{ height: '38px' }}
              onClick={() => setOpenModal(true)}
            >
              <FaPlus className="mb-1 mr-2" color={'white'} />
              {'Crear GPS'}
            </Button>
          </AuthorizedElement>
        </div>
      </div>
      <div className='d-block d-lg-none'>
        <AuthorizedElement roles={['permission_team-create-player-gps']}>
          <Button
            className="ml-1"
            style={{ height: '38px' }}
            onClick={() => setOpenModal(true)}
            variant="primary" block
          >
            <FaPlus className="mb-1 mr-2" color={'white'} />
            {'Crear GPS'}
          </Button>
        </AuthorizedElement>
      </div>

      <PlayerGpsCharts
        gps={getGps?.gps}
        playerGps={data}
      />

      <div className="mt-5 ml-4 mb-5 mr-4">
        <StickyTable
          columns={columns}
          data={data}
          getCellProps={cellInfo => ({
            style: {
              background: cellInfo.row.index % 2 === 0 ? '#e0e6f2' : '#ffffff',
            },
          })}
        />
      </div>

      {openModal &&
        <PlayerGpsModal
          gps={getGps?.gps || []}
          openModal={openModal}
          setOpenModal={setOpenModal}
        />
      }
    </PlayerGpsStyle.Main>
  )
}

PlayerGps.propTypes = {
  idPlayer: PropTypes.number.isRequired,
  categoryType: PropTypes.number.isRequired,
}

export default PlayerGps
