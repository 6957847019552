import { gql } from '@apollo/client'

const GET_SEVERITIES = gql`
  query getSeverities {
    getSeverities {
      severities {
        id
        name
      }
      status{
        success
        code
        message
      }
    }
  }
`

export default GET_SEVERITIES
