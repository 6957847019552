import { gql } from '@apollo/client'

const INACTIVATE_CLINICAL_EVENT = gql`
  mutation inactivateClinicalEvent($input: InactiveClinicalEventInput!) {
    inactivateClinicalEvent(input: $input) {
      status {
        success
        code
        message
      }
    }
  }
`

export default INACTIVATE_CLINICAL_EVENT
