import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { Formik } from 'formik'
import { Modal, Form, Col, Button, Spinner } from 'react-bootstrap'
import { FaTrash, FaRunning } from 'react-icons/fa'
import DatePicker, { registerLocale } from 'react-datepicker'
import { GET_PLAYER, GET_CATEGORY_TYPES, GET_POSITIONS_TYPES, GET_PLAYERS } from '../../../apollo/queries'
import { CREATE_PLAYER, UPDATE_PLAYER, DELETE_PLAYER } from '../../../apollo/mutations'
import { StatusAlert, UserImage } from '../../../components'
import es from 'date-fns/locale/es'
import 'react-datepicker/dist/react-datepicker.css'
import Styles from './PlayerFormStyle'

registerLocale('es', es)

const PlayerForm = ({
  openModal = false,
  setOpenModal,
  values = {
    action: 'CREATE',
    player: {
      name: '',
      last_name: '',
      email: '',
      birthday: new Date(),
      gender: '1',
      id_category_type: 10,
      id_position_type: 1,
      side: 1,
      height: 0.0,
      initial_weight: 0.0,
      jersey: '',
      id_availability_condition_type: 1
    }
  },
  origin = 'LIST'
}) => {
  let history = useHistory()
  const { action, player, user } = values
  const modalHeaderRef = React.useRef(null)
  const categories = useQuery(GET_CATEGORY_TYPES)
  const positions = useQuery(GET_POSITIONS_TYPES)
  // const availabilities = useQuery(GET_AVAILABILITY_CONDITIONS_TYPES)

  const updateSinglePlayer = (cache, { data }) => {
    cache.modify({
      fields: {
        getPlayer(existingPlayer = []) {
          const newPlayer = data.getPlayer
          cache.writeQuery({
            query: GET_PLAYER,
            data: {
              newPlayer,
              ...existingPlayer
            }
          })
        }
      }
    })
  }

  const updatePlayers = (cache, { data }) => {
    cache.modify({
      fields: {
        getPlayers(existingPlayers = []) {
          const newPlayer = data.getPlayers
          cache.writeQuery({
            query: GET_PLAYERS,
            data: {
              newPlayer,
              ...existingPlayers
            }
          })
        }
      }
    })
  }

  const [playerMutation, playerMutationResult] = useMutation(
    action === 'CREATE' ? CREATE_PLAYER : UPDATE_PLAYER, {
      update: origin === 'LIST' ? updatePlayers : updateSinglePlayer
    }
  )
  const [deletePlayer] = useMutation(DELETE_PLAYER)

  const handleClose = () => setOpenModal(false)

  const handleDelete = (event) => {
    event.preventDefault()
    if (window.confirm('Esta acción es irreversible, ¿Estás seguro de borrar este deportista?')) {
      deletePlayer({
        variables: {
          input: {
            id: player.id
          }
        }
      }).then(({ data }) => {
        if (data?.deletePlayer.status.success) {
          handleClose()
          history.replace('/players')
        }
      })
    }
  }

  if (playerMutation.error || playerMutationResult.error) {
    return (
      <div>Error :(</div>
    )
  }

  return (
    <Modal
      size="lg"
      show={openModal}
      onHide={handleClose}
      dialogClassName="full-width"
      contentClassName="full-height"
    >
      <Styles>
        <Modal.Header closeButton ref={modalHeaderRef} className="bg-success text-white">
          <Modal.Title>
            <FaRunning className="mr-1 mb-2" color={'white'} />
            {action === 'CREATE'
              ? 'Crear Deportista'
              : 'Editar Deportista'
            }
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={player}
          validate={values => {
            const errors = {}
            if (!values.name) {
              errors.name = 'Debes ingresar un nombre.'
            }
            if (!values.last_name) {
              errors.last_name = 'Debes ingresar un apellido.'
            }
            if (!values.email) {
              errors.email = 'Debes ingresar un email.'
            }
            if (!values.birthday) {
              errors.birthday = 'Debes seleccionar una fecha de nacimiento.'
            }
            if (!values.id_category_type) {
              errors.id_category_type = 'Debes seleccionar un tipo de categoria.'
            }
            if (!values.id_position_type) {
              errors.id_position_type = 'Debes seleccionar una posición.'
            }
            if (values.side === '') {
              errors.side = 'Debes seleccionar una dominancia.'
            }
            if (!values.height) {
              errors.height = 'Debes seleccionar una altura.'
            }
            if (!values.initial_weight) {
              errors.initial_weight = 'Debes seleccionar un peso.'
            }
            // if (!values.jersey) {
            //   errors.jersey = 'Debes ingresar un jersey.'
            // }
            return errors
          }}
          onSubmit={(values, { resetForm }) => {

            let createInputs = action === 'CREATE'
              ? {
                id_availability_condition_type: parseInt(values.id_availability_condition_type, 10),
                date: moment().format('YYYY-MM-DD')
              }
              : {}

            playerMutation({
              variables: {
                input: {
                  ...values,
                  id_category_type: parseInt(values.id_category_type, 10),
                  id_position_type: parseInt(values.id_position_type, 10),
                  side: parseInt(values.side, 10),
                  gender: parseInt(values.gender, 10),
                  jersey: values.jersey,
                  ...createInputs
                }
              },
            }).then(({ data }) => {
              if (action === 'CREATE' && data.createPlayer.status.success) {
                resetForm({ values: '' })
              }
              modalHeaderRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
            })
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
            setFieldValue
          }) => (
            <Form onSubmit={handleSubmit}>
              {playerMutationResult.data &&
              <StatusAlert
                successMessage={
                  action === 'CREATE'
                    ? 'Deportista creado con éxito.'
                    : 'Deportista actualizado con éxito.'
                }
                status={
                  action === 'CREATE'
                    ? playerMutationResult.data.createPlayer.status
                    : playerMutationResult.data.updatePlayer.status
                }
              />
              }
              <Modal.Body>
                <Form.Row>
                  <Form.Group className="col-12 col-md-6">
                    <Form.Label>
                      Nombre:
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Nombre"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={
                        (touched.name && errors.name)
                          ? true
                          : false
                      }
                    />
                  </Form.Group>

                  <Form.Group className="col-12 col-md-6">
                    <Form.Label>
                      Apellidos:
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="last_name"
                      placeholder="Apellido"
                      value={values.last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={
                        (touched.last_name && errors.last_name)
                          ? true
                          : false
                      }
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group className="col-12 col-md-6">
                    <Form.Label>
                      Email:
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="email"
                      placeholder="Email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={
                        (touched.email && errors.email)
                          ? true
                          : false
                      }
                    />
                  </Form.Group>

                  <Form.Group className="col-12 col-md-6">
                    <Form.Label>Fecha de nacimiento:</Form.Label>
                    {window.innerWidth > 576
                      ? <DatePicker
                        showYearDropdown
                        name="birthday"
                        locale="es"
                        wrapperClassName="w-100"
                        dateFormat="dd/MM/yyyy"
                        maxDate={new Date()}
                        selected={values.birthday}
                        onChange={date => setFieldValue('birthday', date)}
                        onBlur={handleBlur}
                        customInput={
                          <Form.Control
                            required
                            as="input"
                            placeholder="Selecciona una fecha de nacimiento"
                            isInvalid={touched.birthday && errors.birthday ? true : false}
                          />
                        }
                      />
                      : <Form.Control
                        required
                        as="input"
                        type="date"
                        name="birthday"
                        placeholder="Selecciona una fecha de nacimiento"
                        value={
                          typeof values.birthday === 'string'
                            ? values.birthday
                            : new Date(
                              values.birthday - (values.birthday.getTimezoneOffset() * 60000)
                            ).toISOString().split('T')[0]
                        }
                        onChange={handleChange}
                        isInvalid={touched.birthday && errors.birthday ? true : false}
                      />
                    }
                    {(touched.birthday && errors.birthday) &&
                    <div className="invalid-feedback d-block">
                      {errors.birthday}
                    </div>
                    }
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group className="col-12 col-md-6">
                    <Form.Label>
                      Categoría:
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="id_category_type"
                      value={values.id_category_type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={
                        (touched.id_category_type && errors.id_category_type)
                          ? true
                          : false
                      }
                    >
                      <option value="">Selecciona un tipo de categoría</option>
                      {categories.data
                        ? categories.data.getCategoryTypes.categoryTypes.map((categoryType, key) =>
                          <option key={key} value={categoryType.id}>
                            {categoryType.name_category}
                          </option>
                        )
                        : 'Cargando...'
                      }
                    </Form.Control>
                  </Form.Group>

                  <Form.Group className="col-12 col-md-6">
                    <Form.Label>
                      Posición:
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="id_position_type"
                      value={values.id_position_type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={
                        (touched.id_position_type && errors.id_position_type)
                          ? true
                          : false
                      }
                    >
                      <option value="">Selecciona una posición</option>
                      {positions.data
                        ? positions.data.getPositionsTypes.positionsTypes.map((positionType, key) =>
                          <option key={key} value={positionType.id}>
                            {positionType.name_position}
                          </option>
                        )
                        : 'Cargando...'
                      }
                    </Form.Control>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  {/* {action === 'CREATE' &&
                    <Form.Group className="col-12 col-md-6">
                      <Form.Label>
                        Condición de disponibilidad:
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="id_availability_condition_type"
                        value={values.id_availability_condition_type}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={
                          (touched.id_availability_condition_type && errors.id_availability_condition_type)
                            ? true
                            : false
                        }
                      >
                        <option value="">Selecciona una posición</option>
                        {availabilities.data
                          ? availabilities.data.getAvailabilityConditionsTypes
                            .availabilityConditionsTypes.map(
                              (availabilityType, key) =>
                                <option key={key} value={availabilityType.id}>
                                  {availabilityType.name_availability_condition_type}
                                </option>
                            )
                          : 'Cargando...'
                        }
                      </Form.Control>
                    </Form.Group>
                  } */}

                  <Form.Group className="col-12 col-md-6">
                    <Form.Label>
                      Dominancia:
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="side"
                      value={values.side}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={
                        (touched.side && errors.side)
                          ? true
                          : false
                      }
                    >
                      <option value="">Selecciona un lado</option>
                      <option value="0">Derecha</option>
                      <option value="1">Izquierda</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group className="col-12 col-md-6">
                    <Form.Label>
                      Estatura (Centímetros):
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="height"
                      placeholder="Selecciona una altura"
                      value={values.height}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={
                        (touched.height && errors.height)
                          ? true
                          : false
                      }
                    />
                  </Form.Group>
                  <Form.Group className="col-12 col-md-6">
                    <Form.Label>
                      Peso (Kilogramos):
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="initial_weight"
                      placeholder="Selecciona un peso"
                      value={values.initial_weight}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={
                        (touched.initial_weight && errors.initial_weight)
                          ? true
                          : false
                      }
                    />
                  </Form.Group>

                  <Form.Group className="col-12 col-md-6">
                    <Form.Label>
                      Jersey:
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="jersey"
                      placeholder="Ingrese un Jersey"
                      value={values.jersey}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={
                        (touched.jersey && errors.jersey)
                          ? true
                          : false
                      }
                    />
                  </Form.Group>

                  <fieldset className= "col-12 col-md-6">
                    <Form.Group>
                      <Form.Label>
                        Genero:
                      </Form.Label>
                      <Col md={10}>
                        <Form.Check
                          type="radio"
                          label="Masculino"
                          name="gender"
                          value="1"
                          checked={values.gender === '1'}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={
                            (touched.gender && errors.gender)
                              ? true
                              : false
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="Femenino"
                          name="gender"
                          value="2"
                          checked={values.gender === '2'}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Col>
                    </Form.Group>
                  </fieldset>

                  {action !== 'CREATE' &&
                  <Form.Group className= "col-12 col-md-6">
                    <Form.Label>
                      Foto de Perfil:
                    </Form.Label>
                    <UserImage
                      isPlayer
                      withDropZone
                      userId={user.id}
                      userGender={parseInt(player.gender, 10)}
                    />
                  </Form.Group>
                  }
                </Form.Row>
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-end">
                {action === 'UPDATE' &&
                <Button
                  variant="danger"
                  onClick={(event) => handleDelete(event)}
                >
                  <FaTrash/>
                </Button>
                }
                <Button
                  variant="secondary"
                  onClick={() => handleClose()}
                >
                  Cancelar
                </Button>
                <Button variant="success" type="submit">
                  {playerMutationResult.loading ?
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    : (action === 'CREATE')
                      ? 'Crear'
                      : 'Guardar'
                  }
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Styles>
    </Modal>
  )
}

PlayerForm.propTypes = {
  openModal:PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  values: PropTypes.shape({
    action: PropTypes.string.isRequired,
    player: PropTypes.shape({
      name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string,
      birthday: PropTypes.instanceOf(Date).isRequired,
      gender: PropTypes.string,
      id_category_type: PropTypes.number,
      id_position_type: PropTypes.number,
      side: PropTypes.number,
      height: PropTypes.number,
      initial_weight: PropTypes.number,
      jersey: PropTypes.string,
      id_availability_condition_type: PropTypes.number,
    })
  }),
  origin: PropTypes.string
}

export default PlayerForm
