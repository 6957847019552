import { gql } from '@apollo/client'

const GET_PATHOLOGIES = gql`
  query getPathologies($input: PathologyInput!) {
    getPathologies(input: $input) {
      status {
        success
        code
        message
      }
      pathologies {
        id
        name
      }
    }
  }
`

export default GET_PATHOLOGIES
