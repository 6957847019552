import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import moment from 'moment'
import { Container } from 'react-bootstrap'
import { CustomChart } from '../../../../components'
import { useFormatsHelper } from '../../../../hooks'

const NutritionCharts = ({
  meters = [],
  playerDimensions = [],
  jsonToMap
}) => {
  const { parseFloatValue } = useFormatsHelper()
  return (
    <Container>
      <Slider
        dots={true}
        infinite={true}
        speed={500}
        slidesToShow={2}
        slidesToScroll={1}
        responsive={
          [
            {
              breakpoint: 769,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
              }
            },
            {
              breakpoint: 469,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
              }
            },
            {
              breakpoint: 377,
              settings: {
                slidesToShow: 1,
                infinite: false,
                dots: false,
              }
            }
          ]
        }
      >
        {meters.map(meter => (
          meter.required_graphic && (
            <CustomChart
              type="line"
              key={meter.id}
              title={meter.name}
              data={{
                labels: [playerDimensions[0]?.date, playerDimensions[playerDimensions.length - 1]?.date],
                datasets: [{
                  label: meter.name || '',
                  fill: false,
                  backgroundColor: '#98D4ED',
                  borderColor: '#98D4ED',
                  data: playerDimensions.map(playerDimension => {
                    const metersMap = jsonToMap(playerDimension.meters)
                    return {
                      x: moment.utc(playerDimension.date).format('YYYY-MM-DD'),
                      y: parseFloatValue(metersMap.get(parseInt(meter.id, 10)))
                    }
                  })
                }]
              }}
              options={{
                legend: {
                  labels: {
                    boxHeight: 4,
                    boxWidth: 4,
                    fontSize: 10,
                  }
                },
                scales: {
                  xAxes: [{
                    type: 'time',
                    time: {
                      unit: 'day',
                    },
                  }],
                },
              }}
            />
          )
        ))}
      </Slider>
    </Container>
  )
}

NutritionCharts.propTypes = {
  meters: PropTypes.array,
  playerDimensions: PropTypes.array,
  jsonToMap: PropTypes.func.isRequired
}

export default NutritionCharts
