import { gql } from '@apollo/client'

const TOGGLE_ACTIVATE_USER = gql`
  mutation toggleActivateUser($input: DeleteUserInput!) {
    toggleActivateUser(input: $input) {
      user {
        id
        active
      },
      status {
        success
        code
        message
      }
    }
  }
`
export default TOGGLE_ACTIVATE_USER
