import React from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { GET_PLAYER_DIMENSIONS } from '../../../../apollo/queries'
import { CREATE_PLAYER_DIMENSION, UPDATE_PLAYER_DIMENSION } from '../../../../apollo/mutations'
import { Formik } from 'formik'
import { Modal, Form, Button } from 'react-bootstrap'
import { FaCheck } from 'react-icons/fa'
import { StatusAlert, SubmitButton, DatePickerInput } from '../../../../components'
import usePlayerDimension from '../../usePlayerDimension'

const MasotherapyModal = ({
  idPlayer,
  openModal = false,
  setOpenModal,
  action = 'CREATE',
  updateValues = {},
  dimension
}) => {
  const handleClose = () => setOpenModal(false)

  const updateCache = (cache, { data }) => {
    cache.modify({
      fields: {
        getPlayerDimensions(existingPlayerDimensions = []) {
          const newPlayerDimensions = data.getPlayerDimensions
          cache.writeQuery({
            query: GET_PLAYER_DIMENSIONS,
            data: {
              newPlayerDimensions,
              ...existingPlayerDimensions
            }
          })
        }
      }
    })
  }

  const [createPlayerDimension, createResult] = useMutation(
    action === 'CREATE' ? CREATE_PLAYER_DIMENSION : UPDATE_PLAYER_DIMENSION, {
      update: updateCache
    }
  )

  const modalTitle = action === 'CREATE' ? 'Crear Masoterapia': 'Editar Masoterapia'
  const successMessage = action === 'CREATE' ? 'Masoterapia creado con éxito.' : 'Masoterapia actualizado con éxito.'
  const statusResult = action === 'CREATE' ? createResult.data?.createPlayerDimension.status : createResult.data?.updatePlayerDimension.status

  const { getInitialValues, onSubmit, allMeters } = usePlayerDimension(idPlayer, action, 15)

  if (createResult.error) {
    return (
      <div>Error :(</div>
    )
  }

  return (
    <Modal
      size="lg"
      show={openModal}
      dialogClassName="full-width"
      contentClassName="full-height"
      onHide={handleClose}
    >
      <Modal.Header closeButton className="bg-success text-white">
        <Modal.Title>
          <FaCheck className="mr-1" color={'white'} />
          {modalTitle}
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={getInitialValues(allMeters, updateValues)}
        onSubmit={(values, { resetForm }) => {
          onSubmit(createPlayerDimension, values, resetForm)
        }}
        validate={values => {
          const errors = {}
          if (!values.date) {
            errors.date = 'Debes ingresar una fecha'
          }
          return errors
        }}
      >
        {({
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          setFieldValue,
          handleSubmit
        }) => (
          <Form onSubmit={handleSubmit}>
            {createResult.data &&
              <StatusAlert
                successMessage={successMessage}
                status={statusResult}
              />
            }
            <Modal.Body>
              <Form.Row>
                <DatePickerInput
                  label="Fecha"
                  name="date"
                  value={values.date}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  touched={touched.date ? true : false}
                  error={errors.date}
                  width="col-12 col-md-6"
                />
              </Form.Row>

              {dimension.DimensionTests.map(test =>
                <React.Fragment key={test.id}>
                  <p className="bg-success text-white px-3 mb-1">{test.name}:</p>
                  <Form.Row>
                    {test.Meters.map(meter =>
                      <Form.Group key={meter.id} className="col-12 col-md-6">
                        <Form.Label>{meter.name}:</Form.Label>
                        <Form.Control
                          as="select"
                          name={meter.id}
                          value={values[meter.id]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value=''>Selecciona {meter.name}</option>
                          {meter.MeterOptions.map(option =>
                            <option key={option.id} value={option.id}>{option.name}</option>
                          )}
                        </Form.Control>
                      </Form.Group>
                    )}
                  </Form.Row>
                </React.Fragment>
              )}

              <Form.Row>
                <Form.Group className="col-12">
                  <Form.Label className="bg-success text-white px-3">Comentario:</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="comments"
                    value={values.comments}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Form.Group>
              </Form.Row>
            </Modal.Body>

            <Modal.Footer className="d-flex justify-content-end">
              <Button variant="secondary" onClick={handleClose}>
                  Cerrar
              </Button>
              <SubmitButton
                action={action}
                loading={createResult.loading}
                className="btn list-item px-6"
              />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

MasotherapyModal.propTypes = {
  idPlayer: PropTypes.number,
  openModal:PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  action: PropTypes.string,
  updateValues: PropTypes.object,
  dimension: PropTypes.object
}

export default MasotherapyModal
