import styled from 'styled-components'

export default styled.div`

  table{
    font-size: 13px;
  }

  /* .table-striped {
    background-color: #f2f2f2;
  } */

  .date {
    width: 120px;
    min-width: 120px !important;
    max-width: 120px !important;
  }

  .actions  {
    width: 100px;
    min-width: 100px !important;
    max-width: 100px !important;
  }

  .header-accordion {
    background-color:#4e7ace;
  }

  .header-accordion:hover {
    background-color: #142c59;
    cursor: pointer;
  }

  .header-accordion:focus {
    background-color: #142c59;
  }

  .Toggle{
    background-color: #142c59;
  }

  .Toggle:focus{
    background-color: #142c59;
  }

    /* .colSpan{
      background-color: #28a745;
    } */

`
