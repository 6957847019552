
import React from 'react'
import { AutoReportContext } from './AutoReportContext'
import useAutoReportTable from './useAutoReportTable'

function AutoReportTable({ children, players, meters, rowState, setRowState }) {

  const { filters } = React.useContext(AutoReportContext)
  const { getRows, columns } = useAutoReportTable(meters, rowState, setRowState)

  const [tableData, setTableData] = React.useState([])

  React.useEffect(() => {
    if (players) {
      setTableData(players.reduce((acc, player) => {
        const values = getRows(player, meters)
        return [
          ...acc,
          {
            player: {
              id: player.id,
              name: `${player.User.last_name} ${player.User.name}`,
              gender: player.User.gender,
              userId: player.User.id
            },
            ...values
          }
        ]
      }, []))
    }
  }, [players, rowState, filters])

  return children({ columns, tableData })
}

export default AutoReportTable
