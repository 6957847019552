import React from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from '@apollo/client'
import { Button, Modal, Form, Alert } from 'react-bootstrap'
import { FaTrash } from 'react-icons/fa'
import DatePicker, { registerLocale } from 'react-datepicker'
import { getEventFormSchema } from './EventFormSchema'
import { GET_EVENTS, GET_EVENTS_TYPES, GET_CATEGORY_TYPES } from '../../../apollo/queries'
import { CREATE_EVENT, UPDATE_EVENT, DELETE_EVENT } from '../../../apollo/mutations'
import Styles from './EventFormStyle'
import es from 'date-fns/locale/es'
import 'react-datepicker/dist/react-datepicker.css'

registerLocale('es', es)

const EventForm = ({ handleClose, singleEvent, filter }) => {
  const eventsTypesQuery = useQuery(GET_EVENTS_TYPES)
  const categoryTypesQuery = useQuery(GET_CATEGORY_TYPES)

  const [eventMutation, eventMutationResult] = useMutation(
    singleEvent.action === 'CREATE' ? CREATE_EVENT : UPDATE_EVENT,
    {
      refetchQueries: [
        { query: GET_EVENTS, variables: { input: filter } }
      ]
    }
  )

  const [deleteEvent] = useMutation(DELETE_EVENT,
    {
      refetchQueries: [
        { query: GET_EVENTS, variables: { input: filter } }
      ]
    }
  )

  const { eventFormSchema } = getEventFormSchema(singleEvent, eventMutation)

  const handleDelete = (event) => {
    event.preventDefault()
    if (window.confirm('Esta acción es irreversible, ¿Estás seguro de borrar este evento?')) {
      deleteEvent({
        variables: {
          input: {
            id: singleEvent.id
          }
        }
      }).then(() => handleClose())
    }
  }

  if (
    eventsTypesQuery.error ||
    categoryTypesQuery.error ||
    eventMutationResult.error
  ) {
    return (
      <div>Error :(</div>
    )
  }

  return(
    <Styles>
      {(eventsTypesQuery.loading || categoryTypesQuery.loading || eventMutationResult.loading)
        ? 'Cargando...'
        :
        <React.Fragment>
          {eventMutationResult.data && (
            singleEvent.action === 'CREATE' ?
              <Alert
                variant={
                  eventMutationResult.data.createEvent.status.success
                    ? 'success'
                    : 'danger'
                }
              >
                {eventMutationResult.data.createEvent.status.success
                  ? 'Evento creado con éxito.'
                  : 'Ocurrio un error interno.'
                }
              </Alert> :

              <Alert
                variant={
                  eventMutationResult.data.updateEvent.status.success
                    ? 'success'
                    : 'danger'
                }
              >
                {eventMutationResult.data.updateEvent.status.success
                  ? 'Evento actualizado con éxito.'
                  : 'Ocurrio un error interno.'
                }
              </Alert>
          )}
          <Form noValidate onSubmit={eventFormSchema.handleSubmit}>
            <Modal.Body>
              <Form.Group>
                <Form.Label>Fecha inicio</Form.Label>
                {window.innerWidth > 576
                  ? <DatePicker
                    showTimeSelect
                    name="start_date"
                    locale="es"
                    wrapperClassName="w-100"
                    timeFormat="p"
                    dateFormat="Pp"
                    timeIntervals={15}
                    selected={eventFormSchema.values.start_date}
                    onChange={date => eventFormSchema.setFieldValue('start_date', date)}
                    onBlur={eventFormSchema.handleBlur}
                    customInput={
                      <Form.Control
                        required
                        as="input"
                        placeholder="Selecciona una fecha de inicio"
                        isInvalid={eventFormSchema.touched.start_date && eventFormSchema.errors.start_date ? true : false}
                      />
                    }
                  />
                  : <Form.Control
                    required
                    as="input"
                    type="datetime-local"
                    name="start_date"
                    placeholder="Selecciona una fecha de inicio"
                    value={
                      typeof eventFormSchema.values.start_date === 'string'
                        ? eventFormSchema.values.start_date
                        : new Date(
                          eventFormSchema.values.start_date - (eventFormSchema.values.start_date.getTimezoneOffset() * 60000)
                        ).toISOString().split('.')[0]
                    }
                    onChange={eventFormSchema.handleChange}
                    isInvalid={eventFormSchema.touched.start_date && eventFormSchema.errors.start_date ? true : false}
                  />
                }
                {(eventFormSchema.touched.start_date && eventFormSchema.errors.start_date) &&
                    <div className="invalid-feedback d-block">
                      {eventFormSchema.errors.start_date}
                    </div>
                }
              </Form.Group>

              <Form.Group>
                <Form.Label>Fecha fin</Form.Label>
                {window.innerWidth > 576
                  ? <DatePicker
                    showTimeSelect
                    name="end_date"
                    locale="es"
                    timeFormat="p"
                    dateFormat="Pp"
                    wrapperClassName="w-100"
                    timeIntervals={15}
                    selected={eventFormSchema.values.end_date}
                    onChange={date => eventFormSchema.setFieldValue('end_date', date)}
                    onBlur={eventFormSchema.handleBlur}
                    customInput={
                      <Form.Control
                        required
                        as="input"
                        placeholder="Selecciona una fecha fin"
                        isInvalid={eventFormSchema.touched.end_date && eventFormSchema.errors.end_date ? true : false}
                      />
                    }
                  />
                  : <Form.Control
                    required
                    as="input"
                    type="datetime-local"
                    name="end_date"
                    placeholder="Selecciona una fecha de inicio"
                    value={
                      typeof eventFormSchema.values.end_date === 'string'
                        ? eventFormSchema.values.end_date
                        : new Date(
                          eventFormSchema.values.end_date - (eventFormSchema.values.end_date.getTimezoneOffset() * 60000)
                        ).toISOString().split('.')[0]
                    }
                    onChange={eventFormSchema.handleChange}
                    onBlur={eventFormSchema.handleBlur}
                    isInvalid={eventFormSchema.touched.end_date && eventFormSchema.errors.end_date ? true : false}
                  />
                }
                {(eventFormSchema.touched.end_date && eventFormSchema.errors.end_date) &&
                    <div className="invalid-feedback d-block">
                      {eventFormSchema.errors.end_date}
                    </div>
                }
              </Form.Group>

              <Form.Group>
                <Form.Label>Tipo de Evento</Form.Label>
                <Form.Control
                  required
                  as="select"
                  name="id_event_type"
                  placeholder="Selecciona un tipo de Evento"
                  value={eventFormSchema.values.id_event_type}
                  onChange={eventFormSchema.handleChange}
                  onBlur={eventFormSchema.handleBlur}
                  isInvalid={eventFormSchema.touched.id_event_type && eventFormSchema.errors.id_event_type ? true : false}
                >
                  <option value="">Selecciona un tipo de evento</option>
                  {eventsTypesQuery.data.getEventsTypes.eventsTypes.map((eventType, key) =>
                    <option key={key} value={eventType.id}>
                      {eventType.name_event_type}
                    </option>
                  )}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {eventFormSchema.errors.id_event_type}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>Tipo de Categoría</Form.Label>
                <Form.Control
                  required
                  as="select"
                  name="id_category_type"
                  placeholder="Selecciona una categoría"
                  value={eventFormSchema.values.id_category_type}
                  onChange={eventFormSchema.handleChange}
                  onBlur={eventFormSchema.handleBlur}
                  isInvalid={eventFormSchema.touched.id_category_type && eventFormSchema.errors.id_category_type ? true : false}
                >
                  <option value="">Selecciona un tipo de categoría</option>
                  {categoryTypesQuery.data.getCategoryTypes.categoryTypes.map((categoryType, key) =>
                    <option key={key} value={categoryType.id}>
                      {categoryType.name_category}
                    </option>
                  )}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {eventFormSchema.errors.id_category_type}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>Ingrese un Comentario</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="releases"
                  placeholder="Escribe un Comentario"
                  value={eventFormSchema.values.releases}
                  onChange={eventFormSchema.handleChange}
                  onBlur={e => eventFormSchema.setFieldValue('releases', e.target.value.trim())}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-end">
              {singleEvent.action === 'EDIT' &&
                  <React.Fragment>
                    <div className="mr-auto">
                      <small>Creado por:</small>
                      <h5 className="text-success">{singleEvent.user}</h5>
                      <p className="card-subtitle text-muted mb-2">{singleEvent.userType}</p>
                    </div>
                    <Button
                      variant="danger"
                      onClick={(event) => handleDelete(event)}
                    >
                      <FaTrash/>
                    </Button>
                  </React.Fragment>
              }
              <Button variant="secondary" onClick={handleClose}>
                {'Cancelar'}
              </Button>
              <Button variant="success" type="submit">
                {singleEvent.action === 'CREATE'
                  ? 'Crear'
                  : 'Guardar'
                }
              </Button>
            </Modal.Footer>
          </Form>
        </React.Fragment>
      }
    </Styles>
  )
}

EventForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  singleEvent: PropTypes.shape({
    id: PropTypes.string,
    action: PropTypes.string.isRequired,
    id_event_type: PropTypes.string,
    id_category_type: PropTypes.string,
    user: PropTypes.string,
    userType: PropTypes.string,
    start_date: PropTypes.instanceOf(Date).isRequired,
    end_date: PropTypes.instanceOf(Date).isRequired,
    releases: PropTypes.string
  }),
  filter: PropTypes.shape({
    id_category_type: PropTypes.number,
    id_event_type: PropTypes.number
  })
}

export default EventForm
