import { gql } from '@apollo/client'

const GET_TENANT = gql`
  query getTenant {
    getTenant {
      status {
        success
        code
        message
      }
      tenant {
        id
        name
        shinny_active
        shinny_url
        code
        CategoryTypes {
          id
        }
      }
      categoryTypes {
        id
      }
    }
  }
`

export default GET_TENANT
