import { gql } from '@apollo/client'

const UPDATE_MEDICAL_HISTORY_FILE = gql`
  mutation updateMedicalHistoryFile($input: UpdateMedicalHistoryFileInput!) {
    updateMedicalHistoryFile(input: $input) {
      status {
        success
        code
        message
      }
    }
  }
`

export default UPDATE_MEDICAL_HISTORY_FILE
