import { gql } from '@apollo/client'

const GET_DIAGNOSTIC_TYPES = gql`
  {
    getDiagnosticTypes {
      status {
        success
        code
        message
      }
      diagnosticTypes {
        id
        name
      }
    }
  }
`

export default GET_DIAGNOSTIC_TYPES
