import styled from 'styled-components'

export default styled.div`
  /* .table td {
    vertical-align: middle;
  } */

  table{
    font-size:14px;
  }

  .table-striped {
    background-color: #f2f2f2;
  }

  ul {
    margin: 0;
  }

  li {
    margin-right:30px;
  }

  .r-event {
    padding-bottom: 20px;
  }

  .date {
    min-width: 120px;
    max-width: 120px;
  }

  .titular {
    min-width: 50px;
    max-width: 50px;
  }

  .diagnosis {
    max-width: 380px;
  }

  $form-check-label-color: red;
`
