import styled from 'styled-components'

export default styled.div`
  .vl {
    border-left: 1px solid #ccc;
    height: 50px;
    margin: 0 7px;
  }

  .table{
    font-size: 14px;
  }

  .table-striped{
    background-color: #f1f1f1;
  }

  a {
    text-decoration: underline;
  }

  .date {
    min-width:100px;
    max-width:100px;
  }

  .category-2 {
    min-width: 110px;
  }

  .diagnostic {
    min-width: 380px;
  }

  .PlexoSystem {
    min-width: 120px;
  }

  .btn-exam:focus{
    background-color: #1dd0b5;
    border: none;
  }

  .label{
    background-color: #4e7ace;
    color: white;
    padding: 3px 10px;
    width: 100%;
    font-size: 15px;
  }

  .borde{
    border: 1px solid #4e7ace;
    border-radius: 5px;
  }

  .input-bg{
    background-color: #e0e6f2;
  }
`
