import styled from 'styled-components'

export default styled.div`
  .slick-slider {
    .slick-prev:before, .slick-next:before {
      color: #007bffc9;
      cursor: pointer;
    }
  }

  table{
    font-size:14px;
  }

  .bg {
    background: #142c59;
    color: #ffffff;
  }

  .table-striped {
    background-color: #f2f2f2;
  }

  .date {
    min-width: 120px;
    max-width: 120px;
  }

  .actions {
    width: 100px;
    min-width: 100px !important;
    max-width: 100px !important;
  }

  .comments {
    max-width: 200px;
    height: auto;
    word-wrap: break-word;
    text-align: justify;
  }

  div .player {
    width: 100px;
    min-width: 100px;
    padding: 8px !important;
    background-color: #FFFFFF;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  input {
    text-align: center;
  }
`
