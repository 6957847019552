import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'
import { ASSOCIATE_DIAGNOSTIC } from '../../apollo/mutations'
import { useMutation } from '@apollo/client'
import hasRole from '../../keycloak/utils/hasRole'

const ClinicalEventSelect = ({
  clinicalEvents = [],
  idDiagnostic = '',
  idPlayer,
  idPlayerDimension,
  date,
}) => {
  const [selectValue, setSelectValue] = useState(idDiagnostic)

  const [associateDiagnostic, associateDiagnosticResults] = useMutation(ASSOCIATE_DIAGNOSTIC)

  const handleAssociateDiagnostic = (value) => {
    // const { id_diagnostic, id_player_dimension, id_player, date, } = associateDiagnosticInput
    associateDiagnostic({
      variables: {
        input: {
          id_player: parseInt(idPlayer, 10),
          id_diagnostic: value ? parseInt(value, 10) : null,
          id_player_dimension: parseInt(idPlayerDimension, 10),
          date
        }
      }
    }).then(({ data }) => {
      if (data.associateDiagnostic.status.success) {
        setSelectValue(value)
      }
    })
  }

  if (associateDiagnosticResults.error) {
    return <div>Error :(</div>
  }

  if (associateDiagnosticResults.loading) {
    return 'Cargando...'
  }

  if (clinicalEvents.length) {
    return (
      <Form.Group>
        <Form.Control
          as="select"
          value={selectValue}
          onChange={(e) => handleAssociateDiagnostic(e.target.value)}
          disabled={!hasRole(['permission_team-create-kinesic-dimension'])}
          // isInvalid={(touched && error)}
        >
          <option value=''>{'Selecciona un evento clínico'}</option>
          {clinicalEvents.map(({ Diagnostics }) => {
            const [Diagnostic] = Diagnostics
            return (
              <option
                key={Diagnostic.id}
                value={Diagnostic.id}
              >
                {Diagnostic?.DiagnosticType?.name} - {Diagnostic?.Pathology?.name}
              </option>
            )
          })}
        </Form.Control>
      </Form.Group>
    )
  }

  return (
    <p>{'No hay eventos clínicos activos'}</p>
  )
}

ClinicalEventSelect.propTypes = {
  clinicalEvents: PropTypes.array,
  idDiagnostic: PropTypes.string,
  idPlayer: PropTypes.string,
  idPlayerDimension: PropTypes.string,
  date: PropTypes.string,
}

export default ClinicalEventSelect
