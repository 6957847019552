import { gql } from '@apollo/client'

const GET_GENERAL_MECHANISMS = gql`
  query getGeneralMechanisms {
    getGeneralMechanisms {
      generalMechanisms {
        id
        name
      }
      status{
        success
        code
        message
      }
    }
  }
`

export default GET_GENERAL_MECHANISMS
