import React from 'react'
import PropTypes from 'prop-types'
import { Spinner } from 'react-bootstrap'
import Styles from './LoadingStyle'

const Loading = ({ containerStyles = {} }) => {
  return (
    <Styles style={containerStyles}>
      <Spinner
        as="span"
        animation="grow"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    </Styles>
  )
}

Loading.propTypes = {
  containerStyles: PropTypes.object
}

export default Loading
