import React from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { GET_DAILY_MEASUREMENTS } from '../../apollo/queries'
import { CREATE_DAILY_MEASUREMENT, UPDATE_DAILY_MEASUREMENT } from '../../apollo/mutations'

const MeasurementInput = ({ measurement, filters }) => {
  const PCR_TYPE = 25 // Medición tipo PCR
  const [resultId, setResultId] = React.useState(measurement.id)
  const [inputValue, setInputValue] = React.useState(measurement.value)
  const [errorStyle, setErrorStyle] = React.useState(false)
  const [createMeasurement, createMeasurementResult] = useMutation(CREATE_DAILY_MEASUREMENT)
  const [updateMeasurement, updateMeasurementResult] = useMutation(UPDATE_DAILY_MEASUREMENT)

  React.useEffect(() => {
    if (createMeasurementResult.data && createMeasurementResult.data.createDailyMeasurement.status.success) {
      setResultId(createMeasurementResult.data.createDailyMeasurement.dailyMeasurements.id)
    }
  }, [createMeasurementResult])

  React.useEffect(() => {
    if (
      createMeasurementResult.error ||
      (createMeasurementResult.data && !createMeasurementResult.data.createDailyMeasurement.status.success) ||
      updateMeasurementResult.error ||
      (updateMeasurementResult.data && !updateMeasurementResult.data.updateDailyMeasurement.status.success)
    ) {
      setErrorStyle(true)
    }
  }, [createMeasurementResult, updateMeasurementResult])

  const update = (cache, { data }) => {
    cache.modify({
      fields: {
        getDailyMeasurements(existingMeasurements = []) {
          const newMeasurements = data.getDailyMeasurements
          cache.writeQuery({
            query: GET_DAILY_MEASUREMENTS,
            data: {
              newMeasurements,
              ...existingMeasurements
            }
          })
        }
      }
    })
  }

  const handleBlur = (value) => {
    if (resultId) {
      updateMeasurement({
        variables: {
          input: {
            id: resultId,
            value
          }
        },
        update
      })
    } else {
      if (!isNaN(value)) {
        createMeasurement({
          variables: {
            input: {
              id_player: measurement.id_player,
              measurement_moment: filters.measurement_moment,
              measurement_type: measurement.measurement_type,
              date: measurement.date,
              value
            }
          },
          update
        })
      }
    }
  }

  return (
    measurement.measurement_type !== PCR_TYPE ?
      <input
        type="number"
        placeholder="--"
        className={`${errorStyle ? 'error' : ''}`}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onBlur={(e) => handleBlur(parseFloat(e.target.value, 10))}
      /> :
      <select
        className={`${errorStyle ? 'error' : ''}`}
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value)
          handleBlur(parseFloat(e.target.value, 10) || 0)
        }}
      >
        <option value="">--</option>
        <option value="0">Negativo</option>
        <option value="1">Positivo</option>
      </select>
  )
}

MeasurementInput.propTypes = {
  measurement: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
    id_player: PropTypes.number,
    measurement_type: PropTypes.number,
    date: PropTypes.string
  }).isRequired,
  filters: PropTypes.shape({
    measurement_moment: PropTypes.number
  }).isRequired
}

export default MeasurementInput
