import React from 'react'
import moment from 'moment'
import hasRole from '../../keycloak/utils/hasRole'
import { useParams, useHistory } from 'react-router-dom'
import { Formik } from 'formik'
import { Form } from 'react-bootstrap'
import { useMutation } from '@apollo/client'
import { CREATE_CLINICAL_EVENT } from '../../apollo/mutations'
import { DiagnosisForm, ClinicalEventForm, Loading, FooterForm, TitleSection } from '../../components'
import { CustomBreadcrumb } from '../../components'
import { appStore } from '../../store/appStore'
import Styles from './ClinicalEventMainFormStyle'


const ClinicalEventMainForm = () => {
  let history = useHistory()
  let { id_player } = useParams()

  const { state } = React.useContext(appStore)

  const breadcrumbPath = [
    { label: 'Deportistas', href: '/players' },
    { label: 'Resumen médico', href: `/player-info/${state.player?.id_category_type}/${id_player}/clinical-event` }
  ]

  const [createClinicalEvent, { loading, error }] = useMutation(CREATE_CLINICAL_EVENT)

  if (loading) {
    return (
      <Loading />
    )
  }

  if (error) {
    return (
      <div>Error :(</div>
    )
  }

  if (!state.player) {
    return (
      <div onClick={history.goBack()} />
    )
  }

  const isCoch = state?.tenant?.code === 'COCH' || state?.tenant?.code === 'TRICEPS-QA'

  return (
    <React.Fragment>
      <Formik
        initialValues={{
          id_instance: '',
          id_match_moment: '',
          id_severity: '',
          id_specific_mechanism: '',
          id_general_mechanism: '',
          id_diagnostic_type: '',
          id_sub_diagnostic: '',
          id_pathology: '',
          id_diagnostic_complement: '',
          id_skeletal_muscle: '',
          side: '1',
          id_joint_ligament: '',
          id_plexo_system: '',
          id_procedure_material: '',
          id_availability_condition_type: '',
          comments: '',
          injury_muscle_front_rear_dimension: '',
          injury_muscle_middle_side_dimension: '',
          injury_muscle_depth_dimension: '',
        }}
        validate={values => {
          const errors = {}

          if (values.id_diagnostic_type === '2') {
            if (!values.id_instance) {
              errors.id_instance = 'Debes ingresar una instancia.'
            }
            if (!values.id_match_moment && values.id_instance === '2' && !isCoch) {
              errors.id_match_moment = 'Debes ingresar una instancia de partido.'
            }
            if (!values.id_severity) {
              errors.id_severity = 'Debes ingresar una instancia.'
            }
            if (!values.id_specific_mechanism) {
              errors.id_specific_mechanism = 'Debes ingresar una instancia.'
            }
            if (!values.id_general_mechanism) {
              errors.id_general_mechanism = 'Debes ingresar una instancia.'
            }
          }

          if (!values.id_diagnostic_type) {
            errors.id_diagnostic_type = 'Debes ingresar una categoría.'
          }
          if (!values.id_sub_diagnostic) {
            errors.id_sub_diagnostic = 'Debes ingresar una categoría.'
          }
          if (!values.id_pathology) {
            errors.id_pathology = 'Debes ingresar una categoría.'
          }
          if (!values.id_availability_condition_type) {
            errors.id_availability_condition_type = 'Debes ingresar una condición.'
          }
          if (values.id_sub_diagnostic) {
            // if (
            //   !['1', '2', '3', '4', '14', '31', '33'].includes(values.id_sub_diagnostic)
            // ) {
            //   if (!values.id_diagnostic_complement) {
            //     errors.id_diagnostic_complement = 'Debes ingresar una categoría.'
            //   }
            // }

            if (
              !['12', '14', '15', '16', '18', '19', '20', '21', '23', '24', '25', '27', '28', '29'].includes(values.id_sub_diagnostic)
            ) {
              if (!values.id_skeletal_muscle) {
                errors.id_skeletal_muscle = 'Debes ingresar una categoría.'
              }
            }

            if (['3', '5', '11', '30'].includes(values.id_sub_diagnostic)) {
              // if (!values.id_joint_ligament) {
              //   errors.id_joint_ligament = 'Debes ingresar una articulación/ligamento.'
              // }
            }

            // if (values.id_sub_diagnostic === '7' && !values.id_plexo_system) {
            //   errors.id_plexo_system = 'Debes ingresar un sistema plexo.'
            // }
            // if (['7', '12', '30', '32'].includes(values.id_sub_diagnostic) && !values.id_procedure_material) {
            //   errors.id_procedure_material = 'Debes ingresar un procedimiento/materiales/reflejo.'
            // }
          }

          if (!values.id_availability_condition_type) {
            errors.id_availability_condition_type = 'Debes ingresar una condición.'
          }

          if (!values.side) {
            errors.side = 'Debes ingresar una lateralidad.'
          }

          return errors
        }}
        onSubmit={(values) => {
          createClinicalEvent({
            variables: {
              input: {
                id_player: parseInt(id_player),
                id_instance: parseInt(values.id_instance),
                id_match_moment: parseInt(values.id_match_moment),
                id_severity: parseInt(values.id_severity),
                id_specific_mechanism: parseInt(values.id_specific_mechanism),
                id_general_mechanism: parseInt(values.id_general_mechanism),
                id_diagnostic_type: parseInt(values.id_diagnostic_type),
                id_sub_diagnostic: parseInt(values.id_sub_diagnostic),
                id_pathology: parseInt(values.id_pathology),
                id_diagnostic_complement: parseInt(values.id_diagnostic_complement),
                id_skeletal_muscle: parseInt(values.id_skeletal_muscle),
                side: parseInt(values.side),
                id_joint_ligament: parseInt(values.id_joint_ligament),
                id_plexo_system: parseInt(values.id_plexo_system),
                id_procedure_material: parseInt(values.id_procedure_material),
                id_availability_condition_type: parseInt(values.id_availability_condition_type),
                comments: values.comments,
                injury_muscle_front_rear_dimension: values.injury_muscle_front_rear_dimension,
                injury_muscle_middle_side_dimension: values.injury_muscle_middle_side_dimension,
                injury_muscle_depth_dimension: values.injury_muscle_depth_dimension,
                date: moment().format('YYYY-MM-DD')
              }
            }
          }).then(({ data }) => {
            if (data?.createClinicalEvent.status.success) {
              history.replace(`/clinical-event-details/${id_player}/${data?.createClinicalEvent.clinicalEvent.id}`)
            }
          })
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          setFieldValue,
          setTouched,
        }) => (
          <Styles>
            <CustomBreadcrumb
              path={hasRole(['role_player']) ? breadcrumbPath.slice(1) : breadcrumbPath }
              active="Crear Evento Clínico"
            />
            <div className="container">
              <TitleSection title={'Crear Evento Clínico'} />
              <Form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <DiagnosisForm
                    values={values}
                    touched={touched}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    setTouched={setTouched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                  <hr/>
                  {(values.id_diagnostic_type !== '1' && values.id_diagnostic_type !== '3') && (
                    <ClinicalEventForm
                      values={values}
                      touched={touched}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      setTouched={setTouched}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />
                  )}
                  <FooterForm
                    className="mt-3"
                    action={'CREATE'}
                    loading={loading}
                    handleCancel={() => history.goBack()}
                  />
                </div>
              </Form>
            </div>
          </Styles>
        )}
      </Formik>
    </React.Fragment>
  )
}

export default ClinicalEventMainForm
