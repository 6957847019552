import { gql } from '@apollo/client'

const GET_PLAYER_GPS = gql`
  query getPlayerGps($input:PlayerGpsInput!) {
    getPlayerGps (input:$input) {
      playerGps {
        id
        id_player
        id_category_type
        id_gps_moment
        id_gps_moment_desc
        activity
        gps_values
        date
        GpsMoment {
          id
          name
        }
        GpsMomentDescription {
          id
          name
        }
      }
      status {
        success
        code
        message
      }
    }
  }
`
export default GET_PLAYER_GPS

