import styled from 'styled-components'

export default styled.div`
  .table {
    border: 1px solid #ccc;
    font-size: 12px;
    width: 100%;
    height: 100%;
    max-height: 70vh;

    tr:nth-child(odd) td{
      background:rgb(255, 255, 255);
    }
    tr:nth-child(even) td{
      background:rgb(225, 225, 225);
    }

    .th,
    .td {
      /* padding: 5px; */
      border-bottom: 1px solid #ddd;
      border-right: 1px solid #ddd;
      overflow: hidden;

      :last-child {
        border-right: 0;
      }

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }

    &.sticky {
      overflow: auto;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }

      .header {
        top: 0;
        box-shadow: 0px 1px 1px #ccc;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 3px #ccc;
      }

      [data-sticky-first-right-td] {
        box-shadow: -2px 0px 3px #ccc;
      }
    }
  }

  input, select {
    border: none;
    background-color: transparent;
    width: 100%;
    padding: 5px;
    text-align: center;
    vertical-align: middle;

    &.error {
      border: 1px solid red;
      border-radius: 4px;
    }
  }

  .player {
    width: 175px;
    min-width: 175px;
    padding: 8px !important;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  img {
    margin-right: 10px;
  }


  @media (max-width: 426px) {
    img {
      display: none;
    }

    .player {
      width: 100px;
      min-width: 100px;
    }
  }

  .table .header .th, .header .player {
    background-color: #ffffff;
    color: #4e7ace;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
  }

  input[type=checkbox] {
    transform: scale(1.6);
  }

`
