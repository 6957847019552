import { gql } from '@apollo/client'

const UPDATE_EVALUATION_OBJECTIVE = gql`
  mutation updateEvaluationObjective($input: UpdateEvaluationObjectiveInput!) {
    updateEvaluationObjective(input: $input) {
      status {
        success
        code
        message
      }
    }
  }
`

export default UPDATE_EVALUATION_OBJECTIVE
