import { gql } from '@apollo/client'

const UPDATE_SCHEDULE_CONFIG = gql`
  mutation updateScheduleConfig($input: UpdateScheduleConfigInput!) {
    updateScheduleConfig(input: $input) {
      status {
        success
        code
        message
      }
    }
  }
`

export default UPDATE_SCHEDULE_CONFIG
