import { useFormik } from 'formik'
import { isAfter } from 'date-fns'

const getEventFormSchema = (initialValues, mutation) => {
  const validate = values => {
    const errors = {}

    if (!values.start_date) {
      errors.start_date = 'Debes seleccionar una fecha de inicio.'
    }

    if (!values.end_date) {
      errors.end_date = 'Debes seleccionar una fecha fin.'
    }

    if (!values.id_event_type) {
      errors.id_event_type = 'Debes seleccionar un tipo de evento.'
    }

    if (!values.id_category_type) {
      errors.id_category_type = 'Debes seleccionar un tipo de categoria.'
    }

    if (
      values.start_date &&
      values.end_date &&
      isAfter(new Date(values.start_date), new Date(values.end_date))
    ) {
      errors.end_date = 'La fecha fin debe ser posterior a la fecha de inicio.'
    }

    return errors
  }

  const getMutationInput = (values) => {
    let mutationInput = {}

    const {
      id,
      id_event_type,
      id_category_type,
      start_date,
      end_date,
      releases
    } = values

    if (initialValues.action === 'CREATE') {
      mutationInput = {
        input: {
          id_event_type: parseInt(id_event_type, 10),
          id_category_type: parseInt(id_category_type, 10),
          start_date,
          end_date,
          releases: releases ? releases.trim() : ''
        }
      }
    } else {
      mutationInput = {
        input: {
          id,
          id_event_type: parseInt(id_event_type, 10),
          id_category_type: parseInt(id_category_type, 10),
          start_date,
          end_date,
          releases: releases ? releases.trim() : ''
        }
      }
    }

    return mutationInput
  }

  const eventFormSchema = useFormik({
    initialValues,
    validate,
    onSubmit: (values, { resetForm }) => {
      mutation({
        variables: getMutationInput(values)
      }).then(({ data }) => {
        if (initialValues.action === 'CREATE' && data.createEvent.status.success) {
          resetForm({ values: '' })
        }
      })
    }
  })

  return { eventFormSchema }
}

export { getEventFormSchema }
