import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router'
import { useMutation } from '@apollo/client'
import { GET_DIMENSION, GET_PLAYER_DIMENSIONS } from '../../../apollo/queries'
import { CREATE_PLAYER_DIMENSION, UPDATE_PLAYER_DIMENSION } from '../../../apollo/mutations'
import { Formik } from 'formik'
import { Modal, Form, Button } from 'react-bootstrap'
import { FaCheck } from 'react-icons/fa'
import { DatePickerInput, SubmitButton, StatusAlert } from '../../../components'
import { BiomechanicsContext } from './BiomechanicsContext'
import { client } from '../../../apollo/client'
import Styles from './BiomchanicsFormStyle'

const BiomechanicsForm = ({
  openModal,
  setOpenModal,
  action = 'CREATE',
  formValues = {},
  onSubmit
}) => {

  const { id_diagnostic } = useParams()

  const { currentDimension, setCurrentDimension, setFormValues } = React.useContext(BiomechanicsContext)

  const [submitedd, setSubmitedd] = React.useState(false)
  const [currentIdDimension, setCurrentIdDimension] = React.useState(3)

  React.useEffect(() => {
    if (action === 'CREATE') {
      const selectedDimension = client.readQuery({
        query: GET_DIMENSION,
        variables: {
          input: { id: currentIdDimension },
        }
      })

      if (selectedDimension) {
        setCurrentDimension({
          id: parseInt(selectedDimension?.getDimension.dimension.id, 10),
          name: selectedDimension?.getDimension.dimension.name,
          dimensionTests: selectedDimension?.getDimension.dimension.DimensionTests
        })
      }
    }
  }, [client, currentIdDimension, openModal])

  const handleClose = () => {
    if (action === 'CREATE') {
      setCurrentDimension({
        id: null,
        name: '',
        dimensionTests: []
      })
    }
    setSubmitedd(false)
    setOpenModal(false)
  }

  const updateCache = (cache, { data }) => {
    cache.modify({
      fields: {
        getPlayerDimensions(existingPlayerDimensions = []) {
          const newPlayerDimensions = data.getPlayerDimensions
          cache.writeQuery({
            query: GET_PLAYER_DIMENSIONS,
            data: {
              newPlayerDimensions,
              ...existingPlayerDimensions
            }
          })
        }
      }
    })
  }

  const [playerDimensionMutation, createResult] = useMutation(
    action === 'CREATE' ? CREATE_PLAYER_DIMENSION : UPDATE_PLAYER_DIMENSION, {
      update: updateCache
    }
  )

  // React.useEffect(() => {
  //   if (action === 'CREATE' && createResult.data?.createPlayerDimension.status.success) {
  //     resetForm({ values: '' })
  //   }
  // }, [])

  const modalTitle = action === 'CREATE' ? `Crear ${currentDimension.name}` : `Editar ${currentDimension.name}`

  const successMessage = action === 'CREATE'
    ? `${currentDimension.name} creado con éxito.`
    : `${currentDimension.name} actualizado con éxito.`

  const statusResult = action === 'CREATE'
    ? createResult.data?.createPlayerDimension?.status
    : createResult.data?.updatePlayerDimension?.status

  return (
    <Modal
      size="lg"
      show={openModal}
      dialogClassName="full-width"
      contentClassName="full-height"
      onHide={handleClose}
    >
      <Modal.Header closeButton className="bg-success text-white">
        <Modal.Title>
          <FaCheck className="mr-1" color={'white'} />
          {modalTitle}
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={formValues}
        validate={values => {
          const errors = {}
          if (!values.date) {
            errors.date = 'Debes ingresar una fecha'
          }
          return errors
        }}
        onSubmit={(values, { resetForm }) => {
          onSubmit(playerDimensionMutation, values, resetForm, '', action, currentDimension, setFormValues, id_diagnostic)
          setSubmitedd(true)
        }}
      >
        {({
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          setFieldValue,
          handleSubmit
        }) => (
          <Form onSubmit={handleSubmit}>
            {(createResult.data && submitedd) &&
              <StatusAlert
                successMessage={successMessage}
                status={statusResult}
              />
            }
            <Styles>
              <Modal.Body>
                <Form.Row className="d-flex align-items-end">
                  {action === 'CREATE' &&
                  <Form.Group className="col-12 col-md-4">
                    <h6 className='form-label'>Tipo de biomecánica</h6>
                    <Form.Control
                      as="select"
                      custom
                      value={currentIdDimension}
                      onChange={(e) => {
                        setCurrentIdDimension(parseInt(e.target.value))
                      }}
                    >
                      <option value="3">Myoton MR</option>
                      <option value="4">Nórdicos Isquiotibiales</option>
                      <option value="5">Fuerza Isométrica</option>
                      <option value="6">Plataforma de Saltos</option>
                      <option value="7">Índice postural dinámico para tobillo</option>
                      <option value="8">Core Plank Test</option>
                      <option value="9">Isocinética</option>
                      <option value="10">Consumo máximo de oxigeno</option>
                    </Form.Control>
                  </Form.Group>
                  }
                  <DatePickerInput
                    label="Fecha"
                    name="date"
                    value={values.date}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    touched={touched.date ? true : false}
                    error={errors.date}
                  />
                </Form.Row>
                {currentDimension.dimensionTests.map(test =>
                  <React.Fragment key={test.id}>
                    <h6 className="p-1 text-white bg-success">{test.name}</h6>
                    <Form.Row className="d-flex align-items-end">
                      {test.Meters.map(meter =>
                        <Form.Group key={meter.id} className="col-12 col-md-4 ">
                          <Form.Label style={{ fontSize: '12px' }}>{meter.name}</Form.Label>
                          <Form.Control
                            type="text"
                            name={meter.id}
                            placeholder="Ingrese un valor"
                            value={values[meter.id]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              (touched[meter.id] && errors[meter.id])
                                ? true
                                : false
                            }
                          />
                        </Form.Group>
                      )}
                      {/* <Form.Group className="col-12 col-md-4">
                      <Form.Label>Semitendinoso valor Stiffness:</Form.Label>
                      <Form.Control
                        type="text"
                        name="semitendinoso_valor_stiffness_left"
                        placeholder="Ingrese un valor"
                        // value={values.semitendinoso_valor_stiffness_left}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={
                          (touched.semitendinoso_valor_stiffness_left && errors.semitendinoso_valor_stiffness_left)
                            ? true
                            : false
                        }
                      />
                    </Form.Group>
                    <Form.Group className="col-12 col-md-4">
                      <Form.Label>Aquiles valor Stiffness:</Form.Label>
                      <Form.Control
                        type="text"
                        name="aquiles_valor_stiffness_left"
                        placeholder="Ingrese un valor"
                        // value={values.aquiles_valor_stiffness_left}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={
                          (touched.aquiles_valor_stiffness_left && errors.aquiles_valor_stiffness_left)
                            ? true
                            : false
                        }
                      />
                    </Form.Group> */}
                    </Form.Row>
                  </React.Fragment>
                )}
              </Modal.Body>
            </Styles>
            <Modal.Footer className="d-flex justify-content-end">
              <Button variant="secondary" onClick={handleClose}>
                {'Cerrar'}
              </Button>
              <SubmitButton
                className="btn list-item px-6"
                action={action}
                loading={createResult.loading}
              />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

BiomechanicsForm.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  action: PropTypes.string,
  // dimensionName: PropTypes.string,
  // dimensionTests: PropTypes.array,
  formValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired
}

export default BiomechanicsForm
