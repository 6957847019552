/* eslint-disable no-unused-vars */
import React from 'react'
import moment from 'moment'
import { useQuery } from '@apollo/client'
import { GET_METERS, GET_TEAM_AUTOREPORT } from '../../apollo/queries'
import { Form } from 'react-bootstrap'
import { FilterRow, CategoryFilter, StickyTable, Loading, CustomBreadcrumb, DayToolbar, DatePickerInput, TitleSection } from '../../components'
import { AutoReportContext } from './AutoReportContext'
import AutoReportTable from './AutoReportTable'
import Styles from './AutoReportStyle'

const AutoReport = () => {

  const {
    dimensionTestsByMoment,
    initialWindow,
    selectedDimensionTest,
    setSelectedDimensionTest,
    windowDate,
    setWindowDate,
    filters,
    setFilters,
    setFieldValue,
    wellness,
    biomedic
  } = React.useContext(AutoReportContext)

  const meters = useQuery(GET_METERS, {
    variables: {
      input: {
        id_dimension_test: [1, 37, 28, 29]
      }
    }
  })

  const { data, loading, error, refetch } = useQuery(GET_TEAM_AUTOREPORT, {
    notifyOnNetworkStatusChange: true,
    variables: {
      input: filters
    },
    // fetchPolicy: 'network-only'
  })

  React.useEffect(() => {
    refetch()
  }, [filters])

  const [rowState, setRowState] = React.useState({})
  React.useEffect(() => {
    if (data) {
      setRowState(
        data?.getTeamAutoreport.players.reduce((acc, current) => {
          const metersWellness = wellness?.DimensionTests.reduce((stack, test) => ({
            ...stack,
            [test.id]: {
              id: test.id,
              name: test.name,
              meters: test.Meters.reduce((acc, meter) =>
                ({
                  ...acc,
                  [meter.id]: {
                    id: parseInt(meter.id, 10),
                    name: meter.name,
                    value: current.autoreport.meters?.find(m => m.id_meter == meter.id && m.id_dimension_test == test.id)?.value || ''
                  }
                })
              , {})
            }
          }), {})

          const metersBiomedic = biomedic?.DimensionTests.reduce((stack, test) => ({
            ...stack,
            [test.id]: {
              id: test.id,
              name: test.name,
              meters: test.Meters.reduce((acc, meter) =>
                ({
                  ...acc,
                  [meter.id]: {
                    id: parseInt(meter.id, 10),
                    name: meter.name,
                    value: current.autoreport.meters?.find(m => m.id_meter == meter.id && m.id_dimension_test == test.id)?.value || ''
                  }
                })
              , {})
            }
          }), {})

          return ({
            ...acc,
            [current.id]: {
              action: current.autoreport.date ? 'UPDATE' : 'CREATE',
              ...metersWellness,
              ...metersBiomedic
            }
          })
        }, {})
      )
    }
  }, [data, wellness, biomedic])

  if (meters.error) {
    return (
      <div>Error :(</div>
    )
  }

  if (meters.loading) {
    return (
      <Loading />
    )
  }

  return (
    <Styles>
      <CustomBreadcrumb
        active="Autoreporte"
      />
      <TitleSection title={'Autoreporte'} />
      <FilterRow>
        <CategoryFilter
          value={filters.id_category_type}
          setValue={setFilters}
        />
        <Form.Group className='ml-3'>
          <Form.Control
            as="select"
            placeholder="Momento"
            value={selectedDimensionTest.measurement_moment}
            onChange={(e) => {
              setSelectedDimensionTest({
                measurement_moment: parseInt(e.target.value, 10)
              })
              setFilters((current) => ({
                ...current,
                id_dimension_test: dimensionTestsByMoment.get(parseInt(e.target.value, 10))
              }))
            }}
          >
            <option value="1">Entrenamiento</option>
            <option value="2">Partido</option>
          </Form.Control>
        </Form.Group>
        <div className="dateFilter">
          <DatePickerInput
            label=""
            name="start_date"
            value={new Date(moment(`${filters.start_date} 12:00`))}
            setFieldValue={setFieldValue}
            handleChange={(e) => setFieldValue('start_date', e.target.value)}
          />
        </div>
      </FilterRow>
      <div className="mb-3">
        <div className="d-flex justify-content-between">
          <DayToolbar
            initialWindow={initialWindow}
            windowDate={windowDate}
            setWindowDate={setWindowDate}
            setFilters={setFilters}
          />
        </div>
        <AutoReportTable
          players={data?.getTeamAutoreport.players}
          meters={meters.data?.getMeters?.meters}
          rowState={rowState}
          setRowState={setRowState}
        >
          {({ columns, tableData }) => {
            if (error) {
              return (
                <div>Error :(</div>
              )
            }

            if (loading) {
              return (
                <Loading />
              )
            }

            return (
              <StickyTable
                columns={columns}
                data={tableData}
                getCellProps={cellInfo => ({
                  style: {
                    background: cellInfo.row.index % 2 === 0 ? '#e0e6f2' : '#ffffff',
                  },
                })}
              />
            )
          }}
        </AutoReportTable>
      </div>
    </Styles>
  )
}

export default AutoReport
