import { gql } from '@apollo/client'

const DELETE_AUTOREPORT = gql`
  mutation deleteAutoreport($input: DeleteAutoreportInput!) {
    deleteAutoreport(input: $input) {
      status {
        success
        code
        message
      }
    }
  }
`

export default DELETE_AUTOREPORT
