import React from 'react'
import PropTypes from 'prop-types'
import Styles from './KinesicBlockStyle'
import { Card, Accordion } from 'react-bootstrap'
import KinesicMeasureTable from './KinesicMeasureTable'

const KinesicBodyZone = ({ kinesicBlock, bodyZone }) => {
  if (bodyZone.KinesicBodyZone.name === 'Not body zone') {
    return (
      <Styles>
        <KinesicMeasureTable
          kinesicBlock={kinesicBlock}
          kinesicBlockMeasures={bodyZone.KinesicBodyZone.KinesicBlockMeasures}
        />
      </Styles>
    )
  }

  return (
    <Styles>
      <Accordion>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey={bodyZone.KinesicBodyZone.id} className="header-accordion d-flex">
            <p className='mb-0'>{bodyZone.KinesicBodyZone.name}</p>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={bodyZone.KinesicBodyZone.id}>
            <Card.Body className="mt-0">
              <KinesicMeasureTable
                kinesicBlock={kinesicBlock}
                kinesicBlockMeasures={bodyZone.KinesicBodyZone.KinesicBlockMeasures}
              />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </Styles>
  )
}

KinesicBodyZone.propTypes = {
  kinesicBlock: PropTypes.object.isRequired,
  bodyZone: PropTypes.shape({
    KinesicBodyZone: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      KinesicBlockMeasures: PropTypes.array.isRequired
    }).isRequired
  }).isRequired
}

export default KinesicBodyZone
