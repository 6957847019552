import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import { Container } from 'react-bootstrap'
import { CustomChart } from '../../../components'

const PlayerGpsCharts = ({ gps = [], playerGps = [] }) => {
  const getValue = (value = '') => {
    if(isNaN(value)) {
      return parseFloat(value.replace(/,/g, '.'))
    }
    return value
  }

  return (
    <Container>
      <Slider
        dots={true}
        infinite={true}
        speed={500}
        slidesToShow={2}
        slidesToScroll={1}
        responsive={
          [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
              }
            },
            {
              breakpoint: 426,
              settings: {
                slidesToShow: 1,
                dots: true,
                arrows: false,
              }
            },
            {
              breakpoint: 376,
              settings: {
                slidesToShow: 1,
                dots: false,
              }
            }
          ]
        }
      >
        {gps.map(g =>
          g.required_graphic && (
            <CustomChart
              className
              type="line"
              key={g.id}
              title={g.name}
              data={{
                labels: [playerGps[0]?.originalDate, playerGps[playerGps.length - 1]?.originalDate],
                datasets: [{
                  label: g.name || '',
                  fill: false,
                  backgroundColor: 'rgb(255, 99, 132)',
                  borderColor: 'rgb(255, 99, 132)',
                  data: playerGps.reduce((acc, current) => {
                    if (current[g.id]?.cellValue) {
                      return [...acc, { x: current.originalDate, y: getValue(current[g.id]?.cellValue) }]
                    }
                    return acc
                  }, [])
                }]
              }}
              options={{
                legend: {
                  labels: {
                    boxHeight: 4,
                    boxWidth: 4,
                    fontSize: 10,
                  }
                },
                scales: {
                  xAxes: [{
                    type: 'time',
                    time: {
                      unit: 'day',
                    },
                  }],
                },
              }}
            />
          )
        )}
      </Slider>
    </Container>
  )
}

PlayerGpsCharts.propTypes = {
  gps: PropTypes.array,
  playerGps: PropTypes.array
}

export default PlayerGpsCharts
