import styled from 'styled-components'

export default styled.div`
  /* position: absolute;
  top: 50%;
  left: 50%; */

  margin-left: 50vw;
  margin-top: 50vh;

  @media (min-width: 768px) {
    margin-left: calc(50vw - 80px);
    margin-top: calc(50vh - 70px);
  }

`
