import React from 'react'
import PropTypes from 'prop-types'
import { Line, Bar, Pie, HorizontalBar } from '@reactchartjs/react-chart.js'
import { ChartModal } from '../ChartModal'

const CustomChart = ({ title = '', type, data = {}, options = {} }) => {
  const [openModal, setOpenModal] = React.useState(false)

  const renderChart = () => {
    switch (type) {
    case 'line':
      return (
        <Line
          data={data}
          options={options}
        />
      )
    case 'bar':
      return (
        <Bar data={data} options={options} />
      )
    case 'horizontal-bar':
      return (
        <HorizontalBar data={data} options={options} />
      )
    case 'pie':
      return (
        <Pie data={data} options={options} />
      )
    }
  }

  return (
    <>
      <div
        className="m-3 p-3"
        onClick={() => setOpenModal(true)}
      >
        <h5 className="text-center">
          {title}
        </h5>

        {renderChart()}
      </div>

      {openModal &&
        <ChartModal
          openModal={openModal}
          setOpenModal={(value) => setOpenModal(value)}
          title={title}
          data={data}
          options={options}
        >
          {renderChart()}
        </ChartModal>
      }
    </>
  )
}

CustomChart.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string.isRequired,
  data: PropTypes.object,
  options: PropTypes.object
}

export default CustomChart
