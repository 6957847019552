import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa'

const DayToolbar = ({ initialWindow, windowDate, setWindowDate, setFilters }) => {

  const handleDay = (action) => {
    if (action === 'TODAY') {
      setWindowDate(initialWindow)
      setFilters((prevState) => ({
        ...prevState,
        start_date: moment().startOf('day').format('YYYY-MM-DD'),
        end_date: moment().startOf('day').format('YYYY-MM-DD')
      }))
    } else {

      setWindowDate((current) => {
        const newDate = moment(current.completeDate).add(
          (action === 'PREV' ? -1 : 1),
          'days'
        )

        return {
          ...current,
          name: newDate.format('LL'),
          completeDate: newDate.format('YYYY-MM-DD')
        }
      })
    }
  }

  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <button type="button" onClick={() => handleDay('PREV')}>
          <FaArrowLeft size={15}/>
        </button>
        <button type="button" onClick={() => handleDay('TODAY')}>
          Hoy
        </button>
        {!moment(windowDate.completeDate).isSame(moment().format('YYYY-MM-DD')) &&
          <button type="button" onClick={() => handleDay('NEXT')}>
            <FaArrowRight size={15}/>
          </button>
        }
      </span>
    </div>
  )
}

DayToolbar.propTypes = {
  initialWindow: PropTypes.shape({
    name: PropTypes.string,
    completeDate: PropTypes.string
  }).isRequired,
  windowDate: PropTypes.shape({
    name: PropTypes.string,
    completeDate: PropTypes.string
  }).isRequired,
  setWindowDate: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
}

export default DayToolbar
