import { gql } from '@apollo/client'

const GET_MASSIVE_LOAD_EXCEL_BASE = gql`
  query getMassiveLoadExcelBase($input: MassiveLoadExcelBase!) {
    getMassiveLoadExcelBase(input: $input) {
      massiveLoadExcelBase
      status {
        success
        code
        message
      }
    }
  }
`

export default GET_MASSIVE_LOAD_EXCEL_BASE

