import React, { useContext } from 'react'
import ReactToolTip from 'react-tooltip'
import AuthorizedElement from '../../keycloak/utils/AuthorizedElement'
import { useKeycloak } from '@react-keycloak/web'
import { Link, useHistory } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { GET_PLAYERS } from '../../apollo/queries'
import { TOGGLE_ACTIVATE_USER } from '../../apollo/mutations'
import { FaEye, FaPen, FaComment,FaPlus } from 'react-icons/fa'
import { Button, Dropdown, Form, Table } from 'react-bootstrap'
import { FilterRow, CategoryFilter, Loading, PlayerActiveFilter, UserImage } from '../../components'
import { PlayerForm } from '../../views/Players/PlayerForm'
import Styles from './PlayersStyle'
import { CustomBreadcrumb } from '../../components'
import { appStore } from '../../store/appStore'

const Players = () => {
  const history = useHistory()
  const { keycloak } = useKeycloak()
  const { state } = useContext(appStore)

  const [formValues, setFormValues] = React.useState({})
  const [openModal, setOpenModal] = React.useState(false)
  const [openNewPlayerModal, setOpenNewPlayerModal] = React.useState(false)
  const [filters, setFilters] = React.useState({
    id_category_type: state.defaultCategoryType,
    active: 1
  })

  const [activeState, setActiveState] = React.useState({})

  const { data, loading, error } = useQuery(GET_PLAYERS, {
    variables: {
      input: filters
    },
    fetchPolicy: 'network-only'
  })

  React.useEffect(() => {
    if (data) {
      setActiveState((current) => ({
        ...current,
        ...data.getPlayers.players.reduce((acc, player) => {
          return {
            ...acc,
            [player.id]: player.User.active
          }
        }, {})
      }))
    }
  }, [data])

  const [toggleActiveUser] = useMutation(TOGGLE_ACTIVATE_USER)

  const handleModal = (player, action) => {
    const { id_category_type, id_position_type, side, height, initial_weight, jersey, birthday } = player

    const id = action === 'UPDATE' ? { id: parseInt(player.id, 10) } : {}
    setFormValues({
      action,
      player: {
        ...id,
        name: player.User.name,
        last_name: player.User.last_name,
        email: player.User.email,
        gender: player.User.gender.toString(),
        id_category_type,
        id_position_type,
        side,
        height,
        initial_weight,
        jersey,
        birthday: new Date(birthday),
      },
      user: {
        id: player.User.id
      }
    })
    setOpenModal(true)
  }

  const getDefaultTab = () => {
    const userRole = keycloak.realmAccess.roles.find(r => r.includes('role_'))

    switch (userRole) {
    case 'role_nutricionist':
      return 'nutrition'
    case 'role_masotherapist':
      return 'masotherapy'
    default:
      return 'clinical-event'
    }
  }

  const defaultTab = getDefaultTab()

  if (loading) {
    return (
      <Loading />
    )
  }

  if (error) {
    return (
      <div>Error :(</div>
    )
  }

  return (
    <React.Fragment>
      <Styles>
        <CustomBreadcrumb
          active="Ver Deportistas"
          className ="mr-auto"
        />
        <div className="row">
          <div className="col d-flex flex-wrap align-items-baseline justify-content-end">
            <h5 className="mr-auto text-secondary">
              Deportistas
            </h5>
            <FilterRow>
              <div className='mr-2'>
                <CategoryFilter
                  value={filters.id_category_type}
                  setValue={setFilters}
                />
              </div>
              <PlayerActiveFilter
                value={filters.active.toString()}
                setValue={(active) => setFilters((current) => ({ ...current, active: parseInt(active, 10) }))}
              />
            </FilterRow>
            <div className='d-lg-block d-none'>
              <AuthorizedElement roles={['permission_team-create-player']}>
                <Button
                  className="mb-2"
                  onClick={() => {
                  // setAction('CREATE')
                    setOpenNewPlayerModal(true)
                  }}
                >
                  <FaPlus className="mb-1 mr-2" color={'white'} />
                  {'Crear Deportista'}
                </Button>
              </AuthorizedElement>
            </div>
          </div>
        </div>
        <div className='d-block d-lg-none'>
          <AuthorizedElement roles={['permission_team-create-player']}>
            <Button
              className='mb-3' variant="primary" block
              onClick={() => {
                // setAction('CREATE')
                setOpenNewPlayerModal(true)
              }}
            >
              <FaPlus className="mb-1 mr-2" color={'white'} />
              {'Crear Deportista'}
            </Button>
          </AuthorizedElement>
        </div>
        <div className="row">
          <div className="col">
            {data.getPlayers.players.length ?
              <Table className='table-font table-responsive-lg' striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Foto</th>
                    <th>Nombre</th>
                    <th>Categoría</th>
                    <th>Posición</th>
                    <th>Condición</th>
                    {/* <th>Exámenes</th> */}
                    <th>Estado</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {data.getPlayers.players.map((player, key) =>
                    <tr key={key} className="text-center">
                      <td>
                        <Link to={`/player-info/${player.id_category_type}/${player.id}/${defaultTab}`}>
                          <UserImage userId={player.User.id} userGender={player.User.gender} imageWidth="40px" />
                        </Link>
                      </td>
                      <td>
                        <Link to={`/player-info/${player.id_category_type}/${player.id}/${defaultTab}`} >
                          {player.User.last_name} {player.User.name}
                        </Link>
                      </td>
                      <td>
                        <Link to={`/player-info/${player.id_category_type}/${player.id}/${defaultTab}`}>
                          {player.CategoryType.name_category}
                        </Link>
                      </td>
                      <td>
                        <Link to={`/player-info/${player.id_category_type}/${player.id}/${defaultTab}`}>
                          {player.PositionType.name_position}
                        </Link>
                      </td>
                      <td>
                        {player.AvailabilityConditions[0] ?
                          <>
                            <div
                              data-tip data-for={`condition${player.AvailabilityConditions[0].AvailabilityConditionType.id}`}
                              className={`btn-circle zoom color${player.AvailabilityConditions[0].AvailabilityConditionType.id}`}>
                            </div>
                            <ReactToolTip
                              id={`condition${player.AvailabilityConditions[0].AvailabilityConditionType.id}`}
                              place="top"
                              effect="solid"
                              type="info"
                            >
                              {player.AvailabilityConditions[0].AvailabilityConditionType.name_availability_condition_type}
                            </ReactToolTip>
                          </> : 'Sin Condición'
                        }
                      </td>
                      <td>
                        <AuthorizedElement roles={['permission_team-toggle-activate-user']}>
                          <Form.Check
                            className="mt-3"
                            type="switch"
                            id={player.User.id}
                            label={activeState[player.id] ? 'Activo' : 'Inactivo'}
                            checked={activeState[player.id] || false}
                            onChange={() => {
                              setActiveState((current) => ({
                                ...current,
                                [player.id]: !current[player.id]
                              }))
                              toggleActiveUser({
                                variables: {
                                  input: {
                                    id: player.User.id
                                  }
                                }
                              })
                            }}
                          />
                        </AuthorizedElement>
                      </td>
                      <td className="my-0">
                        <Dropdown className="d-xl-none">
                          <Dropdown.Toggle variant="success" />
                          <Dropdown.Menu>
                            <AuthorizedElement roles={['permission_team-show-player']}>
                              <Dropdown.Item onClick={() => history.push(`/player-info/${player.id_category_type}/${player.id}/${defaultTab}`)}>
                                  Ver
                              </Dropdown.Item>
                            </AuthorizedElement>
                            <AuthorizedElement roles={['permission_team-update-player']}>
                              <Dropdown.Item onClick={() => handleModal(player, 'UPDATE')}>Editar</Dropdown.Item>
                            </AuthorizedElement>
                            <Dropdown.Item disabled>Chat</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <div className="d-none d-xl-block">
                          <AuthorizedElement roles={['permission_team-show-player']}>
                            <Button
                              size="sm"
                              variant="primary"
                              className="mr-2"
                              data-tip data-for="detail"
                              onClick={() => history.push(`/player-info/${player.id_category_type}/${player.id}/${defaultTab}`)}
                            >
                              <FaEye color={'white'} />
                              <ReactToolTip
                                id="detail"
                                place="top"
                                effect="solid"
                                type="info"
                              >
                                  Ver
                              </ReactToolTip>
                            </Button>
                          </AuthorizedElement>
                          <AuthorizedElement roles={['permission_team-update-player']}>
                            <Button
                              size="sm"
                              variant="warning"
                              className="mr-2"
                              data-tip data-for="edit"
                              onClick={() => handleModal(player, 'UPDATE')}
                            >
                              <FaPen color={'white'} />
                              <ReactToolTip
                                id="edit"
                                place="top"
                                effect="solid"
                                type="info"
                              >
                                  Editar
                              </ReactToolTip>
                            </Button>
                          </AuthorizedElement>
                          <Button
                            disabled
                            size="sm"
                            variant="success"
                            className="mr-2"
                            data-tip data-for="chat"
                            onClick={() => handleModal(player, 'UPDATE')}
                          >
                            <FaComment />
                            <ReactToolTip
                              id="chat"
                              place="top"
                              effect="solid"
                              type="info"
                            >
                                Chat
                            </ReactToolTip>
                          </Button>
                        </div>
                      </td>

                    </tr>
                  )}
                </tbody>
              </Table> : 'No se encontraron resultados'
            }
          </div>
        </div>
        {openModal &&
          <PlayerForm
            openModal={openModal}
            setOpenModal={(value) => setOpenModal(value)}
            values={formValues}
            filters={filters}
          />
        }

        {openNewPlayerModal &&
          <PlayerForm
            openModal={openNewPlayerModal}
            setOpenModal={(value) => setOpenNewPlayerModal(value)}
          />
        }

      </Styles>
    </React.Fragment>
  )
}

export default Players
