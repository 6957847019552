import { gql } from '@apollo/client'

const DELETE_DIAGNOSTIC = gql`
  mutation deleteDiagnostic($input: DeleteDiagnosticInput!) {
    deleteDiagnostic(input: $input) {
      status {
        success
        code
        message
      }
    }
  }
`

export default DELETE_DIAGNOSTIC
