import { rulesTypes, allRules } from '../constants/dimensionRules'

const useDimensionRules = ({ tenantCode, dimensionId, categoryId, positionTypeId, meterId }) => {
  const getComplianceRule = (rule, value) => {
    switch (rule.type) {
    case rulesTypes.LESS_THAN:
      return (value < rule.max)
    case rulesTypes.GREATER_THAN:
      return (value > rule.min)
    case rulesTypes.BETWEEN:
      return (value <= rule.max && value >= rule.min)
    default:
      return false
    }
  }

  const runRules = (rules = [], value) => {
    let complianceRule = null

    if (value) {
      rules.forEach((rule) => {
        const isCompliance = getComplianceRule(rule, value)
        if (isCompliance) {
          complianceRule = rule
        }
      })
    }

    return complianceRule
  }

  // const getNodeRule = (rules, node) => {
  //   if (!(rules instanceof Map)) return null
  //   return rules.get(node) || rules.values().next().value
  // }

  const getRules = () => {
    // const [firstNode, ...restNodes] = tree
    // const findedRules = getNodeRule(rules,firstNode)
    // if (!findedRules?.child) {
    //   return findedRules
    // }
    // getRules(findedRules.child, [...restNodes])

    const tenantRule = allRules.get(tenantCode) || allRules.values().next().value
    const dimensionRule = tenantRule.child.get(dimensionId) || tenantRule.child.values().next().value
    const categoryRule = dimensionRule.child.get(categoryId) || dimensionRule.child.values().next().value
    const positionRule = categoryRule.child.get(positionTypeId) || categoryRule.child.values().next().value
    return positionRule.child.get('metersRules').rule[meterId]
  }

  return {
    rules: getRules(),
    allRules,
    getRules,
    runRules
  }
}

export default useDimensionRules
