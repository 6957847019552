import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { CustomChart } from '../../../../components'

const MeasurementsCharts = ({ measurements, measurementTypes }) => {
  const { labels, allMeasurements } = measurements

  const measurementsByGroup = measurementTypes.reduce((acc, current) => {
    if (current.grouper_graphic) {
      if (acc[current.grouper_graphic]) {
        acc[current.grouper_graphic].groups.push(current)
      } else {
        acc[current.grouper_graphic] = {
          isGroup: true,
          required_graphic: true,
          name: current.name,
          grouper_graphic: current.grouper_graphic,
          order_graphic: current.order_graphic,
          groups: [current]
        }
      }
    } else {
      acc[current.name] = current
    }

    return acc
  }, {})

  const options = {
    legend: {
      labels: {
        boxHeight: 4,
        boxWidth: 4,
        fontSize: 10,
      }
    },
    scales: {
      xAxes: [{
        type: 'time',
        time: {
          unit: 'day',
        },
      }],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  }

  return (
    <>
      {measurementTypes &&
        <div className="row">
          {Object.entries(measurementsByGroup).sort((a,b) => a[1].order_graphic - b[1].order_graphic).map((key) => {
            let datasets = []
            if (measurementsByGroup[key[0]].isGroup) {
              datasets = measurementsByGroup[key[0]].groups.map((group) => ({
                label: group.name || '',
                fill: false,
                backgroundColor: group.color_graphic || 'rgb(255, 99, 132)',
                borderColor: group.color_graphic || 'rgb(255, 99, 132)',
                data: allMeasurements.reduce((acc, current) => {
                  if (current[group.id]?.measurement) {
                    return [...acc, { x: moment.utc(current[group.id]?.date).format('YYYY-MM-DD'), y: current[group.id]?.measurement }]
                  }
                  return acc
                }
                , [])
              }))
            } else {
              datasets = [{
                label: measurementsByGroup[key[0]].name || '',
                fill: false,
                backgroundColor: measurementsByGroup[key[0]].color_graphic || 'rgb(255, 99, 132)',
                borderColor: measurementsByGroup[key[0]].color_graphic || 'rgb(255, 99, 132)',
                data: allMeasurements.reduce((acc, current) => {
                  if (current[measurementsByGroup[key[0]].id]?.measurement) {
                    return [...acc, { x: moment.utc(current[measurementsByGroup[key[0]].id]?.date).format('YYYY-MM-DD'), y: current[measurementsByGroup[key[0]].id]?.measurement }]
                  }
                  return acc
                }
                , [])
              }]
            }

            return (
              measurementsByGroup[key[0]].required_graphic &&
              <div key={key} className="col-sm-12 col-md-6 text-center">
                <hr/>
                <CustomChart
                  type="line"
                  title={measurementsByGroup[key[0]].name || ''}
                  data={{
                    labels,
                    datasets
                  }}
                  options={
                    measurementsByGroup[key[0]].id === '25' ? {
                      legend: {
                        labels: {
                          boxHeight: 4,
                          boxWidth: 4,
                          fontSize: 10,
                        }
                      },
                      scales: {
                        xAxes: [{
                          type: 'time',
                          time: { parser: 'YYYY-MM-DD' },
                        }],
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                              callback: function(value) {
                                if (value === 0) return 'Negativo'
                                if (value === 1) return 'Positivo'
                                return ''
                              }
                            },
                          },
                        ],
                      },
                    } : options
                  }
                />
              </div>
            )
          })}
          <hr/>
        </div>
      }
    </>
  )
}

MeasurementsCharts.propTypes = {
  measurements: PropTypes.shape({
    labels: PropTypes.array,
    allMeasurements: PropTypes.array
  }),
  measurementTypes: PropTypes.array
}

export default MeasurementsCharts
