import React from 'react'
import PropTypes from 'prop-types'
import { default as AsyncReactSelect } from 'react-select/async'

const AsyncSelect = ({
  isClearable,
  placeholder,
  defaultInputValue,
  defaultOptions,
  noOptionsMessage,
  loadOptionsFunction,
  onChange,
  customComponents,
  ...restProps
}) => {
  return (
    <AsyncReactSelect
      components={customComponents}
      placeholder={placeholder}
      defaultInputValue={defaultInputValue}
      defaultOptions={defaultOptions}
      isClearable={isClearable}
      noOptionsMessage={noOptionsMessage}
      loadOptions={loadOptionsFunction}
      onChange={onChange}
      {...restProps}
    />
  )
}

AsyncSelect.defaultProps = {
  isClearable: true,
  placeholder: '',
  defaultInputValue: '',
  defaultOptions: [],
  customComponents: {},
  noOptionsMessage: () => 'No se encontraron resultados.'
}

AsyncSelect.propTypes = {
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
  defaultInputValue: PropTypes.string,
  defaultOptions: PropTypes.array,
  noOptionsMessage: PropTypes.func,
  loadOptionsFunction: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  customComponents: PropTypes.shape({})
}

export default AsyncSelect



