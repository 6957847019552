import React, { useState, useContext } from 'react'
import moment from 'moment'
import AuthorizedElement from '../../keycloak/utils/AuthorizedElement'
import { Link, useParams } from 'react-router-dom'
import { Calendar as BigCalendar, momentLocalizer, Views } from 'react-big-calendar'
import { FaArrowRight, FaArrowLeft, FaPlus, FaCog } from 'react-icons/fa'
import { Button, Form } from 'react-bootstrap'
import { useQuery } from '@apollo/client'
import { GET_EVENTS, GET_SCHEDULES } from '../../apollo/queries'
import { CustomBreadcrumb, FilterRow, CategoryFilter, EventTypeFilter, Loading, TitleSection } from '../../components'
import { appStore } from '../../store/appStore'
import { ScheduleInfo } from './Schedule/ScheduleInfo'
import { CALENDAR_MESSAGES } from './constants'
import useCalendar from './useCalendar'
import hasRole from '../../keycloak/utils/hasRole'
import Styles from './CalendarStyle'

import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'moment/locale/es.js'
import CalendarModal from './CalendarModal/CalendarModal'
import { ScheduleBlockInfo } from './Schedule/ScheduleBlockInfo'

const localizer = momentLocalizer(moment)

const Calendar = () => {
  const today = new Date()
  const { view } = useParams()
  const { state } = useContext(appStore)

  const [mainFilter, setMainFilter] = useState('ALL')
  const [filter, setFilter] = useState({ id_category_type: state.defaultCategoryType })
  const [currentView, setView] = useState(view)
  const [singleEvent, setSingleEvent] = useState({})
  const [openModal, setOpenModal] = useState(false)
  const [openScheduleModal, setOpenScheduleModal] = useState(false)
  const [openScheduleBlockModal, setOpenScheduleBlockModal] = useState(false)
  const [schedule, setSchedule] = useState(false)

  const { data, loading, error } = useQuery(GET_EVENTS, {
    variables: {
      input: filter
    },
    fetchPolicy: 'network-only'
  })

  const { data: schedules } = useQuery(GET_SCHEDULES, {
    fetchPolicy: 'network-only',
    skip: !hasRole(['permission_calendar-show-schedules'])
  })

  const { allEvents } = useCalendar(data?.getEvents.events, schedules?.getSchedules.schedules, mainFilter, currentView)

  const handleEvent = (event, action) => {
    if (event.isSchedule) {
      setSchedule(event)
      if (event.id_schedule_type === 4) {
        setOpenScheduleBlockModal(true)
      } else {
        setOpenScheduleModal(true)
      }
    } else {
      setSingleEvent({
        action,
        id: action === 'EDIT' ? event.id : '',
        id_event_type: action === 'EDIT' ? event.id_event_type : '',
        id_category_type: action === 'EDIT' ? event.id_category_type : '',
        user: action === 'EDIT' ? event.user: '',
        userType: action === 'EDIT' ? event.userType : '',
        start_date: event.start || new Date(),
        end_date: event.end || new Date(),
        releases: event.releases
      })
      setOpenModal(true)
    }
  }

  const eventStyleGetter = (event) => {
    var backgroundColor = '#' + event.hexColor
    var style = {
      backgroundColor: backgroundColor,
      color: 'white',
      border: 'none',
    }
    return { style }
  }

  if (error) {
    <div>Error :(</div>
  }

  if (loading) {
    <Loading />
  }

  return (
    <Styles>
      <CustomBreadcrumb
        active="Calendario"
      />
      <TitleSection title={'Calendario'} />
      <div className="row d-flex flex-wrap justify-content-lg-between mb-3">
        <div className='ml-3 order-sm-1'>
          <FilterRow>
            <AuthorizedElement roles={['permission_calendar-show-schedules']}>
              <Form.Group className="mr-3">
                <Form.Control
                  as="select"
                  placeholder=""
                  value={mainFilter}
                  onChange={(e) => setMainFilter(e.target.value)}
                >
                  <option value="ALL">Todos</option>
                  <option value="EVENTS">Eventos</option>
                  <option value="SCHEDULE">Consultas</option>
                </Form.Control>
              </Form.Group>
            </AuthorizedElement>
            {['ALL', 'EVENTS'].includes(mainFilter) && (
              <>
                <CategoryFilter
                  value={filter.id_category_type}
                  setValue={setFilter}
                />
                <EventTypeFilter
                  value={filter.id_event_type}
                  setValue={setFilter}
                />
              </>
            )}
          </FilterRow>
        </div>
        <div className='ml-sm-3 mr-lg-3 order-sm-2'>
          <AuthorizedElement roles={['permission_calendar-create-schedule']}>
            <Link to="/available-schedules">
              <Button variant="primary" size="sm" className='p-2'>
                <FaPlus className="mb-1 mr-2" color={'white'} />
                {'Agendar Consulta'}
              </Button>
            </Link>
          </AuthorizedElement>
          <AuthorizedElement roles={['permission_calendar-show-schedule-config']}>
            <Link to="/schedule-config">
              <Button variant="outline-primary" size="sm" className='ml-3 p-2'>
                <FaCog /> {'Configurar Consultas'}
              </Button>
            </Link>
          </AuthorizedElement>
        </div>
      </div>

      <BigCalendar
        selectable
        startAccessor="start"
        endAccessor="end"
        step={60}
        timeslots={1}
        localizer={localizer}
        events={allEvents}
        defaultView={window.innerWidth > 767 ? currentView : Views.DAY}
        onView={(view) => setView(view)}
        min={
          new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate(),
            7
          )
        }
        eventPropGetter={(event) => eventStyleGetter(event)}
        onSelectSlot={event => handleEvent(event, 'CREATE')}
        onSelectEvent={event => handleEvent(event, 'EDIT')}
        messages={{
          next:  <FaArrowRight size={15}/>,
          previous: <FaArrowLeft size={15}/>,
          ...CALENDAR_MESSAGES
        }}
      />



      {openModal &&
        <AuthorizedElement
          roles={[
            'permission_calendar-create-event',
            'permission_calendar-update-event',
            'permission_calendar-delete-event'
          ]}
        >
          <CalendarModal
            openModal={openModal}
            setOpenModal={(value) => setOpenModal(value)}
            singleEvent={singleEvent}
            filter={filter}
          />
        </AuthorizedElement>
      }

      {openScheduleModal &&
        <AuthorizedElement roles={['permission_calendar-show-schedules']}>
          <ScheduleInfo
            openModal={openScheduleModal}
            setOpenModal={(value) => setOpenScheduleModal(value)}
            schedule={schedule}
          />
        </AuthorizedElement>
      }

      {openScheduleBlockModal &&
        <AuthorizedElement roles={['permission_calendar-show-schedules']}>
          <ScheduleBlockInfo
            openModal={openScheduleBlockModal}
            setOpenModal={(value) => setOpenScheduleBlockModal(value)}
            schedule={schedule}
          />
        </AuthorizedElement>
      }
    </Styles>
  )
}

export default Calendar
