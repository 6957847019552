import styled from 'styled-components'
export default styled.div`

  table{
    font-size:14px;
  }

  .text-no-break {
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }

  .comments{
    max-width: 200px;
    height: auto;
    word-wrap: break-word;
    text-align: justify;
  }

`
