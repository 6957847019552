import React from 'react'
import { Carousel } from 'react-bootstrap'
import Styles from './SliderStyle'

const Slider = () => {
  return(
    <Styles className="d-none col-md-7 d-md-block">
      <Carousel>
        <Carousel.Item>
          <div className="d-block img-3 min-vh-100" />
        </Carousel.Item>
        <Carousel.Item>
          <div className="d-block img-2 min-vh-100" />
        </Carousel.Item>
        <Carousel.Item>
          <div className="d-block img-4 min-vh-100" />
        </Carousel.Item>
        <Carousel.Item>
          <div className="d-block img-1 min-vh-100" />
        </Carousel.Item>
      </Carousel>
    </Styles>
  )
}

export default Slider
