import { gql } from '@apollo/client'

const CREATE_EVALUATION_OBJECTIVE = gql`
  mutation createEvaluationObjective($input: CreateEvaluationObjectiveInput!) {
    createEvaluationObjective(input: $input) {
      status {
        success
        code
        message
      }
    }
  }
`

export default CREATE_EVALUATION_OBJECTIVE
