import { gql } from '@apollo/client'

const DELETE_CLINICAL_EVENT = gql`
  mutation deleteClinicalEvent($input: DeleteClinicalEventInput!) {
    deleteClinicalEvent(input: $input) {
      status {
        success
        code
        message
      }
    }
  }
`

export default DELETE_CLINICAL_EVENT
