import { gql } from '@apollo/client'

const CREATE_AVAILABILITY_CONDITION = gql`
  mutation createAvailabilityCondition($input: CreateAvailabilityConditionInput!) {
    createAvailabilityCondition(input: $input) {
      status {
        success
        code
        message
      }
    }
  }
`

export default CREATE_AVAILABILITY_CONDITION
