import { gql } from '@apollo/client'

const GET_DIAGNOSTIC_COMPLEMENTS = gql`
  query getDiagnosticComplements($input: DiagnosticComplementInput!) {
    getDiagnosticComplements(input: $input) {
      status {
        success
        code
        message
      }
      diagnosticComplements {
        id
        name
      }
    }
  }
`

export default GET_DIAGNOSTIC_COMPLEMENTS
