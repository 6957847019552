import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const CustomLink = ({ to = '', label, ...rest }) => {
  return (
    <Link to={to} {...rest}>
      {label}
    </Link>
  )
}

CustomLink.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string.isRequired,
}

export default CustomLink
