import React, { useContext } from 'react'
import { PropTypes } from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'
import { appStore } from '../store/appStore'
import hasRole from '../keycloak/utils/hasRole'

const Home = React.lazy(() => import('../views/Home/Home'))
const PlayerInfo = React.lazy(() => import('../views/PlayerInfo/PlayerInfo'))
const Calendar = React.lazy(() => import('../views/Calendar/Calendar'))

const PrivateRoute = ({ component, path, roles }) => {
  const { keycloak } = useKeycloak()
  const { state } = useContext(appStore)

  const getComponent = () => {
    if (hasRole(['role_player'])) {
      // TODO: borrar condición QA al pasar a prod
      if (
        state.tenant?.code === 'COCH'
        || state.tenant?.code === 'TRICEPS-QA'
      ) {
        return Calendar
      }
      return PlayerInfo
    }

    return Home

  }

  if (keycloak.authenticated) {
    if (path === '/') {
      return (
        <Route
          exact
          path={path}
          component={getComponent()}
        />
      )
    }
    return (
      <>
        <Route
          exact
          path={path}
          component={hasRole(roles) ? component : () => <div>Oops! Página no encontrada</div>}
        />
      </>
    )
  } else {
    return (
      <Redirect
        to={{
          pathname: '/login',
        }}
      />
    )
  }
}

PrivateRoute.propTypes = {
  component: PropTypes.shape({}),
  path: PropTypes.string,
  roles: PropTypes.arrayOf(PropTypes.string)
}

export default PrivateRoute
