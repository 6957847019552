import { gql } from '@apollo/client'

const GET_PLAYER = gql`
  query getPlayer($input: PlayerInput!) {
    getPlayer(input: $input) {
      status {
        success
        code
        message
      }
      player {
        id
        id_category_type
        id_position_type
        birthday
        side
        height
        initial_weight
        jersey
        User {
          id
          name
          last_name
          gender
          email
          active
          image
        }
        # AvailabilityConditions {
        #   id
        #   AvailabilityConditionType {
        #     id
        #     name_availability_condition_type
        #   }
        # }
        PlayerCategoryHistories {
          id_type_category
        }
        CategoryType {
          name_category
        }
        PositionType {
          name_position
        }
      }
    }
  }
`

export default GET_PLAYER
