import React, { createContext } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

export const BiomechanicsContext = createContext()

const BiomechanicsProvider = ({ children }) => {
  let { id_player, id_diagnostic } = useParams()

  const [currentDimension, setCurrentDimension] = React.useState({
    id: null,
    name: '',
    dimensionTests: []
  })
  const [action, setAction] = React.useState('CREATE')
  const [openModal, setOpenModal] = React.useState(false)
  const [formValues, setFormValues] = React.useState({})
  const [filters, setFilters] = React.useState({
    id_player: parseInt(id_player, 10),
    id_diagnostic: parseInt(id_diagnostic, 10),
    start_date: new Date(moment().subtract(6, 'months')),
    end_date: new Date()
  })

  return (
    <BiomechanicsContext.Provider
      value={{
        filters,
        setFilters,
        action,
        setAction,
        openModal,
        setOpenModal,
        formValues,
        setFormValues,
        currentDimension,
        setCurrentDimension
      }}
    >
      {children}
    </BiomechanicsContext.Provider>
  )
}

BiomechanicsProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default BiomechanicsProvider
