import { gql } from '@apollo/client'

const GET_TEAM_AUTOREPORT = gql`
  query getTeamAutoreport($input: TeamAutoreportInput!) {
    getTeamAutoreport(input: $input) {
      players {
        id
        User {
          id
          name
          last_name
          gender
        }
        autoreport {
          date
          id_wellness
          id_biomedic
          meters {
            id
            id_meter
            id_dimension_test
            name
            value
          }
          aritmeticValues {
            idDimentsionTest
            totalSumWellness
            averageWellness
            multiplicationPSE
          }
        }
      }
      status {
        success
        code
        message
      }
    }
  }
`

export default GET_TEAM_AUTOREPORT
