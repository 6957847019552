import { gql } from '@apollo/client'

const GET_POSITIONS_TYPES = gql`
  {
    getPositionsTypes {
      status {
        code
        message
      }
      positionsTypes {
        id
        name_position
      }
    }
  }
`

export default GET_POSITIONS_TYPES
