import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import axios from 'axios'
import domainConfig from '../../../config/domains'
import { useMutation } from '@apollo/client'
import { CREATE_EXAM, UPDATE_EXAM, UPDATE_EXAM_IMAGE } from '../../../apollo/mutations'
import { Formik } from 'formik'
import { FaFileMedicalAlt } from 'react-icons/fa'
import { Modal, Form, Button } from 'react-bootstrap'
import { StatusAlert, SubmitButton, DatePickerInput, DropZone } from '../../../components'
import Styles from './ExamsModalStyle'

const ACCEPTED_MIMETYPES = `
    image/jpeg,
    image/png,
    image/gif,
    application/pdf,
    application/msword,
    application/vnd.openxmlformats-officedocument.wordprocessingml.document,
    application/vnd.ms-excel,
    application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
  `

const ExamsModal = ({
  idPlayer,
  idDiagnostic,
  openModal = false,
  setOpenModal,
  action = 'CREATE',
  origin = '',
  updateValues = {},
  request,
  setRequest,
  setImagesState,
  refetch
}) => {
  const handleClose = () => setOpenModal(false)

  const [files, setFiles] = React.useState([])

  const uploadFiles = (id_exam) => {
    const formData = new FormData()

    for (let i = 0; i < files.length; i++) {
      formData.append('files[]', files[i])
    }

    axios.post(`${domainConfig.clinicalApiUri}/exam/${id_exam}/files`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(({ data }) => {
      setRequest({ status: 'SUCCESS', data })
      setFiles([])
      refetch()
    }).catch(() => setRequest({ status: 'ERROR' }))
  }

  const [mutation, mutationResult] = useMutation(
    action === 'CREATE' ? CREATE_EXAM : origin === 'DETAILS' ? UPDATE_EXAM_IMAGE : UPDATE_EXAM,
  )

  const modalTitle = action === 'CREATE' ? 'Agregar Examen': 'Editar Examen'
  const successMessage = action === 'CREATE' ? 'Examen agregado con éxito.' : 'Examen actualizado con éxito.'
  const statusResult = (request.status === 'SUCCESS' && action === 'CREATE') ? ({ success: true }) : ({ success: true })

  if (request.status === 'ERROR') {
    return (
      <div>Error :(</div>
    )
  }

  return (
    <Modal
      size="lg"
      show={openModal}
      dialogClassName="full-width"
      contentClassName="full-height"
      onHide={handleClose}
    >
      <Modal.Header
        closeButton
        className="bg-success text-white">
        <Modal.Title>
          <FaFileMedicalAlt className="mr-1 mb-1" color={'white'} />
          {modalTitle}
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={updateValues}
        validate={values => {
          const errors = {}
          if (!values.date) {
            errors.date = 'Debes ingresar una fecha.'
          }
          if (origin !== 'DETAILS' && !values.title) {
            errors.title = 'Debes ingresar titúlo.'
          }
          if (origin === 'DETAILS' && !values.name) {
            errors.title = 'Debes ingresar nombre.'
          }
          return errors
        }}
        onSubmit={(values, { resetForm }) => {
          const id = (action === 'EDIT' ? ({ id: values.id }) : {})
          const name = (origin === 'DETAILS' ? ({ name: values.name }) : ({ title: values.title }))

          mutation({
            variables: {
              input: {
                ...id,
                ...name,
                id_player: parseInt(idPlayer, 10),
                id_diagnostic: parseInt(idDiagnostic, 10),
                date: moment(values.date).format('YYYY-MM-DD'),
                comments: values.comments
              }
            }
          }).then(({ data }) => {
            if (action === 'CREATE') {
              if (data.createExam.status.success) {
                if (files.length) {
                  setRequest({ status: 'LOADING' })
                  uploadFiles(data.createExam.exam.id)
                } else {
                  setRequest({ status: 'SUCCESS', data: data.createExam })
                  refetch()
                }
                resetForm({ values: '' })
              }
            } else {
              if (data.updateExam?.status.success) {
                setRequest({ status: 'SUCCESS' })
                refetch()
              }
              if (data.updateExamImage?.status.success) {
                const { id, name, date, image_key, comments } = data.updateExamImage.examImage
                setRequest({ status: 'SUCCESS' })
                setImagesState((prevState) => ([
                  ...prevState.filter(image => image.id != id),
                  {
                    id,
                    name,
                    date,
                    image_key,
                    comments
                  }
                ]))
              }
            }
          })
        }}
      >
        {({
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          setFieldValue,
          handleSubmit
        }) => (
          <Form onSubmit={handleSubmit}>
            {mutationResult.data &&
              <StatusAlert
                successMessage={successMessage}
                status={statusResult}
              />
            }
            <Styles>
              <Modal.Body>
                <Form.Row>
                  <DatePickerInput
                    label="Fecha:"
                    name="date"
                    value={values.date}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    touched={touched.date ? true : false}
                    error={errors.date}
                    width="col-12 col-md-6"
                  />
                  {origin === 'DETAILS' ?
                    <Form.Group className="col-12 col-md-6">
                      <Form.Label className="mb-1">Nombre de la imágen:</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Ingresa un nombre"
                        value={values.name ||''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={
                          (touched.name && errors.name)
                            ? true
                            : false
                        }
                      />
                    </Form.Group> :
                    <Form.Group className="col-12 col-md-6">
                      <Form.Label className="mb-1">Nombre del examen:</Form.Label>
                      <Form.Control
                        type="text"
                        name="title"
                        placeholder="Ingresa un nombre"
                        value={values.title ||''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={
                          (touched.title && errors.title)
                            ? true
                            : false
                        }
                      />
                    </Form.Group>
                  }
                </Form.Row>
                {origin !== 'DETAILS' &&
                <Form.Row>
                  <Form.Group className="col-12 col-md-6">
                    <Form.Label className="mb-1">Comentario:</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="comments"
                      rows={3}
                      placeholder="Ingresa un comentario"
                      value={values.comments ||''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={
                        (touched.comments && errors.comments)
                          ? true
                          : false
                      }
                    />
                  </Form.Group>
                  {action === 'CREATE' &&
                    <div className="col-12 col-md-6">
                      <p className="mb-0 form-label">Fotos Examen:</p>
                      <DropZone
                        accept={ACCEPTED_MIMETYPES}
                        setFiles={setFiles}
                      />
                      <ul>
                        {files.map((file, key) =>
                          <li key={key}>{file.name}</li>
                        )}
                      </ul>
                    </div>
                  }
                </Form.Row>
                }
              </Modal.Body>
            </Styles>
            <Modal.Footer className="d-flex justify-content-end">
              <Button variant="secondary" onClick={handleClose}>
                Cerrar
              </Button>
              <SubmitButton
                action={action}
                loading={request.status === 'LOADING' || mutationResult.loading}
                className="btn list-item px-6"
              />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

ExamsModal.propTypes = {
  idPlayer: PropTypes.string,
  idDiagnostic: PropTypes.string,
  openModal:PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  action: PropTypes.string,
  origin: PropTypes.string,
  updateValues: PropTypes.object,
  dimension: PropTypes.object,
  request: PropTypes.object,
  setRequest: PropTypes.func,
  setImagesState: PropTypes.func,
  refetch: PropTypes.func
}

export default ExamsModal
