import React, { useContext } from 'react'
import moment from 'moment'
import ReactToolTip from 'react-tooltip'
import hasRole from '../../keycloak/utils/hasRole'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_AVAILABILITY_CONDITIONS } from '../../apollo/queries'
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa'
import { FilterRow, CategoryFilter, AvailabilityFilter, Loading, StickyTable, CustomBreadcrumb, UserImage, TitleSection } from '../../components'
import { AvailabilityConditions } from './AvailabilityConditions'
import { AvailabilityForm } from './AvailabilityForm'
import Styles from './SummaryStyle'
import { appStore } from '../../store/appStore'

const Summary = () => {
  const initialWindow = React.useMemo(() => ({
    id: moment().month(),
    name: moment().startOf('month').format('MMMM YYYY'),
    daysOfMonth: moment().daysInMonth(),
    completeDate: moment().format('YYYY-MM')
  }), [])

  const { state } = useContext(appStore)

  const [windowDate, setWindowDate] = React.useState(initialWindow)
  const [openModal, setOpenModal] = React.useState(false)
  const [availabilityCondition, setAvailabilityCondition] = React.useState({})
  const [filters, setFilters] = React.useState({
    id_category_type: state.defaultCategoryType,
    start_date: moment().startOf('month').format('YYYY-MM-DD'),
    end_date: moment().endOf('month').format('YYYY-MM-DD')
  })

  const { data, loading, error } = useQuery(GET_AVAILABILITY_CONDITIONS, {
    variables: {
      input: filters
    },
    fetchPolicy: 'network-only'
  })

  React.useEffect(() => {
    setFilters((prevState) => ({
      ...prevState,
      start_date: moment(windowDate.completeDate).startOf('month').format('YYYY-MM-DD'),
      end_date: moment(windowDate.completeDate).endOf('month').format('YYYY-MM-DD')
    }))
  }, [windowDate])

  const handleMonth = (action) => {
    if (action === 'TODAY') {
      setWindowDate(initialWindow)
      setFilters((prevState) => ({
        ...prevState,
        start_date: moment().startOf('month').format('YYYY-MM-DD'),
        end_date: moment().endOf('month').format('YYYY-MM-DD')
      }))
    } else {
      setWindowDate((prevState) => {
        const newDate = moment(prevState.completeDate).add(
          (action === 'PREV' ? -1 : 1),
          'months'
        )
        return {
          id: newDate.month(),
          name: newDate.startOf('month').format('MMMM YYYY'),
          daysOfMonth: newDate.daysInMonth(),
          completeDate: newDate.format('YYYY-MM')
        }
      })
    }
  }

  const handleModal = (cellInfo) => {
    const { id, id_player, id_availability_condition_type, id_clinical_event, clinicalEventActive } = cellInfo.value
    setAvailabilityCondition({
      action: id ? 'UPDATE' : 'CREATE',
      initialValues: {
        id: id || '',
        id_player: id_player || '',
        id_availability_condition_type: id_availability_condition_type || '',
        day: cellInfo.column.id,
        id_clinical_event,
        clinicalEventActive
      }
    })
    setOpenModal(true)
  }

  const getRows = (conditions) => {
    let rows = {}

    const rowConditions = conditions.AvailabilityConditions.reduce((acc, availability) => {
      return {
        ...acc,
        [new Date(`${availability.date}T12:00`).getDate()]: {
          id: availability.id,
          id_player: conditions.id,
          id_availability_condition_type: availability.id_availability_condition_type.toString(),
          name_availability_condition_type: availability.AvailabilityConditionType.name_availability_condition_type,
          id_clinical_event: availability.DiagnosticAvailabilities.length ? availability.DiagnosticAvailabilities[0].Diagnostic.ClinicalEvent.id : null,
          clinicalEventActive: availability.DiagnosticAvailabilities.length ? availability.DiagnosticAvailabilities[0].Diagnostic.ClinicalEvent.active : false
        }
      }
    }, {})

    for(let i = 1; i < (windowDate.daysOfMonth + 1 ); i++) {
      if (rowConditions[i]) {
        rows = {
          ...rows,
          [i]: rowConditions[i]
        }
      } else {
        rows = {
          ...rows,
          [i]: {
            id_player: conditions.id
          }
        }
      }
    }

    return rows
  }

  const getColumns = () => {
    const columns = []

    const currentDay = moment().format('D')
    const currentMonth = windowDate.completeDate === moment().format('YYYY-MM')

    for(let i = 1; i < (windowDate.daysOfMonth + 1); i++) {
      columns.push({
        Header: i.toString(),
        accessor: i.toString(),
        style: {
          color: (i.toString() === currentDay && currentMonth) ? 'white' : '',
          background: (i.toString() === currentDay && currentMonth) ? 'rgba(0,0,0,0.8)' : ''
        },
        Cell: function empty({ value }) {
          return (
            <span data-tip={value.name_availability_condition_type}>
              <div style={{ width: '100%', height: '100%' }}></div>
            </span>
          )
        }
      })
    }

    return columns
  }

  const columns = React.useMemo(
    () => [
      {
        Header: ' ',
        sticky: 'left',
        className: 'player',
        columns: [
          {
            Header: 'Deportista',
            accessor: 'player',
            className: 'player',
            Cell: function playerName({ cell: { value } }) {
              return(
                <Link to={`/player-info/${filters.id_category_type}/${value.id}/clinical-event`}>
                  <UserImage userId={value.userId} userGender={value.gender} imageWidth="30px" />
                  {value.name}
                </Link>
              )
            }
          },
        ],
      },
      {
        Header: windowDate.name,
        columns: getColumns()
      },
    ],
    [windowDate, filters]
  )

  const renderTable = () => {
    if (loading) {
      return (
        <Loading />
      )
    }

    if (error) {
      return (
        <div>Error :(</div>
      )
    }

    let tableData = []
    if (data) {
      tableData = data.getAvailabilityConditions.playersConditions.reduce((acc, condition) => {
        const values = getRows(condition)
        return [
          ...acc,
          {
            player: {
              id: condition.id,
              name: `${condition.User.last_name} ${condition.User.name}`,
              gender: condition.User.gender,
              userId: condition.User.id
            },
            ...values
          }
        ]
      }, [])

      return (
        <>

          <ReactToolTip place="top" effect="solid" type="info" />
          <StickyTable
            columns={columns}
            data={tableData}
            getCellProps={cellInfo => {
              return {
                ['data-tip']: true,
                ['data-for']: `condition${cellInfo.value ? cellInfo.value.id_availability_condition_type : ''}`,
                className: `td color${cellInfo.value ? cellInfo.value.id_availability_condition_type : ''} `,
                onClick: (hasRole(['permission_team-create-player-availability']) ? () => handleModal(cellInfo) : ''),
                style: { background: cellInfo.row.index % 2 === 0 ? '#e0e6f2' : '#ffffff' }
              }
            }}
          />
        </>
      )
    }
  }


  return (
    <Styles>
      <CustomBreadcrumb
        active="Resumen diario"
      />
      <FilterRow>
        <CategoryFilter
          value={filters.id_category_type}
          setValue={setFilters}
        />
        <AvailabilityFilter
          value={filters.id_availability_condition_type}
          setValue={setFilters}
        />
      </FilterRow>

      <TitleSection title={'Resumen Diario'} />

      <div className="mb-3">
        <div className="d-flex justify-content-between">
          <div className="rbc-toolbar">
            <span className="rbc-btn-group">
              <button type="button" onClick={() => handleMonth('PREV')}>
                <FaArrowLeft size={15}/>
              </button>
              <button type="button" onClick={() => handleMonth('TODAY')}>
                Hoy
              </button>
              {!moment(windowDate.completeDate).isSame(moment().format('YYYY-MM')) &&
                <button type="button" onClick={() => handleMonth('NEXT')}>
                  <FaArrowRight size={15}/>
                </button>
              }
            </span>
          </div>
          <Link
            to="/calendar/agenda"
            className="font-weight-bold d-none d-lg-block"
          >
            <p className='proximos-eventos'>Próximos Eventos</p>
          </Link>
          <AvailabilityConditions />
        </div>
        {renderTable()}
      </div>

      {openModal && hasRole(['permission_team-create-player-availability']) &&
        <AvailabilityForm
          openModal={openModal}
          setOpenModal={(value) => setOpenModal(value)}
          availabilityCondition={availabilityCondition}
          filters={filters}
        />
      }
    </Styles>
  )
}

export default Summary
