import React from 'react'
import AuthorizedElement from '../../../keycloak/utils/AuthorizedElement'
import PropTypes from 'prop-types'
import moment from 'moment'
import ReactToolTip from 'react-tooltip'
import { useQuery, useMutation } from '@apollo/client'
import { GET_PLAYER_DIMENSIONS } from '../../../apollo/queries'
import { DELETE_PLAYER_DIMENSION } from '../../../apollo/mutations'
import { useHistory } from 'react-router-dom'
import { FaEye, FaTrash, FaPlus } from 'react-icons/fa'
import { Button, Table } from 'react-bootstrap'
import { FilterRow, DateFilter, Loading, EmptyMessage } from '../../../components'
import { PlayerNutritionalForm } from '../Nutrition/NutritionDetail/PlayerNutritionalForm'
import { NutritionCharts } from './NutritionCharts'
import { NutritionTable } from './NutritionTable'
import { NUTRITION_DIMENSION } from './constants'
import usePlayerDimension from '../usePlayerDimension'
import Styles from './NutritionStyle'

const Nutrition = ({ idPlayer, isLegacy = true }) => {
  const history = useHistory()
  const [openModal, setOpenModal] = React.useState(false)

  const [filters, setFilters] = React.useState({
    id_player: idPlayer,
    id_dimension: NUTRITION_DIMENSION,
    start_date: new Date(moment().subtract(6, 'months')),
    end_date: new Date()
  })

  const updateCache = (cache, { data }) => {
    cache.modify({
      fields: {
        getPlayerDimensions(existingPlayerDimensions = []) {
          const newPlayerDimensions = data.getPlayerDimensions
          cache.writeQuery({
            query: GET_PLAYER_DIMENSIONS,
            data: {
              newPlayerDimensions,
              ...existingPlayerDimensions
            }
          })
        }
      }
    })
  }

  const [deletePlayerDimension] = useMutation(DELETE_PLAYER_DIMENSION, {
    update: updateCache
  })
  const { data, loading , error } = useQuery(GET_PLAYER_DIMENSIONS, {
    variables: {
      input: {
        ...filters,
        start_date: moment(filters.start_date).format('YYYY-MM-DD'),
        end_date: moment(filters.end_date).format('YYYY-MM-DD')
      }
    }
  })

  const handleDelete = (event, id) => {
    event.preventDefault()
    if (window.confirm('Esta acción es irreversible, ¿Estás seguro de borrar esta medición?')) {
      deletePlayerDimension({
        variables: {
          input: { id }
        }
      })
    }
  }

  const { dimension, jsonToMap, allMeters } = usePlayerDimension(null, null, 2)

  // eslint-disable-next-line no-constant-condition
  if (
    loading
    // || true
  ) {
    return (
      <Loading containerStyles={{ marginTop: '0' }} />
    )
  }

  if (error) {
    return (
      <div>Error :(</div>
    )
  }

  const { playerDimensions } = data.getPlayerDimensions

  return (
    <Styles>
      <div className="row">
        <div className="col d-flex flex-wrap align-items-baseline justify-content-end">
          <h5 className="mr-auto text-secondary">
            {'Historial Antropométrico'}
          </h5>
          <FilterRow>
            <DateFilter
              value={{
                start_date: filters.start_date,
                end_date: filters.end_date
              }}
              setValue={setFilters}
            />
          </FilterRow>
        </div>
        <div className='d-lg-block d-none'>
          <AuthorizedElement roles={['permission_team-create-nutritional', 'permission_team-create-nutritional-new']}>
            <Button
              className="ml-1"
              onClick={() => {
                setOpenModal(true)
              }}
            >
              <FaPlus className="mb-1 mr-2" color={'white'} />
              {'Crear Antropometría'}
            </Button>
          </AuthorizedElement>
        </div>
      </div>

      <div className='d-block d-lg-none'>
        <AuthorizedElement roles={['permission_team-create-nutritional', 'permission_team-create-nutritional-new']}>
          <Button className='mb-3' variant="primary" block
            onClick={() => {
              setOpenModal(true)
            }}
          >
            <FaPlus className="mb-1 mr-2" color={'white'} />
            {'Crear Antropometría'}
          </Button>
        </AuthorizedElement>
      </div>

      {isLegacy ? (
        <div className="row">
          <div className="col">
            <Table striped bordered hover className="table-responsive-lg" >
              <thead>
                <tr className="text-center">
                  <th className="date">Fecha</th>
                  <th>Peso Kg</th>
                  <th>% Masa Grasa</th>
                  <th>Masa Grasa Kg</th>
                  <th>% Masa Muscular</th>
                  <th>Masa Muscular Kg</th>
                  <th>Sumatoria 6 Plieges</th>
                  <th>Índice Músculo Óseo</th>
                  <th>Comentarios</th>
                  <th className="actions">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {playerDimensions.length ? (
                  playerDimensions.map(playerDimension => {
                    const metersMap = jsonToMap(playerDimension.meters)
                    return (
                      <tr key={playerDimension.id}>
                        <td className='text-center'>{moment(playerDimension.date).format('DD/MM/YYYY')}</td>
                        <td className="text-center">{metersMap.get(7) || '--'}</td>
                        <td className="text-center">{metersMap.get(14) || '--'}</td>
                        <td className="text-center">{metersMap.get(15) || '--'}</td>
                        <td className="text-center">{metersMap.get(18) || '--'}</td>
                        <td className="text-center">{metersMap.get(19) || '--'}</td>
                        <td className="text-center">{metersMap.get(11) || '--'}</td>
                        <td className="text-center">{metersMap.get(13) || '--'}</td>
                        <td className="comments">
                          {playerDimension.comments ? playerDimension.comments : 'sin comentarios'}
                        </td>
                        <td>
                          <div className=" d-flex justify-content-center">
                            <AuthorizedElement roles={['permission_team-update-nutritional', 'permission_team-update-nutritional-new']}>
                              <Button
                                variant="primary"
                                size="sm"
                                data-tip data-for="detail"
                                className="mx-1"
                                onClick={() => history.push(`/nutrition/${idPlayer}/${playerDimension.id}`)}
                              >
                                <FaEye color={'white'}/>
                                <ReactToolTip
                                  id="detail"
                                  place="top"
                                  effect="solid"
                                  type="info"
                                >
                                  {'Ver detalle'}
                                </ReactToolTip>
                              </Button>
                            </AuthorizedElement>
                            <AuthorizedElement roles={['permission_team-update-nutritional', 'permission_team-update-nutritional-new']}>
                              <Button
                                variant="danger"
                                size="sm"
                                data-tip data-for="delete"
                                className="mx-1"
                                onClick={(event) => handleDelete(event, playerDimension.id)}
                              >
                                <FaTrash color={'white'}/>
                                <ReactToolTip
                                  id="delete"
                                  place="top"
                                  effect="solid"
                                  type="info"
                                >
                                  {'Eliminar'}
                                </ReactToolTip>
                              </Button>
                            </AuthorizedElement>
                          </div>
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <EmptyMessage />
                )}
              </tbody>
            </Table>
          </div>
        </div>
      ) : (
        <>
          {/* Graficos */}
          <NutritionCharts
            meters={allMeters}
            playerDimensions={playerDimensions}
            jsonToMap={jsonToMap}
          />

          <NutritionTable
            meters={allMeters}
            playerDimensions={playerDimensions}
            jsonToMap={jsonToMap}
          />
        </>
      )}

      {openModal &&
        <PlayerNutritionalForm
          idPlayer={idPlayer}
          openModal={openModal}
          setOpenModal={(value) => setOpenModal(value)}
          dimension={dimension.data?.getDimension?.dimension}
        />
      }
    </Styles>
  )
}

Nutrition.propTypes = {
  idPlayer: PropTypes.number,
  isLegacy: PropTypes.bool
}

export default Nutrition
