import { gql } from '@apollo/client'

const CREATE_SCHEDULE = gql`
  mutation createSchedule($input: CreateScheduleInput!) {
    createSchedule(input: $input) {
      schedule {
        id_schedule_type
        id_user_profesional
        id_user_patient
        datetime
        Profesional {
          id
          name
          last_name
          UserType {
            name_user
          }
        }
        Patient {
          id
          name
          last_name
          UserType {
            name_user
          }
        }
      }
      status {
        success
        code
        message
      }
    }
  }
`

export default CREATE_SCHEDULE
