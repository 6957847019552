import { gql } from '@apollo/client'

const DELETE_EVALUATION_OBJECTIVE = gql`
  mutation deleteEvaluationObjective($input: DeleteEvaluationObjectiveInput!) {
    deleteEvaluationObjective(input: $input) {
      status {
        success
        code
        message
      }
    }
  }
`

export default DELETE_EVALUATION_OBJECTIVE
