const tenants = {
  TRICEPS: 'TRICEPS',
  ANFP: 'ANFP',
  UCATOLICA: 'UCATOLICA',
  PALESTINO: 'PALESTINO',
  COQUIMBO: 'COQUIMBO',

}
const dimensions = {
  NUTRITION: 2
}

const categories = {
  MALE_ADULT: 10,
  SUB_17: 7,
  FEMALE_ADULT: 20
}

const positionsTypes = {
  GOAL_KEEPER: 1,
  STRIKER: 13
}

const meters = {
  SUM_SIX_FOLDS: 11,
  IMO: 13,
  ADIPOSE_TISSUE: 14,
  MUSCLE_MASS: 18,
}

export {
  tenants,
  dimensions,
  categories,
  positionsTypes,
  meters,
}
