import { gql } from '@apollo/client'

const DELETE_PLAYER_DIMENSION = gql`
  mutation deletePlayerDimension($input: DeletePlayerDimensionInput!) {
    deletePlayerDimension(input: $input) {
      status {
        success
        code
        message
      }
    }
  }
`

export default DELETE_PLAYER_DIMENSION
