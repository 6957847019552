import { gql } from '@apollo/client'

const GET_USERS = gql`
  query getUsers($input: UsersInput) {
    getUsers(input: $input) {
      status {
        success
        code
        message
      }
      users {
        id
        name
        last_name
        gender
        UserType {
          id
          name_user
        }
      }
    }
  }
`

export default GET_USERS
