import React from 'react'
import ReactToolTip from 'react-tooltip'
import PropTypes from 'prop-types'


const ToolTip = ({ label, tooltipId, tooltipText, tooltipClassName, tooltipPlace }) => {
  return(
    <p
      data-tip
      data-for={tooltipId}
      className={tooltipClassName}
    >
      {label}
      <ReactToolTip
        id={tooltipId}
        place={tooltipPlace}
        effect="solid"
        type="info"
      >
        {tooltipText}
      </ReactToolTip>
    </p>
  )
}

ToolTip.propTypes= {
  label: PropTypes.string,
  tooltipText: PropTypes.string,
  tooltipPlace: PropTypes.string,
  tooltipId: PropTypes.string,
  tooltipClassName: PropTypes.string
}

export default ToolTip
