import { gql } from '@apollo/client'

const GET_EVENTS = gql`
  query getEvents($input: EventsInput!)
    {
      getEvents(input: $input) {
        events {
          id
          start_date
          end_date
          releases
          EventType {
            id
            name_event_type
          }
          CategoryType {
            id
            name_category
          }
          User {
            name
            last_name
            UserType {
              name_user
            }
          }
        }
        status {
          success
          code
          message
        }
      }
    }


`

export default GET_EVENTS
