import { gql } from '@apollo/client'

const UPDATE_PLAYER_DIMENSION = gql`
  mutation updatePlayerDimension($input: UpdatePlayerDimensionInput!) {
    updatePlayerDimension(input: $input) {
      status {
        success
        code
        message
      }
    }
  }
`

export default UPDATE_PLAYER_DIMENSION
