import { gql } from '@apollo/client'

const UPDATE_AVAILABILITY_CONDITION = gql`
  mutation updateAvailabilityCondition($input: UpdateAvailabilityConditionInput!) {
    updateAvailabilityCondition(input: $input) {
      status {
        success
        code
        message
      }
    }
  }
`

export default UPDATE_AVAILABILITY_CONDITION
