import { gql } from '@apollo/client'

const CREATE_PLAYER_DIMENSION = gql`
  mutation createPlayerDimension($input: CreatePlayerDimensionInput!) {
    createPlayerDimension(input: $input) {
      playerDimension {
        id
      }
      status {
        success
        code
        message
      }
    }
  }
`

export default CREATE_PLAYER_DIMENSION
