import React from 'react'
import PropTypes from 'prop-types'
import { CustomChart } from '../../../components'

const BiomechanicsChart = ({
  title = '',
  typeChart = 'bar',
  labels = [],
  data = [],
  datasets = []
}) => {

  const ChartData = datasets.length > 0
    ? ({
      labels,
      datasets
    })
    : ({
      labels,
      datasets: [
        {
          data,
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
          ],
          borderWidth: 1,
        },
      ]
    })

  return (
    <div className="container">
      <CustomChart
        title={title}
        type={typeChart}
        data={ChartData}
        options={{
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  stepSize: 1,
                  beginAtZero: true,
                },
              },
            ],
          },
        }}
      />
    </div>

  )
}

BiomechanicsChart.propTypes = {
  title: PropTypes.string,
  typeChart: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.array,
  datasets: PropTypes.array
}

export default BiomechanicsChart
