import moment from 'moment'

const today = moment().format('YYYY-MM-DD')

const getRows = (idPlayer, measurement, measurementTypes) => {
  let rows = {}
  let rowMeasurement = {}

  if (measurement) {
    rowMeasurement = {
      [measurement.measurement_type]: {
        id: measurement.id,
        id_player: measurement.id_player,
        measurement: measurement.value.toString(),
        date: measurement.date
      }
    }
  }

  measurementTypes.forEach((type) => {
    if (rowMeasurement[type.id]) {
      rows = {
        ...rows,
        [type.id]: rowMeasurement[type.id]
      }
    } else {
      rows = {
        ...rows,
        [type.id]: {
          id: null,
          id_player: idPlayer,
          measurement: '',
          date: measurement?.date || today,
        }
      }
    }
  })

  return rows
}

const getMeasurements = (idPlayer, data, measurementTypes) => {
  let current = []
  let history = []

  const playersMeasurements = data?.getDailyMeasurements?.playersMeasurements[0] || null
  if (playersMeasurements) {
    const measurements = playersMeasurements.DailyMeasurements.reduce((acc, measurement) => {
      const exist = acc.find(a => a.date === measurement.date)

      if (exist) {
        exist[measurement.measurement_type] = {
          id: measurement.id,
          id_player: measurement.id_player,
          measurement: measurement.value.toString(),
          date: measurement.date,
        }

        return acc
      }

      const values = getRows(idPlayer, measurement, measurementTypes)

      return [
        ...acc,
        {
          date: measurement.date,
          ...values
        }
      ]
    }, [])

    const existCurrent = measurements?.find(m => m.date === today)

    if (existCurrent) {
      current = measurements.slice(0, 1)
      history = measurements.slice(1)
    } else {
      const values = getRows(idPlayer, null, measurementTypes)
      current = [{
        date: today,
        ...values
      }]

      history = measurements
    }
  } else {
    const values = getRows(idPlayer, null, measurementTypes)
    current = [{
      date: today,
      ...values
    }]
  }

  const allMeasurements = current.concat(history)
  let labels = []

  if (allMeasurements.length) {
    labels = [allMeasurements[0].date, allMeasurements[allMeasurements.length - 1].date]
  }

  return { current, history, allMeasurements, labels }
}

export { getMeasurements }
