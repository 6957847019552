import { useKeycloak } from '@react-keycloak/web'

export default function hasRole(roles) {
  const { keycloak } = useKeycloak()

  const checkRoles = () => {
    if (keycloak && roles) {
      return roles.some(r => {
        const realm = keycloak.hasRealmRole(r)
        const resource = keycloak.hasResourceRole(r)
        return realm || resource
      })
    }
    return false
  }

  return checkRoles()
}
