import { gql } from '@apollo/client'

const UPDATE_GPS = gql`
  mutation updateGps($input: UpdateGpsInput!) {
    updateGps(input: $input) {
      status {
        success
        code
        message
      }
    }
  }
`
export default UPDATE_GPS
