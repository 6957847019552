import { gql } from '@apollo/client'

const GET_KINESIC_TREATMENTS = gql`
  query getKinesicTreatments($input: KinesicTreatmentInput!) {
    getKinesicTreatments(input: $input) {
      kinesicTreatments {
        id
        text
        date
      }
      status {
        success
        code
        message
      }
    }
  }
`

export default GET_KINESIC_TREATMENTS

