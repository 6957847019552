import React from 'react'
import { useQuery } from '@apollo/client'
import { GET_KINESIC_BLOCKS } from '../../../apollo/queries/'
import { Loading } from '../../../components'
import KinesicEvaluationProvider from './KinesicEvaluationContext'
import KinesicBlock from './KinesicBlock'
import KinesicEvaluationFilters from './KinesicEvaluationFilters'
import Styles from './KinesicEvaluationsStyle'

const KinesicEvaluations = () => {
  const { data, loading, error } = useQuery(GET_KINESIC_BLOCKS)

  if (error) {
    return (
      <div>Error :(</div>
    )
  }

  if (loading) {
    return(
      <Loading/>
    )
  }

  return (
    <KinesicEvaluationProvider>
      <Styles>
        <div className='d-flex flex-wrap align-items-baseline justify-content-end'>
          <h5 className="mr-auto text-secondary">
                Evaluaciones Kinésicas
          </h5>
          <KinesicEvaluationFilters />
        </div>
        <div className="row mb-3">
          <div className="col">
            {data.getKinesicBlocks.kinesicBlocks.map(kinesicBlock =>
              <KinesicBlock
                key={kinesicBlock.id}
                kinesicBlock={kinesicBlock}
              />
            )}
          </div>
        </div>
      </Styles>
    </KinesicEvaluationProvider>
  )
}

export default KinesicEvaluations
