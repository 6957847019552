import { gql } from '@apollo/client'

const ACCEPT_TERMS_AND_CONDITIONS = gql`
  mutation acceptTermsConditions {
    acceptTermsConditions {
      status {
        success
        code
        message
      }
    }
  }
`
export default ACCEPT_TERMS_AND_CONDITIONS
