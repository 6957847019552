import React from 'react'
import PropTypes from 'prop-types'
import ReactToolTip from 'react-tooltip'
import AuthorizedElement from '../../../../keycloak/utils/AuthorizedElement'
import { Button } from 'react-bootstrap'
import { FaEye, FaTrash, FaPen } from 'react-icons/fa'
import { useParams, useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { GET_PLAYER_KINESIC_EVALUATIONS } from '../../../../apollo/queries'
import { DELETE_PLAYER_KINESIC_EVALUATION } from '../../../../apollo/mutations'
import moment from 'moment'

const KinesicBlockRow = ({
  playerKinesicEvaluation,
  kinesicBlockMeasures,
  kinesicBlockid,
  setAction,
  setOpenModal,
  setFormValues
}) => {
  let { id_player } = useParams()
  let history = useHistory()

  const parseValues = JSON.parse(playerKinesicEvaluation.values)

  const updateCache = (cache, { data }) => {
    cache.modify({
      fields: {
        getPlayerKinesicEvaluations(existingPlayerKinesicEvaluations = []) {
          const newPlayerKinesicEvaluation = data.getPlayerKinesicEvaluations
          cache.writeQuery({
            query: GET_PLAYER_KINESIC_EVALUATIONS,
            data: {
              newPlayerKinesicEvaluation,
              ...existingPlayerKinesicEvaluations
            }
          })
        }
      }
    })
  }

  const [deleteKinesicEvaluation] = useMutation(DELETE_PLAYER_KINESIC_EVALUATION, {
    update: updateCache
  })

  const handleDelete = (event, id) => {
    event.preventDefault()
    if (window.confirm('Esta acción es irreversible, ¿Estás seguro de borrar esta medición?')) {
      deleteKinesicEvaluation({
        variables: {
          input: { id }
        }
      })
    }
  }

  return(
    <tr>
      <td>{moment(playerKinesicEvaluation.date).format('DD/MM/YYYY')}</td>
      {kinesicBlockMeasures.map(kinesicBlockMeasure =>
        kinesicBlockMeasure.KinesicMeasureOptions.map(KinesicMeasureOptions =>
          <td key= {KinesicMeasureOptions.id}>
            {parseValues[KinesicMeasureOptions.id] || '-'}
          </td>
        )
      )}
      <td>
        <div className="d-flex justify-content-center">
          <AuthorizedElement roles={['permission_team-show-player-kinesic-evaluation']}>
            <Button
              variant="primary"
              size="sm"
              data-tip data-for="detail"
              className="mx-1"
              onClick={() => history.push(`/player/${id_player}/kinesic-block/${kinesicBlockid}/kinesic-body-zone/${kinesicBlockMeasures[0].id_kinesic_body_zone}/kinesic-evaluation/${playerKinesicEvaluation.id}`)}
            >
              <FaEye color={'white'}/>
              <ReactToolTip
                id="detail"
                place="top"
                effect="solid"
                type="info"
              >
                {'Ver detalle'}
              </ReactToolTip>
            </Button>
          </AuthorizedElement>
          <AuthorizedElement roles={['permission_team-update-player-kinesic-evaluation']}>
            <Button
              variant="warning"
              size="sm"
              data-tip data-for="edit"
              className="mx-1"
              onClick={() => {
                setFormValues({
                  ...parseValues,
                  id: playerKinesicEvaluation.id,
                  date: new Date(`${playerKinesicEvaluation.date} `)
                })
                setAction('EDIT')
                setOpenModal(true)
              }}
            >
              <FaPen color={'white'}/>
              <ReactToolTip
                id="edit"
                place="top"
                effect="solid"
                type="info"
              >
                {'Editar'}
              </ReactToolTip>
            </Button>
          </AuthorizedElement>
          <AuthorizedElement roles={['permission_team-delete-player-kinesic-evaluation']}>
            <Button
              variant="danger"
              size="sm"
              data-tip data-for="delete"
              className="mx-1"
              onClick={(event) => handleDelete(event, playerKinesicEvaluation.id)}
            >
              <FaTrash color={'white'}/>
              <ReactToolTip
                id="delete"
                place="top"
                effect="solid"
                type="info"
              >
                {'Eliminar'}
              </ReactToolTip>
            </Button>
          </AuthorizedElement>
        </div>
      </td>
    </tr>
  )
}

KinesicBlockRow.propTypes = {
  playerKinesicEvaluation: PropTypes.shape({
    id: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    values: PropTypes.string.isRequired
  }).isRequired,
  kinesicBlockMeasures: PropTypes.array.isRequired,
  kinesicBlockid: PropTypes.string.isRequired,
  setAction: PropTypes.func.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  setFormValues: PropTypes.func.isRequired
}

export default KinesicBlockRow

