import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { Form } from 'react-bootstrap'
import { GET_AVAILABILITY_CONDITIONS_TYPES } from '../../../apollo/queries'
import Styles from '../../../views/Summary/SummaryStyle'

const AvailabilityFilter = ({ value, setValue, modal = false }) => {
  const availabilityTypesQuery = useQuery(GET_AVAILABILITY_CONDITIONS_TYPES)

  const handleValue = (filter) => {
    setValue((prevState)=>({
      ...prevState,
      id_availability_condition_type: parseInt(filter,10)
    }))
  }

  return (
    <>
      <Form.Group controlId="availability-filter" className={!modal && 'ml-3'}>
        {modal &&
          <Styles className="d-flex" style={{
            height: '100%',
            padding: '8px',
            justifyContent: 'space-between',
            flexWrap: 'wrap'
          }}>
            <Form.Label>Condición</Form.Label>
            <div className="d-flex">
              <div
                className={`btn-circle color1 ${value === 1 && 'selected'}`}
                onClick={() => handleValue(1)}
              >
              </div>

              <div
                className={`btn-circle color2 ${value === 2 && 'selected'}`}
                onClick={() => handleValue(2)}
              >
              </div>

              <div
                className={`btn-circle color3 ${value === 3 && 'selected'}`}
                onClick={() => handleValue(3)}
              >
              </div>

              <div
                className={`btn-circle color4 ${value === 4 && 'selected'}`}
                onClick={() => handleValue(4)}
              >
              </div>

              <div
                className={`btn-circle color5 ${value === 5 && 'selected'}`}
                onClick={() => handleValue(5)}
              >
              </div>

              <div
                className={`btn-circle color6 ${value === 6 && 'selected'}`}
                onClick={() => handleValue(6)}
              >
              </div>

              <div
                className={`btn-circle color7 ${value === 7 && 'selected'}`}
                onClick={() => handleValue(7)}
              >
              </div>

              <div
                className={`btn-circle color8 ${value === 8 && 'selected'}`}
                onClick={() => handleValue(8)}
              >
              </div>

              <div
                className={`btn-circle color9 ${value === 9 && 'selected'}`}
                onClick={() => handleValue(9)}
              >
              </div>

              <div
                className={`btn-circle color20 ${value === 20 && 'selected'}`}
                onClick={() => handleValue(20)}
              >
              </div>

              <div
                className={`btn-circle color21 ${value === 21 && 'selected'}`}
                onClick={() => handleValue(21)}
              >
              </div>
            </div>
          </Styles>
        }
        <Form.Control
          as="select"
          custom
          value={value || ''}
          onChange={(e) => handleValue(e.target.value)}
        >
          {availabilityTypesQuery.loading
            ? <option>Cargando...</option>
            : <React.Fragment>
              <option>Todas las condiciones</option>
              {
                availabilityTypesQuery.data.getAvailabilityConditionsTypes
                  .availabilityConditionsTypes.map(
                    (availabilityType, key) =>
                      <option key={key} value={availabilityType.id}>
                        {availabilityType.name_availability_condition_type}
                      </option>
                  )
              }
            </React.Fragment>
          }
        </Form.Control>
      </Form.Group>
    </>
  )
}

AvailabilityFilter.propTypes = {
  value: PropTypes.number,
  setValue: PropTypes.func.isRequired,
  modal: PropTypes.bool
}

export default AvailabilityFilter
