import { gql } from '@apollo/client'

const GET_DASHBOARD_SUB_DIAGNOSTICS = gql`
  query getDashboardSubDiagnostics($input: DashboardSubDiagnosticInput) {
    getDashboardSubDiagnostics(input: $input) {
      status {
        success
        code
        message
      }
      dashboardData {
        bodyZones {
          x
          y
        }
        skeletalMuscles {
          x
          y
        }
        groupers {
          x
          y
        }
      }
    }
  }
`

export default GET_DASHBOARD_SUB_DIAGNOSTICS
