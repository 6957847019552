import { gql } from '@apollo/client'

const CREATE_EXAM = gql`
  mutation createExam($input: CreateExamInput!) {
    createExam(input: $input) {
      exam {
        id
      }
      status {
        success
        code
        message
      }
    }
  }
`

export default CREATE_EXAM
