import { gql } from '@apollo/client'

const UPDATE_PLAYER_KINESIC_EVALUATION = gql`
  mutation updatePlayerKinesicEvaluation($input: UpdatePlayerKinesicEvaluationInput!) {
    updatePlayerKinesicEvaluation(input: $input) {
      status {
          success
          code
          message
      }
    }
  }
`

export default UPDATE_PLAYER_KINESIC_EVALUATION
