import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { Form } from 'react-bootstrap'
import { CREATE_SINGLE_KINESIC_TREATMENT } from '../../../apollo/mutations'
import hasRole from '../../../keycloak/utils/hasRole'
import Styles from './KinesicTreatmentTeamInputStyle'

const KinesicTreatmentTeamInput = ({ cellInfo, column }) => {
  const [cheched, setChecked] = useState(cellInfo.cellValue)
  const [createSingleKinesicTreatment, mutationResults] = useMutation(CREATE_SINGLE_KINESIC_TREATMENT)

  const handleChange = () => {
    createSingleKinesicTreatment({
      variables: {
        input: {
          id_player: parseInt(cellInfo.id_player, 10),
          id_diagnostic: cellInfo.id_diagnostic,
          date: cellInfo.date,
          meter_id: parseInt(column.id, 10),
          meter_value: !cheched
        }
      }
    }).then(({ data }) => {
      if (data.createSingleKinesicTreatment.status.success) {
        setChecked(!cheched)
      }
    })
  }

  if (mutationResults.error) {
    return <div>Error :(</div>
  }

  if (mutationResults.loading) {
    return 'Cargando...'
  }

  return(
    <Styles>
      <Form>
        <Form.Check
          type="checkbox"
          className="checkbox-center"
          id={`${column.id}-${cellInfo.id_player}-${cellInfo.date}`}
          checked={cheched}
          onChange={(e) => handleChange(e.target.value)}
          disabled={!hasRole(['permission_team-create-kinesic-dimension'])}
        />
      </Form>
    </Styles>
  )
}

KinesicTreatmentTeamInput.propTypes = {
  cellInfo: PropTypes.shape({
    id_player: PropTypes.string,
    date: PropTypes.string,
    id_diagnostic: PropTypes.number,
    cellValue: PropTypes.string,
  }),
  column: PropTypes.shape({
    id: PropTypes.string
  })
}

export default KinesicTreatmentTeamInput
