import { gql } from '@apollo/client'

const DELETE_EXAM_IMAGE = gql`
  mutation deleteExamImage($input: DeleteExamInput!) {
    deleteExamImage(input: $input) {
      examImage {
        id
      }
      status {
        success
        code
        message
      }
    }
  }
`

export default DELETE_EXAM_IMAGE
