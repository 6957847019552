import { gql } from '@apollo/client'

const GET_AUTOREPORT = gql`
  query getAutoreport($input: AutoreportInput!) {
    getAutoreport(input: $input) {
      autoreport {
        date
        id_wellness
        id_biomedic
        meters {
          id
          id_meter
          id_dimension_test
          name
          value
        }
        aritmeticValues {
          idDimentsionTest
          totalSumWellness
          averageWellness
          multiplicationPSE
        }
      }
      allMeters {
        id
        name
        order
        id_meter_type
        id_dimension_test
        MeterOptions {
          id
          name
          value
        }
      }
      status {
        success
        code
        message
      }
    }
  }
`

export default GET_AUTOREPORT
