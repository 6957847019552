import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { DayToolbar } from '../../DayToolbar'
import { DatePickerInput } from '../../Form/DatePickerInput'
import { CategoryFilter } from '../CategoryFilter'
import { FilterRow } from '../FilterRow'

const TeamTableFilter = ({ children, filters, setFilters, extras }) => {
  const initialWindow = React.useMemo(() => ({
    name: moment().format('LL'),
    completeDate: moment().format('YYYY-MM-DD')
  }), [])

  const [windowDate, setWindowDate] = React.useState(initialWindow)

  React.useEffect(() => {
    setFilters((prevState) => ({
      ...prevState,
      start_date: moment(windowDate.completeDate).startOf('day').format('YYYY-MM-DD'),
      end_date: moment(windowDate.completeDate).startOf('day').format('YYYY-MM-DD')
    }))
  }, [windowDate])

  const setFieldValue = React.useCallback((name, value) => {
    if (name === 'start_date') {
      setWindowDate({
        name: moment(value).format('LL'),
        completeDate: moment(value).format('YYYY-MM-DD')
      })
      setFilters((current) => ({
        ...current,
        start_date: moment(value).format('YYYY-MM-DD'),
        end_date: moment(value).format('YYYY-MM-DD')
      }))
    } else {
      setFilters((current) => ({
        ...current,
        [name]: value
      }))
    }
  }, [])

  return (
    <>
      <FilterRow>
        <CategoryFilter
          value={filters.id_category_type}
          setValue={setFilters}
        />
        {children || <></>}
        <div className="dateFilter">
          <DatePickerInput
            label=""
            width="ml-2"
            className="ml-2"
            name="start_date"
            value={new Date(moment(`${filters.start_date} 12:00`))}
            setFieldValue={setFieldValue}
            handleChange={(e) => setFieldValue('start_date', e.target.value)}
          />
        </div>
      </FilterRow>
      <div className="d-flex justify-content-between align-items-baseline">
        <DayToolbar
          initialWindow={initialWindow}
          windowDate={windowDate}
          setWindowDate={setWindowDate}
          setFilters={setFilters}
        />
        {extras}
      </div>
    </>
  )
}

TeamTableFilter.propTypes = {
  children: PropTypes.node,
  extras: PropTypes.node,
  filters: PropTypes.shape({
    id_category_type: PropTypes.number.isRequired,
    start_date: PropTypes.string.isRequired,
    end_date: PropTypes.string.isRequired,
  }),
  setFilters: PropTypes.func.isRequired
}

export default TeamTableFilter
