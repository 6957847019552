import { gql } from '@apollo/client'

const CREATE_DIAGNOSTIC_MEDICINE = gql`
  mutation createDiagnosticMedicine($input: CreateDiagnosticMedicineInput!) {
    createDiagnosticMedicine(input: $input) {
      status {
        success
        code
        message
      }
    }
  }
`

export default CREATE_DIAGNOSTIC_MEDICINE
